import { useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import get from 'lodash/get';
import {
  GET_ALL_ACCESSIBLE_BUSINESSES,
  GET_ALL_ACCESSIBLE_CONGLOMERATES,
  GET_ALL_ACCESSIBLE_CORPORATIONS,
  GET_ALL_ACCESSIBLE_ENTERPRISES,
  GET_AVAILABLE_SESSION_TYPES,
  GET_CATALOG_DIAGNOSTICS,
  GET_CORPORATION_LOCATIONS,
  GET_MY_BOOKABLE_SESSION_TYPES,
  GET_MY_CALENDARS,
  GET_MY_CATEGORIES,
  GET_MY_LOCATIONS,
  GET_MY_LOCATION_OVERVIEW,
  GET_MY_PROMOS,
  GET_MY_SESSION_TYPES,
  GET_MY_STAFF,
  GET_ORGANIZATION_TEMPLATES,
  GET_ORGANIZATION_TEMPLATE_BY_ID,
  GET_PROMO_BY_ID,
  GET_SERVICE_SETTINGS,
} from '../queries/enterprise';

export const useMyLocations = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_LOCATIONS, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  const locations = get(data, 'getMyLocations', []);

  return {
    error,
    loading,
    refetch,
    locations,
  };
};

export const useMyLocationOverview = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_LOCATION_OVERVIEW, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  const overview = get(data, 'getMyLocationOverview', null);

  return {
    error,
    loading,
    refetch,
    overview,
  };
};

export const useMyCalendars = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_CALENDARS, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  const calendars = get(data, 'getMyCalendars', []);

  return {
    error,
    loading,
    refetch,
    calendars,
  };
};

export const useMyCategories = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_CATEGORIES, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  const categories = sortBy(get(data, 'getMyCategories', []), (c) => c.name);

  return {
    error,
    loading,
    refetch,
    categories,
  };
};

export const useMySessionTypes = ({ skipCondition, onlyBookable = false }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_SESSION_TYPES, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
    variables: {
      onlyBookable,
    },
  });

  const sessionTypes = get(data, 'getMySessionTypes', null);

  return {
    error,
    loading,
    refetch,
    sessionTypes,
  };
};

export const useMyBookableSessionTypes = ({ skipCondition = null }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_MY_BOOKABLE_SESSION_TYPES,
    {
      fetchPolicy: 'cache-first',
      skip: skipCondition,
    },
  );

  return {
    error,
    loading,
    refetch,
    data: get(data, 'getMyBookableSessionTypes', null),
  };
};

export const useMyStaff = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_STAFF, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  return {
    error,
    loading,
    refetch,
    staff: get(data, 'getMyStaff', null),
  };
};

export const useMyPromos = ({ locationId }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_PROMOS, {
    fetchPolicy: 'cache-first',
    skip: !locationId,
  });

  return {
    error,
    loading,
    refetch,
    promos: get(data, 'getMyPromos', null),
  };
};

export const usePromoById = ({ id, editing }) => {
  const { data, error, loading, refetch } = useQuery(GET_PROMO_BY_ID, {
    fetchPolicy: 'cache-first',
    skip: !id,
    variables: {
      id,
      editing,
    },
  });

  return {
    error,
    loading,
    refetch,
    promo: get(data, 'getPromoById', null),
  };
};

export const useAllEnterprises = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_ALL_ACCESSIBLE_ENTERPRISES,
    {
      fetchPolicy: 'network-only',
      skip: skipCondition,
    },
  );

  const enterprises = get(data, 'getAllAccessibleEnterprises', null);
  const sortedEnterprises = enterprises
    ? sortBy(enterprises, (e) => [
        e.corporation?.toLowerCase() || '',
        e.business ? e.business?.toLowerCase() : '',
        e.location.toLowerCase(),
      ])
    : null;

  return {
    error,
    loading,
    refetch,
    enterprises: sortedEnterprises,
  };
};

export const useAllConglomerates = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_ALL_ACCESSIBLE_CONGLOMERATES,
    {
      fetchPolicy: 'network-only',
      skip: skipCondition,
    },
  );

  const conglomerates = get(data, 'getAllAccessibleConglomerates', null);
  const sortedConglomerates = conglomerates
    ? sortBy(conglomerates, (c) => c.name)
    : null;

  return {
    error,
    loading,
    refetch,
    conglomerates: sortedConglomerates,
  };
};

export const useAllCorporations = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_ALL_ACCESSIBLE_CORPORATIONS,
    {
      fetchPolicy: 'network-only',
      skip: skipCondition,
    },
  );

  const corporations = get(data, 'getAllAccessibleCorporations', null);
  const sortedCorporations = corporations
    ? sortBy(corporations, (c) => c.name)
    : null;

  return {
    error,
    loading,
    refetch,
    corporations: sortedCorporations,
  };
};

export const useAllBusinesses = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_ALL_ACCESSIBLE_BUSINESSES,
    {
      fetchPolicy: 'network-only',
      skip: skipCondition,
    },
  );

  const businesses = get(data, 'getAllAccessibleBusinesses', null);
  const sortedBusinesses = businesses
    ? sortBy(businesses, (c) => c.name)
    : null;

  return {
    error,
    loading,
    refetch,
    businesses: sortedBusinesses,
  };
};

export const useServiceSettings = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_SERVICE_SETTINGS, {
    skip: skipCondition,
    fetchPolicy: 'cache-first',
  });

  const serviceSettings = get(data, 'getServiceSettings', null);

  return {
    error,
    loading,
    refetch,
    serviceSettings,
  };
};

export const useAvailableSessionTypes = ({ asCatalog }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_AVAILABLE_SESSION_TYPES,
    {
      fetchPolicy: 'cache-first',
      variables: {
        asCatalog,
      },
    },
  );

  const sessionTypes = get(data, 'getAvailableSessionTypes', null);

  return {
    error,
    loading,
    refetch,
    sessionTypes,
  };
};

export const useCatalogDiagnostics = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_CATALOG_DIAGNOSTICS, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  const diagnostics = get(data, 'getCatalogDiagnostics', []);

  return {
    error,
    loading,
    refetch,
    data: diagnostics,
  };
};

export const useCorporationLocations = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_CORPORATION_LOCATIONS,
    {
      fetchPolicy: 'cache-first',
      skip: skipCondition,
    },
  );

  const locations = get(data, 'getCorporationLocations', null);

  return {
    error,
    loading,
    refetch,
    locations,
  };
};

export const useOrganizationTemplates = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_ORGANIZATION_TEMPLATES,
    {
      fetchPolicy: 'cache-first',
      skip: skipCondition,
    },
  );

  const templates = get(data, 'getOrganizationTemplates', null);

  return {
    error,
    loading,
    refetch,
    templates,
  };
};

export const useOrganizationTemplateById = ({ id, skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_ORGANIZATION_TEMPLATE_BY_ID,
    {
      fetchPolicy: 'cache-first',
      skip: skipCondition,
      variables: {
        id,
      },
    },
  );

  const template = get(data, 'getOrganizationTemplateById', null);

  return {
    error,
    loading,
    refetch,
    template,
  };
};
