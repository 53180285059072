import React, { useState } from 'react';
import {
  CenteredDivWithLargerGap,
  ExtraSmallPrimaryButton,
  FullWidthCenteredDivWithGap,
  TinyText,
  WhiteTinyText,
} from '../../../styles/shared-styled-components';
import { endKey, startKey } from '../../../utils/constants';
import { getFormattedDateString } from '../../../utils/date';
import PopperMenu from '../../PopperMenu';
import {
  MenuCalendar,
  RangeField,
  RangeFieldContainer,
  ResetFilterIcon,
  ResetFilterIconContainer,
} from './styled';

const RangePopperMenuCalendar = ({
  start,
  end,
  onSave,
  onReset,
  open,
  anchorElement,
  onClose,
  range = true,
  placement = 'bottom',
}) => {
  const [fieldEditing, setFieldEditing] = useState(startKey);
  const [stagingStart, setStagingStart] = useState(start);
  const [stagingEnd, setStagingEnd] = useState(end);

  const modifiers =
    placement === 'bottom'
      ? {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        }
      : {
          name: 'offset',
          options: {
            offset: [-300, -440],
          },
        };

  const onChange = (updatedValue) => {
    if (range) {
      if (fieldEditing === startKey) {
        setStagingStart(updatedValue);
        setFieldEditing(endKey);
      } else {
        setStagingEnd(updatedValue);
      }
    } else {
      setStagingStart(updatedValue);
    }
  };

  const handleClose = () => {
    setStagingStart(start);
    setStagingEnd(end);
    setFieldEditing(startKey);
    onClose();
  };

  const handleReset = () => {
    handleClose();
    onReset();
  };

  const startLabel = stagingStart
    ? getFormattedDateString(stagingStart)
    : 'Start';
  const endLabel = stagingEnd ? getFormattedDateString(stagingEnd) : 'Present';
  let dateLabel;
  let isInvalid = false;
  if (range) {
    if (!stagingStart && !stagingEnd) {
      dateLabel = 'All-Time';
    } else {
      dateLabel = `${startLabel} - ${endLabel}`;
      if (stagingStart && stagingEnd) {
        isInvalid = stagingStart > stagingEnd;
      }
    }
  } else {
    dateLabel = startLabel;
  }

  return (
    <PopperMenu
      open={open}
      anchorElement={anchorElement}
      onClose={handleClose}
      variant='offset'
      placement={placement}
      modifiers={modifiers}
      largeMaxHeight={true}
      removeScroll={true}
      largePaperHeight
    >
      {range ? (
        <>
          <FullWidthCenteredDivWithGap relativePosition>
            <RangeFieldContainer position={startKey}>
              <RangeField
                selected={fieldEditing === startKey}
                onClick={() => setFieldEditing(startKey)}
                position={startKey}
              >
                {fieldEditing === startKey ? (
                  <WhiteTinyText>Start</WhiteTinyText>
                ) : (
                  <TinyText>Start</TinyText>
                )}
              </RangeField>
              <RangeField
                selected={fieldEditing === endKey}
                onClick={() => setFieldEditing(endKey)}
                position={endKey}
              >
                {fieldEditing === endKey ? (
                  <WhiteTinyText>End</WhiteTinyText>
                ) : (
                  <TinyText>End</TinyText>
                )}
              </RangeField>
            </RangeFieldContainer>
            <ResetFilterIconContainer onClick={handleReset}>
              <ResetFilterIcon />
            </ResetFilterIconContainer>
          </FullWidthCenteredDivWithGap>
          <MenuCalendar
            onChange={onChange}
            value={fieldEditing === startKey ? stagingStart : stagingEnd}
            locale='en-US'
            firstDay={1}
          />
        </>
      ) : (
        <MenuCalendar
          onChange={onChange}
          value={fieldEditing === startKey ? stagingStart : stagingEnd}
          locale='en-US'
          firstDay={1}
        />
      )}
      <CenteredDivWithLargerGap topMargin={-45}>
        {dateLabel && <TinyText error={isInvalid}>{dateLabel}</TinyText>}
        {isInvalid ? (
          <ExtraSmallPrimaryButton
            largePadding
            disabled
          >
            Invalid filter selected
          </ExtraSmallPrimaryButton>
        ) : (
          <ExtraSmallPrimaryButton
            largePadding
            onClick={() => onSave(stagingStart, stagingEnd)}
          >
            Apply filter
          </ExtraSmallPrimaryButton>
        )}
      </CenteredDivWithLargerGap>
    </PopperMenu>
  );
};

export default RangePopperMenuCalendar;
