import styled from 'styled-components';

export const CalendarSection = styled.div`
  border: 1px solid ${({ theme }) => theme.color.muiInput};
  border-radius: 10px;
  max-height: calc(100dvh - 200px);
  overflow: hidden;
  padding: 3px;
  padding-bottom: 0px;
  margin-top: -30px;
`;
