import React from 'react';
import { LargeTinyText } from '../../../styles/shared-styled-components';
import {
  baseOptions,
  basePalette,
  ChartContainer,
  ChartTitleContainer,
  StyledChart,
} from '../shared-dashboard-styles';

const StackedBarChart = ({ data, series, fills, title }) => {
  const options = {
    ...baseOptions,
    theme: {
      palette: {
        ...basePalette,
        fills: fills || basePalette.fills,
      },
    },
    data,
    series,
  };

  return (
    <ChartContainer>
      <StyledChart options={options} />
      <ChartTitleContainer left>
        <LargeTinyText>{title}</LargeTinyText>
      </ChartTitleContainer>
    </ChartContainer>
  );
};

export default StackedBarChart;
