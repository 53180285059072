import React, { useContext } from 'react';
import { useContactById } from '../../../api/hooks/client';
import {
  ColumnCenteredDiv,
  ExtraSmallPrimaryCopyIcon,
  MediumDarkEssText,
  MediumNoDataIcon,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import {
  clientContactType,
  femaleKey,
  internalContactType,
  leadContactType,
  maleKey,
  membershipStatusLabelMap,
} from '../../../utils/constants';
import { getFormattedDateString } from '../../../utils/date';
import { formatPhoneNumber } from '../../../utils/numbers';
import { copyTextToClipboard } from '../../../utils/string';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../LoadingIndicator';
import {
  CloseDrawerIcon,
  CloseDrawerIconContainer,
  CloseDrawerIconSection,
  DrawerContainer,
  DrawerContentContainer,
  NameContainer,
  NameText,
  SubfieldContainer,
  SubfieldTitleText,
  SubfieldValueText,
  SubfieldsSection,
} from './styled';

const ClientProfileDrawer = ({ isOpen, onClose, clientId }) => {
  const { inDemoMode } = useContext(BaseContext);

  const isInternalChat = clientId === internalContactType;
  const { client, loading } = useContactById({
    id: clientId,
    skipCondition: isInternalChat,
  });

  const clientMatches =
    isInternalChat ||
    (client?.id && (client.id === clientId || client.leadId === clientId));

  console.log('client', client);

  return (
    isOpen && (
      <DrawerContainer>
        <DrawerContentContainer>
          <CloseDrawerIconSection>
            <CloseDrawerIconContainer onClick={onClose}>
              <CloseDrawerIcon />
            </CloseDrawerIconContainer>
          </CloseDrawerIconSection>
          {loading ? (
            <LoadingIndicator
              fullScreen
              size={40}
            />
          ) : isInternalChat ? (
            <>
              <NameContainer>
                <NameText>Internal Chat</NameText>
              </NameContainer>
              <SubfieldsSection>
                <SubfieldContainer>
                  <SubfieldTitleText>
                    This is where your agent will contact your business, using
                    the numbers provided in the 'Contact' training module.
                    <br></br>
                    <br></br>
                    Your agent will contact you about specific chat events,
                    which can also be customized in the same module.
                  </SubfieldTitleText>
                </SubfieldContainer>
              </SubfieldsSection>
            </>
          ) : client && clientMatches ? (
            <>
              <NameContainer>
                <NameText>Profile</NameText>
                <Tooltip
                  title={
                    <TooltipTitleText>
                      Copy ID '{clientId.slice(0, 5)}'
                    </TooltipTitleText>
                  }
                >
                  <ExtraSmallPrimaryCopyIcon
                    onClick={() => copyTextToClipboard(clientId)}
                  />
                </Tooltip>
              </NameContainer>
              <SubfieldsSection>
                <SubfieldContainer>
                  <SubfieldTitleText>Type:</SubfieldTitleText>
                  <SubfieldValueText>
                    {client.type === leadContactType ? `Lead` : 'Client'}
                  </SubfieldValueText>
                </SubfieldContainer>
                <SubfieldContainer>
                  <SubfieldTitleText>Gender:</SubfieldTitleText>
                  <SubfieldValueText>
                    {client.gender === femaleKey
                      ? `Female`
                      : client.gender === maleKey
                      ? 'Male'
                      : 'Unknown'}
                  </SubfieldValueText>
                </SubfieldContainer>
                {client.type === clientContactType && (
                  <>
                    <SubfieldContainer>
                      <SubfieldTitleText>Upcoming visits:</SubfieldTitleText>
                      <SubfieldValueText>
                        {client.numUpcomingVisits
                          ? `${client.numUpcomingVisits}, next one in ${client.daysUntilNextUpcomingVisit} days`
                          : 'None'}
                      </SubfieldValueText>
                    </SubfieldContainer>
                    <SubfieldContainer>
                      <SubfieldTitleText>Number of visits:</SubfieldTitleText>
                      <SubfieldValueText>
                        {client.numVisits || 0}
                      </SubfieldValueText>
                    </SubfieldContainer>
                    <SubfieldContainer>
                      <SubfieldTitleText>First visit:</SubfieldTitleText>
                      <SubfieldValueText>
                        {client.firstAppointmentDate
                          ? getFormattedDateString(
                              new Date(client.firstAppointmentDate),
                            )
                          : 'None'}
                      </SubfieldValueText>
                    </SubfieldContainer>
                    <SubfieldContainer>
                      <SubfieldTitleText>Last visit:</SubfieldTitleText>
                      <SubfieldValueText>
                        {client.lastVisit ? (
                          <span>
                            {getFormattedDateString(new Date(client.lastVisit))}
                            <br></br>Received{' '}
                            {client.lastVisitSessionTypeNames.join(',') ||
                              'Unknown service'}{' '}
                            with{' '}
                            {inDemoMode
                              ? '*'
                              : client.lastVisitStaffName ||
                                'unknown staff member'}
                          </span>
                        ) : (
                          `None`
                        )}
                      </SubfieldValueText>
                    </SubfieldContainer>
                    <SubfieldContainer>
                      <SubfieldTitleText>Membership:</SubfieldTitleText>
                      <SubfieldValueText>
                        {membershipStatusLabelMap[client.memberStatus]}
                      </SubfieldValueText>
                    </SubfieldContainer>
                  </>
                )}
                <SubfieldContainer>
                  <SubfieldTitleText>Phone:</SubfieldTitleText>
                  <SubfieldValueText>
                    {inDemoMode ? '***' : formatPhoneNumber(client.phone)}
                  </SubfieldValueText>
                </SubfieldContainer>
                <SubfieldContainer>
                  <SubfieldTitleText>Email:</SubfieldTitleText>
                  <SubfieldValueText>
                    {inDemoMode ? '***' : client.email}
                  </SubfieldValueText>
                </SubfieldContainer>
              </SubfieldsSection>
            </>
          ) : !client && clientId ? (
            <ColumnCenteredDiv>
              <MediumDarkEssText>No contact found</MediumDarkEssText>
              <MediumNoDataIcon />
            </ColumnCenteredDiv>
          ) : (
            <></>
          )}
        </DrawerContentContainer>
      </DrawerContainer>
    )
  );
};

export default ClientProfileDrawer;
