import React from 'react';
import {
  LightDarkTinyText,
  MediumDarkTinyText,
} from '../../../styles/shared-styled-components';
import { ComponentContainer, Container, MinusIcon, PlusIcon } from './styled';

const QuantitySelector = ({ value, onChange, step = 1 }) => {
  return (
    <Container>
      <ComponentContainer>
        <MinusIcon
          onClick={() => {
            if (value) {
              onChange(value - step);
            }
          }}
        />
      </ComponentContainer>
      <ComponentContainer>
        {!value ? (
          <LightDarkTinyText>{value}</LightDarkTinyText>
        ) : (
          <MediumDarkTinyText>{value}</MediumDarkTinyText>
        )}
      </ComponentContainer>
      <ComponentContainer>
        <PlusIcon onClick={() => onChange(value + step)} />
      </ComponentContainer>
    </Container>
  );
};

export default QuantitySelector;
