import ReactCalendar from 'react-calendar';
import styled, { css } from 'styled-components';
import {
  CancelIcon,
  CenterFlexStyle,
  CenteredDiv,
  EssIconSize,
} from '../../../styles/shared-styled-components';
import { startKey } from '../../../utils/constants';

export const CalendarFixedMaxWidth = 965;

export const StyledCalendar = styled(ReactCalendar).attrs((props) => ({
  tileClassName: ({ date }) =>
    props.selectedDates?.includes(new Date(date).toISOString().slice(0, 10))
      ? 'react-calendar__tile--active'
      : 'tile',
  showOtherMonthDays: false,
}))`
  font-family: ${({ theme }) => theme.typography.baseFont};
  font-size: ${({ theme }) => theme.typography.essFontSize};
  color: ${({ theme }) => theme.color.primary};

  ${(props) =>
    !props.hideBorder &&
    css`
      padding-bottom: 25px !important;
      border-radius: 10px;
      border-bottom: 1px solid ${({ theme }) => theme.color.muiInput};
      border-left: 1px solid ${({ theme }) => theme.color.muiInput};
      border-right: 1px solid ${({ theme }) => theme.color.muiInput};
    `}

  background-color: white;

  .tile {
    padding: 15px 8px;
    font-family: ${({ theme }) => theme.typography.baseFont};
    background-color: white;
    border: 1px solid ${({ theme }) => theme.color.muiInput};
    cursor: pointer;

    &:hover {
      ${(props) =>
        props.disabled
          ? css`
              cursor: default;
            `
          : css`
              background-color: ${({ theme }) => theme.color.lightPrimary};
              color: white;
            `}
    }
  }

  .react-calendar__tile--active {
    padding: 8px 5px;
    background-color: ${({ theme }) => theme.color.lightGreen};
    border: 1px solid ${({ theme }) => theme.color.muiInput};
    color: white;

    &:hover {
      background-color: ${({ theme }) => theme.color.lightGreen};
      color: white;
    }
  }

  .react-calendar__year-view__months {
    color: red;
    .tile {
      &:hover {
        color: white;
        background-color: ${({ theme }) => theme.color.lightPrimary};
      }
    }
  }

  .react-calendar__navigation {
    display: flex;
    margin-bottom: 15px;
    border: 1px dotted ${({ theme }) => theme.color.muiInput};
    border-radius: 10px;

    .react-calendar__navigation__label {
      cursor: pointer;
      padding: 10px 0px;
      background-color: white;
      border: 0px;
      font-family: ${({ theme }) => theme.typography.baseFont};
      font-size: ${({ theme }) => theme.typography.essSize};
      color: ${({ theme }) => theme.color.mediumDarkText};
      width: 100px;
      &:hover {
        color: white;
        background-color: ${({ theme }) => theme.color.lightPrimary};
      }
    }

    .react-calendar__navigation__arrow {
      flex-grow: 0.333;
      cursor: pointer;
      background-color: white;
      color: ${({ theme }) => theme.color.mediumDarkText};
      border: 0px;
      &:hover {
        color: white;
        background-color: ${({ theme }) => theme.color.lightPrimary};
      }
      border-radius: 10px;
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    margin: 5px 0px;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: auto !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    // Can't be display none as this screws up the day of week <> day number sync
    visibility: hidden;
  }
`;

export const MenuCalendar = styled(StyledCalendar)`
  width: 600px;
  padding: 10px;
  height: 400px;
`;

export const RangeFieldContainer = styled(CenteredDiv)`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.color.muiInput};
  border-radius: 10px;
`;

export const RangeField = styled(CenteredDiv)`
  padding: 4px 20px;
  background-color: ${(props) =>
    props.selected && props.theme.color.lightGreen};
  width: 80px;
  cursor: pointer;
  border-radius: ${(props) =>
    props.position === startKey ? `8px 0px 0px 8px` : `0px 8px 8px 0px`};
`;

export const ResetFilterIconContainer = styled.div`
  ${CenterFlexStyle}
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.lightError};
  position: absolute;
  right: calc(50% - 160px);
`;

export const ResetFilterIcon = styled(CancelIcon)`
  ${EssIconSize}
`;
