import { emptyStringValue } from './constants';
import { formatPhoneNumber } from './numbers';
import { getStringWithCapitalizedFirstLetter } from './string';

export const maskName = (firstName = '', lastName = '') => {
  // Mask the characters in the first and last names
  const maskedFirstName = firstName
    ? firstName.charAt(0) + '*'.repeat(firstName.length - 1)
    : '';
  const maskedLastName = lastName
    ? lastName.charAt(0) + '*'.repeat(lastName.length - 1)
    : '';

  // Combine the masked names
  const maskedName = `${maskedFirstName} ${maskedLastName}`;

  return maskedName;
};

export const getInitials = (firstName, lastName) => {
  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};

export const getFormattedFirstName = (firstName, charIndexToSliceAt = 9) => {
  if (!firstName) {
    return '';
  }

  return firstName.length > charIndexToSliceAt
    ? `${firstName.slice(0, charIndexToSliceAt)}...`
    : firstName;
};

export const getWordWithCapitalizedFirstLetter = (word) => {
  return word ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : '';
};

export const getFormattedName = (name) => {
  const split = name?.split(' ');
  return split.map((n) => getWordWithCapitalizedFirstLetter(n)).join(' ');
};

export const getFormattedFullNameFromUser = (
  user,
  redacted,
  fakeClientFirstName = null,
  phoneNumber,
) => {
  const firstName = user?.firstName;
  const lastName = user?.lastName;

  if (
    (!firstName && !lastName) ||
    (firstName === emptyStringValue && lastName === emptyStringValue)
  ) {
    return phoneNumber?.length > 5
      ? formatPhoneNumber(phoneNumber)
      : 'Unknown Contact';
  }

  if (redacted) {
    return fakeClientFirstName
      ? fakeClientFirstName
      : `${user?.firstName?.[0]?.toUpperCase()}.${user?.lastName?.[0]?.toUpperCase()}.`;
  }

  return `${getWordWithCapitalizedFirstLetter(
    user?.firstName,
  )} ${getWordWithCapitalizedFirstLetter(user?.lastName)}`;
};

export const getFormattedGender = (gender) => {
  return !gender ? `Gender unset` : getStringWithCapitalizedFirstLetter(gender);
};

export default { maskName, getInitials };
