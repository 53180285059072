import React, { useEffect, useState } from 'react';
import {
  MLText,
  MediumPrimaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
} from '../../../styles/shared-styled-components';
import {
  booleanOptions,
  dollarInputType,
  dropdownInputType,
  integerInputType,
  membershipTypeOptions,
  percentInputType,
} from '../../../utils/constants';
import Input from '../../Form/Input';

const EditMembershipModal = ({
  isOpen,
  onClose,
  membership,
  onSave,
  onCreate,
}) => {
  const isEditing = !!membership;
  const [name, setName] = useState(membership?.name || '');
  const [description, setDescription] = useState(membership?.description || '');
  const [type, setType] = useState(membership?.type || '');
  const [isActive, setIsActive] = useState(membership?.isActive || '');
  const [price, setPrice] = useState(membership?.price || '');
  const [paymentCadenceMonths, setPaymentCadenceMonths] = useState(
    membership?.paymentCadenceMonths || '',
  );
  const [serviceDiscountPercentage, setServiceDiscountPercentage] = useState(
    membership?.serviceDiscountPercentage || '',
  );
  const [retailDiscountPercentage, setRetailDiscountPercentage] = useState(
    membership?.retailDiscountPercentage || '',
  );

  useEffect(() => {
    if (membership) {
      setName(membership.name);
      setDescription(membership.description);
      setType(membership.type);
      setIsActive(membership.isActive);
      setPrice(membership.price);
      setPaymentCadenceMonths(membership.paymentCadenceMonths);
      setServiceDiscountPercentage(membership.serviceDiscountPercentage);
      setRetailDiscountPercentage(membership.retailDiscountPercentage);
    }
  }, [membership]);

  const onClickSave = () => {
    const updatedMembership = {
      id: membership?.id,
      name,
      description,
      type,
      isActive,
      price,
      paymentCadenceMonths,
      serviceDiscountPercentage,
      retailDiscountPercentage,
    };

    if (isEditing) {
      onSave(updatedMembership);
    } else {
      onCreate(updatedMembership);
    }
  };

  const handleClose = () => {
    setName();
    setDescription();
    setIsActive();
    setPrice();
    setServiceDiscountPercentage();
    setRetailDiscountPercentage();
    onClose();
  };

  const saveEnabled = !!name && isActive != null;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <ModalContainer useSmallGap={true}>
        <MLText>{isEditing ? 'Edit' : 'Create'} Membership</MLText>
        <ModalInputsContainer>
          <ModalInputsContainerScrollWrapper columnFlex>
            <Input
              id={'name'}
              value={name}
              label={'Name'}
              onChange={(e) => setName(e.target.value)}
              useExtraSmallGap
            />
            <Input
              id={'description'}
              value={description}
              label={'Description'}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              numRows={3}
              useExtraSmallGap
            />
            <Input
              id={'type'}
              value={type}
              label={'Type'}
              onChange={(e) => setType(e.target.value)}
              useExtraSmallGap
              type={dropdownInputType}
              options={membershipTypeOptions}
            />
            <Input
              id={'active'}
              value={isActive}
              label={'Available for enrolment'}
              onChange={(e) => {
                const updatedIsActive = e.target.value;
                setIsActive(updatedIsActive);
              }}
              options={booleanOptions}
              type={dropdownInputType}
              useExtraSmallGap
            />
            <Input
              id={'price'}
              value={price}
              label={'Price'}
              onChange={(e) => setPrice(e.target.value)}
              type={dollarInputType}
              useExtraSmallGap
            />
            <Input
              id={'cadence'}
              value={paymentCadenceMonths}
              label={'Payment cadence months'}
              onChange={(e) => setPaymentCadenceMonths(e.target.value)}
              type={integerInputType}
              useExtraSmallGap
            />
            <Input
              id={'service'}
              value={serviceDiscountPercentage}
              label={'Service discount'}
              onChange={(e) => setServiceDiscountPercentage(e.target.value)}
              type={percentInputType}
              useExtraSmallGap
            />
            <Input
              id={'retail'}
              value={retailDiscountPercentage}
              label={'Retail discount'}
              onChange={(e) => setRetailDiscountPercentage(e.target.value)}
              type={percentInputType}
              useExtraSmallGap
            />
          </ModalInputsContainerScrollWrapper>
        </ModalInputsContainer>
        <ModalActions addTopMargin={true}>
          <MediumPrimaryButton
            onClick={onClickSave}
            disabled={!saveEnabled}
          >
            Save
          </MediumPrimaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default EditMembershipModal;
