import { useMutation } from '@apollo/client';
import { sortBy } from 'lodash';
import { default as React, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EDIT_INTERCEPTION } from '../../../../api/mutations/analytics';
import OptionsFilterDropdown from '../../../../components/Analytics/OptionsFilterDropdown/OptionsFilterDropdown';
import { FilterSectionContainer } from '../../../../components/Analytics/shared-analytics-styles';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Header from '../../../../components/NavBar/Header';
import PopperMenu from '../../../../components/PopperMenu';
import ClientSearchBox from '../../../../components/Search/ClientSearchBox/ClientSearchBox';
import Snackbar from '../../../../components/Snackbar';
import { EmptyDataContainer } from '../../../../components/Training/shared-training-components';
import Paths from '../../../../Paths';
import {
  BaseCard,
  CardActionsContainer,
  CardHeaderContainer,
  CardPageSection,
  CenteredDivWithLargerGap,
  ColumnDivWithGap,
  ContentContainer,
  DarkEssText,
  ExtraSmallText,
  LightDarkLargeTinyText,
  LightDarkTextSpan,
  MediumGapColumnCenteredDiv,
  MenuItem,
  NoDataIcon,
  PageContainer,
  PageTitleText,
  SecondaryBackIcon,
  SmallMenuItemText,
  StartAlignedMediumDarkLargeTinyText,
  TinyPrimaryButton,
  TinySecondaryButton,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  activeMemberContactTypeKey,
  appointmentContactTypeLabelMap,
  ascendingKey,
  bookingSystemErrorCauseKey,
  chatInterceptionCauseLabelMap,
  clarifyingCauseKey,
  clientContactTypeKey,
  descendingKey,
  errorCauseKey,
  expiredMemberContactTypeKey,
  leadContactType,
  separateMarketingCampaignCauseKey,
  separateTransactionalCampaignCauseKey,
  stagingFilterConversionDelay,
  suspendedMemberContactTypeKey,
  systemKey,
  textSeparatorChar,
  unclassifiedKey,
  upsellingCauseKey,
  usingSeparatePlatformlInterceptionReasonKey,
} from '../../../../utils/constants';
import { dateToTextFormat } from '../../../../utils/date';
import { getFormattedName, getInitials } from '../../../../utils/name';
import { formatKeyToLabel } from '../../../../utils/string';
import { CountContainer, StatusChip } from './styled';

const sortOptions = [
  {
    key: `date_${ascendingKey}`,
    label: 'Date (earliest to latest)',
  },
  {
    key: `date_${descendingKey}`,
    label: 'Date (latest to earliest)',
  },
  {
    key: `clientType_${ascendingKey}`,
    label: 'Client type (least to most familiar)',
  },
  {
    key: `clientType_${descendingKey}`,
    label: 'Client type (most to least familiar)',
  },
  {
    key: `reason_${ascendingKey}`,
    label: 'Interception reason severity (least to most)',
  },
  {
    key: `reason_${descendingKey}`,
    label: 'Interception reason severity (most to least)',
  },
];

const reasonOrder = [
  errorCauseKey,
  bookingSystemErrorCauseKey,
  clarifyingCauseKey,
  separateMarketingCampaignCauseKey,
  separateTransactionalCampaignCauseKey,
  upsellingCauseKey,
  usingSeparatePlatformlInterceptionReasonKey,
  unclassifiedKey,
];

const contactTypeOrder = [
  leadContactType,
  clientContactTypeKey,
  expiredMemberContactTypeKey,
  suspendedMemberContactTypeKey,
  activeMemberContactTypeKey,
];

const Interceptions = ({
  interceptions,
  loading,
  refetch,
  campaigns,
  inDemoMode,
}) => {
  const navigate = useNavigate();

  const { user, drawerOpen, drawerExpanded } = useContext(BaseContext);

  const [editInterceptionMutation, { loading: editingLoading }] =
    useMutation(EDIT_INTERCEPTION);

  const [sortField, setSortField] = useState({
    key: 'date',
    descending: true,
  });
  const [contactNameSearch, setContactNameSearch] = useState('');
  const [stagingContactNameSearch, setStagingContactNameSearch] = useState('');
  const [interceptionEditing, setInterceptionEditing] = useState();
  const [editMenuAnchorEl, setEditMenuAnchorEl] = useState();
  const [causeMenuAnchorEl, setCauseMenuAnchorEl] = useState();
  const [notesModalOpen, setNotesModalOpen] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const editMenuOpen = Boolean(editMenuAnchorEl);
  const causeMenuOpen = Boolean(causeMenuAnchorEl);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingContactNameSearch !== contactNameSearch) {
        setContactNameSearch(stagingContactNameSearch);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingContactNameSearch]);

  const formattedInterceptions = [];
  interceptions?.map((i) => {
    const contact = i?.contact;
    const name = contact?.name || '';

    if (
      !contactNameSearch ||
      name.toLowerCase().includes(contactNameSearch.toLowerCase())
    ) {
      formattedInterceptions.push(i);
    }
  });

  let sortedInterceptions = sortBy(formattedInterceptions, (i) => {
    const key = sortField.key;
    if (key === 'clientType') {
      return contactTypeOrder.indexOf(i?.contact?.type);
    }
    if (key === 'reason') {
      return reasonOrder.indexOf(i?.attribution?.cause);
    }
    return i[key];
  });
  if (sortField.descending) {
    sortedInterceptions = sortedInterceptions.reverse();
  }
  const totalNumInterceptions = interceptions?.length;
  const numFilteredInterceptions = sortedInterceptions.length;

  const campaignMap = {};
  campaigns.map((c) => {
    campaignMap[c.key] = c.label;
  });

  const onUpdateClassification = (id, cause) => {
    editInterceptionMutation({
      variables: {
        id,
        cause,
      },
      onCompleted: async () => {
        setSnackbarMessage(`Changes saved`);
        setCauseMenuAnchorEl();
        await refetch();
      },
    });
  };

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <CenteredDivWithLargerGap bottomMargin={10}>
            <Tooltip
              title={<TooltipTitleText>Back to dashboard</TooltipTitleText>}
              placement='left'
            >
              <SecondaryBackIcon onClick={() => navigate(Paths.dashboard)} />
            </Tooltip>
            <PageTitleText>Interceptions</PageTitleText>
          </CenteredDivWithLargerGap>
          <CardPageSection>
            <FilterSectionContainer spaced>
              <CountContainer>
                <LightDarkLargeTinyText>
                  {contactNameSearch
                    ? `${numFilteredInterceptions} filtered interception${
                        numFilteredInterceptions === 1 ? '' : 's'
                      }`
                    : `${totalNumInterceptions} total interception${
                        totalNumInterceptions === 1 ? '' : 's'
                      }`}{' '}
                </LightDarkLargeTinyText>
              </CountContainer>
              <ClientSearchBox
                entityLabel='client'
                value={stagingContactNameSearch}
                onChange={(e) => setStagingContactNameSearch(e.target.value)}
              />
              <OptionsFilterDropdown
                label='Sort by'
                options={sortOptions}
                isMultiSelect={false}
                filtered={[
                  `${sortField.key}_${
                    sortField.descending
                      ? `${descendingKey}`
                      : `${ascendingKey}`
                  }`,
                ]}
                onChange={(updatedSortField) => {
                  const values = updatedSortField.split('_');
                  setSortField({
                    key: values[0],
                    descending: values[1] === descendingKey,
                  });
                }}
              />
            </FilterSectionContainer>
            <MediumGapColumnCenteredDiv>
              {sortedInterceptions.length ? (
                <>
                  {sortedInterceptions.map((i) => {
                    const { chatId, contact, date, attribution, reason } = i;
                    const contactType = contact?.type;
                    const contactName = inDemoMode
                      ? getInitials(
                          contact?.name?.split(' ')[0] || '-',
                          contact?.name?.split(' ')[1] || '-',
                        )
                      : getFormattedName(contact?.name || 'Unknown contact');
                    const cause = attribution?.cause || reason;
                    const dateStr = dateToTextFormat(date);
                    const title = `${contactName}`;
                    const campaignKey = attribution?.campaignId;
                    console.log('c', cause);
                    const attributionText = `Interception reason: ${
                      chatInterceptionCauseLabelMap[cause]
                    } (classified by ${
                      attribution?.attributedBy === systemKey ? `AI` : 'a human'
                    })`;
                    const campaignNameText = `While messaging for the '${campaignMap[campaignKey]}' campaign`;
                    const messageContent = attribution?.messageContent;
                    return (
                      <>
                        <BaseCard>
                          <ColumnDivWithGap>
                            <CardHeaderContainer>
                              <DarkEssText>
                                {title}
                                <LightDarkTextSpan>
                                  &nbsp;&nbsp;
                                  {textSeparatorChar}&nbsp;&nbsp;{dateStr}
                                </LightDarkTextSpan>
                              </DarkEssText>
                              <StatusChip
                                status={cause}
                                fixedMinWidth={80}
                              >
                                {formatKeyToLabel(cause)}
                              </StatusChip>
                            </CardHeaderContainer>
                            <StartAlignedMediumDarkLargeTinyText>
                              {attributionText}
                              <br></br>
                              {campaignNameText}
                              <br></br>
                              👤&nbsp;&nbsp;
                              {appointmentContactTypeLabelMap[contactType]}
                              {messageContent && !inDemoMode && (
                                <>
                                  <br></br>
                                  <br></br>
                                  <LightDarkTextSpan smallLineHeight>
                                    Message:
                                    <br></br>"
                                    {messageContent.length > 100
                                      ? `${messageContent.slice(0, 100)}...`
                                      : messageContent}
                                    "
                                  </LightDarkTextSpan>
                                </>
                              )}
                            </StartAlignedMediumDarkLargeTinyText>
                          </ColumnDivWithGap>
                          <CardActionsContainer>
                            <TinyPrimaryButton
                              largePadding
                              onClick={(e) => {
                                setEditMenuAnchorEl(e.currentTarget);
                                setInterceptionEditing(i);
                              }}
                            >
                              Edit interception
                            </TinyPrimaryButton>
                            <TinySecondaryButton
                              largePadding
                              onClick={() => {
                                const chatPath = `${Paths.chats}?chat-id=${chatId}`;
                                navigate(chatPath);
                              }}
                            >
                              View chat
                            </TinySecondaryButton>
                          </CardActionsContainer>
                        </BaseCard>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  <EmptyDataContainer>
                    <NoDataIcon />
                    <ExtraSmallText>No interceptions found</ExtraSmallText>
                  </EmptyDataContainer>
                </>
              )}
            </MediumGapColumnCenteredDiv>
          </CardPageSection>
        </ContentContainer>
      </PageContainer>
      <PopperMenu
        open={editMenuOpen}
        anchorElement={editMenuAnchorEl}
        onClose={() => setEditMenuAnchorEl()}
        variant='offset'
      >
        <MenuItem
          onClick={() => {
            setCauseMenuAnchorEl(editMenuAnchorEl);
            setEditMenuAnchorEl();
          }}
        >
          <SmallMenuItemText>Update classification</SmallMenuItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setNotesModalOpen(true);
          }}
        >
          <SmallMenuItemText>Attach note or feedback request</SmallMenuItemText>
        </MenuItem>
      </PopperMenu>
      <PopperMenu
        open={causeMenuOpen}
        anchorElement={causeMenuAnchorEl}
        onClose={() => setCauseMenuAnchorEl()}
        variant='offset'
      >
        {reasonOrder.map((reason) => {
          const label = chatInterceptionCauseLabelMap[reason];
          const isCurrent = interceptionEditing?.attribution?.cause === reason;
          return (
            <MenuItem
              onClick={() => {
                if (!isCurrent) {
                  onUpdateClassification(interceptionEditing?.id, reason);
                }
              }}
              disabled={isCurrent}
            >
              <SmallMenuItemText>
                {label}
                {isCurrent && ' (current)'}
              </SmallMenuItemText>
            </MenuItem>
          );
        })}
      </PopperMenu>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Interceptions;
