import { useQuery } from '@apollo/client';
import { get, sortBy } from 'lodash';
import {
  GET_ALL_PAYMENT_PLANS,
  GET_CHECKOUT_SESSION,
  GET_LOCATION_PAYMENT_PLANS,
} from '../queries/payment';

export const useAllPaymentPlans = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_ALL_PAYMENT_PLANS, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  const plans = sortBy(get(data, 'getAllPaymentPlans', null), (p) => p.name);

  return {
    error,
    loading,
    refetch,
    plans,
  };
};

export const useLocationPaymentPlans = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_LOCATION_PAYMENT_PLANS,
    {
      fetchPolicy: 'network-only',
      skip: skipCondition,
    },
  );

  const plans = get(data, 'getLocationPaymentPlans', null);

  return {
    error,
    loading,
    refetch,
    plans,
  };
};

export const useCheckoutSession = ({ id, skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_CHECKOUT_SESSION, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-first',
    skip: skipCondition || !id,
  });

  const session = get(data, 'getCheckoutSession', null);

  return {
    error,
    loading,
    refetch,
    data: session,
  };
};
