import { useMutation } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  useMySessionTypes,
  useMyStaff,
  useServiceSettings,
} from '../../../../api/hooks/enterprise';
import {
  useAgentSettings,
  useClosures,
  useMemberships,
  useModuleStatus,
  useOverview,
  useServiceCorpus,
  useTemplateSyncStatus,
} from '../../../../api/hooks/training';
import { EDIT_AGENT_NAME } from '../../../../api/mutations/agent';
import { EDIT_CLOSURES } from '../../../../api/mutations/calendar';
import {
  CREATE_BOOKING_FLOW,
  CREATE_FAQ,
  DELETE_PROMPT,
  EDIT_AGENT_SETTINGS,
  EDIT_CATEGORY_ASSIGNMENT,
  EDIT_FAQ,
  EDIT_LOCATION_CONTACT_INFO,
  EDIT_POLICY,
  EDIT_PROMPT,
  EDIT_SERVICES,
  EDIT_STAFF_MEMBERS,
} from '../../../../api/mutations/training';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import AgentVideoMessageModal from '../../../../components/Modals/AgentVideoMessageModal';
import CreateBookingFlowModal from '../../../../components/Modals/CreateBookingFlowModal';
import CreateFAQModal from '../../../../components/Modals/CreateFAQModal';
import LaunchChecklistModal from '../../../../components/Modals/LaunchChecklistModal';
import OrganizationTemplateSyncModal from '../../../../components/Modals/OrganizationTemplateSyncModal/OrganizationTemplateSyncModal';
import Header from '../../../../components/NavBar/Header';
import PopperMenu from '../../../../components/PopperMenu';
import Snackbar from '../../../../components/Snackbar';
import PhoneSettings from '../../../../components/Training/Agent/Deployment/Deployment';
import Info from '../../../../components/Training/Agent/Info';
import Messaging from '../../../../components/Training/Agent/Messaging';
import TextSettings from '../../../../components/Training/Agent/TextSettings';
import Assets from '../../../../components/Training/Catalog/Assets';
import BookingFlows from '../../../../components/Training/Catalog/BookingFlows';
import Categories from '../../../../components/Training/Catalog/Categories/Categories';
import GeneralFAQ from '../../../../components/Training/Catalog/GeneralFAQ';
import Memberships from '../../../../components/Training/Catalog/Memberships/Memberships';
import Packages from '../../../../components/Training/Catalog/Packages';
import ServiceFAQ from '../../../../components/Training/Catalog/ServiceFAQ';
import Services from '../../../../components/Training/Catalog/Services';
import Staff from '../../../../components/Training/Catalog/Staff';
import Upsells from '../../../../components/Training/Catalog/Upsells/Upsells';
import Closures from '../../../../components/Training/Overview/Closures/Closures';
import Contact from '../../../../components/Training/Overview/Contact/Contact';
import Hours from '../../../../components/Training/Overview/Hours';
import Links from '../../../../components/Training/Overview/Links';
import Location from '../../../../components/Training/Overview/Location/Location';
import Booking from '../../../../components/Training/Policies/Booking';
import Cancellations from '../../../../components/Training/Policies/Cancellations';
import FollowUps from '../../../../components/Training/Policies/FollowUps';
import LastMinute from '../../../../components/Training/Policies/LastMinute';
import Payments from '../../../../components/Training/Policies/Payments';
import {
  CancelIconContainer,
  corporationEditingMode,
  CorporationIcon,
  EditingModeComponent,
  EmptySelectOptionsContainer,
  ListBannerContainer,
  ListBannerTextContainer,
  locationEditingMode,
  LocationIcon,
  OverhangTextContainer,
  portfolioEditingMode,
  PortfolioIcon,
} from '../../../../components/Training/shared-training-components';
import MetaSetter from '../../../../components/Utils/MetaSetter';
import {
  AbsoluteLoadingIndicatorContainer,
  AgentAvatarContainer,
  AutoScrollWrapper,
  CenteredDivWithGap,
  ColumnCenteredDiv,
  CompletionCheckmark,
  CompletionCircle,
  DarkEssText,
  EndAlignedFlexDiv,
  ExtraSmallAddIcon,
  InProgressIcon,
  LightDarkSmallText,
  LightErrorTinyText,
  LightExtraTinyText,
  MediumDarkTinyText,
  MenuItem,
  OverhangCheckmarkIcon,
  OverhangIconContainer,
  OverhangInProgressIcon,
  OverhangText,
  PageContainer,
  PageTitleText,
  ReceptionistAvatar,
  RedCancelIcon,
  RelativeDiv,
  SelectIcon,
  SmallMenuItemText,
  SmallMenuItemTitleContainer,
  SmallMenuItemTitleText,
  SmallText,
  SMText,
  SuccessTinyText,
  SyncIcon,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  addOnKey,
  addressSubmoduleKey,
  agentInfoKey,
  agentSettingsModuleKey,
  agentStyleKey,
  assetsSubmoduleKey,
  autoSaveDelayMs,
  bookingFlowsSubmoduleKey,
  bookingPromptTypeKey,
  bookingSubmoduleKey,
  cancellationSubmoduleKey,
  catalogModuleKey,
  catalogSubmodules,
  categoriesSubmoduleKey,
  checkoutPageSubmoduleKey,
  closuresSubmoduleKey,
  collapsedModulesCookieKey,
  collapsedSubmodulesCookieKey,
  completeStatusKey,
  consultationKey,
  contactSubmoduleKey,
  defaultMultipersonServicePromptKey,
  defaultServicePromptKey,
  deploymentKey,
  dropInKey,
  editingModeCookieKey,
  faqPromptTypeKey,
  followUpsSubmoduleKey,
  franchiseBusinessType,
  generalCategoryTypeKey,
  generalFaqsSubmoduleKey,
  hoursSubmoduleKey,
  inProgressStatusKey,
  linksSubmoduleKey,
  locationCustomPromptKey,
  locationModuleKey,
  membershipsSubmoduleKey,
  messagingKey,
  multiLocationBusinessType,
  offeringsSubmoduleKey,
  overviewSubmodules,
  packageKey,
  packagesSubmoduleKey,
  paymentsSubmoduleKey,
  policyModuleKey,
  policySubmodules,
  scheduleFillUpSubmoduleKey,
  selectedPromptCookieKey,
  selectedSubmoduleCookieKey,
  serviceCategoryTypeKey,
  serviceFaqsSubmoduleKey,
  staffSubmoduleKey,
  uncategorizedKey,
  universalPromptKey,
  upsellsSubmoduleKey,
} from '../../../../utils/constants';
import { objectIsEmpty } from '../../../../utils/data';
import { getCookieExpiryObject, getTimestamp } from '../../../../utils/date';
import {
  formatKeyToLabel,
  parseCookieValueString,
} from '../../../../utils/string';
import {
  checkIfUserIsSuperAdmin,
  getUserBusiness,
  getUserCorporation,
  getUserLiveIQAgents,
  getUserLocation,
} from '../../../../utils/user';
import {
  AddSubmoduleCardButton,
  AddSubmoduleCardButtonContainer,
  AgentSettingsModuleIcon,
  AnswerSection,
  AnswerTextContainer,
  CatalogModuleIcon,
  CategoryDownArrow,
  CategoryUpArrow,
  CompleteModuleIcon,
  DrawerLoadingIndicatorContainer,
  IncompleteModuleIcon,
  LocationModuleIcon,
  ModuleContainer,
  ModuleDownArrow,
  ModuleIconsContainer,
  ModuleListItem,
  ModuleNameContainer,
  ModuleNameText,
  ModulesCompletionStatusContainer,
  ModulesContentContainer,
  ModulesDrawer,
  ModulesDrawerHeaderContainer,
  ModuleStatusIconContainer,
  ModuleUpArrow,
  PolicyModuleIcon,
  SoonIcon,
  SubmoduleCard,
  SubmoduleCardScrollContainer,
  SubmoduleCardSectionContainer,
  SubmoduleCardText,
  SubmoduleContainer,
  SubmoduleNameContainer,
  SubmoduleNameText,
  SubmodulesContainer,
  SubmodulesDrawer,
  SubmoduleSectionHeaderContainer,
  SubmodulesSection,
  SubmoduleText,
} from './styled';

const modules = {
  [agentSettingsModuleKey]: {
    label: 'Agent',
    icon: AgentSettingsModuleIcon,
    submodules: [
      {
        key: agentInfoKey,
        label: 'Info',
      },
      {
        key: deploymentKey,
        label: 'Deployment',
      },
      {
        key: agentStyleKey,
        label: 'Style',
      },
    ],
  },
  [locationModuleKey]: {
    label: 'Location',
    icon: LocationModuleIcon,
    submodules: [
      {
        key: addressSubmoduleKey,
        label: 'Address',
      },
      {
        key: hoursSubmoduleKey,
        label: 'Hours',
      },
      {
        key: linksSubmoduleKey,
        label: 'Links',
      },
      {
        key: contactSubmoduleKey,
        label: 'Contact',
      },
      {
        key: closuresSubmoduleKey,
        label: 'Closures',
      },
      ,
    ],
  },
  [catalogModuleKey]: {
    label: 'Catalog',
    icon: CatalogModuleIcon,
    submodules: [
      {
        key: staffSubmoduleKey,
        label: 'Staff',
      },
      {
        key: categoriesSubmoduleKey,
        label: 'Categories',
      },
      {
        key: offeringsSubmoduleKey,
        label: 'Services',
      },
      {
        key: packagesSubmoduleKey,
        label: 'Packages',
      },
      {
        key: upsellsSubmoduleKey,
        label: 'Upsells',
      },
      {
        key: bookingFlowsSubmoduleKey,
        label: 'Booking Flows',
      },
      {
        key: serviceFaqsSubmoduleKey,
        label: 'Service FAQs',
      },
      {
        key: membershipsSubmoduleKey,
        label: 'Memberships',
      },
      {
        key: assetsSubmoduleKey,
        label: 'Assets',
      },
    ],
  },
  [policyModuleKey]: {
    label: 'Policies',
    icon: PolicyModuleIcon,
    submodules: [
      {
        key: bookingSubmoduleKey,
        label: 'Booking',
      },
      // {
      //   key: checkoutPageSubmoduleKey,
      //   label: 'Check-Out Page',
      // },
      {
        key: cancellationSubmoduleKey,
        label: 'Cancellations',
      },
      {
        key: followUpsSubmoduleKey,
        label: 'Follow-Ups',
      },
      {
        key: scheduleFillUpSubmoduleKey,
        label: 'Schedule Fill-Up',
      },
      {
        key: paymentsSubmoduleKey,
        label: 'Payments',
      },
    ],
  },
};
const moduleArr = Object.keys(modules);

const comingSoonModules = [checkoutPageSubmoduleKey];

const CompleteIcon = (
  <ModuleStatusIconContainer>
    <Tooltip
      title={<TooltipTitleText>Module complete</TooltipTitleText>}
      placement='right'
    >
      <CompleteModuleIcon />
    </Tooltip>
  </ModuleStatusIconContainer>
);
const IncompleteIcon = (
  <ModuleStatusIconContainer>
    <Tooltip
      title={<TooltipTitleText>Module incomplete</TooltipTitleText>}
      placement='right'
    >
      <IncompleteModuleIcon />
    </Tooltip>
  </ModuleStatusIconContainer>
);
const ComingSoonIcon = (
  <ModuleStatusIconContainer>
    <Tooltip
      title={<TooltipTitleText>Coming soon</TooltipTitleText>}
      placement='right'
    >
      <SoonIcon />
    </Tooltip>
  </ModuleStatusIconContainer>
);

const cookieExpiryObject = getCookieExpiryObject();

const getSelectedModule = (selectedSubmodule) => {
  if (!selectedSubmodule) {
    return null;
  }

  if (overviewSubmodules.includes(selectedSubmodule)) {
    return locationModuleKey;
  }
  if (catalogSubmodules.includes(selectedSubmodule)) {
    return catalogModuleKey;
  }
};

const getStringifiedLocationContactInfo = (location) => {
  const businessPhoneNumberForClient = location?.businessPhoneNumberForClient;
  const contactPhoneNumbers = (location?.contactPhoneNumbers || [])
    .map((c) => (c ? `${c.id}${c.value}` : ''))
    .join(',');
  const notificationPreference = location?.notificationPreference || {};
  return `${businessPhoneNumberForClient}${contactPhoneNumbers}${JSON.stringify(
    notificationPreference,
  )}`;
};

const stringifyPromptObject = (prompt, resolutions) => {
  const abbreviatedPrompt = {
    id: prompt?.id,
    status: prompt?.status,
    title: prompt?.title,
    utterance: prompt?.utterance,
  };
  const promptStr = JSON.stringify(abbreviatedPrompt);
  const resolutionStr = JSON.stringify(resolutions);
  return `${promptStr}_${resolutionStr}`;
};

const getStringifiedPrompt = (promptToStringify, serviceCorpus) => {
  if (!promptToStringify || !serviceCorpus) {
    return {};
  }

  const { id: promptId, categoryId } = promptToStringify;
  const categoryToUpdate = serviceCorpus?.[categoryId];
  const prompts = categoryToUpdate?.prompts || {};
  const selectedPromptObject = prompts[promptId];
  const basePrompt = selectedPromptObject?.prompt;
  const baseResolutions = selectedPromptObject?.resolutions;

  return {
    obj: selectedPromptObject,
    str: stringifyPromptObject(basePrompt, baseResolutions),
  };
};

const getStringifiedFaq = (faq, resolutions) => {
  return `${faq?.utterance}${resolutions?.[0]?.explanation}`;
};

const editingModeComponentMap = {
  [locationEditingMode]: {
    icon: LocationIcon,
    text: `You're in 'Location Editing' mode, meaning that changes you apply here will only apply to this specific location`,
    header: `Location Mode`,
  },
  [portfolioEditingMode]: {
    icon: PortfolioIcon,
    text: `You're in 'Portfolio Editing' mode, meaning that changes you apply here will apply to all locations in this portfolio`,
    header: `Portfolio Mode`,
  },
  [corporationEditingMode]: {
    icon: CorporationIcon,
    text: `You're in 'Corporation Editing' mode, meaning that changes you apply here will apply to all locations in this corporation`,
    header: `Corporation Mode`,
  },
};

const Training = () => {
  const {
    width,
    user,
    refetchUser,
    userLoading,
    drawerOpen,
    drawerExpanded,
    cookies,
    setCookie,
  } = useContext(BaseContext);

  const [saveAgentNameMutation] = useMutation(EDIT_AGENT_NAME);
  const [saveLocationContactInfoMutation] = useMutation(
    EDIT_LOCATION_CONTACT_INFO,
  );
  const [saveAgentSettingsMutation] = useMutation(EDIT_AGENT_SETTINGS);
  const [saveClosuresMutation] = useMutation(EDIT_CLOSURES);
  const [saveStaffMutation] = useMutation(EDIT_STAFF_MEMBERS);
  const [saveCategoryAssignmentMutation] = useMutation(
    EDIT_CATEGORY_ASSIGNMENT,
  );
  const [saveServicesMutation] = useMutation(EDIT_SERVICES);
  const [
    saveServiceBookingPromptMutation,
    { loading: saveBookingPromptLoading },
  ] = useMutation(EDIT_PROMPT);
  const [createBookingFlowMutation] = useMutation(CREATE_BOOKING_FLOW);
  const [createFaqMutation] = useMutation(CREATE_FAQ);
  const [saveFaqMutation] = useMutation(EDIT_FAQ);
  const [deletePromptMutation] = useMutation(DELETE_PROMPT);
  const [savePolicyMutation] = useMutation(EDIT_POLICY);

  const baseLocation = getUserLocation(user);
  const baseLocationContactInfo = {
    businessPhoneNumberForClient: baseLocation?.businessPhoneNumberForClient,
    contactPhoneNumbers: baseLocation?.contactPhoneNumbers || [],
    notificationPreference: baseLocation?.notificationPreference || {},
  };
  const userLocationId = baseLocation?.id;
  const baseAgent = getUserLiveIQAgents(user)?.[0] || {};
  const baseAgentName = baseAgent?.name;
  const isSuperAdmin = checkIfUserIsSuperAdmin(user);
  const calendarPlatform = baseLocation?.calendarPlatform;

  const corporation = getUserCorporation(user);
  const corporationId = corporation?.id;

  const contentContainerRef = useRef();

  const business = getUserBusiness(user);
  const editingModeCookieValue = cookies[editingModeCookieKey];
  const editingModeFromCookie = [
    multiLocationBusinessType,
    franchiseBusinessType,
  ].includes(business?.type)
    ? editingModeCookieValue || locationEditingMode
    : null;

  const collapsedModulesCookieValue = cookies[collapsedModulesCookieKey];
  const collapsedSubmodulesCookieValue = cookies[collapsedSubmodulesCookieKey];
  const selectedSubmoduleCookieValue =
    cookies[selectedSubmoduleCookieKey] || agentInfoKey;
  const selectedPromptCookieValue = cookies[selectedPromptCookieKey];
  const stringifiedSelectedPromptCookieValue = JSON.stringify(
    selectedPromptCookieValue,
  );

  const [editingMode, setEditingMode] = useState(editingModeFromCookie);
  const [editingModeMenuAnchorEl, setEditingModeMenuAnchorEl] = useState(null);
  const [collapsedModules, setCollapsedModules] = useState(
    parseCookieValueString(collapsedModulesCookieValue),
  );
  const [collapsedSubmodules, setCollapsedSubmodules] = useState(
    parseCookieValueString(collapsedSubmodulesCookieValue),
  );
  const [selectedSubmodule, setSelectedSubmodule] = useState(
    selectedSubmoduleCookieValue,
  );
  const [createBookingFlowModalOpen, setCreateBookingFlowModalOpen] =
    useState(false);
  const [createFaqModalOpen, setCreateFaqModalOpen] = useState(false);
  const [
    createServicePromptModalCategory,
    setCreateServicePromptModalCategory,
  ] = useState(null);
  const [launchChecklistModalOpen, setLaunchChecklistModalOpen] =
    useState(false);
  const [templateSyncModalOpen, setTemplateSyncModalOpen] = useState(false);
  const [agentVideoMessageModalOpen, setAgentVideoMessageModalOpen] =
    useState(false);
  const [remainingHeight, setRemainingHeight] = useState(0);
  const [hasLoaded, setHasLoaded] = useState(false);

  const editingModeComponent = editingMode
    ? editingModeComponentMap[editingMode]
    : null;

  const editingModeMenuOpen = Boolean(editingModeMenuAnchorEl);

  useEffect(() => {
    if (editingModeCookieValue) {
      setEditingMode(editingModeCookieValue);
    }
  }, [editingModeCookieValue]);

  const onChangeEditingMode = (mode) => {
    setCookie(editingModeCookieKey, mode, cookieExpiryObject);
    setEditingModeMenuAnchorEl();
    setSnackbarMessage('Editing mode updated');
  };

  // Sync status
  const templateSyncStatusRes = useTemplateSyncStatus({});
  const refetchTemplateSyncStatus = templateSyncStatusRes?.refetch;
  const templateSyncStatusLoading = templateSyncStatusRes?.loading;
  const templateSyncStatus = templateSyncStatusRes?.data?.status;
  const templateSyncSource = templateSyncStatusRes?.data?.source;
  const templateSyncTimestamp = templateSyncStatusRes?.data?.date
    ? getTimestamp(templateSyncStatusRes?.data?.date)
    : '';
  const templateSyncing = templateSyncStatus === inProgressStatusKey;

  const selectedModule = getSelectedModule(selectedSubmodule);
  const submoduleDrawerExpanded =
    selectedSubmodule === bookingFlowsSubmoduleKey ||
    selectedSubmodule === serviceFaqsSubmoduleKey ||
    selectedSubmodule === generalFaqsSubmoduleKey;

  // Module status
  const moduleStatusRes = useModuleStatus();
  const refetchModuleStatus = moduleStatusRes?.refetch;
  const moduleStatusLoading = moduleStatusRes?.loading;
  const moduleStatusMap = moduleStatusRes?.data || {};
  const completionPercentage = moduleStatusMap['percentage'];

  // Agent
  const agentSettingRes = useAgentSettings({
    skipCondition: ![deploymentKey, agentStyleKey, messagingKey].includes(
      selectedSubmodule,
    ),
  });
  const baseAgentSettings = agentSettingRes?.data;
  const refetchAgentSettings = agentSettingRes?.refetch;
  const agentSettingsLoading = agentSettingRes?.loading;

  // Overview
  const overviewRes = useOverview({
    skipCondition:
      (selectedModule !== locationModuleKey ||
        selectedSubmodule === contactSubmoduleKey) &&
      selectedSubmodule !== followUpsSubmoduleKey,
  });
  const overviewData = overviewRes?.data;
  const refetchOverview = overviewRes?.refetch;
  const closuresRes = useClosures({
    skipCondition: selectedSubmodule !== closuresSubmoduleKey,
  });
  const baseClosuresData = closuresRes?.data;
  const refetchClosures = closuresRes?.refetch;

  // Catalog
  const staffRes = useMyStaff({
    skipCondition: selectedSubmodule !== staffSubmoduleKey,
  });
  const baseStaffData = staffRes?.staff;
  const staffLoading = staffRes?.loading;
  const refetchStaff = staffRes?.refetch;
  const serviceCorpusRes = useServiceCorpus({
    skipCondition:
      selectedModule !== catalogModuleKey ||
      [staffSubmoduleKey, membershipsSubmoduleKey].includes(selectedSubmodule),
  });
  const {
    data: baseServiceCorpus,
    loading: serviceCorpusLoading,
    refetch: refetchServiceCorpus,
  } = serviceCorpusRes;
  const {
    sessionTypes: baseSessionTypeCatalog,
    loading: sessionTypesLoading,
    refetch: refetchSessionTypeCatalog,
  } = useMySessionTypes({
    onlyBookable: false,
    skipCondition: ![
      bookingFlowsSubmoduleKey,
      offeringsSubmoduleKey,
      upsellsSubmoduleKey,
      packagesSubmoduleKey,
    ].includes(selectedSubmodule),
  });
  const membershipsRes = useMemberships({
    skipCondition: selectedSubmodule !== membershipsSubmoduleKey,
  });

  // Policies
  const policiesRes = useServiceSettings({
    skipCondition: !policySubmodules.includes(selectedSubmodule),
  });
  const basePolicyData = policiesRes?.serviceSettings;
  const policyLoading = policiesRes?.loading;
  const refetchPolicy = policiesRes?.refetch;

  const refetchAllData = () => {
    refetchAgentSettings();
    refetchOverview();
    refetchUser();
    refetchStaff();
    refetchServiceCorpus();
    refetchSessionTypeCatalog();
    refetchPolicy();
  };

  // State
  const [location, setLocation] = useState(baseLocation);
  const [agent, setAgent] = useState(baseAgent);
  const [agentSettings, setAgentSettings] = useState(baseAgentSettings);
  const [closures, setClosures] = useState(baseClosuresData);
  const [staff, setStaff] = useState(baseStaffData);
  const [modifiedStaff, setModifiedStaff] = useState([]);
  const [sessionTypeCatalog, setSessionTypeCatalog] = useState(
    baseSessionTypeCatalog,
  );
  const [modifiedOfferings, setModifiedOfferings] = useState([]);
  const [excludedOfferingCategoriesShown, setExcludedOfferingCategoriesShown] =
    useState(false);
  const [serviceCorpus, setServiceCorpus] = useState(baseServiceCorpus || {});
  const [selectedPrompt, setSelectedPrompt] = useState(
    selectedPromptCookieValue,
  );
  const [policy, setPolicy] = useState(basePolicyData);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Parsed values
  const agentName = agent?.name;

  const { str: selectedPromptStr } = getStringifiedPrompt(
    selectedPrompt,
    serviceCorpus,
  );

  const sortedCategories = useMemo(() => {
    const serviceCategoryIds = Object.keys(serviceCorpus);
    return sortBy(
      serviceCategoryIds.map((id) => serviceCorpus[id]),
      (c) => (c.included ? -1 : 1),
      (c) => c.name?.toLowerCase(),
    );
  }, [serviceCorpus]);

  // Data fetching UEs
  useEffect(() => {
    if (baseLocation) {
      setLocation(baseLocation);
    }
  }, [baseLocation]);

  useEffect(() => {
    if (baseAgent) {
      setAgent(baseAgent);
    }
  }, [baseAgent]);

  useEffect(() => {
    if (baseAgentSettings) {
      setAgentSettings(baseAgentSettings);
    }
  }, [baseAgentSettings]);

  useEffect(() => {
    if (baseClosuresData) {
      setClosures(baseClosuresData);
    }
  }, [baseClosuresData]);

  useEffect(() => {
    if (baseStaffData) {
      setStaff(baseStaffData);
    }
  }, [baseStaffData]);

  useEffect(() => {
    if (baseServiceCorpus) {
      setServiceCorpus(baseServiceCorpus);
    }
  }, [baseServiceCorpus]);

  useEffect(() => {
    if (baseSessionTypeCatalog) {
      setSessionTypeCatalog(baseSessionTypeCatalog);
    }
  }, [baseSessionTypeCatalog]);

  useEffect(() => {
    if (basePolicyData) {
      setPolicy(basePolicyData);
    }
  }, [basePolicyData]);

  // Refetch on changes
  useEffect(() => {
    if (hasLoaded) {
      refetchAllData();
    }
  }, [userLocationId]);

  // Refetch module status
  useEffect(() => {
    refetchModuleStatus();
  }, [
    baseAgent,
    baseAgentSettings,
    baseStaffData,
    overviewData,
    baseClosuresData,
    baseServiceCorpus,
    baseSessionTypeCatalog,
    basePolicyData,
  ]);

  useEffect(() => {
    if (collapsedModulesCookieValue != null) {
      setCollapsedModules(parseCookieValueString(collapsedModulesCookieValue));
    }
  }, [collapsedModulesCookieValue]);

  useEffect(() => {
    if (collapsedSubmodulesCookieValue != null) {
      setCollapsedSubmodules(
        parseCookieValueString(collapsedSubmodulesCookieValue),
      );
    }
  }, [collapsedSubmodulesCookieValue]);

  useEffect(() => {
    if (selectedSubmoduleCookieValue != null) {
      setSelectedSubmodule(selectedSubmoduleCookieValue);
    }
  }, [selectedSubmoduleCookieValue]);

  useEffect(() => {
    if (selectedPromptCookieValue != null) {
      setSelectedPrompt(selectedPromptCookieValue);
    }
  }, [stringifiedSelectedPromptCookieValue]);

  // Setting the remaining height - need to watch variables that trigger re-renders
  useEffect(() => {
    const handleResize = () => {
      const divHeight =
        contentContainerRef?.current?.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const heightDifference = windowHeight - divHeight;
      setRemainingHeight(heightDifference);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    setHasLoaded(true);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width, submoduleDrawerExpanded, hasLoaded]);

  // Sync
  const onSync = async (locationName) => {
    setSnackbarMessage(`Training data syncing from location '${locationName}'`);
    await refetchTemplateSyncStatus();
  };

  // Save location
  const saveLocationContactInfo = async (updatedLocation) => {
    saveLocationContactInfoMutation({
      variables: {
        businessPhoneNumberForClient:
          updatedLocation.businessPhoneNumberForClient,
        contactPhoneNumbers: updatedLocation.contactPhoneNumbers,
        notificationPreference: updatedLocation.notificationPreference || {},
      },
      onCompleted: () => {
        refetchUser();
      },
    });
  };

  const autoSaveLocationContactInfo = async (updatedLocation = null) => {
    const locationToUse = updatedLocation || location;
    if (!locationToUse) {
      return;
    }

    if (
      getStringifiedLocationContactInfo(baseLocationContactInfo) !==
      getStringifiedLocationContactInfo(locationToUse)
    ) {
      await saveLocationContactInfo(locationToUse);
    }
  };

  // Save agent
  const saveAgentName = async (updatedName) => {
    saveAgentNameMutation({
      variables: {
        agent: baseAgent,
        name: updatedName,
        editingMode,
      },
      onCompleted: (data) => {
        refetchUser();
      },
    });
  };

  const autoSaveAgentName = async () => {
    if (agentName !== baseAgentName) {
      await saveAgentName(agentName);
    }
  };

  const saveAgentSettings = async (updatedSettings) => {
    saveAgentSettingsMutation({
      variables: {
        settings: updatedSettings,
      },
      onCompleted: () => {
        refetchAgentSettings();
      },
    });
  };

  const autoSaveAgentSettings = async () => {
    if (!baseAgentSettings?.agentId) {
      return;
    }

    if (JSON.stringify(baseAgentSettings) !== JSON.stringify(agentSettings)) {
      await saveAgentSettings(agentSettings);
    }
  };

  const saveClosures = async (updatedClosures) => {
    saveClosuresMutation({
      variables: {
        closures: updatedClosures,
      },
      onCompleted: () => {
        refetchClosures();
      },
    });
  };

  const autoSaveClosures = async () => {
    if (
      JSON.stringify(closures?.join(',')) !==
      JSON.stringify(baseClosuresData?.join(','))
    ) {
      await saveClosures(closures);
    }
  };

  // Save staff
  const onSaveStaff = () => {
    setSnackbarMessage(`Staff updated`);
    refetchStaff();
  };

  const saveStaff = async (updatedStaff, sourceModifiedStaff) => {
    saveStaffMutation({
      variables: {
        staff: updatedStaff,
      },
      onCompleted: async (data) => {
        const success = data.editStaffMembers;

        if (success) {
          const justUpdatedStaffIdMap = {};
          const lastUpdatedAtMap = {};

          sourceModifiedStaff.map((s) => {
            const { id, lastUpdatedAt } = s;

            if (lastUpdatedAt) {
              lastUpdatedAtMap[lastUpdatedAt] = true;
            }
            justUpdatedStaffIdMap[id] = true;
          });

          refetchStaff();

          // Check for fresh updates
          const recentlyModifiedStaff = modifiedStaff.filter((s) => {
            return (
              !justUpdatedStaffIdMap[s.id] || !lastUpdatedAtMap[s.lastUpdatedAt]
            );
          });
          setModifiedStaff(recentlyModifiedStaff);
        } else {
          refetchStaff();
        }
      },
    });
  };

  const autoSaveStaff = async () => {
    const sourceModifiedStaff = [...modifiedStaff];
    if (!sourceModifiedStaff.length) {
      return;
    }

    const modifiedStaffIdMap = {};
    modifiedStaff.map((s) => {
      modifiedStaffIdMap[s.id] = true;
    });

    const staffToUpdate = staff.filter((s) => {
      return !!modifiedStaffIdMap[s.id];
    });

    await saveStaff(staffToUpdate, sourceModifiedStaff);
  };

  // Save services
  const updateCategoryAssignment = async (categoryId, assignedCategory) => {
    saveCategoryAssignmentMutation({
      variables: {
        categoryId,
        assignedCategory,
      },
      onCompleted: async () => {
        await refetchSessionTypeCatalog();
      },
    });
  };

  const saveOfferings = async (updatedOfferings, sourceModifiedOfferings) => {
    if (!updatedOfferings?.length) {
      return;
    }

    saveServicesMutation({
      variables: {
        services: updatedOfferings,
      },
      onCompleted: async (data) => {
        const success = data.editServices;

        if (success) {
          const justUpdatedOfferingIdMap = {};
          const lastUpdatedAtMap = {};
          sourceModifiedOfferings.map((s) => {
            const { id, lastUpdatedAt } = s;

            if (lastUpdatedAt) {
              lastUpdatedAtMap[lastUpdatedAt] = true;
            }
            justUpdatedOfferingIdMap[id] = true;
          });

          refetchSessionTypeCatalog();

          // Check for fresh updates
          const recentlyModifiedOfferings = modifiedOfferings.filter((s) => {
            return (
              !justUpdatedOfferingIdMap[s.id] ||
              !lastUpdatedAtMap[s.lastUpdatedAt]
            );
          });
          setModifiedOfferings(recentlyModifiedOfferings);
        } else {
          refetchSessionTypeCatalog();
        }
      },
    });
  };

  const autoSaveOfferings = async () => {
    const sourceModifiedOfferings = [...modifiedOfferings];

    const categories = sessionTypeCatalog?.categories;
    const packages = sessionTypeCatalog?.packages;

    const modifiedOfferingObjects = [];
    sourceModifiedOfferings.map((s) => {
      const { id, categoryId, type } = s;

      if (type === packageKey) {
        const modifiedPackage = packages[id];
        modifiedOfferingObjects.push(modifiedPackage);
      } else {
        const category = categories[categoryId];
        const typeKeyToSearch =
          type === addOnKey
            ? 'addOns'
            : type === dropInKey
            ? 'dropIns'
            : type === consultationKey
            ? 'consultations'
            : null;

        if (typeKeyToSearch) {
          let sessionTypesArr;
          if (category) {
            sessionTypesArr = category[typeKeyToSearch];
          } else {
            sessionTypesArr =
              sessionTypeCatalog?.[uncategorizedKey]?.[typeKeyToSearch];
          }
          const modifiedSessionTypeObject = sessionTypesArr.find(
            (s) => s.id === id,
          );
          modifiedOfferingObjects.push(modifiedSessionTypeObject);
        }
      }
    });

    // Should always be the case
    await saveOfferings(modifiedOfferingObjects, sourceModifiedOfferings);
  };

  // Save prompts
  const saveServiceBookingPrompt = async (prompt) => {
    saveServiceBookingPromptMutation({
      variables: {
        prompt,
      },
      onCompleted: async (data) => {
        const promptObject = prompt.prompt;
        const status = promptObject?.status;
        const promptInputObject = {
          id: promptObject?.id,
          categoryId: promptObject?.corpusCategoryId,
          status,
        };
        const resolutions = prompt.resolutions;
        updateServiceBookingPrompt(promptInputObject, status, resolutions);
        refetchServiceCorpus();
      },
    });
  };

  const autoSaveSelectedServiceBookingPrompt = async (
    promptToSave,
    inputCorpus = null,
  ) => {
    if (objectIsEmpty(promptToSave)) {
      return;
    }

    const corpusToUse = inputCorpus || serviceCorpus;

    const { str: promptToSaveStr, obj: promptToSaveObject } =
      getStringifiedPrompt(promptToSave, corpusToUse);

    if (promptToSaveStr) {
      const { id: promptId, categoryId } = promptToSave;
      const baseCategory = baseServiceCorpus[categoryId];
      const basePrompts = baseCategory?.prompts;

      if (basePrompts) {
        const basePromptObject = basePrompts[promptId];
        const basePrompt = basePromptObject?.prompt;
        const basePromptResolutions = basePromptObject?.resolutions;
        const basePromptStr = stringifyPromptObject(
          basePrompt,
          basePromptResolutions,
        );
        const hasChanged = promptToSaveStr !== basePromptStr;

        if (hasChanged) {
          await saveServiceBookingPrompt(promptToSaveObject);
        }
      }
    }
  };

  // Save FAQs
  const saveFaq = async (id, utterance, resolution) => {
    saveFaqMutation({
      variables: {
        id,
        utterance,
        resolution,
      },
      onCompleted: async (data) => {
        refetchServiceCorpus();
      },
    });
  };

  const autoSaveSelectedFaq = async (faqToSave, inputCorpus = null) => {
    if (objectIsEmpty(faqToSave)) {
      return;
    }

    const corpusToUse = inputCorpus || serviceCorpus;

    const inputFaq = faqToSave?.prompt ? faqToSave.prompt : faqToSave;
    const id = inputFaq?.id;

    const { obj: faqObject } = getStringifiedPrompt(
      {
        id,
        categoryId: inputFaq?.categoryId || inputFaq?.corpusCategoryId,
      },
      corpusToUse,
    );

    const faq = faqObject?.prompt;
    const resolutions = faqObject?.resolutions;

    const faqStr = getStringifiedFaq(faq, resolutions);

    if (faq && faqStr) {
      const { id: promptId, corpusCategoryId: categoryId } = faq;
      const baseCategory = baseServiceCorpus[categoryId];
      const basePrompts = baseCategory?.prompts;
      const basePromptObject = basePrompts[promptId];
      const basePrompt = basePromptObject?.prompt;
      const basePromptResolutions = basePromptObject?.resolutions;
      const basePromptStr = getStringifiedFaq(
        basePrompt,
        basePromptResolutions,
      );
      const hasChanged = faqStr !== basePromptStr;

      if (hasChanged) {
        await saveFaq(
          id,
          faqObject?.prompt?.utterance,
          faqObject?.resolutions?.[0],
        );
      }
    }
  };

  // Save policy
  const savePolicy = async (updatedPolicy) => {
    savePolicyMutation({
      variables: {
        policy: updatedPolicy,
      },
      onCompleted: (data) => {
        refetchPolicy();
      },
    });
  };

  const autoSavePolicy = async () => {
    if (!basePolicyData) {
      return;
    }

    if (JSON.stringify(policy) !== JSON.stringify(basePolicyData)) {
      savePolicy(policy);
    }
  };

  // Auto-save Location
  useEffect(() => {
    const intervalId = setInterval(() => {
      autoSaveLocationContactInfo();
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [location, baseLocationContactInfo]);

  // Auto-save Agent
  useEffect(() => {
    const intervalId = setInterval(() => {
      autoSaveAgentName();
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [agentName, baseAgentName]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      autoSaveAgentSettings();
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [agentSettings, baseAgentSettings]);

  // Auto-save closures
  useEffect(() => {
    const intervalId = setInterval(() => {
      autoSaveClosures();
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [closures, baseClosuresData]);

  // Auto-save Staff
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (modifiedStaff.length) {
        autoSaveStaff();
      }
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [modifiedStaff, staff, baseStaffData]);

  // Auto-save Services
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (modifiedOfferings.length) {
        autoSaveOfferings();
      }
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [modifiedOfferings, sessionTypeCatalog, baseSessionTypeCatalog]);

  // Auto-save Prompts/FAQs
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (selectedSubmodule === bookingFlowsSubmoduleKey) {
        autoSaveSelectedServiceBookingPrompt(selectedPrompt);
      } else if (
        selectedSubmodule === serviceFaqsSubmoduleKey ||
        selectedSubmodule === generalFaqsSubmoduleKey
      ) {
        autoSaveSelectedFaq(selectedPrompt);
      }
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [selectedPromptStr, serviceCorpus, baseServiceCorpus]);

  // Auto-save Policy
  useEffect(() => {
    const intervalId = setInterval(() => {
      autoSavePolicy();
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [policy, basePolicyData]);

  // Modify location
  const onChangeLocationContactInfo = (updatedLocation) => {
    setLocation({
      ...updatedLocation,
    });
  };

  const onChangeClosures = (updatedClosures) => {
    setClosures(updatedClosures);
  };

  // Modify agent
  const onChangeAgentName = (updatedName) => {
    setAgent({ ...agent, name: updatedName });
  };

  const onChangeAgentSettings = (updatedSettings) => {
    setAgentSettings({
      ...updatedSettings,
    });
  };

  // Modify staff
  const onChangeStaff = (updatedStaff) => {
    const id = updatedStaff?.id;

    if (!id) {
      return;
    }

    const updatedStaffArr = staff.map((s) => {
      if (s.id === id) {
        return updatedStaff;
      }
      return s;
    });

    setStaff(updatedStaffArr);
    setModifiedStaff([
      ...modifiedStaff,
      { id, lastUpdatedAt: new Date().toISOString() },
    ]);
  };

  // Modify services
  const onCreateOffering = async (createdService) => {
    const { id, categoryId, type } = createdService;

    let updatedSessionTypeCatalog;
    if (type === packageKey) {
      const packages = sessionTypeCatalog?.packages;

      updatedSessionTypeCatalog = {
        ...sessionTypeCatalog,
        packages: {
          ...packages,
          [id]: createdService,
        },
      };
    } else {
      const categories = sessionTypeCatalog?.categories;
      let categoryToUpdate = !categoryId
        ? sessionTypeCatalog?.[uncategorizedKey]
        : categories[categoryId];
      if (!categoryToUpdate) {
        categoryToUpdate = {};
      }

      const updatedCategory = {
        ...categoryToUpdate,
        [type]: [...(categoryToUpdate[type] || []), createdService],
      };

      if (!categoryId) {
        updatedSessionTypeCatalog = {
          ...sessionTypeCatalog,
          [uncategorizedKey]: updatedCategory,
        };
      } else {
        updatedSessionTypeCatalog = {
          ...sessionTypeCatalog,
          categories: {
            ...categories,
            [categoryId]: updatedCategory,
          },
        };
      }
    }
    setSessionTypeCatalog(updatedSessionTypeCatalog);
    setSnackbarMessage(`Created`);
    await refetchSessionTypeCatalog();
  };

  const displayChangesSavedSnackbar = () => {
    setSnackbarMessage(`Changes saved`);
  };

  const displaySavingErrorSnackbar = () => {
    setSnackbarMessage(`Error when saving, please retry or contact support`);
  };

  const updateOffering = (
    originalOffering,
    updatedOffering,
    showSnackbarMessage = false,
  ) => {
    const originalCategoryId = originalOffering?.categoryId;
    const originalType = originalOffering?.type;
    const { id, categoryId, type } = updatedOffering;

    let updatedSessionTypeCatalog;

    if (type === packageKey) {
      const packages = sessionTypeCatalog?.packages;

      updatedSessionTypeCatalog = {
        ...sessionTypeCatalog,
        packages: {
          ...packages,
          [id]: updatedOffering,
        },
      };
    } else {
      const categoryHasChanged = originalCategoryId !== categoryId;
      const typeHasChanged = originalType !== type;

      const originalTypeKeyToSearch =
        originalType === addOnKey
          ? 'addOns'
          : originalType === dropInKey
          ? 'dropIns'
          : originalType === consultationKey
          ? 'consultations'
          : null;
      const typeKeyToSearch = !typeHasChanged
        ? originalTypeKeyToSearch
        : type === addOnKey
        ? 'addOns'
        : type === dropInKey
        ? 'dropIns'
        : type === consultationKey
        ? 'consultations'
        : null;

      if (!typeKeyToSearch) {
        return;
      }

      const categories = sessionTypeCatalog?.categories;
      const wasInitiallyUncategorized = !originalCategoryId;
      const existingCategoryToUpdate = wasInitiallyUncategorized
        ? sessionTypeCatalog?.[uncategorizedKey]
        : categories[originalCategoryId];

      const originalTypeOfferings =
        existingCategoryToUpdate[originalTypeKeyToSearch];
      const updatedOfferings = originalTypeOfferings
        .map((s) => {
          if (s.id === id) {
            return categoryHasChanged || typeHasChanged
              ? null
              : updatedOffering;
          }
          return s;
        })
        .filter((s) => s != null);

      let updatedCategory = {
        ...existingCategoryToUpdate,
        [originalTypeKeyToSearch]: updatedOfferings,
      };

      if (typeHasChanged && !categoryHasChanged) {
        const newTypeOfferings = existingCategoryToUpdate[typeKeyToSearch];
        const newUpdatedOfferings = [...newTypeOfferings, updatedOffering];

        updatedCategory = {
          ...updatedCategory,
          [typeKeyToSearch]: newUpdatedOfferings,
        };
      }

      if (wasInitiallyUncategorized) {
        updatedSessionTypeCatalog = {
          ...sessionTypeCatalog,
          [uncategorizedKey]: {
            ...sessionTypeCatalog[uncategorizedKey],
            ...updatedCategory,
          },
        };
      } else {
        updatedSessionTypeCatalog = {
          ...sessionTypeCatalog,
          categories: {
            ...categories,
            [originalCategoryId]: updatedCategory,
          },
        };
      }

      if (categoryHasChanged) {
        const newCategoryToUpdate = categories[categoryId];
        const newCategoryTypeOfferings = newCategoryToUpdate[typeKeyToSearch];
        const newCategoryUpdatedOfferings = [
          ...newCategoryTypeOfferings,
          updatedOffering,
        ];

        const updatedNewCategory = {
          ...newCategoryToUpdate,
          [typeKeyToSearch]: newCategoryUpdatedOfferings,
        };

        updatedSessionTypeCatalog = {
          ...updatedSessionTypeCatalog,
          categories: {
            ...updatedSessionTypeCatalog.categories,
            [categoryId]: updatedNewCategory,
          },
        };
      }
    }

    setSessionTypeCatalog(updatedSessionTypeCatalog);
    setModifiedOfferings([
      ...modifiedOfferings,
      { id, categoryId, type, lastUpdatedAt: new Date().toISOString() },
    ]);

    if (showSnackbarMessage) {
      displayChangesSavedSnackbar();
    }
  };

  const onSaveUpdatedOffering = async (updatedOffering) => {
    saveServicesMutation({
      variables: {
        services: [updatedOffering],
      },
      onCompleted: async (data) => {
        await refetchSessionTypeCatalog();
        displayChangesSavedSnackbar();
      },
    });
  };

  const onSaveBulkServiceEdits = async (success) => {
    if (success) {
      await refetchSessionTypeCatalog();
      displayChangesSavedSnackbar();
    } else {
      displaySavingErrorSnackbar();
    }
  };

  // Modify packages
  const onSavePackage = async (isNew) => {
    setSnackbarMessage(`Package ${isNew ? 'created' : 'saved'}`);
    await refetchSessionTypeCatalog();
  };

  // Modify upsells
  const updateUpsell = (updatedService) => {
    const { id, categoryId, type, upsellSessionTypeIds } = updatedService;

    let updatedSessionTypeCatalog;
    if (!categoryId) {
      const updatedSessionTypes = sessionTypeCatalog[uncategorizedKey]?.[
        dropInKey
      ].map((s) => {
        if (s.id === id) {
          return {
            ...s,
            upsellSessionTypeIds,
          };
        }
        return s;
      });
      updatedSessionTypeCatalog = {
        ...sessionTypeCatalog,
        [uncategorizedKey]: {
          ...sessionTypeCatalog[uncategorizedKey],
          dropIns: updatedSessionTypes,
        },
      };
    } else {
      const categories = sessionTypeCatalog?.categories;
      const updatedSessionTypes = categories[categoryId]?.dropIns.map((s) => {
        if (s.id === id) {
          return {
            ...s,
            upsellSessionTypeIds,
          };
        }
        return s;
      });
      updatedSessionTypeCatalog = {
        ...sessionTypeCatalog,
        categories: {
          ...categories,
          [categoryId]: {
            ...categories[categoryId],
            dropIns: updatedSessionTypes,
          },
        },
      };
    }

    setSessionTypeCatalog(updatedSessionTypeCatalog);
    setModifiedOfferings([
      ...modifiedOfferings,
      {
        id,
        categoryId,
        type,
        upsellSessionTypeIds,
        lastUpdatedAt: new Date().toISOString(),
      },
    ]);
  };

  // Modify prompts
  const updateServiceBookingPrompt = (
    promptToSave,
    status,
    resolutions = null,
    triggerAutoSave = false,
  ) => {
    const { id: promptId, categoryId } = promptToSave;
    const categoryToUpdate = serviceCorpus[categoryId];
    const prompts = categoryToUpdate?.prompts;
    const promptToUpdate = prompts[promptId];

    const updatedPrompt = {
      ...promptToUpdate,
      prompt: {
        ...promptToUpdate['prompt'],
        status,
      },
      resolutions: resolutions || promptToUpdate.resolutions,
    };

    const updatedCorpus = {
      ...serviceCorpus,
      [categoryId]: {
        ...categoryToUpdate,
        prompts: {
          ...prompts,
          [promptId]: updatedPrompt,
        },
      },
    };
    setServiceCorpus(updatedCorpus);

    if (triggerAutoSave) {
      autoSaveSelectedServiceBookingPrompt(
        {
          id: updatedPrompt?.prompt?.id,
          categoryId: updatedPrompt?.prompt?.corpusCategoryId,
        },
        updatedCorpus,
      );
    }
  };

  // Modify FAQs
  const onCreateBookingFlow = (title, utterance, universal) => {
    const categoryId = createServicePromptModalCategory.id;

    createBookingFlowMutation({
      variables: {
        categoryId,
        title,
        utterance,
        universal,
      },
      onCompleted: async (data) => {
        const createdBookingFlow = data.createBookingFlow;
        const {
          id: createdId,
          status,
          title,
          utterance,
          key,
        } = createdBookingFlow;

        if (createdId) {
          refetchServiceCorpus();
          onChangeSelectedPrompt({
            id: createdId,
            categoryId,
            status,
            title,
            utterance,
            key,
            resolutions: [],
          });
          onChangeCollapsedSubmodules(categoryId, false);
        }
      },
    });
  };

  const onCreateFaq = (utterance, answer, universal) => {
    const categoryId = createServicePromptModalCategory.id;

    createFaqMutation({
      variables: {
        categoryId,
        title: utterance,
        utterance,
        answer,
        universal,
      },
      onCompleted: async (data) => {
        const createdFaq = data.createFaq;
        const {
          id: createdId,
          status,
          title,
          utterance,
          resolutions,
          key,
        } = createdFaq;

        if (createdId) {
          refetchServiceCorpus();
          onChangeSelectedPrompt({
            id: createdId,
            categoryId,
            status,
            title,
            utterance,
            key,
            resolutions,
          });
          onChangeCollapsedSubmodules(categoryId, false);
        }
      },
    });
  };

  const onUpdateFaq = ({ faq, utterance, answer }) => {
    const { id: promptId, corpusCategoryId: categoryId } = faq;
    const categoryToUpdate = serviceCorpus[categoryId];
    const prompts = categoryToUpdate?.prompts;
    const promptToUpdate = prompts[promptId];

    const currentCategoryId = promptToUpdate?.prompt?.corpusCategoryId;

    const updatedPrompt = {
      ...promptToUpdate,
      prompt: {
        ...promptToUpdate['prompt'],
        utterance: utterance || promptToUpdate?.prompt?.utterance,
        corpusCategoryId: categoryId || currentCategoryId,
      },
      resolutions:
        answer != null
          ? [{ ...promptToUpdate.resolutions[0], explanation: answer }]
          : [...promptToUpdate.resolutions],
    };

    const updatedCorpus = {
      ...serviceCorpus,
      [categoryId]: {
        ...categoryToUpdate,
        prompts: {
          ...prompts,
          [promptId]: updatedPrompt,
        },
      },
    };

    setServiceCorpus(updatedCorpus);
  };

  const onDeletePrompt = (id) => {
    deletePromptMutation({
      variables: {
        id,
      },
      onCompleted: () => {
        refetchServiceCorpus();

        if (selectedPrompt.id === id) {
          onChangeSelectedPrompt();
        }
      },
    });
  };

  // Modify deposits
  const onChangeBookingPolicy = (field, updatedPolicy) => {
    setPolicy((prevPolicy) => ({
      ...prevPolicy,
      [field]: {
        ...prevPolicy[field],
        ...updatedPolicy,
      },
    }));
  };

  const onChangeCancellationsPolicy = (updatedCancellationsPolicy) => {
    setPolicy((prevPolicy) => ({
      ...prevPolicy,
      cancellation: {
        ...prevPolicy.cancellation,
        ...updatedCancellationsPolicy,
      },
    }));
  };

  const onChangeFollowUpsPolicy = (updatedFollowUpsPolicy) => {
    setPolicy({
      ...policy,
      followUp: {
        ...updatedFollowUpsPolicy,
      },
    });
  };

  const onChangeLastMinutePolicy = (updatedLastMinutePolicy) => {
    setPolicy({
      ...policy,
      lastMinute: {
        ...updatedLastMinutePolicy,
      },
    });
  };

  const onChangePaymentsPolicy = (acceptedType, shouldAdd) => {
    const currentPaymentPolicy = policy?.payment;
    const currentAcceptedTypes = currentPaymentPolicy?.acceptedTypes || [];
    const updatedAcceptedTypes = shouldAdd
      ? [...currentAcceptedTypes, acceptedType]
      : currentAcceptedTypes.filter((t) => t !== acceptedType);
    setPolicy({
      ...policy,
      payment: {
        ...currentPaymentPolicy,
        acceptedTypes: Array.from(new Set(updatedAcceptedTypes)),
      },
    });
  };

  // On change selections
  const onChangeCollapsedModules = (key, shouldAdd) => {
    let updatedCollapsedModules;
    if (shouldAdd) {
      updatedCollapsedModules = [...collapsedModules, key];
    } else {
      updatedCollapsedModules = collapsedModules.filter((k) => k !== key);
    }

    const updatedCollapsedModuleString = parseCookieValueString(
      updatedCollapsedModules,
    );

    setCookie(
      collapsedModulesCookieKey,
      updatedCollapsedModuleString,
      cookieExpiryObject,
    );

    const subModules = modules[key]['submodules'];
    if (
      !shouldAdd &&
      subModules.map((s) => s.key).includes(selectedSubmodule)
    ) {
      onChangeSelectedSubmodule('');
    }
  };

  const onChangeCollapsedSubmodules = (key, shouldAdd) => {
    let updatedCollapsedSubmodules;
    if (shouldAdd) {
      updatedCollapsedSubmodules = [...collapsedSubmodules, key];
    } else {
      updatedCollapsedSubmodules = collapsedSubmodules.filter((k) => k !== key);
    }

    if (updatedCollapsedSubmodules.length === collapsedSubmodules) {
      return;
    }

    const updatedCollapsedSubmodulestring = parseCookieValueString(
      updatedCollapsedSubmodules,
    );

    setCookie(
      collapsedSubmodulesCookieKey,
      updatedCollapsedSubmodulestring,
      cookieExpiryObject,
    );
  };

  const autoSaveOnBlur = (previousSelectedSubmodule) => {
    if (previousSelectedSubmodule === agentInfoKey) {
      autoSaveAgentName();
    } else if (
      previousSelectedSubmodule === agentStyleKey ||
      previousSelectedSubmodule === deploymentKey ||
      previousSelectedSubmodule === messagingKey
    ) {
      autoSaveAgentSettings();
    } else if (previousSelectedSubmodule === contactSubmoduleKey) {
      autoSaveLocationContactInfo();
    } else if (previousSelectedSubmodule === closuresSubmoduleKey) {
      autoSaveClosures();
    } else if (previousSelectedSubmodule === staffSubmoduleKey) {
      autoSaveStaff();
    } else if (previousSelectedSubmodule === bookingFlowsSubmoduleKey) {
      autoSaveSelectedServiceBookingPrompt(selectedPrompt);
    } else if (
      previousSelectedSubmodule === serviceFaqsSubmoduleKey ||
      previousSelectedSubmodule === generalFaqsSubmoduleKey
    ) {
      autoSaveSelectedFaq(selectedPrompt);
    } else if (
      previousSelectedSubmodule === offeringsSubmoduleKey ||
      previousSelectedSubmodule === upsellsSubmoduleKey
    ) {
      autoSaveOfferings();
    } else if (policySubmodules.includes(previousSelectedSubmodule)) {
      autoSavePolicy();
    }
  };

  const onChangeSelectedSubmodule = (updatedSelectedSubmodule) => {
    onChangeSelectedPrompt();

    setCookie(
      selectedSubmoduleCookieKey,
      updatedSelectedSubmodule,
      cookieExpiryObject,
    );
  };

  const onChangeSelectedPrompt = (updatedSelectedPrompt) => {
    autoSaveOnBlur(selectedSubmodule);

    setCookie(
      selectedPromptCookieKey,
      updatedSelectedPrompt || {},
      cookieExpiryObject,
    );
  };

  const categoryInclusionArr = [];

  return (
    <>
      <MetaSetter
        title={`Training`}
        description={`Train your LiveIQ Agent`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
        moduleDrawerDisplayed
      >
        <ModulesContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          submoduleDrawerExpanded={
            (submoduleDrawerExpanded ||
              selectedSubmodule === upsellsSubmoduleKey) &&
            !templateSyncing
          }
          serviceSubmoduleDrawerExpanded={
            selectedSubmodule === upsellsSubmoduleKey && !templateSyncing
          }
          ref={contentContainerRef}
          largeBottomPadding
        >
          <CenteredDivWithGap>
            <PageTitleText>
              Training
              {selectedSubmodule && ` - ${formatKeyToLabel(selectedSubmodule)}`}
            </PageTitleText>
            {editingMode && !templateSyncing && (
              <EditingModeComponent
                onClick={(e) => setEditingModeMenuAnchorEl(e.currentTarget)}
                mode={editingMode}
              >
                <Tooltip
                  title={
                    <TooltipTitleText>
                      {editingModeComponent.text}
                    </TooltipTitleText>
                  }
                >
                  <editingModeComponent.icon />
                </Tooltip>
                <OverhangTextContainer mode={editingMode}>
                  <OverhangText>{editingModeComponent.header}</OverhangText>
                </OverhangTextContainer>
              </EditingModeComponent>
            )}
          </CenteredDivWithGap>
          <ModulesDrawer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
          >
            {moduleStatusLoading ? (
              <DrawerLoadingIndicatorContainer>
                <LoadingIndicator size={40} />
              </DrawerLoadingIndicatorContainer>
            ) : (
              <>
                <ModulesDrawerHeaderContainer>
                  <DarkEssText>Modules</DarkEssText>
                  <EndAlignedFlexDiv gap={4}>
                    <AgentAvatarContainer>
                      <Tooltip
                        title={
                          <TooltipTitleText>
                            "Let's customize your business guidelines"
                            <br></br> - AI Receptionist
                          </TooltipTitleText>
                        }
                        placement='bottom'
                      >
                        <ReceptionistAvatar />
                      </Tooltip>
                    </AgentAvatarContainer>
                    {corporationId && (
                      <Tooltip
                        title={
                          <TooltipTitleText>
                            {templateSyncing
                              ? `Syncing data from '${templateSyncSource}' (started ${templateSyncTimestamp})`
                              : templateSyncStatus === completeStatusKey
                              ? `Last synced data from '${templateSyncSource}' (${templateSyncTimestamp})`
                              : 'Sync your data from an existing location template'}
                          </TooltipTitleText>
                        }
                        placement='bottom'
                      >
                        <RelativeDiv>
                          <SyncIcon
                            small
                            onClick={() => setTemplateSyncModalOpen(true)}
                          />
                          {templateSyncing ? (
                            <OverhangIconContainer status={templateSyncStatus}>
                              <OverhangInProgressIcon />
                            </OverhangIconContainer>
                          ) : templateSyncStatus === completeStatusKey ? (
                            <OverhangIconContainer status={templateSyncStatus}>
                              <OverhangCheckmarkIcon />
                            </OverhangIconContainer>
                          ) : (
                            <></>
                          )}
                        </RelativeDiv>
                      </Tooltip>
                    )}
                  </EndAlignedFlexDiv>
                </ModulesDrawerHeaderContainer>
                <AutoScrollWrapper offset={94}>
                  {moduleArr.map((key, idx) => {
                    const m = modules[key];
                    const { label, submodules } = m;
                    const labelToUse = label;
                    const isExpanded = !collapsedModules.includes(key);

                    const submoduleStatusMap = moduleStatusMap[key] || {};
                    const submoduleStatuses = Object.values(submoduleStatusMap);

                    const moduleIsComplete =
                      !!submoduleStatuses.length &&
                      !submoduleStatuses.some((s) => s === false);
                    return (
                      <ModuleContainer top={idx === 0}>
                        <ModuleListItem>
                          <ModuleNameContainer>
                            {moduleIsComplete ? CompleteIcon : IncompleteIcon}
                            <ModuleNameText selected={selectedModule === key}>
                              {labelToUse}
                            </ModuleNameText>
                          </ModuleNameContainer>
                          <ModuleIconsContainer>
                            <m.icon />
                            {isExpanded ? (
                              <ModuleUpArrow
                                onClick={() => {
                                  onChangeCollapsedModules(key, true);
                                }}
                              />
                            ) : (
                              <ModuleDownArrow
                                onClick={() => {
                                  onChangeCollapsedModules(key, false);
                                }}
                              />
                            )}
                          </ModuleIconsContainer>
                        </ModuleListItem>
                        {isExpanded && (
                          <SubmodulesContainer>
                            {(submodules || []).map((s) => {
                              const { key, label } = s;
                              const selected = key === selectedSubmodule;

                              const comingSoon =
                                comingSoonModules.includes(key);
                              const submoduleIsComplete =
                                !!submoduleStatusMap[key];
                              return (
                                <SubmoduleContainer
                                  onClick={() => {
                                    onChangeSelectedSubmodule(key);
                                  }}
                                >
                                  <SubmoduleText selected={selected}>
                                    {label}
                                  </SubmoduleText>
                                  {comingSoon
                                    ? ComingSoonIcon
                                    : submoduleIsComplete
                                    ? CompleteIcon
                                    : IncompleteIcon}
                                </SubmoduleContainer>
                              );
                            })}
                          </SubmodulesContainer>
                        )}
                      </ModuleContainer>
                    );
                  })}
                  <Tooltip
                    title={
                      <TooltipTitleText>View launch checklist</TooltipTitleText>
                    }
                    placement='top'
                  >
                    <ModulesCompletionStatusContainer
                      onClick={() => setLaunchChecklistModalOpen(true)}
                    >
                      {completionPercentage === '100' ? (
                        <>
                          <SuccessTinyText>Training Complete</SuccessTinyText>
                          <CompletionCheckmark />
                        </>
                      ) : (
                        <>
                          <MediumDarkTinyText>
                            {moduleStatusMap['percentage'] || 0}% Complete
                          </MediumDarkTinyText>
                          <CompletionCircle
                            value={moduleStatusMap['percentage']}
                          />
                        </>
                      )}
                    </ModulesCompletionStatusContainer>
                  </Tooltip>
                </AutoScrollWrapper>
              </>
            )}
          </ModulesDrawer>
          {submoduleDrawerExpanded && !moduleStatusLoading && (
            <SubmodulesDrawer
              drawerOpen={drawerOpen}
              drawerExpanded={drawerExpanded}
            >
              {serviceCorpusLoading ? (
                <AbsoluteLoadingIndicatorContainer>
                  <LoadingIndicator />
                </AbsoluteLoadingIndicatorContainer>
              ) : (
                <SubmoduleCardScrollContainer>
                  {sortedCategories.map((category, idx) => {
                    const {
                      id: categoryId,
                      name,
                      prompts,
                      type,
                      included,
                    } = category;
                    const formattedName = formatKeyToLabel(name);
                    const typeToFilterOn =
                      selectedSubmodule === bookingFlowsSubmoduleKey
                        ? [serviceCategoryTypeKey]
                        : selectedSubmodule === serviceFaqsSubmoduleKey
                        ? [serviceCategoryTypeKey, generalCategoryTypeKey]
                        : selectedSubmodule === generalFaqsSubmoduleKey
                        ? [generalCategoryTypeKey]
                        : null;

                    const displayed =
                      typeToFilterOn.includes(type) &&
                      (selectedSubmodule === bookingFlowsSubmoduleKey ||
                        ((selectedSubmodule === serviceFaqsSubmoduleKey ||
                          selectedSubmodule === generalFaqsSubmoduleKey) &&
                          included));

                    let shouldDisplayExclusionBanner;
                    if (displayed) {
                      const numExistingInclusions = categoryInclusionArr.length;
                      const previousInclusion = numExistingInclusions
                        ? categoryInclusionArr[numExistingInclusions - 1]
                        : null;
                      shouldDisplayExclusionBanner = !previousInclusion
                        ? false
                        : !included && previousInclusion === true;
                      categoryInclusionArr.push(included);
                    }

                    let categoryIsComplete = true;

                    const promptObjects = sortBy(
                      Object.keys(prompts).map((id) => {
                        const promptObject = prompts[id]?.prompt;
                        if (
                          categoryIsComplete &&
                          promptObject.status !== completeStatusKey &&
                          ((selectedSubmodule === bookingFlowsSubmoduleKey &&
                            promptObject.type === 'booking') ||
                            (selectedSubmodule !== bookingFlowsSubmoduleKey &&
                              promptObject.type !== 'booking'))
                        ) {
                          categoryIsComplete = false;
                        }
                        return promptObject;
                      }),
                      (p) =>
                        p.key === defaultServicePromptKey
                          ? -1
                          : p.key === defaultMultipersonServicePromptKey
                          ? 0
                          : 1,
                      (p) => p.title,
                    );

                    const categoryIsExpanded =
                      !collapsedSubmodules.includes(categoryId);

                    return (
                      displayed && (
                        <>
                          {shouldDisplayExclusionBanner && (
                            <ListBannerContainer>
                              <ListBannerTextContainer>
                                <LightErrorTinyText centerElements>
                                  Excluded Categories{' '}
                                  {excludedOfferingCategoriesShown ? (
                                    <CategoryUpArrow
                                      onClick={() => {
                                        setExcludedOfferingCategoriesShown(
                                          false,
                                        );
                                      }}
                                    />
                                  ) : (
                                    <CategoryDownArrow
                                      onClick={() => {
                                        setExcludedOfferingCategoriesShown(
                                          true,
                                        );
                                      }}
                                    />
                                  )}
                                </LightErrorTinyText>
                              </ListBannerTextContainer>
                            </ListBannerContainer>
                          )}
                          {!(!excludedOfferingCategoriesShown && !included) && (
                            <SubmodulesSection>
                              <SubmoduleSectionHeaderContainer>
                                <SubmoduleNameContainer>
                                  {categoryIsComplete
                                    ? CompleteIcon
                                    : IncompleteIcon}
                                  <SubmoduleNameText>
                                    {formattedName}
                                  </SubmoduleNameText>
                                </SubmoduleNameContainer>
                                {categoryIsExpanded ? (
                                  <ModuleUpArrow
                                    onClick={() => {
                                      onChangeCollapsedSubmodules(
                                        categoryId,
                                        true,
                                      );
                                    }}
                                  />
                                ) : (
                                  <ModuleDownArrow
                                    onClick={() => {
                                      onChangeCollapsedSubmodules(
                                        categoryId,
                                        false,
                                      );
                                    }}
                                  />
                                )}
                              </SubmoduleSectionHeaderContainer>
                              {categoryIsExpanded && (
                                <SubmoduleCardSectionContainer>
                                  {promptObjects.map((prompt) => {
                                    const id = prompt.id;
                                    const promptObject = prompts[id];
                                    const resolutions =
                                      promptObject?.resolutions;
                                    const status = prompt?.status;
                                    const title = prompt?.title;
                                    const key = prompt?.key;

                                    const titleCharLimit = 25;
                                    const formattedTitle =
                                      title.length > titleCharLimit
                                        ? `${title
                                            .slice(0, titleCharLimit)
                                            .trim()}...`
                                        : title;

                                    const submoduleTypeToFilterOn =
                                      selectedSubmodule ===
                                      bookingFlowsSubmoduleKey
                                        ? bookingPromptTypeKey
                                        : selectedSubmodule ===
                                            serviceFaqsSubmoduleKey ||
                                          selectedSubmodule ===
                                            generalFaqsSubmoduleKey
                                        ? faqPromptTypeKey
                                        : null;
                                    const type = prompt?.type;

                                    if (type !== submoduleTypeToFilterOn) {
                                      return <></>;
                                    }

                                    const isCustomToLocation =
                                      prompt?.key === locationCustomPromptKey;
                                    const isUniversalCustom =
                                      prompt?.key?.includes(universalPromptKey);

                                    const corpusCategoryId =
                                      prompt?.corpusCategoryId;
                                    const categoryName = prompt?.categoryName;
                                    const selected = selectedPrompt?.id === id;
                                    const complete =
                                      prompt?.status === completeStatusKey;

                                    return (
                                      <>
                                        <SubmoduleCard
                                          onClick={() => {
                                            const promptObject = {
                                              id,
                                              categoryId: corpusCategoryId,
                                              categoryName,
                                              title,
                                              status,
                                              key,
                                              utterance: prompt.utterance,
                                              resolutions,
                                            };
                                            onChangeSelectedPrompt(
                                              promptObject,
                                            );
                                          }}
                                          selected={selected}
                                          disableBoxShadow={!selected}
                                        >
                                          <SubmoduleCardText>
                                            {formattedTitle}
                                          </SubmoduleCardText>
                                          {complete
                                            ? CompleteIcon
                                            : IncompleteIcon}
                                          {(isCustomToLocation ||
                                            (isUniversalCustom &&
                                              isSuperAdmin)) && (
                                            <CancelIconContainer
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                onDeletePrompt(id);
                                              }}
                                            >
                                              <RedCancelIcon extraSmall />
                                            </CancelIconContainer>
                                          )}
                                        </SubmoduleCard>
                                      </>
                                    );
                                  })}
                                </SubmoduleCardSectionContainer>
                              )}
                              {(selectedSubmodule === serviceFaqsSubmoduleKey ||
                                selectedSubmodule ===
                                  bookingFlowsSubmoduleKey) && (
                                <AddSubmoduleCardButtonContainer>
                                  <AddSubmoduleCardButton
                                    onClick={() => {
                                      if (
                                        selectedSubmodule ===
                                        serviceFaqsSubmoduleKey
                                      ) {
                                        setCreateFaqModalOpen(true);
                                      } else {
                                        setCreateBookingFlowModalOpen(true);
                                      }
                                      setCreateServicePromptModalCategory({
                                        id: categoryId,
                                        name: formattedName,
                                      });
                                    }}
                                  >
                                    <ExtraSmallAddIcon green />
                                    <LightExtraTinyText>Add</LightExtraTinyText>
                                  </AddSubmoduleCardButton>
                                </AddSubmoduleCardButtonContainer>
                              )}
                            </SubmodulesSection>
                          )}
                        </>
                      )
                    );
                  })}
                </SubmoduleCardScrollContainer>
              )}
            </SubmodulesDrawer>
          )}
          {templateSyncing ? (
            <ColumnCenteredDiv topMargin={20}>
              <SmallText>
                Unable to edit while template data sync is in progress
                <br></br>
                Template data is being synced from '{templateSyncSource}'
                <br></br>
                {templateSyncTimestamp}
              </SmallText>
              <InProgressIcon />
            </ColumnCenteredDiv>
          ) : selectedSubmodule && !moduleStatusLoading ? (
            <AnswerSection remainingHeight={remainingHeight}>
              {selectedSubmodule === agentInfoKey ? (
                <Info
                  agent={agent}
                  loading={userLoading}
                  onChange={onChangeAgentName}
                  onBlur={() => autoSaveAgentName()}
                />
              ) : selectedSubmodule === deploymentKey ? (
                <PhoneSettings
                  agentName={agentName}
                  agentSettings={agentSettings}
                  loading={agentSettingsLoading}
                  onChange={onChangeAgentSettings}
                  onBlur={() => autoSaveAgentSettings()}
                />
              ) : selectedSubmodule === messagingKey ? (
                <Messaging
                  agentName={agentName}
                  agentSettings={agentSettings}
                  loading={agentSettingsLoading}
                  onChange={onChangeAgentSettings}
                  onBlur={() => autoSaveAgentSettings()}
                />
              ) : selectedSubmodule === agentStyleKey ? (
                <TextSettings
                  agentName={agentName}
                  agentSettings={agentSettings}
                  loading={agentSettingsLoading}
                  onChange={onChangeAgentSettings}
                  onBlur={() => autoSaveAgentSettings()}
                />
              ) : selectedSubmodule === addressSubmoduleKey ? (
                <Location res={overviewRes} />
              ) : selectedSubmodule === hoursSubmoduleKey ? (
                <Hours res={overviewRes} />
              ) : selectedSubmodule === linksSubmoduleKey ? (
                <Links res={overviewRes} />
              ) : selectedSubmodule === contactSubmoduleKey ? (
                <Contact
                  agentName={agentName}
                  location={location}
                  onChange={onChangeLocationContactInfo}
                  loading={userLoading}
                />
              ) : selectedSubmodule === closuresSubmoduleKey ? (
                <Closures
                  closuresData={closures}
                  onChange={onChangeClosures}
                  agentName={agentName}
                  location={location}
                  loading={userLoading}
                />
              ) : selectedSubmodule === categoriesSubmoduleKey ? (
                <Categories
                  loading={serviceCorpusLoading}
                  baseCategories={sortedCategories}
                  refetch={refetchServiceCorpus}
                  locationId={userLocationId}
                />
              ) : selectedSubmodule === staffSubmoduleKey ? (
                <Staff
                  staff={staff}
                  onUpdate={onChangeStaff}
                  onSave={onSaveStaff}
                  loading={staffLoading}
                  agentName={agentName}
                  remainingHeight={remainingHeight}
                />
              ) : selectedSubmodule === offeringsSubmoduleKey ? (
                <Services
                  loading={sessionTypesLoading}
                  sessionTypeCatalog={sessionTypeCatalog}
                  onUpdate={updateOffering}
                  onCreate={onCreateOffering}
                  onSave={onSaveUpdatedOffering}
                  onSaveBulk={onSaveBulkServiceEdits}
                  displayChangesSavedSnackbar={displayChangesSavedSnackbar}
                  onUpdateCategoryAssignment={updateCategoryAssignment}
                  agentName={agentName}
                  calendarPlatform={calendarPlatform}
                  remainingHeight={remainingHeight}
                />
              ) : selectedSubmodule === packagesSubmoduleKey ? (
                <Packages
                  loading={sessionTypesLoading}
                  sessionTypeCatalog={sessionTypeCatalog}
                  onSave={onSavePackage}
                  agentName={agentName}
                  remainingHeight={remainingHeight}
                />
              ) : selectedSubmodule === upsellsSubmoduleKey ? (
                <Upsells
                  drawerOpen={drawerOpen}
                  drawerExpanded={drawerExpanded}
                  sessionTypeCatalog={sessionTypeCatalog}
                  agentName={agentName}
                  calendarPlatform={calendarPlatform}
                  onUpdate={updateUpsell}
                />
              ) : selectedSubmodule === bookingFlowsSubmoduleKey ? (
                <BookingFlows
                  serviceCorpus={serviceCorpus}
                  loading={sessionTypesLoading}
                  saving={saveBookingPromptLoading}
                  sessionTypeCatalog={sessionTypeCatalog}
                  agentName={agentName}
                  selectedPrompt={selectedPrompt}
                  onChangePromptResolutions={(
                    updatedStatus,
                    updatedResolutions,
                  ) =>
                    updateServiceBookingPrompt(
                      selectedPrompt,
                      updatedStatus,
                      updatedResolutions,
                    )
                  }
                  onChangePromptStatus={(status) =>
                    updateServiceBookingPrompt(
                      selectedPrompt,
                      status,
                      null,
                      true,
                    )
                  }
                  remainingHeight={remainingHeight}
                />
              ) : selectedSubmodule === serviceFaqsSubmoduleKey ? (
                <ServiceFAQ
                  selectedFaq={selectedPrompt}
                  serviceCorpus={serviceCorpus}
                  refetch={refetchServiceCorpus}
                  onUpdate={onUpdateFaq}
                  agentName={agentName}
                />
              ) : selectedSubmodule === generalFaqsSubmoduleKey ? (
                <GeneralFAQ
                  selectedFaq={selectedPrompt}
                  serviceCorpus={serviceCorpus}
                  refetch={refetchServiceCorpus}
                  onUpdate={onUpdateFaq}
                  agentName={agentName}
                />
              ) : selectedSubmodule === membershipsSubmoduleKey ? (
                <Memberships res={membershipsRes} />
              ) : selectedSubmodule === assetsSubmoduleKey ? (
                <Assets agentName={agentName} />
              ) : selectedSubmodule === bookingSubmoduleKey ? (
                <Booking
                  policy={policy}
                  onChange={onChangeBookingPolicy}
                  loading={policyLoading}
                />
              ) : comingSoonModules.includes(selectedSubmodule) ? (
                <AnswerTextContainer topAligned>
                  <SMText>Coming soon</SMText>
                </AnswerTextContainer>
              ) : selectedSubmodule === cancellationSubmoduleKey ? (
                <Cancellations
                  policy={policy}
                  loading={policyLoading}
                  onChange={onChangeCancellationsPolicy}
                />
              ) : selectedSubmodule === followUpsSubmoduleKey ? (
                <FollowUps
                  policy={policy}
                  onChange={onChangeFollowUpsPolicy}
                  links={overviewData?.links}
                  loading={policyLoading}
                />
              ) : selectedSubmodule === scheduleFillUpSubmoduleKey ? (
                <LastMinute
                  policy={policy}
                  onChange={onChangeLastMinutePolicy}
                  loading={policyLoading}
                />
              ) : selectedSubmodule === paymentsSubmoduleKey ? (
                <Payments
                  policy={policy}
                  onChange={onChangePaymentsPolicy}
                  loading={policyLoading}
                />
              ) : (
                <></>
              )}
            </AnswerSection>
          ) : selectedSubmodule === bookingFlowsSubmoduleKey ? (
            <EmptySelectOptionsContainer>
              <LightDarkSmallText>
                Select a module to train {agentName} on
              </LightDarkSmallText>
              <SelectIcon />
            </EmptySelectOptionsContainer>
          ) : (
            <></>
          )}
        </ModulesContentContainer>
      </PageContainer>
      <PopperMenu
        open={editingModeMenuOpen}
        anchorElement={editingModeMenuAnchorEl}
        onClose={() => {
          setEditingModeMenuAnchorEl();
        }}
        variant='offset'
        placement='bottom'
      >
        <SmallMenuItemTitleContainer disableRipple>
          <SmallMenuItemTitleText>Select editing mode</SmallMenuItemTitleText>
        </SmallMenuItemTitleContainer>
        <MenuItem
          disabled={editingMode === locationEditingMode}
          onClick={() => onChangeEditingMode(locationEditingMode)}
        >
          <SmallMenuItemText>
            Location{editingMode === locationEditingMode && ` (current)`}
          </SmallMenuItemText>
          <LocationIcon small />
        </MenuItem>
        <MenuItem
          disabled={editingMode === portfolioEditingMode}
          onClick={() => onChangeEditingMode(portfolioEditingMode)}
        >
          <SmallMenuItemText>
            Portfolio{editingMode === portfolioEditingMode && ` (current)`}
          </SmallMenuItemText>
          <PortfolioIcon small />
        </MenuItem>
        <MenuItem
          disabled={editingMode === corporationEditingMode}
          onClick={() => onChangeEditingMode(corporationEditingMode)}
        >
          <SmallMenuItemText>
            Corporation{editingMode === corporationEditingMode && ` (current)`}
          </SmallMenuItemText>
          <CorporationIcon small />
        </MenuItem>
      </PopperMenu>
      <CreateBookingFlowModal
        isOpen={createBookingFlowModalOpen}
        onClose={() => {
          setCreateBookingFlowModalOpen(false);
          setCreateServicePromptModalCategory();
        }}
        category={createServicePromptModalCategory}
        onCreate={onCreateBookingFlow}
      />
      <CreateFAQModal
        isOpen={createFaqModalOpen}
        onClose={() => {
          setCreateFaqModalOpen(false);
          setCreateServicePromptModalCategory();
        }}
        category={createServicePromptModalCategory}
        onCreate={onCreateFaq}
      />
      <AgentVideoMessageModal
        isOpen={agentVideoMessageModalOpen}
        onClose={() => setAgentVideoMessageModalOpen(false)}
        agentName={agentName}
      />
      <OrganizationTemplateSyncModal
        isOpen={templateSyncModalOpen}
        onClose={() => setTemplateSyncModalOpen(false)}
        currentLocationId={userLocationId}
        onSync={onSync}
      />
      <LaunchChecklistModal
        isOpen={launchChecklistModalOpen}
        onClose={() => setLaunchChecklistModalOpen(false)}
      />
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Training;
