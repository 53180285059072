import { default as React, useState } from 'react';
import {
  ContainerWithInputWidth,
  StartAlignedMediumDarkExtraSmallText,
} from '../../../../styles/shared-styled-components';
import { MenuCalendar } from '../../../Schedule/Calendar/styled';
import {
  PromptContainer,
  PromptsSection,
} from '../../shared-training-components';

const Closures = ({ closuresData, onChange }) => {
  const closures = closuresData || [];

  const [viewDate, setViewDate] = useState(new Date());

  const onChangeClosure = (date) => {
    const formatted = new Date(date).toISOString().slice(0, 10);
    const shouldAdd = !closures.includes(formatted);
    if (shouldAdd) {
      onChange([...closures, formatted]);
    } else {
      onChange(closures.filter((d) => d !== formatted));
    }
  };

  const isDateSelected = (date) => {
    const formatted = new Date(date).toISOString().slice(0, 10);
    return closures.includes(formatted);
  };

  return (
    <PromptsSection>
      <PromptContainer addMediumGap>
        <ContainerWithInputWidth useSmallWidth>
          <StartAlignedMediumDarkExtraSmallText>
            If your business will be closed on specific days outside of your
            regular weekly hours, you can mark them here
          </StartAlignedMediumDarkExtraSmallText>
        </ContainerWithInputWidth>
        <MenuCalendar
          hideBorder
          key={closures.join(',')}
          onChange={onChangeClosure}
          locale='en-US'
          selectedDates={closures}
          tileClassName={({ date }) =>
            isDateSelected(date) ? 'react-calendar__tile--active' : 'tile'
          }
          activeStartDate={viewDate}
          onActiveStartDateChange={({ activeStartDate }) =>
            setViewDate(activeStartDate)
          }
        />
      </PromptContainer>
    </PromptsSection>
  );
};

export default Closures;
