import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Paths from '../../../Paths';
import { useAgentById } from '../../../api/hooks/agent';
import { INITIALIZE_APP } from '../../../api/mutations/app-connections';
import { GET_GHL_AUTH_URI } from '../../../api/queries/app-connections';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import AuthenticationPromptModal from '../../../components/Modals/AuthenticationPromptModal';
import MindBodyActivationCodeUrlModal from '../../../components/Modals/MindBodyActivationCodeUrlModal/MindBodyActivationCodeUrlModal';
import MindBodySiteIDModal from '../../../components/Modals/MindBodySiteIDModal';
import TwilioModal from '../../../components/Modals/TwilioModal';
import ZenotiAPIKeyModal from '../../../components/Modals/ZenotiAPIKeyModal';
import Header from '../../../components/NavBar/Header';
import PopperMenu from '../../../components/PopperMenu';
import Snackbar from '../../../components/Snackbar';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  CenteredDiv,
  ColumnCenteredDiv,
  CompleteButtonCheckmarkIcon,
  ContentContainer,
  DarkMLText,
  LightDarkSMText,
  MediumDarkSMText,
  MenuItem,
  PageContainer,
  PrimaryButton,
  SmallMenuItemText,
  SuccessfulPrimaryButton,
} from '../../../styles/shared-styled-components';
import {
  clientReactivationAgentProductKey,
  connectedStatusKey,
  ghlAppKey,
  ghlKey,
  hubSpotKey,
  inProgressStatusKey,
  initializedStatusKey,
  leadConversionAgentProductKey,
  meevoKey,
  messagingPlatformKey,
  metaAdsKey,
  mindBodyKey,
  pmsAppKey,
  twilioKey,
  unconnectedStatusKey,
  websiteSupportAgentProductKey,
  zenotiKey,
} from '../../../utils/constants';
import { getUserConnections, getUserLocation } from '../../../utils/user';
import { ConnectAppsContainer } from './styled';

const connectionsRequiredForAgentMap = {
  [clientReactivationAgentProductKey]: [pmsAppKey],
  [leadConversionAgentProductKey]: [ghlAppKey, pmsAppKey],
  [websiteSupportAgentProductKey]: [ghlAppKey, pmsAppKey],
};

const getConnectionsRequiredText = (connectionsRequired) => {
  return { connectionsRequiredTitle: `your EMR & Marketing systems` };
};

const metaAdsAppId = process.env.REACT_APP_META_ADS_APP_ID;
const metaAdScopes = [
  'read_insights',
  'ads_management',
  'ads_read',
  'business_management',
  'whatsapp_business_management',
  'whatsapp_business_messaging',
];

const AgentAppConnection = () => {
  const navigate = useNavigate();
  const { user, refetchUser, drawerOpen, drawerExpanded } =
    useContext(BaseContext);
  const { agentId } = useParams();

  const { agent, loading: agentLoading } = useAgentById({
    id: agentId,
  });
  const [getGHLAuthUri, { data: ghlAuthUriData }] = useLazyQuery(
    GET_GHL_AUTH_URI,
    { fetchPolicy: 'network-only' },
  );
  const [initializeAppMutation, { loading: initializeAppLoading }] =
    useMutation(INITIALIZE_APP);

  const [mindBodySiteIdModalIsOpen, setMindBodySiteIdModalIsOpen] =
    useState(false);
  const [mindBodyActivationCodeUrl, setMindBodyActivationCodeUrl] =
    useState('');
  const [
    mindBodyActivationCodeUrlModalIsOpen,
    setMindBodyActivationCodeUrlModalIsOpen,
  ] = useState(false);
  const [zenotiApiKeyModalIsOpen, setZenotiApiKeyModalIsOpen] = useState(false);
  const [twilioAccessTokenModalIsOpen, setTwilioAccessTokenModalIsOpen] =
    useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [authModalIsOpen, setAuthModalIsOpen] = useState(false);
  const [initializeMarketingMenuAnchorEl, setInitializeMarketingMenuAnchorEl] =
    useState(null);

  const initializeMarketingMenuOpenBoolean = Boolean(
    initializeMarketingMenuAnchorEl,
  );

  useEffect(() => {
    if (ghlAuthUriData) {
      const uri = ghlAuthUriData.getGHLAuthUri;
      window.location.href = uri;
    }
  }, [ghlAuthUriData]);

  const onSuccessfulSiteIdInput = (activationCodeUrl) => {
    setMindBodySiteIdModalIsOpen(false);
    setMindBodyActivationCodeUrl(activationCodeUrl);
    setMindBodyActivationCodeUrlModalIsOpen(true);
  };

  const onSuccessfulMindBodyConnection = () => {
    setMindBodyActivationCodeUrlModalIsOpen(false);
    setSnackbarMessage('MindBody connection successful');
    refetchUser();
    navigate(Paths.training);
  };

  const onClickConnectZenoti = () => {
    if (!user) {
      setAuthModalIsOpen(true);
    } else {
      setZenotiApiKeyModalIsOpen(true);
    }
  };

  const onSuccessfulZenotiConnection = async () => {
    setZenotiApiKeyModalIsOpen(false);
    setSnackbarMessage('Zenoti API key upload successful');
    await refetchUser();
  };

  const onSuccessfulTwilioAccessToken = async () => {
    setTwilioAccessTokenModalIsOpen(false);
    setSnackbarMessage('Twilio access token upload successful');
    await refetchUser();
  };

  const onClickConnectGHL = () => {
    if (!user) {
      setAuthModalIsOpen(true);
    } else {
      setLoading(true);
      getGHLAuthUri();
    }
  };

  const onClickConnectHubSpot = () => {
    if (!user) {
      setAuthModalIsOpen(true);
    } else {
      setLoading(true);
      window.location.href = Paths.hubSpotRedirectUrl;
    }
  };

  const onClickConnectMetaAds = () => {
    const url =
      `https://www.facebook.com/v21.0/dialog/oauth?response_type=code&client_id=${metaAdsAppId}` +
      `&redirect_uri=${encodeURIComponent(
        'https://backend.liveiq.ai/meta-callback',
      )}` +
      `&auth_type=rerequest&scope=${metaAdScopes.join('%2C')}`;
    window.location.href = url;
  };

  const onInitialize = (appName, asEmr = true) => {
    initializeAppMutation({
      variables: {
        appName,
        asEmr,
      },
      onCompleted: async (data) => {
        const success = data.initializeApp?.success;

        if (success) {
          await refetchUser();
          setSnackbarMessage(`Initialization started`);
        }
      },
    });
  };

  const location = getUserLocation(user);
  const messagingPlatform = location?.[messagingPlatformKey];
  console.log('messagingPlatform', messagingPlatform);

  const connections = getUserConnections(user);
  const mindbodyStatus = connections[mindBodyKey];
  const zenotiStatus = connections[zenotiKey];
  const meevoStatus = connections[meevoKey];
  const ghlStatus = connections[ghlKey];
  const hubSpotStatus = connections[hubSpotKey];
  const metaAdsStatus = connections[metaAdsKey];

  const mindbodyConnected = mindbodyStatus === connectedStatusKey;
  const mindbodyInitialized = mindbodyStatus === initializedStatusKey;
  const mindbodyInProgress = mindbodyStatus === inProgressStatusKey;
  const zenotiConnected = zenotiStatus === connectedStatusKey;
  const zenotiInitialized = zenotiStatus === initializedStatusKey;
  const zenotiInProgress = zenotiStatus === inProgressStatusKey;
  const meevoConnected = meevoStatus === connectedStatusKey;
  const meevoInitialized = meevoStatus === initializedStatusKey;
  const meevoInProgress = meevoStatus === inProgressStatusKey;
  const ghlConnected = ghlStatus === connectedStatusKey;
  const ghlInitialized = ghlStatus === initializedStatusKey;
  const ghlInProgress = ghlStatus === inProgressStatusKey;
  const hubSpotConnected = hubSpotStatus === connectedStatusKey;
  const hubSpotInitialized = hubSpotStatus === initializedStatusKey;
  const hubSpotInProgress = hubSpotStatus === inProgressStatusKey;

  const emrConnected =
    mindbodyInitialized || mindbodyConnected || mindbodyInProgress
      ? mindBodyKey
      : zenotiInitialized || zenotiConnected || zenotiInProgress
      ? zenotiKey
      : meevoInitialized || meevoConnected || meevoInProgress
      ? meevoKey
      : null;
  const ghlAdded = ghlConnected || ghlInitialized || ghlInProgress;
  const crmConnected =
    hubSpotInitialized || hubSpotConnected || hubSpotInProgress
      ? hubSpotKey
      : ghlAdded
      ? ghlAppKey
      : null;

  const emrInitialized =
    mindbodyInitialized ||
    zenotiInitialized ||
    meevoInitialized ||
    (mindbodyStatus === unconnectedStatusKey &&
      zenotiStatus === unconnectedStatusKey &&
      meevoStatus === unconnectedStatusKey &&
      ghlInitialized);

  const agentProductKey = agent?.productKey;
  const connectionsRequired =
    connectionsRequiredForAgentMap[agentProductKey] || [];

  const { connectionsRequiredTitle } =
    getConnectionsRequiredText(connectionsRequired);

  const isLoading = agentLoading || loading;

  if (isLoading) {
    return <LoadingIndicator fullScreen />;
  }

  const GHLComponent = (
    <>
      {ghlStatus === initializedStatusKey ? (
        <SuccessfulPrimaryButton
          onClick={() => {}}
          fixedMinWidth={410}
          addGap
        >
          GHL Initialized <CompleteButtonCheckmarkIcon />
        </SuccessfulPrimaryButton>
      ) : ghlInProgress ? (
        <PrimaryButton
          fixedMinWidth={410}
          onClick={() => {}}
        >
          Syncing GHL...
        </PrimaryButton>
      ) : ghlConnected ? (
        <PrimaryButton
          fixedMinWidth={410}
          onClick={(e) => setInitializeMarketingMenuAnchorEl(e.currentTarget)}
        >
          Initialize GHL
        </PrimaryButton>
      ) : (
        <PrimaryButton
          onClick={onClickConnectGHL}
          fixedMinWidth={410}
        >
          Connect GHL
        </PrimaryButton>
      )}
    </>
  );

  return (
    agent && (
      <>
        <MetaSetter
          title={`Agent App Connections`}
          description={`Agent App Connections`}
        />
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
          >
            <ColumnCenteredDiv>
              {emrInitialized ? (
                <>
                  <DarkMLText>Agents connected!</DarkMLText>
                  <LightDarkSMText>
                    Now your AI agents will be able to automatically book
                    appointments into your calendar
                  </LightDarkSMText>
                </>
              ) : (
                <>
                  <DarkMLText>
                    Your AI agents requires access to {connectionsRequiredTitle}
                  </DarkMLText>
                  <LightDarkSMText>
                    This is how appointments are automatically booked into your
                    calendar
                  </LightDarkSMText>
                </>
              )}
              <ConnectAppsContainer>
                <CenteredDiv>
                  <MediumDarkSMText>Booking System</MediumDarkSMText>
                </CenteredDiv>
                {(!emrConnected || emrConnected === mindBodyKey) && (
                  <>
                    {mindbodyInitialized ? (
                      <SuccessfulPrimaryButton
                        onClick={() => {}}
                        fixedMinWidth={410}
                        addGap
                      >
                        Mindbody Initialized <CompleteButtonCheckmarkIcon />
                      </SuccessfulPrimaryButton>
                    ) : mindbodyInProgress ? (
                      <PrimaryButton
                        onClick={() => {}}
                        fixedMinWidth={410}
                        addGap
                      >
                        Syncing Mindbody...
                      </PrimaryButton>
                    ) : mindbodyConnected ? (
                      <PrimaryButton
                        onClick={() => onInitialize(mindBodyKey)}
                        fixedMinWidth={410}
                        addGap
                      >
                        Initialize Mindbody
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        onClick={() => setMindBodySiteIdModalIsOpen(true)}
                        fixedMinWidth={410}
                        addGap
                      >
                        Connect Mindbody
                      </PrimaryButton>
                    )}
                  </>
                )}
                {(!emrConnected || emrConnected === zenotiKey) && (
                  <>
                    {zenotiInitialized ? (
                      <SuccessfulPrimaryButton
                        onClick={() => {}}
                        fixedMinWidth={410}
                        addGap
                      >
                        Zenoti Initialized <CompleteButtonCheckmarkIcon />
                      </SuccessfulPrimaryButton>
                    ) : zenotiInProgress ? (
                      <PrimaryButton
                        fixedMinWidth={410}
                        onClick={() => {}}
                      >
                        Syncing Zenoti...
                      </PrimaryButton>
                    ) : zenotiConnected ? (
                      <PrimaryButton
                        fixedMinWidth={410}
                        onClick={() => onInitialize(zenotiKey)}
                      >
                        Initialize Zenoti
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        fixedMinWidth={410}
                        onClick={onClickConnectZenoti}
                      >
                        Connect Zenoti
                      </PrimaryButton>
                    )}
                  </>
                )}
                {(!emrConnected || emrConnected === meevoKey) && (
                  <>
                    {meevoInitialized ? (
                      <SuccessfulPrimaryButton
                        onClick={() => {}}
                        fixedMinWidth={410}
                        addGap
                      >
                        Meevo Initialized <CompleteButtonCheckmarkIcon />
                      </SuccessfulPrimaryButton>
                    ) : meevoInProgress ? (
                      <PrimaryButton
                        onClick={() => {}}
                        fixedMinWidth={410}
                        addGap
                      >
                        Syncing Meevo...
                      </PrimaryButton>
                    ) : meevoConnected ? (
                      <PrimaryButton
                        onClick={() => {}}
                        fixedMinWidth={410}
                        addGap
                      >
                        Initialize Meevo
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        onClick={() => {}}
                        fixedMinWidth={410}
                        addGap
                      >
                        Connect Meevo
                      </PrimaryButton>
                    )}
                  </>
                )}
                {!emrConnected || (emrConnected === ghlAppKey && GHLComponent)}
                <CenteredDiv topMargin={20}>
                  <MediumDarkSMText>Marketing</MediumDarkSMText>
                </CenteredDiv>
                {(!crmConnected || crmConnected === hubSpotKey) && (
                  <>
                    {hubSpotInitialized ? (
                      <SuccessfulPrimaryButton
                        onClick={() => {}}
                        fixedMinWidth={410}
                        addGap
                      >
                        HubSpot Initialized <CompleteButtonCheckmarkIcon />
                      </SuccessfulPrimaryButton>
                    ) : hubSpotInProgress ? (
                      <PrimaryButton
                        onClick={() => {}}
                        fixedMinWidth={410}
                        addGap
                      >
                        Syncing HubSpot...
                      </PrimaryButton>
                    ) : hubSpotConnected ? (
                      <PrimaryButton
                        onClick={() => {}}
                        fixedMinWidth={410}
                        addGap
                      >
                        Initialize HubSpot
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        onClick={onClickConnectHubSpot}
                        fixedMinWidth={410}
                        addGap
                      >
                        Connect HubSpot
                      </PrimaryButton>
                    )}
                  </>
                )}
                {(!crmConnected || crmConnected === ghlAppKey) && GHLComponent}
                {metaAdsStatus?.status === connectedStatusKey ? (
                  <SuccessfulPrimaryButton
                    onClick={() => {}}
                    fixedMinWidth={410}
                    addGap
                  >
                    Meta Ads Connected <CompleteButtonCheckmarkIcon />
                  </SuccessfulPrimaryButton>
                ) : (
                  <PrimaryButton
                    onClick={onClickConnectMetaAds}
                    fixedMinWidth={410}
                    addGap
                  >
                    Connect Meta Ads
                  </PrimaryButton>
                )}
                <CenteredDiv topMargin={20}>
                  <MediumDarkSMText>Messaging Platform</MediumDarkSMText>
                </CenteredDiv>
                {messagingPlatform === ghlAppKey ? (
                  <SuccessfulPrimaryButton
                    onClick={() => {}}
                    fixedMinWidth={410}
                    addGap
                  >
                    GHL Messaging Initialized <CompleteButtonCheckmarkIcon />
                  </SuccessfulPrimaryButton>
                ) : messagingPlatform === twilioKey ? (
                  <SuccessfulPrimaryButton
                    onClick={() => setTwilioAccessTokenModalIsOpen(true)}
                    fixedMinWidth={410}
                    addGap
                  >
                    Twilio Initialized <CompleteButtonCheckmarkIcon />
                  </SuccessfulPrimaryButton>
                ) : (
                  <PrimaryButton
                    onClick={() => setTwilioAccessTokenModalIsOpen(true)}
                    fixedMinWidth={410}
                    addGap
                  >
                    Initialize Twilio
                  </PrimaryButton>
                )}
              </ConnectAppsContainer>
              <PopperMenu
                open={initializeMarketingMenuOpenBoolean}
                anchorElement={initializeMarketingMenuAnchorEl}
                onClose={() => {
                  setInitializeMarketingMenuAnchorEl();
                }}
                variant='offset'
                modifiers={{
                  name: 'offset',
                  options: {
                    offset: [10, 0],
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    onInitialize(ghlKey, false);
                    setInitializeMarketingMenuAnchorEl();
                  }}
                >
                  <SmallMenuItemText>As marketing system</SmallMenuItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onInitialize(ghlKey, true);
                    setInitializeMarketingMenuAnchorEl();
                  }}
                >
                  <SmallMenuItemText>
                    As marketing system & EMR
                  </SmallMenuItemText>
                </MenuItem>
              </PopperMenu>
            </ColumnCenteredDiv>
          </ContentContainer>
        </PageContainer>
        <MindBodySiteIDModal
          isOpen={mindBodySiteIdModalIsOpen}
          onClose={() => setMindBodySiteIdModalIsOpen(false)}
          onSuccess={onSuccessfulSiteIdInput}
        />
        <MindBodyActivationCodeUrlModal
          activationCodeUrl={mindBodyActivationCodeUrl}
          isOpen={mindBodyActivationCodeUrlModalIsOpen}
          onClose={() => setMindBodyActivationCodeUrlModalIsOpen(false)}
          onSuccess={onSuccessfulMindBodyConnection}
        />
        <ZenotiAPIKeyModal
          isOpen={zenotiApiKeyModalIsOpen}
          onClose={() => setZenotiApiKeyModalIsOpen(false)}
          onSuccess={onSuccessfulZenotiConnection}
        />
        <TwilioModal
          isOpen={twilioAccessTokenModalIsOpen}
          onClose={() => setTwilioAccessTokenModalIsOpen(false)}
          onSuccess={onSuccessfulTwilioAccessToken}
          location={location}
        />
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
        />
        <AuthenticationPromptModal
          isOpen={authModalIsOpen}
          onClose={() => setAuthModalIsOpen(false)}
          actionText='connect your systems'
        />
      </>
    )
  );
};

export default AgentAppConnection;
