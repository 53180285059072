import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useMySequenceSettings } from '../../../api/hooks/sequence';
import { EDIT_SEQUENCE_SETTINGS } from '../../../api/mutations/sequence';
import {
  DarkMLText,
  EmptyGapColumnCenteredDiv,
  MediumDarkEssText,
  MediumDarkSMText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  StartAlignedFlexDiv,
  StartAlignedLightDarkLargeTinyText,
  Tab,
  Tabs,
} from '../../../styles/shared-styled-components';
import {
  activeEndTimeKey,
  activeStartTimeKey,
  booleanOptions,
  clientContactType,
  dripCadenceOptions,
  dropdownInputType,
  followUpOptions,
  leadContactType,
  sendableSequenceHours,
  sequenceEndTimeKey,
  sequenceStartTimeKey,
} from '../../../utils/constants';
import { valueIsEmpty } from '../../../utils/data';
import { timezoneLabelMap } from '../../../utils/date';
import { safeParseInt } from '../../../utils/numbers';
import { getUserLocationTimezone } from '../../../utils/user';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';
import SwitchToggle from '../../Micro/SwitchToggle/SwitchToggle';
import { CadenceTabsContainer, SequenceInputDiv } from './styled';

const batchSizeOptions = [
  ...Array.from([1, 5, 10, 25], (value, _) => {
    return { key: value, label: value };
  }),
  ...Array.from({ length: 500 / 50 }, (_, i) => {
    const value = (i + 1) * 50;
    return { key: value, label: value };
  }),
];

const EditSequenceSettingsModal = ({ isOpen, onClose, onSave }) => {
  const { user } = useContext(BaseContext);

  const timezone = getUserLocationTimezone(user);
  const timezoneLabel = timezoneLabelMap[timezone];

  const { data: sequenceSettings, refetch } = useMySequenceSettings();
  const [editSequenceSettingsMutation, { loading: editLoading }] = useMutation(
    EDIT_SEQUENCE_SETTINGS,
  );

  const [stopWord, setStopWord] = useState(sequenceSettings?.stopWord);
  const [timing, setTiming] = useState(sequenceSettings?.timing || {});
  const [messageWhenBusinessClosed, setMessageWhenBusinessClosed] = useState(
    sequenceSettings?.messageWhenBusinessClosed,
  );
  const [numberOfClientFollowUps, setNumberOfClientFollowUps] = useState(
    sequenceSettings?.numberOfClientFollowUps,
  );
  const [numberOfLeadFollowUps, setNumberOfLeadFollowUps] = useState(
    sequenceSettings?.numberOfLeadFollowUps,
  );
  const [maxNewChatsPerCampaignPerBatch, setMaxNewChatsPerCampaignPerBatch] =
    useState(sequenceSettings?.maxNewChatsPerCampaignPerBatch || null);
  const [maxFollowUpsPerCampaignPerBatch, setMaxFollowUpsPerCampaignPerBatch] =
    useState(sequenceSettings?.maxFollowUpsPerCampaignPerBatch || null);
  const [cadenceTab, setCadenceTab] = useState(clientContactType);
  const [dripCadence, setDripCadence] = useState(
    sequenceSettings?.dripCadence || {},
  );

  const cadenceToDisplay = dripCadence[cadenceTab] || {};

  useEffect(() => {
    if (sequenceSettings) {
      setStopWord(sequenceSettings.stopWord);
      setTiming(sequenceSettings.timing || {});
      setMessageWhenBusinessClosed(sequenceSettings.messageWhenBusinessClosed);
      setNumberOfClientFollowUps(sequenceSettings.numberOfClientFollowUps);
      setNumberOfLeadFollowUps(sequenceSettings.numberOfLeadFollowUps);
      setMaxNewChatsPerCampaignPerBatch(
        sequenceSettings.maxNewChatsPerCampaignPerBatch,
      );
      setMaxFollowUpsPerCampaignPerBatch(
        sequenceSettings.maxFollowUpsPerCampaignPerBatch,
      );
      setDripCadence(sequenceSettings.dripCadence || {});
    }
  }, [sequenceSettings]);

  const handleClose = () => {
    setStopWord(sequenceSettings?.stopWord);
    setTiming(sequenceSettings?.timing || {});
    setNumberOfClientFollowUps(sequenceSettings?.numberOfClientFollowUps);
    setNumberOfLeadFollowUps(sequenceSettings?.numberOfLeadFollowUps);
    setMaxNewChatsPerCampaignPerBatch(
      sequenceSettings?.maxNewChatsPerCampaignPerBatch,
    );
    setMaxFollowUpsPerCampaignPerBatch(
      sequenceSettings?.maxFollowUpsPerCampaignPerBatch,
    );
    setDripCadence(sequenceSettings?.dripCadence || {});
    onClose();
  };

  const onUpdateTiming = (key, value) => {
    const updatedTiming = { ...timing };
    updatedTiming[key] = value;
    setTiming(updatedTiming);
  };

  const onUpdateDripCadence = (key, value) => {
    const updatedCadence = {
      ...dripCadence,
      [cadenceTab]: {
        ...dripCadence[cadenceTab],
        [key]: value,
      },
    };
    setDripCadence(updatedCadence);
  };

  const handleSave = () => {
    const clientCadence = dripCadence[clientContactType];
    const leadCadence = dripCadence[leadContactType];
    const formattedClientCadence = {};
    const formattedLeadCadence = {};
    Object.keys(clientCadence).map((key) => {
      if (key <= numberOfClientFollowUps) {
        formattedClientCadence[key] = clientCadence[key];
      }
    });
    Object.keys(leadCadence).map((key) => {
      if (key <= numberOfLeadFollowUps) {
        formattedLeadCadence[key] = leadCadence[key];
      }
    });
    const formattedCadence = {
      [clientContactType]: {
        ...formattedClientCadence,
      },
      [leadContactType]: {
        ...formattedLeadCadence,
      },
    };

    const alwaysOnTiming = {
      ...timing,
      [activeStartTimeKey]: undefined,
      [activeEndTimeKey]: undefined,
    };

    const formattedMaxNewChatsPerCampaignPerBatch =
      safeParseInt(maxNewChatsPerCampaignPerBatch) || null;
    const formattedMaxFollowUpsPerCampaignPerBatch =
      safeParseInt(maxFollowUpsPerCampaignPerBatch) || null;

    editSequenceSettingsMutation({
      variables: {
        messageWhenBusinessClosed,
        continueIfUpcomingVisit: false,
        stopWord,
        timing: alwaysOnTiming,
        numberOfClientFollowUps,
        numberOfLeadFollowUps,
        maxNewChatsPerCampaignPerBatch: formattedMaxNewChatsPerCampaignPerBatch,
        maxFollowUpsPerCampaignPerBatch:
          formattedMaxFollowUpsPerCampaignPerBatch,
        dripCadence: formattedCadence,
      },
      onCompleted: async (data) => {
        onSave();
        await refetch();
      },
    });
  };

  if (editLoading) {
    return <LoadingIndicator />;
  }

  const isAlwaysEnabled = !timing?.[activeStartTimeKey];

  const usingDefaultBatchSizes =
    maxNewChatsPerCampaignPerBatch == null &&
    maxFollowUpsPerCampaignPerBatch == null;

  const saveEnabled =
    !valueIsEmpty(stopWord) &&
    Object.keys(timing)?.length > 0 &&
    (usingDefaultBatchSizes ||
      (!usingDefaultBatchSizes &&
        maxNewChatsPerCampaignPerBatch > 0 &&
        maxFollowUpsPerCampaignPerBatch > 0));

  const numCadenceOptionsToDisplay = Array.from(
    {
      length:
        cadenceTab === clientContactType
          ? numberOfClientFollowUps
          : numberOfLeadFollowUps,
    },
    (_, i) => i + 1,
  );

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <ModalContainer>
          <DarkMLText>Edit Sequence Settings</DarkMLText>
          <ModalInputsContainer>
            <ModalInputsContainerScrollWrapper
              columnFlex
              largeGap
            >
              <EmptyGapColumnCenteredDiv>
                <MediumDarkSMText>Config</MediumDarkSMText>
                <SequenceInputDiv>
                  <Input
                    id='stopword'
                    label='Stop word'
                    value={stopWord}
                    onChange={(e) => setStopWord(e.target.value)}
                    useExtraSmallGap
                    useFullWidth
                  />
                </SequenceInputDiv>
                {false && (
                  <SequenceInputDiv>
                    <StartAlignedFlexDiv margin='20px 0px -5px 0px'>
                      <MediumDarkEssText>Agent always active</MediumDarkEssText>
                      <SwitchToggle
                        value={isAlwaysEnabled}
                        onChange={() => {
                          let updatedTiming = { ...timing };

                          if (isAlwaysEnabled) {
                            updatedTiming[activeStartTimeKey] = '09:00:00';
                            updatedTiming[activeEndTimeKey] = '21:00:00';
                          } else {
                            updatedTiming[activeStartTimeKey] = undefined;
                            updatedTiming[activeEndTimeKey] = undefined;
                          }

                          setTiming(updatedTiming);
                        }}
                      />
                    </StartAlignedFlexDiv>
                  </SequenceInputDiv>
                )}
                {!isAlwaysEnabled && (
                  <>
                    <SequenceInputDiv>
                      <Input
                        id='start_time'
                        label='Bot active start time'
                        value={timing[activeStartTimeKey]}
                        onChange={(e) =>
                          onUpdateTiming(activeStartTimeKey, e.target.value)
                        }
                        type={dropdownInputType}
                        options={sendableSequenceHours}
                        useSmallGap
                        useFullWidth
                      />
                    </SequenceInputDiv>
                    <SequenceInputDiv>
                      <Input
                        id='end_time'
                        label='Bot active end time'
                        value={timing[activeEndTimeKey]}
                        onChange={(e) =>
                          onUpdateTiming(activeEndTimeKey, e.target.value)
                        }
                        type={dropdownInputType}
                        options={sendableSequenceHours}
                        useSmallGap
                        useFullWidth
                      />
                    </SequenceInputDiv>
                  </>
                )}
                <SequenceInputDiv>
                  <Input
                    id='sequence_execution_start_time'
                    label={`Sequence execution start time (${timezoneLabel})`}
                    value={timing[sequenceStartTimeKey]}
                    onChange={(e) =>
                      onUpdateTiming(sequenceStartTimeKey, e.target.value)
                    }
                    type={dropdownInputType}
                    options={sendableSequenceHours}
                    useSmallGap
                    useFullWidth
                  />
                </SequenceInputDiv>
                <SequenceInputDiv>
                  <Input
                    id='sequence_execution_end_time'
                    label={`Sequence execution end time (${timezoneLabel})`}
                    value={timing[sequenceEndTimeKey]}
                    onChange={(e) =>
                      onUpdateTiming(sequenceEndTimeKey, e.target.value)
                    }
                    type={dropdownInputType}
                    options={sendableSequenceHours}
                    useSmallGap
                    useFullWidth
                  />
                </SequenceInputDiv>
                <SequenceInputDiv>
                  <Input
                    id='biz_closed'
                    label={`Send sequences when business closed`}
                    value={messageWhenBusinessClosed}
                    onChange={(e) =>
                      setMessageWhenBusinessClosed(e.target.value)
                    }
                    type={dropdownInputType}
                    options={booleanOptions}
                    useSmallGap
                    useFullWidth
                  />
                </SequenceInputDiv>
                <SequenceInputDiv margin='20px 0px 0px 0px'>
                  <StartAlignedFlexDiv>
                    <MediumDarkEssText>
                      Use default batch size (
                      {sequenceSettings?.defaultBatchSize})
                    </MediumDarkEssText>
                    <SwitchToggle
                      value={usingDefaultBatchSizes}
                      onChange={() => {
                        if (usingDefaultBatchSizes) {
                          setMaxNewChatsPerCampaignPerBatch(
                            sequenceSettings?.maxNewChatsPerCampaignPerBatch ||
                              50,
                          );
                          setMaxFollowUpsPerCampaignPerBatch(
                            sequenceSettings?.maxFollowUpsPerCampaignPerBatch ||
                              50,
                          );
                        } else {
                          setMaxNewChatsPerCampaignPerBatch();
                          setMaxFollowUpsPerCampaignPerBatch();
                        }
                      }}
                    />
                  </StartAlignedFlexDiv>
                  <StartAlignedLightDarkLargeTinyText>
                    Sequences run every 2 hours
                  </StartAlignedLightDarkLargeTinyText>
                  {!usingDefaultBatchSizes && (
                    <>
                      <Input
                        id='max_new'
                        label='Max new chats per campaign per batch'
                        value={maxNewChatsPerCampaignPerBatch}
                        onChange={(e) =>
                          setMaxNewChatsPerCampaignPerBatch(e.target.value)
                        }
                        type={dropdownInputType}
                        options={batchSizeOptions}
                        useSmallGap
                        useFullWidth
                      />
                      <Input
                        id='max_follow'
                        label='Max follow-ups per campaign per batch'
                        value={maxFollowUpsPerCampaignPerBatch}
                        onChange={(e) =>
                          setMaxFollowUpsPerCampaignPerBatch(e.target.value)
                        }
                        type={dropdownInputType}
                        options={batchSizeOptions}
                        useSmallGap
                        useFullWidth
                      />
                    </>
                  )}
                </SequenceInputDiv>
              </EmptyGapColumnCenteredDiv>
              <EmptyGapColumnCenteredDiv>
                <MediumDarkSMText>Drip Cadence</MediumDarkSMText>
                <SequenceInputDiv>
                  <Input
                    id='client-followup'
                    label='Number of follow-ups for clients'
                    value={numberOfClientFollowUps}
                    onChange={(e) => setNumberOfClientFollowUps(e.target.value)}
                    type={dropdownInputType}
                    options={followUpOptions}
                    useSmallGap
                    useFullWidth
                  />
                </SequenceInputDiv>
                <SequenceInputDiv>
                  <Input
                    id='lead-followup'
                    label='Number of follow-ups for leads'
                    value={numberOfLeadFollowUps}
                    onChange={(e) => setNumberOfLeadFollowUps(e.target.value)}
                    type={dropdownInputType}
                    options={followUpOptions}
                    useSmallGap
                    useFullWidth
                  />
                </SequenceInputDiv>
                <CadenceTabsContainer>
                  <Tabs
                    value={cadenceTab}
                    onChange={(_, key) => setCadenceTab(key)}
                    addBottomMargin
                  >
                    <Tab
                      value={clientContactType}
                      label='Client cadence'
                    />
                    <Tab
                      value={leadContactType}
                      label='Lead cadence'
                    />
                  </Tabs>
                </CadenceTabsContainer>
                {numCadenceOptionsToDisplay.map((i) => {
                  const value = cadenceToDisplay?.[i];
                  return (
                    <SequenceInputDiv>
                      <Input
                        id={`drip-${i}`}
                        label={`Delay for unresponsive follow-up #${i}`}
                        value={parseInt(value)}
                        onChange={(e) => onUpdateDripCadence(i, e.target.value)}
                        type={dropdownInputType}
                        options={dripCadenceOptions}
                        removeGap={i === 1}
                        useSmallGap={i !== 1}
                        useFullWidth
                      />
                    </SequenceInputDiv>
                  );
                })}
              </EmptyGapColumnCenteredDiv>
            </ModalInputsContainerScrollWrapper>
          </ModalInputsContainer>
          <ModalActions>
            <MediumPrimaryButton
              disabled={!saveEnabled}
              onClick={handleSave}
            >
              Save
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={handleClose}>
              Close
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default EditSequenceSettingsModal;
