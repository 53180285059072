import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import React, { useContext, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useSearchParams } from 'react-router-dom';
import {
  useAppointmentClients,
  useCallAnalytics,
  useCampaignAnalytics,
  useInterceptions,
  useMyAnalytics,
} from '../../../../api/hooks/analytics';
import { useMyCampaigns } from '../../../../api/hooks/campaigns';
import { useMyLocations } from '../../../../api/hooks/enterprise';
import {
  InfoIcon,
  teal,
} from '../../../../components/Analytics/shared-analytics-styles';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import BarChart from '../../../../components/Dashboard/BarChart/BarChart';
import PieChart from '../../../../components/Dashboard/PieChart';
import {
  carrot,
  emerald,
  royalBlue,
  TooltipText,
  TooltipWrapper,
} from '../../../../components/Dashboard/shared-dashboard-styles';
import StackedBarChart from '../../../../components/Dashboard/StackedBarChart';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import AddLocationModal from '../../../../components/Modals/AddLocationModal';
import Header from '../../../../components/NavBar/Header';
import PopperMenu from '../../../../components/PopperMenu';
import RangePopperMenuCalendar from '../../../../components/Schedule/Calendar/RangePopperMenuCalendar';
import Snackbar from '../../../../components/Snackbar';
import MetaSetter from '../../../../components/Utils/MetaSetter';
import {
  AddIcon,
  AgentAvatarContainer,
  AnalystAvatar,
  ButtonText,
  ButtonTextContainer,
  ColumnCenteredDiv,
  ContentContainer,
  EssText,
  ExtraSmallPrimaryAddIcon,
  FullSizeColumnCenteredDiv,
  FullWidthCenteredDiv,
  LargeNoDataIcon,
  LargeTinyText,
  MediumDarkMediumText,
  MediumPrimaryButton,
  MenuItem,
  MenuItemCheckmark,
  PageContainer,
  PageTitleText,
  SmallMenuItemText,
  StartAlignedFlexDiv,
  StartAlignedMediumDarkExtraSmallText,
  StartAlignedMediumDarkLargeTinyText,
  StartAlignedSMText,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  activeChatStatusKey,
  activeMemberContactTypeKey,
  appointmentContactTypeLabelMap,
  cancelledStatusKey,
  clientContactType,
  completeStatusKey,
  contactedCampaignMemberStatusKey,
  hotLeadCampaignMemberStatusKey,
  inboundKey,
  incompleteStatusKey,
  indirectlyBookedCampaignMemberStatusKey,
  interceptedChatStatusKey,
  leadContactType,
  nameKey,
  oneTimeFeeKey,
  optedOutCampaignMemberStatusKey,
  percentDataType,
  recurringFeeKey,
  respondedCampaignMemberStatusKey,
  scheduledStatusKey,
  successfullyBookedCampaignMemberStatusKey,
  superAdminRole,
  suspendedMemberContactTypeKey,
  textSeparatorChar,
} from '../../../../utils/constants';
import { getFormattedDateString } from '../../../../utils/date';
import {
  formatNumber,
  getFormattedPercentageString,
  safeParseInt,
} from '../../../../utils/numbers';
import { formatKeyToLabel } from '../../../../utils/string';
import {
  getUserBusiness,
  getUserCorporation,
  getUserLiveIQAgentName,
} from '../../../../utils/user';
import Appointments from '../Appointments';
import Interceptions from '../Interceptions';
import {
  AvatarSection,
  ColumnSectionContentDiv,
  Container,
  NoLocationsCreatedContainer,
  QueryFieldText,
  QueryPanel,
  QueryPanelContainer,
  SectionComponentDiv,
  SectionDiv,
  SectionTitleContainer,
  SectionTopPanelContainer,
  SubSectionTopPanel,
  TopPanel,
  TopPanelSection,
  TopSection,
} from './styled';

const viewParamKey = 'view';
const appointmentsKey = 'appointments';
const interceptionsKey = 'interceptions';
const leadsKey = 'leads';
const topPanelFields = [
  {
    hardcodedKey: 'revenue',
    key: 'revenue',
    label: 'Revenue',
    prefix: '$',
    view: appointmentsKey,
  },
  {
    hardcodedKey: 'appointments',
    key: 'numDirectAppointments',
    subkey: 'numIndirectAppointments',
    label: 'Appointments Booked (Indirect)',
    hardcodedLabel: 'Appointments Booked',
    explanation: `Indirect appointments are those where the AI agent prompted the client to book, and a human completed the booking`,
    view: appointmentsKey,
  },
  {
    hardcodedKey: 'interceptionRate',
    key: 'interceptionRate',
    postSubkey: 'interceptionErrors',
    label: 'Interception Rate (Error)',
    suffix: '%',
    explanation: `Some interceptions don't represent an error, such as a separate marketing campaign running or a human agent upselling, so the bracketed number shows only the interceptions where an error was made`,
    view: interceptionsKey,
  },
  {
    hardcodedKey: 'talkMinutes',
    key: 'numCalls',
    subkey: 'talkMinutes',
    subkeySuffix: 'mins',
    label: 'Phone Calls',
  },
];

const leadPanelFields = [
  {
    hardcodedKey: 'numLeads',
    key: 'numLeads',
    label: 'Leads Converted',
    view: leadsKey,
  },
  {
    hardcodedKey: 'leadRevenue',
    key: 'leadRevenue',
    label: 'Lead Revenue',
    prefix: '$',
    view: leadsKey,
  },
  {
    hardcodedKey: 'roas',
    key: 'roas',
    label: 'ROAS',
    suffix: 'x',
  },
  {
    hardcodedKey: 'cpc',
    key: 'cpc',
    label: 'CPC',
    prefix: '$',
  },
];

const totalNumHardcodedAppointments = 338;
const hardcodedData = {
  revenue: 42307,
  appointments: totalNumHardcodedAppointments,
  talkMinutes: 2611,
  humanCostDiverted: 2400,
  interceptionRate: 6,
};

// Overview
const appointmentCategoryData = [
  {
    label: 'Massages',
    value: 53,
  },
  {
    label: 'Facials',
    value: 18,
  },
  {
    label: 'Stretch',
    value: 29,
  },
];
const total = 52 + 22 + 9;
const appointmentStatusData = [
  {
    label: 'Scheduled',
    status: scheduledStatusKey,
    value: (22 / total).toFixed(1) * 100,
  },
  {
    label: 'Completed',
    status: completeStatusKey,
    value: (52 / total).toFixed(1) * 100,
  },
  {
    label: 'Cancel',
    status: cancelledStatusKey,
    value: (9 / total).toFixed(1) * 100,
  },
];
const campaignData = [
  {
    key: 'a',
    label: 'September Stretch',
    value: 50,
  },
  {
    key: 'b',
    label: 'Membership Upsell',
    value: 30,
  },
  {
    key: 'c',
    label: 'Facials Promo',
    value: 30,
  },
];

// Phone calls
const phoneCallResolutionData = [
  {
    label: 'Handled',
    value: 55,
  },
  {
    label: 'Callback',
    value: 18,
  },
  {
    label: 'Transferred',
    value: 27,
  },
];
const callTopicsData = [
  {
    range: 'Sep 23-29',
    Create: 3,
    Reschedule: 2,
    Cancel: 3,
  },
  {
    range: 'Sep 30-Oct 6',
    Create: 9,
    Reschedule: 2,
    Cancel: 5,
  },
  {
    range: 'Oct 7-13',
    Create: 12,
    Reschedule: 3,
    Cancel: 2,
  },
  {
    range: 'Oct 14-21',
    Create: 11,
    Reschedule: 3,
    Cancel: 5,
  },
  {
    range: 'Oct 21-Now',
    Create: 4,
    Reschedule: 1,
    Cancel: 1,
  },
];
const callContactTypes = [
  {
    label: 'Clients',
    value: 68,
  },
  {
    label: 'Leads',
    value: 12,
  },
  {
    label: 'Unknown',
    value: 20,
  },
];

// Campaigns
const campaignLeadConversionData = [
  {
    time: 'Sep 23-29',
    'September Stretch': 20,
    'Membership Upsell': 35,
    'Facials Promo': 40,
  },
  {
    time: 'Sep 30-Oct 6',
    'September Stretch': 24,
    'Membership Upsell': 33,
    'Facials Promo': 48,
  },
  {
    time: 'Oct 7-13',
    'Membership Upsell': 37,
    'Facials Promo': 34,
  },
  {
    time: 'Oct 14-21',
    'Membership Upsell': 31,
    'Facials Promo': 46,
  },
  {
    time: 'Oct 21-Now',
    'Membership Upsell': 40,
    'Facials Promo': 29,
  },
];
const campaignRatesData = [
  {
    time: 'Sep 23-29',
    Response: 8,
    Interception: 1,
    Unsubscribe: 3,
  },
  {
    time: 'Sep 30-Oct 6',
    Response: 9,
    Interception: 1.5,
    Unsubscribe: 2.7,
  },
  {
    time: 'Oct 7-13',
    Response: 7,
    Interception: 0.8,
    Unsubscribe: 4,
  },
  {
    time: 'Oct 14-21',
    Response: 11,
    Interception: 2.5,
    Unsubscribe: 5,
  },
  {
    time: 'Oct 21-Now',
    Response: 7,
    Interception: 1,
    Unsubscribe: 3,
  },
];
const campaignSourceData = [
  {
    key: 'a',
    label: 'Meta',
    value: 62,
  },
  {
    key: 'a',
    label: 'Google',
    value: 21,
  },
  {
    key: 'a',
    label: 'Web',
    value: 17,
  },
];

const serviceBookingTooltipRenderer = (params) => {
  const content = (
    <TooltipWrapper color={params.fill}>
      <TooltipText>
        Service: {params.datum[params.xKey]}
        <br></br>
        {params.title}: {params.datum[params.yKey].toFixed(0)}
        {params.yKey === completeStatusKey && (
          <>
            <br></br>
            Revenue: ${formatNumber(params.datum['revenue'])}
          </>
        )}
      </TooltipText>
    </TooltipWrapper>
  );

  // Return the tooltip content as a string.
  // This step converts the React element to a string that can be used.
  return renderToString(content);
};
const serviceBookingSeries = [
  {
    type: 'bar',
    xKey: nameKey,
    yKey: scheduledStatusKey,
    yName: 'Scheduled',
    stacked: true,
    tooltip: { renderer: serviceBookingTooltipRenderer },
  },
  {
    type: 'bar',
    xKey: nameKey,
    yKey: completeStatusKey,
    yName: 'Completed',
    stacked: true,
    tooltip: { renderer: serviceBookingTooltipRenderer },
  },
  {
    type: 'bar',
    xKey: nameKey,
    yKey: cancelledStatusKey,
    yName: 'Cancelled',
    stacked: true,
    tooltip: { renderer: serviceBookingTooltipRenderer },
  },
];

const bookingTypeTooltipRenderer = (params) => {
  const content = (
    <TooltipWrapper color={params.fill}>
      <TooltipText>
        {params.datum['type']}: {params.datum['amount']} bookings
      </TooltipText>
    </TooltipWrapper>
  );

  return renderToString(content);
};
const bookingAmountSeries = [
  {
    type: 'pie',
    angleKey: 'amount',
    legendItemKey: 'type',
    tooltip: { renderer: bookingTypeTooltipRenderer },
  },
];

const memberTooltipRenderer = (params) => {
  const isContacted = params.datum?.[contactedCampaignMemberStatusKey];
  const totalForStatus = params.datum[params.yKey] || 0;
  let content;

  if (isContacted) {
    content = (
      <TooltipWrapper color={params.fill}>
        <TooltipText>
          Campaign: {params.datum[params.xKey]}
          <br></br>
          {params.title}: {totalForStatus}
        </TooltipText>
      </TooltipWrapper>
    );
  } else {
    const overallTotal = params.datum['total'];
    const pct = overallTotal
      ? ((totalForStatus / overallTotal) * 100).toFixed(1)
      : 0;
    content = (
      <TooltipWrapper color={params.fill}>
        <TooltipText>
          Campaign: {params.datum[params.xKey]}
          <br></br>
          {params.title}: {totalForStatus}/{overallTotal} ({pct}%)
        </TooltipText>
      </TooltipWrapper>
    );
  }

  // Return the tooltip content as a string.
  // This step converts the React element to a string that can be used.
  return renderToString(content);
};
const campaignMemberSeries = [
  {
    type: 'bar',
    xKey: nameKey,
    yKey: respondedCampaignMemberStatusKey,
    yName: 'Responded',
    stacked: true,
    tooltip: { renderer: memberTooltipRenderer },
    fill: '#5dade2',
  },
  {
    type: 'bar',
    xKey: nameKey,
    yKey: optedOutCampaignMemberStatusKey,
    yName: 'Opted-Out',
    stacked: true,
    tooltip: { renderer: memberTooltipRenderer },
    fill: '#ff6f61',
  },
  {
    type: 'bar',
    xKey: nameKey,
    yKey: successfullyBookedCampaignMemberStatusKey,
    yName: 'Booked',
    stacked: true,
    tooltip: { renderer: memberTooltipRenderer },
    fill: '#48b37c',
  },
];
const allCampaignMemberSeries = [
  ...campaignMemberSeries,
  {
    type: 'bar',
    xKey: nameKey,
    yKey: contactedCampaignMemberStatusKey,
    yName: 'Contacted',
    stacked: true,
    tooltip: { renderer: memberTooltipRenderer },
    fill: '#7b68ee',
  },
];

const callActionTooltipRenderer = (params) => {
  const totalForStatus = params.datum[params.yKey] || 0;
  const content = (
    <TooltipWrapper color={params.fill}>
      <TooltipText>
        {params.datum['name']} Appointment: {totalForStatus} call
        {totalForStatus !== 1 ? 's' : ''}
      </TooltipText>
    </TooltipWrapper>
  );

  // Return the tooltip content as a string.
  // This step converts the React element to a string that can be used.
  return renderToString(content);
};
const callActionSeries = [
  {
    type: 'bar',
    xKey: nameKey,
    yKey: 'value',
    tooltip: { renderer: callActionTooltipRenderer },
    fill: '#5dade2',
  },
];

const callSentimentTooltipRenderer = (params) => {
  const content = (
    <TooltipWrapper color={params.fill}>
      <TooltipText>
        {params.datum['type']}: {params.datum['amount']} call
        {params.datum['amount'] !== 1 ? 's' : ''}
      </TooltipText>
    </TooltipWrapper>
  );

  return renderToString(content);
};
const callSentimentSeries = [
  {
    type: 'pie',
    angleKey: 'amount',
    legendItemKey: 'type',
    tooltip: { renderer: callSentimentTooltipRenderer },
  },
];

const maxElements = 15;

const appointmentStatusFills = ['#9c90bd', 'rgb(37, 179, 77)', '#d8504d'];
const clientTypeFills = [royalBlue, emerald, carrot, teal];

const campaignMemberStatusFills = ['#ff6f61', '#48b37c', '#7b68ee'];
const campaignBookingFills = [
  '#f4a261',
  '#b39ddb',
  '#2a9d8f',
  '#e76f51',
  '#264653',
  '#2c7a7b',
];

const callSentimentFills = [
  'rgb(37, 179, 77)',
  '#9c90bd',
  '#d8504d',
  '#9c90bd',
];

const allowMultiLocations = false;

const getEmptyComponent = (section) => {
  return (
    <FullSizeColumnCenteredDiv
      topMargin={100}
      bottomMargin={100}
    >
      <LargeNoDataIcon />
      <EssText>No {section} data</EssText>
    </FullSizeColumnCenteredDiv>
  );
};

const leadSourceStatuses = [
  {
    name: 'Facebook',
    total: 13,
    responded: 3,
    opted_out: 2,
    successfully_booked: 3,
  },
  {
    name: 'Instagram',
    total: 23,
    responded: 4,
    opted_out: 8,
    successfully_booked: 10,
  },
  {
    name: 'Google',
    total: 23,
    responded: 1,
    opted_out: 1,
    successfully_booked: 2,
  },
];
const leadSourceBookings = [
  {
    type: 'Instagram',
    amount: 10,
  },
  {
    type: 'Google',
    amount: 2,
  },
  {
    type: 'Facebook',
    amount: 3,
  },
];

const Analytics = () => {
  const { user, inMonitoringMode, drawerOpen, drawerExpanded, inDemoMode } =
    useContext(BaseContext);

  const agentName = getUserLiveIQAgentName(user);

  const [searchParams, setSearchParams] = useSearchParams();

  const onAppointmentsView = searchParams.get(viewParamKey) === appointmentsKey;
  const onInterceptionsView =
    searchParams.get(viewParamKey) === interceptionsKey;

  const isSuperAdmin = user?.role === superAdminRole;
  const corporation = getUserCorporation(user);
  const corporationName = corporation?.name;
  const business = getUserBusiness(user);
  const businessName = business?.name;
  const formattedBusinessName = corporationName
    ? `${corporationName}${
        businessName !== corporationName ? ` (${businessName})` : ''
      }`
    : businessName;
  const inHardcodedMode = inDemoMode;

  const {
    locations,
    refetch: refetchLocations,
    loading: locationsLoading,
  } = useMyLocations({});
  const { campaigns, loading: campaignsLoading } = useMyCampaigns({
    shallow: true,
  });

  const allLocationIds = [];
  const locationOptions = (locations || []).map((l) => {
    allLocationIds.push(l.id);
    return { key: l.id, label: l.nickname };
  });
  const locationOptionString = locationOptions.map((l) => l.key).join(',');
  const [filteredLocations, setFilteredLocations] = useState(
    locationOptions.map((c) => c.key),
  );
  const [startDateRange, setStartDateRange] = useState();
  const [endDateRange, setEndDateRange] = useState();
  const [addedLocation, setAddedLocation] = useState(false);
  const [campaignsHaveLoaded, setCampaignsHaveLoaded] = useState(false);

  let allCampaignIds = ['inbound'];
  let campaignOptions = [{ key: 'inbound', label: 'Inbound' }];
  const campaignMap = {
    ['inbound']: 'Inbound',
  };
  let campaignsToUse = campaigns || [];
  let isEmpty = false;
  if (campaignsToUse.length) {
    campaignsToUse.map((c, idx) => {
      allCampaignIds.push(c.id);
      campaignOptions.push({ key: c.id || c.key, label: c.name || c.label });
      campaignMap[c.id] = c.name;
    });
  }
  const [stagingFilteredCampaigns, setStagingFilteredCampaigns] = useState(
    campaignOptions.map((c) => c.key),
  );
  const [filteredCampaigns, setFilteredCampaigns] = useState(
    campaignOptions.map((c) => c.key),
  );

  useEffect(() => {
    if ((!filteredLocations?.length || addedLocation) && !inMonitoringMode) {
      setFilteredLocations(locationOptions.map((c) => c.key));
      setAddedLocation(false);
    }
  }, [addedLocation, locationOptionString]);

  useEffect(() => {
    if (campaigns && !campaignsHaveLoaded) {
      allCampaignIds = [];
      campaignOptions = [];
      if (campaigns.length) {
        campaigns.map((c, idx) => {
          if (idx === 0) {
            allCampaignIds.push(inboundKey);
            campaignOptions.push({ key: inboundKey, label: 'Inbound' });
          }
          allCampaignIds.push(c.id);
          campaignOptions.push({
            key: c.id || c.key,
            label: c.name || c.label,
          });
        });
        setFilteredCampaigns(campaignOptions.map((c) => c.key));
        setStagingFilteredCampaigns(campaignOptions.map((c) => c.key));
      } else {
        setFilteredCampaigns([inboundKey]);
        setStagingFilteredCampaigns([inboundKey]);
      }
      setCampaignsHaveLoaded(true);
    }
  }, [campaigns]);

  if (campaignsHaveLoaded && !campaigns?.length) {
    campaignsToUse = [{ key: 'inbound', label: 'Inbound' }];
    isEmpty = true;
  }

  const filteredCampaignIds = filteredCampaigns?.length
    ? filteredCampaigns
    : allCampaignIds;

  const formattedStart = startDateRange
    ? new Date(startDateRange).toISOString()
    : null;
  const formattedEnd = endDateRange
    ? new Date(endDateRange).toISOString()
    : null;

  const { analytics, loading: analyticsLoading } = useMyAnalytics({
    campaignIds: filteredCampaignIds,
    start: formattedStart,
    end: formattedEnd,
  });

  let internalRevenue;
  if (inMonitoringMode && analytics) {
    internalRevenue = {
      [recurringFeeKey]: {
        [incompleteStatusKey]: {
          amount: 0,
          subscriptions: [],
        },
        [completeStatusKey]: {
          amount: 0,
          subscriptions: [],
        },
        subscriptions: [],
      },
      [oneTimeFeeKey]: {
        [incompleteStatusKey]: {
          amount: 0,
          subscriptions: [],
        },
        [completeStatusKey]: {
          amount: 0,
          subscriptions: [],
        },
        subscriptions: [],
      },
    };
    analytics.map((subscription) => {
      const key = subscription.type;
      const status = subscription.status;
      const obj = internalRevenue[key][status];
      internalRevenue[key] = {
        [status]: {
          subscriptions: [...(obj?.subscriptions || []), subscription],
          amount: obj.amount + parseFloat(subscription.amount),
        },
        subscriptions: [...internalRevenue[key].subscriptions, subscription],
      };
    });
  }

  const { data: campaignAnalytics, loading: campaignDataLoading } =
    useCampaignAnalytics({
      skipCondition: inMonitoringMode,
      campaignIds: filteredCampaignIds.filter((id) => id !== 'inbound'),
    });

  const { data: callAnalytics, loading: callAnalyticsLoading } =
    useCallAnalytics({
      skipCondition: inMonitoringMode,
      start: formattedStart,
      end: formattedEnd,
    });
  const hasCalls = !!analytics?.numCalls;
  const actionsMap = callAnalytics?.actions || {};
  const callActions = Object.keys(actionsMap).map((k) => {
    const name = formatKeyToLabel(k);
    const value = actionsMap[k]?.length || 0;
    return { name, value };
  });

  const sentimentData = [];
  const sentimentMap = callAnalytics?.sentiment || {};
  Object.keys(sentimentMap).map((k) => {
    const type = formatKeyToLabel(k);
    const amount = sentimentMap[k];
    sentimentData.push({ type, amount });
  });

  const appointments = analytics?.appointments || [];
  const { clients, loading: clientsLoading } = useAppointmentClients({
    clientIds: appointments.map((a) => a.clientId),
    skipCondition: !onAppointmentsView,
  });

  const chats = analytics?.chats || {};
  const {
    interceptions,
    loading: interceptionsLoading,
    refetch: refetchInterceptions,
  } = useInterceptions({
    chats: chats['interceptions'] || [],
    skipCondition: !onInterceptionsView,
  });

  const [menuField, setMenuField] = useState();
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const menuOpen = Boolean(menuAnchorEl);

  const locationsMenuOpen = menuOpen && menuField === 'locations';
  const campaignsMenuOpen = menuOpen && menuField === 'campaigns';
  const datesMenuOpen = menuOpen && menuField === 'dates';

  const menuOptions =
    menuField === 'locations'
      ? locationOptions
      : menuField === 'campaigns'
      ? campaignOptions
      : [];
  const filteredOptions =
    menuField === 'locations'
      ? filteredLocations
      : menuField === 'campaigns'
      ? stagingFilteredCampaigns
      : [];

  const startLabel = startDateRange
    ? getFormattedDateString(startDateRange, false)
    : 'Start';
  const endLabel = endDateRange
    ? getFormattedDateString(endDateRange, false)
    : 'Present';
  const dateLabel =
    !startDateRange && !endDateRange
      ? `All-Time`
      : `${startLabel} - ${endLabel}`;

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);

  const onChangeFilter = (id, add) => {
    if (menuField === 'locations') {
      if (add) {
        setFilteredLocations([...filteredLocations, id]);
      } else {
        setFilteredLocations([
          ...filteredLocations.filter((key) => key !== id),
        ]);
      }
      setMenuAnchorEl();
    } else if (menuField === 'campaigns') {
      if (add) {
        setStagingFilteredCampaigns([...stagingFilteredCampaigns, id]);
      } else {
        setStagingFilteredCampaigns([
          ...stagingFilteredCampaigns.filter((key) => key !== id),
        ]);
      }
    }
  };

  const onApplyCampaignFilter = () => {
    setFilteredCampaigns([...stagingFilteredCampaigns]);
    setMenuAnchorEl();
  };

  const onSaveDateFilter = (updatedStart, updatedEnd) => {
    setStartDateRange(updatedStart);
    setEndDateRange(updatedEnd);
    setMenuAnchorEl();
  };

  const onNavigateToView = (view) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    if (!view) {
      newSearchParams.delete(viewParamKey);
    } else {
      newSearchParams.set(viewParamKey, view);
    }

    setSearchParams(newSearchParams);
  };

  if (
    !analyticsLoading &&
    !locationsLoading &&
    !locations?.length &&
    !inMonitoringMode
  ) {
    return (
      <>
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
          >
            <ColumnCenteredDiv bottomMargin={10}>
              <PageTitleText>Dashboard - No Location Selected</PageTitleText>
            </ColumnCenteredDiv>
            <Container></Container>
          </ContentContainer>
        </PageContainer>
      </>
    );
  }

  if (
    (locationsLoading ||
      campaignsLoading ||
      analyticsLoading ||
      !campaignsHaveLoaded) &&
    !inMonitoringMode
  ) {
    return <LoadingIndicator fullScreen />;
  }

  const serviceBookings = [];
  let serviceBookingsTitle = `Bookings by service`;
  let values = Object.keys(analytics?.serviceMap || {});
  if (values.length > maxElements) {
    serviceBookingsTitle += ` (top ${maxElements} displayed)`;
    values = orderBy(
      values,
      [
        (key) => analytics.serviceMap[key]?.revenue,
        (key) => analytics.serviceMap[key]?.completed,
        (key) => analytics.serviceMap[key]?.scheduled,
      ],
      ['desc', 'desc', 'desc'], // sorting order for each field
    ).slice(0, maxElements);
  }
  values.map((serviceName) => {
    const value = analytics.serviceMap[serviceName];
    serviceBookings.push({
      name: serviceName,
      complete: value.complete || 0,
      scheduled: value.scheduled || 0,
      cancelled: value.cancelled || 0,
      revenue: value.revenue,
    });
  });

  const clientTypeMap = {};
  const clientTypeRevenueMap = {};
  const campaignRevenueMap = {};
  appointments.map((a) => {
    const clientType = a.clientType;
    const numForType = clientTypeMap[clientType] || 0;
    clientTypeMap[clientType] = numForType + 1;
    const campaignId = a.attribution?.campaignId;
    const revenue = a.items?.reduce((total, obj) => total + obj.amount, 0);
    if (campaignId) {
      const campaignObj = campaignRevenueMap[campaignId] || {};
      campaignRevenueMap[campaignId] = {
        revenue: (campaignObj?.revenue || 0) + (revenue || 0),
        count: (campaignObj?.count || 0) + 1,
      };
    }
    if (revenue) {
      const existingRevenueForType = clientTypeRevenueMap[clientType] || 0;
      clientTypeRevenueMap[clientType] = existingRevenueForType + revenue;
    }
  });
  const hasAppointmentData = !!appointments?.length;
  const hasLeadData = !!clientTypeMap[leadContactType];

  const campaignStatuses = [];
  const campaignBookings = [];
  let shouldIncludeContactedSection = false;
  Object.keys(campaignAnalytics || {}).map((campaignId) => {
    const campaignData = campaignAnalytics[campaignId];
    const statusKeys = Object.keys(campaignData).filter(
      (k) => !['lead', 'client', 'contacted'].includes(k),
    );

    const campaignName = campaignMap[campaignId];
    const totalContacted = campaignData[contactedCampaignMemberStatusKey] || 0;
    let statusObj = {
      name: campaignName,
      total: totalContacted,
    };
    if (inHardcodedMode) {
      const numBookedForCampaign = safeParseInt(totalContacted * 0.07);
      statusObj = {
        ...statusObj,
        [optedOutCampaignMemberStatusKey]: safeParseInt(totalContacted * 0.05),
        [successfullyBookedCampaignMemberStatusKey]: numBookedForCampaign,
        [hotLeadCampaignMemberStatusKey]: safeParseInt(
          campaignData[hotLeadCampaignMemberStatusKey] || 0.01,
        ),
      };
      campaignBookings.push({
        type: campaignName,
        amount: numBookedForCampaign,
      });
    } else if (statusKeys.length) {
      const numBookedForCampaign =
        (campaignData[successfullyBookedCampaignMemberStatusKey] || 0) +
        (campaignData[indirectlyBookedCampaignMemberStatusKey] || 0);
      campaignBookings.push({
        type: campaignName,
        amount: numBookedForCampaign,
      });
      statusObj = {
        ...statusObj,
        [respondedCampaignMemberStatusKey]:
          campaignData[respondedCampaignMemberStatusKey] || 0,
        [optedOutCampaignMemberStatusKey]:
          campaignData[optedOutCampaignMemberStatusKey] || 0,
        [successfullyBookedCampaignMemberStatusKey]: numBookedForCampaign,
        [hotLeadCampaignMemberStatusKey]:
          campaignData[hotLeadCampaignMemberStatusKey] || 0,
      };
    } else {
      shouldIncludeContactedSection = true;
      statusObj = {
        ...statusObj,
        [contactedCampaignMemberStatusKey]: totalContacted,
      };
    }
    campaignStatuses.push(statusObj);
  });
  const hasCampaignData = !!campaignStatuses?.length;

  const clientTypes = inHardcodedMode
    ? [
        {
          type: `${appointmentContactTypeLabelMap[activeMemberContactTypeKey]}s`,
          amount: safeParseInt(totalNumHardcodedAppointments * 0.16),
        },
        {
          type: `${appointmentContactTypeLabelMap[suspendedMemberContactTypeKey]}s`,
          amount: safeParseInt(totalNumHardcodedAppointments * 0.04),
        },
        {
          type: `${appointmentContactTypeLabelMap[clientContactType]}s`,
          amount: safeParseInt(totalNumHardcodedAppointments * 0.55),
        },
        {
          type: `${appointmentContactTypeLabelMap[leadContactType]}s`,
          amount: safeParseInt(totalNumHardcodedAppointments * 0.25),
        },
      ]
    : Object.keys(clientTypeMap).map((k) => {
        return {
          type: `${appointmentContactTypeLabelMap[k]}s`,
          amount: clientTypeMap[k],
        };
      });

  if (onAppointmentsView) {
    return (
      <Appointments
        appointments={appointments}
        clients={clients}
        agentName={inDemoMode ? 'Ava' : agentName}
        campaigns={campaignOptions}
        loading={clientsLoading}
        inDemoMode={inDemoMode}
      />
    );
  }

  if (onInterceptionsView) {
    return (
      <Interceptions
        interceptions={interceptions}
        refetch={refetchInterceptions}
        campaigns={campaignOptions}
        loading={interceptionsLoading}
        inDemoMode={inDemoMode}
      />
    );
  }

  return (
    locations && (
      <>
        <MetaSetter
          title={`Dashboard`}
          description={`Business Analytics Dashboard`}
        />
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
          >
            <ColumnCenteredDiv bottomMargin={10}>
              <PageTitleText>
                {inHardcodedMode
                  ? ''
                  : inMonitoringMode
                  ? `LiveIQ Business `
                  : `${formattedBusinessName} - `}
                Dashboard
              </PageTitleText>
            </ColumnCenteredDiv>
            <Container>
              {inMonitoringMode ? (
                <>
                  <TopSection>
                    <TopPanelSection>
                      <TopPanel
                        clickable={true}
                        onClick={() => {}}
                      >
                        <StartAlignedMediumDarkLargeTinyText smallLineHeight>
                          Active MRR (USD)
                        </StartAlignedMediumDarkLargeTinyText>
                        <StartAlignedSMText smallLineHeight>
                          $
                          {formatNumber(
                            internalRevenue?.[recurringFeeKey]?.[
                              completeStatusKey
                            ]?.amount,
                          )}
                        </StartAlignedSMText>
                      </TopPanel>
                    </TopPanelSection>
                    <TopPanelSection>
                      <TopPanel
                        clickable={true}
                        onClick={() => {}}
                      >
                        <StartAlignedMediumDarkLargeTinyText smallLineHeight>
                          Pending MRR (USD)
                        </StartAlignedMediumDarkLargeTinyText>
                        <StartAlignedSMText smallLineHeight>
                          $
                          {formatNumber(
                            internalRevenue?.[recurringFeeKey]?.[
                              incompleteStatusKey
                            ]?.amount || 0,
                          )}
                        </StartAlignedSMText>
                      </TopPanel>
                    </TopPanelSection>
                  </TopSection>
                  <SectionDiv>
                    <SectionTitleContainer>
                      <StartAlignedMediumDarkExtraSmallText>
                        Active Subscriptions
                      </StartAlignedMediumDarkExtraSmallText>
                    </SectionTitleContainer>
                    <SectionComponentDiv>
                      <ColumnCenteredDiv leftMargin={30}>
                        {(
                          sortBy(
                            internalRevenue?.[recurringFeeKey].subscriptions,
                            (s) => s.name,
                          ) || []
                        ).map((s) => {
                          const location = s.name;
                          return (
                            <StartAlignedFlexDiv>
                              <LargeTinyText>
                                {location} {textSeparatorChar} $
                                {formatNumber(parseInt(s.amount))}/month
                              </LargeTinyText>
                            </StartAlignedFlexDiv>
                          );
                        })}
                      </ColumnCenteredDiv>
                    </SectionComponentDiv>
                  </SectionDiv>
                </>
              ) : locationsLoading ? (
                <LoadingIndicator />
              ) : locations?.length ? (
                <>
                  <TopSection>
                    <TopPanelSection>
                      {topPanelFields.map((p) => {
                        const key = inHardcodedMode ? p.hardcodedKey : p.key;
                        const subkey = p.subkey;
                        let value;
                        let numTotalChats;
                        if (key === 'interceptionRate') {
                          if (inHardcodedMode) {
                            value = '3.5% (0.7)';
                          } else {
                            const active = chats[activeChatStatusKey];
                            const interceptions =
                              chats[interceptedChatStatusKey] || 0;

                            value =
                              !interceptions || !active
                                ? 0
                                : (
                                    (interceptions / (interceptions + active)) *
                                    100
                                  ).toFixed(1);

                            numTotalChats = interceptions + active;
                          }
                        } else {
                          value = inHardcodedMode
                            ? hardcodedData[key]
                            : analytics?.[key];
                        }

                        if (!inHardcodedMode) {
                          if (p.divisor && value) {
                            value = (value / p.divisor).toFixed(0);
                          } else if (p.coefficient && value) {
                            value = value * p.coefficient;
                          }
                        }

                        value =
                          p.type === percentDataType
                            ? getFormattedPercentageString(value)
                            : formatNumber(value);

                        const shouldDisplay =
                          (!value || value === '0') && key === 'talkSeconds'
                            ? false
                            : true;

                        if (p.subkey && !inHardcodedMode) {
                          value += ` (${analytics?.[subkey]}${
                            p.subkeySuffix ? ` ${p.subkeySuffix}` : ''
                          })`;
                        }

                        let postSubkeyValue;
                        if (p.postSubkey && !inHardcodedMode) {
                          if (
                            p.postSubkey === 'interceptionErrors' &&
                            numTotalChats
                          ) {
                            postSubkeyValue = ` (${(
                              ((chats?.['interceptionErrors'] || 0) /
                                numTotalChats) *
                              100
                            ).toFixed(1)}%)`;
                          }
                        }
                        return (
                          shouldDisplay && (
                            <TopPanel
                              clickable={true}
                              onClick={() => {
                                if (p.view) {
                                  onNavigateToView(p.view);
                                }
                              }}
                            >
                              <StartAlignedMediumDarkLargeTinyText
                                smallLineHeight
                              >
                                {inHardcodedMode
                                  ? p.hardcodedLabel || p.label
                                  : p.label}
                                {p.explanation && !inHardcodedMode && (
                                  <Tooltip
                                    placement='bottom'
                                    title={
                                      <TooltipTitleText>
                                        {p.explanation}
                                      </TooltipTitleText>
                                    }
                                  >
                                    <InfoIcon />
                                  </Tooltip>
                                )}
                              </StartAlignedMediumDarkLargeTinyText>
                              <StartAlignedSMText smallLineHeight>
                                {p.prefix || ''}
                                {value}
                                {p.suffix || ''}
                                {postSubkeyValue ? postSubkeyValue : ''}
                              </StartAlignedSMText>
                            </TopPanel>
                          )
                        );
                      })}
                    </TopPanelSection>
                    <QueryPanelContainer>
                      <QueryPanel center>
                        <AvatarSection>
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                "Let's explore your data"
                                <br></br> - AI Analyst
                              </TooltipTitleText>
                            }
                          >
                            <AgentAvatarContainer analyst>
                              <AnalystAvatar />
                            </AgentAvatarContainer>
                          </Tooltip>
                        </AvatarSection>
                        <StartAlignedFlexDiv lowerGap>
                          {allowMultiLocations ? (
                            <>
                              <QueryFieldText
                                selected={locationsMenuOpen}
                                onClick={(e) => {
                                  setMenuField('locations');
                                  setMenuAnchorEl(e.currentTarget);
                                }}
                              >
                                {filteredLocations?.length === locations?.length
                                  ? 'All Locations'
                                  : `Locations`}{' '}
                                ({filteredLocations?.length})
                              </QueryFieldText>
                              <Tooltip
                                title={
                                  <TooltipTitleText>
                                    Add location
                                  </TooltipTitleText>
                                }
                              >
                                <ExtraSmallPrimaryAddIcon
                                  topMargin={-4}
                                  onClick={() => setAddLocationModalOpen(true)}
                                />
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <QueryFieldText>Add Location</QueryFieldText>
                              <ExtraSmallPrimaryAddIcon
                                topMargin={-4}
                                onClick={() => setAddLocationModalOpen(true)}
                              />
                            </>
                          )}
                        </StartAlignedFlexDiv>
                        <StartAlignedFlexDiv>
                          <QueryFieldText
                            selected={campaignsMenuOpen}
                            onClick={(e) => {
                              setMenuField('campaigns');
                              setMenuAnchorEl(e.currentTarget);
                            }}
                          >
                            {isEmpty
                              ? filteredCampaigns?.length ===
                                campaignsToUse?.length
                                ? `All Campaigns`
                                : `Campaigns`
                              : filteredCampaigns?.length ===
                                campaignsToUse?.length + 1
                              ? 'All Campaigns'
                              : `Campaigns`}{' '}
                            ({filteredCampaigns?.length})
                          </QueryFieldText>
                        </StartAlignedFlexDiv>
                        <StartAlignedFlexDiv>
                          <QueryFieldText
                            selected={datesMenuOpen}
                            onClick={(e) => {
                              setMenuField('dates');
                              setMenuAnchorEl(e.currentTarget);
                            }}
                          >
                            {dateLabel}{' '}
                          </QueryFieldText>
                        </StartAlignedFlexDiv>
                      </QueryPanel>
                    </QueryPanelContainer>
                    <PopperMenu
                      open={menuOpen && !datesMenuOpen}
                      anchorElement={menuAnchorEl}
                      onClose={() => setMenuAnchorEl()}
                      variant='offset'
                    >
                      {menuOptions.map((o) => {
                        const { key, label } = o;
                        const selected = !!filteredOptions.find(
                          (filteredValue) => filteredValue === key,
                        );
                        return (
                          <MenuItem
                            disableRipple
                            onClick={() => {
                              if (!(selected && filteredOptions.length === 1)) {
                                onChangeFilter(key, !selected);
                              }
                            }}
                          >
                            <SmallMenuItemText>{label}</SmallMenuItemText>
                            {selected && <MenuItemCheckmark />}
                          </MenuItem>
                        );
                      })}
                      {menuField === 'campaigns' && (
                        <MenuItem onClick={() => onApplyCampaignFilter()}>
                          <ButtonTextContainer>
                            <ButtonText>Apply filter</ButtonText>
                          </ButtonTextContainer>
                        </MenuItem>
                      )}
                    </PopperMenu>
                    <RangePopperMenuCalendar
                      start={startDateRange}
                      end={endDateRange}
                      onSave={onSaveDateFilter}
                      onReset={() => {
                        setStartDateRange();
                        setEndDateRange();
                      }}
                      open={datesMenuOpen}
                      anchorElement={menuAnchorEl}
                      onClose={() => setMenuAnchorEl()}
                    />
                  </TopSection>
                  <SectionDiv>
                    <SectionTitleContainer>
                      <StartAlignedMediumDarkExtraSmallText>
                        Appointments
                      </StartAlignedMediumDarkExtraSmallText>
                    </SectionTitleContainer>
                    {hasAppointmentData ? (
                      <SectionComponentDiv>
                        <FullWidthCenteredDiv>
                          <StackedBarChart
                            data={serviceBookings}
                            series={serviceBookingSeries}
                            title={serviceBookingsTitle}
                            fills={appointmentStatusFills}
                          />
                        </FullWidthCenteredDiv>
                        <FullWidthCenteredDiv>
                          <PieChart
                            data={clientTypes}
                            series={bookingAmountSeries}
                            title={'Bookings by client type'}
                            fills={clientTypeFills}
                          />
                        </FullWidthCenteredDiv>
                      </SectionComponentDiv>
                    ) : (
                      <>{getEmptyComponent('appointment')}</>
                    )}
                  </SectionDiv>
                  <SectionDiv>
                    <SectionTitleContainer>
                      <StartAlignedMediumDarkExtraSmallText>
                        Campaigns
                      </StartAlignedMediumDarkExtraSmallText>
                    </SectionTitleContainer>
                    {hasCampaignData ? (
                      <SectionComponentDiv>
                        <FullWidthCenteredDiv>
                          <StackedBarChart
                            data={campaignStatuses}
                            series={
                              shouldIncludeContactedSection
                                ? allCampaignMemberSeries
                                : campaignMemberSeries
                            }
                            title={'Statuses by campaign'}
                            fills={campaignMemberStatusFills}
                          />
                        </FullWidthCenteredDiv>
                        <FullWidthCenteredDiv>
                          <PieChart
                            data={campaignBookings}
                            series={bookingAmountSeries}
                            title={'Bookings by campaign'}
                            fills={campaignBookingFills}
                          />
                        </FullWidthCenteredDiv>
                      </SectionComponentDiv>
                    ) : (
                      <>{getEmptyComponent('campaign')}</>
                    )}
                  </SectionDiv>
                  {isSuperAdmin && (
                    <SectionDiv>
                      <SectionTitleContainer>
                        <StartAlignedMediumDarkExtraSmallText>
                          Leads
                        </StartAlignedMediumDarkExtraSmallText>
                      </SectionTitleContainer>
                      <ColumnSectionContentDiv>
                        <SectionTopPanelContainer>
                          <SubSectionTopPanel>
                            {leadPanelFields.map((p, idx) => {
                              const key = inHardcodedMode
                                ? p.hardcodedKey
                                : p.key;
                              let value;
                              if (key === 'numLeads') {
                                value = clientTypeMap[leadContactType];
                              } else if (key === 'leadRevenue') {
                                value = formatNumber(
                                  clientTypeRevenueMap[leadContactType] || 0,
                                );
                              } else if (key === 'roas') {
                                value = 4.3;
                              } else if (key === 'cpc') {
                                value = 0.82;
                              }
                              return (
                                <TopPanel
                                  clickable={true}
                                  onClick={() => {
                                    if (p.view) {
                                      onNavigateToView(p.view);
                                    }
                                  }}
                                  end={idx === leadPanelFields.length - 1}
                                >
                                  <StartAlignedMediumDarkLargeTinyText
                                    smallLineHeight
                                  >
                                    {inHardcodedMode
                                      ? p.hardcodedLabel || p.label
                                      : p.label}
                                    {p.explanation && !inHardcodedMode && (
                                      <Tooltip
                                        placement='bottom'
                                        title={
                                          <TooltipTitleText>
                                            {p.explanation}
                                          </TooltipTitleText>
                                        }
                                      >
                                        <InfoIcon />
                                      </Tooltip>
                                    )}
                                  </StartAlignedMediumDarkLargeTinyText>
                                  <StartAlignedSMText smallLineHeight>
                                    {p.prefix || ''}
                                    {value}
                                    {p.suffix || ''}
                                  </StartAlignedSMText>
                                </TopPanel>
                              );
                            })}
                          </SubSectionTopPanel>
                        </SectionTopPanelContainer>
                        {hasLeadData ? (
                          <SectionComponentDiv>
                            <FullWidthCenteredDiv>
                              <StackedBarChart
                                data={leadSourceStatuses}
                                series={campaignMemberSeries}
                                title={'Statuses by source'}
                                fills={campaignMemberStatusFills}
                              />
                            </FullWidthCenteredDiv>
                            <FullWidthCenteredDiv>
                              <PieChart
                                data={leadSourceBookings}
                                series={bookingAmountSeries}
                                title={'Bookings by source'}
                                fills={clientTypeFills}
                              />
                            </FullWidthCenteredDiv>
                          </SectionComponentDiv>
                        ) : (
                          <>{getEmptyComponent('campaign')}</>
                        )}
                      </ColumnSectionContentDiv>
                    </SectionDiv>
                  )}
                  <SectionDiv>
                    <SectionTitleContainer>
                      <StartAlignedMediumDarkExtraSmallText>
                        Phone Calls
                      </StartAlignedMediumDarkExtraSmallText>
                    </SectionTitleContainer>
                    {hasCalls ? (
                      <SectionComponentDiv>
                        <FullWidthCenteredDiv>
                          <BarChart
                            data={callActions}
                            series={callActionSeries}
                            title={'Appointment actions in calls'}
                          />
                        </FullWidthCenteredDiv>
                        <FullWidthCenteredDiv>
                          <PieChart
                            data={sentimentData}
                            series={callSentimentSeries}
                            title={'Sentiment by call'}
                            fills={callSentimentFills}
                          />
                        </FullWidthCenteredDiv>
                      </SectionComponentDiv>
                    ) : (
                      <>{getEmptyComponent('call')}</>
                    )}
                  </SectionDiv>
                </>
              ) : (
                <NoLocationsCreatedContainer>
                  <MediumDarkMediumText>
                    Add a location to begin tracking metrics
                  </MediumDarkMediumText>
                  <MediumPrimaryButton
                    onClick={() => setAddLocationModalOpen(true)}
                  >
                    <AddIcon /> Add location
                  </MediumPrimaryButton>
                </NoLocationsCreatedContainer>
              )}
            </Container>
          </ContentContainer>
        </PageContainer>
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
        />
        <AddLocationModal
          isOpen={addLocationModalOpen}
          onClose={() => setAddLocationModalOpen(false)}
          onAdd={async (sentInviteToOwner) => {
            await refetchLocations();
            const snackbarText = `Added location${
              sentInviteToOwner ? ' and sent invite to location admin' : ''
            }`;
            setSnackbarMessage(snackbarText);
            setAddedLocation(true);
          }}
        />
      </>
    )
  );
};

export default Analytics;
