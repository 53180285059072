import { useMutation } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { default as React, useEffect, useState } from 'react';
import {
  CREATE_MEMBERSHIP,
  EDIT_MEMBERSHIPS,
} from '../../../../api/mutations/training';
import {
  AddIcon,
  CenteredDiv,
  ExpandViewIcon,
  StartAlignedLightDarkLargeTinyText,
  StartAlignedMediumDarkEssText,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  buttonTriggeredAutoSaveDelayMs,
  membershipPlanTypeKey,
  textSeparatorChar,
} from '../../../../utils/constants';
import LoadingIndicator from '../../../LoadingIndicator';
import SwitchToggle from '../../../Micro/SwitchToggle/SwitchToggle';
import EditMembershipModal from '../../../Modals/EditMembershipModal/EditMembershipModal';
import Snackbar from '../../../Snackbar';
import {
  ListInputContainer,
  ListInputSection,
  ListInputTextContainer,
  ListInputTitleContainer,
  OptionActionsContainer,
  OptionIconContainer,
  PromptContainer,
  PromptsSection,
  PromptTitleText,
} from '../../shared-training-components';

const sortMemberships = (memberships) => {
  return orderBy(memberships, ['isActive', 'name'], ['desc', 'asc']);
};

const getStringifiedMemberships = (memberships) => {
  return JSON.stringify(
    memberships.map((m) => {
      return {
        id: m.id,
        name: m.name,
        isActive: m.isActive,
        description: m.description,
        price: m.price,
        type: m.type,
        paymentCadenceMonths: m.paymentCadenceMonths,
        serviceDiscountPercentage: m.serviceDiscountPercentage,
        retailDiscountPercentage: m.retailDiscountPercentage,
      };
    }),
  );
};

const Memberships = ({ res }) => {
  const { data, loading, refetch } = res;

  const [createMembershipMutation, { loading: creating }] =
    useMutation(CREATE_MEMBERSHIP);
  const [editMembershipsMutation, { loading: saving }] =
    useMutation(EDIT_MEMBERSHIPS);

  const membershipData = sortMemberships(data || []);
  const stringifiedMemberships = JSON.stringify(membershipData);

  const [memberships, setMemberships] = useState(membershipData);
  const [lastSavedMemberships, setLastSavedMemberships] =
    useState(membershipData);
  const [membershipToEdit, setMembershipToEdit] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (data) {
      setMemberships(membershipData);
      setLastSavedMemberships(membershipData);
    }
  }, [stringifiedMemberships]);

  const onToggleMembershipInclusion = (id, isActive) => {
    const updatedMemberships = memberships.map((m) => {
      if (m.id === id) {
        return {
          ...m,
          isActive,
        };
      }
      return m;
    });
    setMemberships(sortMemberships(updatedMemberships));
  };

  const autoSave = async (inputMemberships) => {
    const membershipsToSave = inputMemberships ?? memberships;

    if (
      getStringifiedMemberships(membershipsToSave) !==
      getStringifiedMemberships(lastSavedMemberships)
    ) {
      console.log('Saving memberships...', membershipsToSave);

      try {
        await editMembershipsMutation({
          variables: { memberships: membershipsToSave },
          onCompleted: async (data) => {
            const updatedMemberships = data.editMemberships;
            setLastSavedMemberships(updatedMemberships);
            refetch();
          },
        });
        console.log('Memberships saved successfully');
      } catch (error) {
        console.error('Error saving memberships:', error);
      }
    }
  };

  const onSaveEdits = (updatedMembership) => {
    const updatedMemberships = memberships.map((m) => {
      if (m.id === updatedMembership.id) {
        return {
          ...m,
          ...updatedMembership,
        };
      }
      return m;
    });
    setMembershipToEdit();
    setEditModalOpen(false);
    autoSave(updatedMemberships);
    setSnackbarMessage(`Changes saved`);
  };

  const onCreate = async (membership) => {
    createMembershipMutation({
      variables: {
        membership,
      },
      onCompleted: async (data) => {
        setEditModalOpen(false);
        const success = data?.createMembership?.success;
        await refetch();
        if (success) {
          setSnackbarMessage(`Membership created`);
        }
      },
    });
  };

  // Auto-save interval effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!saving) {
        autoSave();
      }
    }, buttonTriggeredAutoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [memberships, lastSavedMemberships]);

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  const hasValidMemberships = !!memberships?.length;

  return (
    <>
      <PromptsSection hideOverflow>
        <PromptContainer overflow>
          <ListInputTitleContainer>
            <PromptTitleText>
              {hasValidMemberships
                ? `Select which memberships are active`
                : `No memberships`}
            </PromptTitleText>
            <Tooltip
              title={<TooltipTitleText>Add new</TooltipTitleText>}
              placement='bottom'
            >
              <AddIcon
                lightPrimary
                pointer
                onClick={() => setEditModalOpen(true)}
                leftMargin={8}
              />
            </Tooltip>
          </ListInputTitleContainer>
          {hasValidMemberships ? (
            <ListInputSection>
              {memberships.map((membership) => {
                const {
                  id,
                  name,
                  price,
                  type,
                  isActive,
                  retailDiscountPercentage,
                  serviceDiscountPercentage,
                  paymentCadenceMonths,
                } = membership;

                const typeLabel =
                  type === membershipPlanTypeKey ? `Plan` : 'Package';

                const discountText =
                  retailDiscountPercentage && serviceDiscountPercentage
                    ? `${serviceDiscountPercentage}% off services, ${retailDiscountPercentage}% off retail`
                    : retailDiscountPercentage
                    ? `${retailDiscountPercentage}% off retail`
                    : serviceDiscountPercentage
                    ? `${serviceDiscountPercentage}% off services`
                    : `Discount unknown`;

                const cadenceMonths = parseInt(paymentCadenceMonths);
                const cadenceText = !cadenceMonths
                  ? `(unknown cadence)`
                  : cadenceMonths === 1
                  ? `per month`
                  : cadenceMonths === 12
                  ? `per year`
                  : `per ${cadenceMonths} months`;
                return (
                  <ListInputContainer>
                    <ListInputTextContainer>
                      <StartAlignedMediumDarkEssText>
                        {name}
                      </StartAlignedMediumDarkEssText>
                      <StartAlignedLightDarkLargeTinyText>
                        {typeLabel} {textSeparatorChar}{' '}
                        {price != null
                          ? `$${price} ${cadenceText}`
                          : 'Price unknown'}{' '}
                        <br></br>
                        {discountText}
                      </StartAlignedLightDarkLargeTinyText>
                    </ListInputTextContainer>
                    <OptionActionsContainer>
                      <SwitchToggle
                        value={isActive}
                        onChange={() =>
                          onToggleMembershipInclusion(id, !isActive)
                        }
                      />
                      <OptionIconContainer>
                        <Tooltip
                          title={<TooltipTitleText>Edit</TooltipTitleText>}
                        >
                          <CenteredDiv
                            onClick={() => {
                              setMembershipToEdit(membership);
                              setEditModalOpen(true);
                            }}
                          >
                            <ExpandViewIcon />
                          </CenteredDiv>
                        </Tooltip>
                      </OptionIconContainer>
                    </OptionActionsContainer>
                  </ListInputContainer>
                );
              })}
            </ListInputSection>
          ) : (
            <></>
          )}
        </PromptContainer>
      </PromptsSection>
      <EditMembershipModal
        isOpen={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setMembershipToEdit();
        }}
        membership={membershipToEdit}
        onSave={onSaveEdits}
        onCreate={onCreate}
      />
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Memberships;
