import sortBy from 'lodash/sortBy';
import { default as React, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCorporationLocations,
  useOrganizationTemplates,
} from '../../../../api/hooks/enterprise';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import OrganizationTemplateModal from '../../../../components/Modals/OrganizationTemplateModal';
import Header from '../../../../components/NavBar/Header';
import Snackbar from '../../../../components/Snackbar';
import {
  BaseCard,
  CardActionsContainer,
  CardHeaderContainer,
  CardPageSection,
  ColumnCenteredDiv,
  ColumnDivWithGap,
  ContentContainer,
  DarkEssText,
  LargeNoDataIcon,
  LargeTinyText,
  LightDarkTextSpan,
  MediumGapColumnCenteredDiv,
  PageContainer,
  PageTitleText,
  PlusIcon,
  SmallSecondaryButton,
  StartAlignedLightDarkLargeTinyText,
  Tab,
  Tabs,
  TinyPrimaryButton,
} from '../../../../styles/shared-styled-components';
import {
  organizationTemplateTypeLabelMap,
  templatesTabCookieKey,
  textSeparatorChar,
} from '../../../../utils/constants';

const industryTemplates = [
  {
    label: '[Salon] Campaign: 90 Day 15% Off Reactivation',
    description:
      '15% off all bookings for clients without appointments in 90 days',
  },
  {
    label: '[All Industries] Script: Massage Recommendation',
    description:
      'Series of questions to find out the best Massage to recommend',
  },
  {
    label: '[MedSpa] Campaign: Hydrafacial Promo',
    description: '15% off first Hydrafacial, including Serum + Mask',
  },
];

const corporateTemplateKey = 0;
const industryTemplateKey = 1;

const Templates = () => {
  const navigate = useNavigate();
  const { drawerOpen, drawerExpanded, cookies, setCookie } =
    useContext(BaseContext);

  const {
    templates: organizationTemplates,
    loading: organizationTemplatesLoading,
    refetch,
  } = useOrganizationTemplates({});
  const { locations, loading: locationsLoading } = useCorporationLocations({});
  const locationMap = {};
  const locationOptions = sortBy(
    locations?.map((l) => {
      locationMap[l.id] = l.name;
      return { key: l.id, label: l.name };
    }),
    (l) => l.label,
  );

  const tabCookie = cookies[templatesTabCookieKey];
  const [tab, setTab] = useState(
    tabCookie != null ? parseInt(tabCookie) : corporateTemplateKey,
  );
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editingTemplate, setEditingTemplate] = useState();
  const [organizationTemplateModalOpen, setOrganizationTemplateModalOpen] =
    useState(false);

  useEffect(() => {
    if (tabCookie != null) {
      setTab(parseInt(tabCookie));
    }
  }, [tabCookie]);

  const onChangeTab = (tabValue) => {
    setCookie(templatesTabCookieKey, parseInt(tabValue), {});
  };

  const onSave = async () => {
    setEditingTemplate();
    await refetch();
    setSnackbarMessage('Changes saved');
  };

  const templatesToDisplay =
    tab === corporateTemplateKey ? organizationTemplates : [];

  if (organizationTemplatesLoading || locationsLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ColumnCenteredDiv>
            <PageTitleText>Templates</PageTitleText>
          </ColumnCenteredDiv>
          <ColumnCenteredDiv>
            <Tabs
              value={tab}
              onChange={(_, key) => onChangeTab(key)}
            >
              <Tab
                value={corporateTemplateKey}
                label='Corporation'
              />
              <Tab
                value={industryTemplateKey}
                label='Industry'
              />
            </Tabs>
          </ColumnCenteredDiv>
          <CardPageSection>
            <MediumGapColumnCenteredDiv>
              {templatesToDisplay?.length ? (
                <>
                  {templatesToDisplay.map((template) => {
                    const { type, locationId } = template;
                    const value = organizationTemplateTypeLabelMap[type];
                    const locationName = locationMap[locationId];

                    return (
                      <BaseCard>
                        <ColumnDivWithGap>
                          <CardHeaderContainer>
                            <DarkEssText>
                              {value?.label} {textSeparatorChar} Saved from{' '}
                              {locationName}
                              <LightDarkTextSpan></LightDarkTextSpan>
                            </DarkEssText>
                          </CardHeaderContainer>
                          <StartAlignedLightDarkLargeTinyText>
                            {value?.sublabel}
                          </StartAlignedLightDarkLargeTinyText>
                        </ColumnDivWithGap>
                        <CardActionsContainer>
                          <TinyPrimaryButton
                            largePadding
                            onClick={() => {
                              setEditingTemplate(template);
                              setOrganizationTemplateModalOpen(true);
                            }}
                          >
                            Edit
                          </TinyPrimaryButton>
                        </CardActionsContainer>
                      </BaseCard>
                    );
                  })}
                </>
              ) : (
                <>
                  <ColumnCenteredDiv
                    topMargin={30}
                    bottomMargin={3}
                  >
                    <LargeNoDataIcon />
                    <LargeTinyText>No templates found</LargeTinyText>
                  </ColumnCenteredDiv>
                  {tab === corporateTemplateKey && (
                    <SmallSecondaryButton
                      addGap
                      onClick={() => {
                        setOrganizationTemplateModalOpen(true);
                      }}
                    >
                      Create template <PlusIcon />
                    </SmallSecondaryButton>
                  )}
                </>
              )}
            </MediumGapColumnCenteredDiv>
          </CardPageSection>
        </ContentContainer>
      </PageContainer>
      <OrganizationTemplateModal
        id={editingTemplate?.id}
        locationOptions={locationOptions}
        isOpen={organizationTemplateModalOpen}
        onClose={() => {
          setEditingTemplate();
          setOrganizationTemplateModalOpen(false);
        }}
        onSave={onSave}
      />
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Templates;
