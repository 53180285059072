export const Paths = {
  // ** API Endpoints **
  prodHttpsBackend: 'https://api.liveiq.ai/graphql',
  testHttpsBackend: 'https://demoapi.liveiq.ai/graphql',
  localHttpBackend: 'http://localhost:4002/graphql',
  prodWsBackend: 'api.liveiq.ai/graphql',
  testWsBackend: 'demoapi.liveiq.ai/graphql',
  localWsBackend: 'localhost:4002/graphql',

  // Homepage
  homepage: '/',
  newHomepage: '/new-homepage',
  chat: '/chat',

  // Onboarding
  middleRouter: '/middle-router',
  personalOnboarding: `/onboarding/personal`,
  businessOnboarding: `/onboarding/business`,
  systemOnboarding: `/location-onboarding/system`,
  integrationOnboarding: `/location-onboarding/integration`,
  surveyOnboarding: `/location-onboarding/survey`,

  // Setup
  agentSelection: '/agent-selection',
  agentAppConnection: '/agent-app-connection/:agentId',
  billing: '/billing',
  ghlSyncStatus: '/ghl-sync-status',
  hubSpotSyncStatus: '/hs-sync-status',
  hubSpotRedirectUrl:
    'https://app.hubspot.com/oauth/authorize?client_id=dd37d0be-d4c8-464f-834d-73d8c319d805&redirect_uri=https://backend.liveiq.ai/hs-callback&scope=crm.objects.custom.read%20oauth%20conversations.read%20crm.schemas.contacts.read%20crm.objects.leads.read%20crm.objects.contacts.read&optional_scope=social%20conversations.custom_channels.read%20conversations.custom_channels.write%20crm.objects.leads.write%20crm.export%20marketing.campaigns.read%20marketing.campaigns.write%20marketing.campaigns.revenue.read%20communication_preferences.read_write%20crm.objects.companies.read%20crm.lists.read%20communication_preferences.statuses.batch.read%20crm.schemas.companies.read%20communication_preferences.statuses.batch.write%20crm.schemas.companies.write%20crm.schemas.contacts.write%20marketing-email%20crm.objects.subscriptions.read%20crm.import%20communication_preferences.read%20communication_preferences.write%20conversations.write%20crm.objects.appointments.read%20crm.objects.appointments.write%20crm.objects.marketing_events.read%20crm.objects.marketing_events.write',
  metaAuthStatus: '/meta-auth-status',
  initializationPurgatory: '/initialization',

  // Enterprise
  headOffice: '/head-office',
  createBusiness: '/create-business',

  // Overview
  dashboard: '/app/dashboard',
  appointments: '/app/appointments',
  calendar: '/app/calendar',
  // Chats
  chats: '/app/chats',
  events: '/app/events',
  pipeline: '/app/pipeline',
  // Training
  training: '/app/training',
  requests: '/app/requests',
  // Contacts
  contacts: '/app/contacts',
  // Campaigns
  campaigns: '/app/campaigns',
  editScript: '/app/campaigns/edit-script/:scriptId',
  editPromo: '/app/campaigns/edit-promo/:promoId',
  audience: '/app/audience',
  // Demo
  agentDemo: '/app/agent-demo',
  // Sequences
  sequences: '/app/sequences',
  // Users
  users: '/app/users',
  // Templates
  templates: '/app/templates',

  // Admin
  connections: '/connections',
  terms: '/terms',
  settings: '/settings',

  // Public
  appointment: '/app/appointment/:appointmentId',

  // Auth
  callback: '/callback',
  logout: '/logout',

  // Assets
  calendlyLink: 'https://calendly.com/liveiq/demo/',

  // Legal
  privacyPolicy:
    'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/files/legal-docs/LiveIQ%20Privacy%20Policy.pdf',
  eula: 'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/files/legal-docs/LiveIQ%20EULA.pdf',
};

export default Paths;
