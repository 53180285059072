import { useAuth0 } from '@auth0/auth0-react';
import FontFaceObserver from 'fontfaceobserver';
import React, { createContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Paths from '../../../Paths';
import { useMyUser } from '../../../api/hooks/users';
import GetStarted from '../../../pages-app/Auth/MiddleRouter';
import '../../../styles/font.css';
import {
  appModeCookieKey,
  completeStatusKey,
  consultantRole,
  demoAppModeKey,
  ghlKey,
  initializedStatusKey,
  integrationOnboardingStatus,
  liveAppModeKey,
  mindBodyKey,
  monitoringAllActionKey,
  superAdminRole,
  systemOnboardingStatus,
  zenotiKey,
} from '../../../utils/constants';
import {
  checkIfPathIsAgentSetupPage,
  checkIfPathIsAppPage,
  checkIfPathIsLocationOnboarding,
  checkIfPathIsPrivilegedPage,
  checkIfPathIsUserOnboarding,
  getUrlWithoutParams,
} from '../../../utils/routing';
import {
  checkIfUserIsMultiLocationBusinessOwner,
  getUserBusiness,
  getUserConnections,
  getUserLiveIQAgentId,
  getUserLocation,
  getUserLocationId,
  locationIdSelectedKey,
} from '../../../utils/user';
import {
  drawerExpandedKey,
  drawerOpenKey,
  screenWidthIsIpadSizeOrSmaller,
  screenWidthIsMediumMobileSizeOrSmaller,
} from '../../../utils/view';
import LoadingIndicator from '../../LoadingIndicator';

const BaseContext = createContext();

const AuthRouter = ({ Component, authGated = false, ...propsForComponent }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();

  const isWebsitePage = pathname === Paths.homepage;
  const isCallbackPage = pathname === Paths.callback;
  const isMiddleRouter = pathname === Paths.middleRouter;

  const locationIdSelected = localStorage.getItem(locationIdSelectedKey);

  const { user: auth0User, logout } = useAuth0();
  const {
    user,
    refetch: refetchUser,
    loading: userLoading,
    error: userError,
  } = useMyUser({
    email: auth0User?.email,
    shouldSkip: pathname === Paths.homepage,
  });

  useEffect(() => {
    if (
      userError?.response?.status === 401 &&
      !locationIdSelected &&
      !isWebsitePage &&
      !isCallbackPage &&
      !isMiddleRouter
    ) {
      window.localStorage.clear();
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}${Paths.homepage}`,
        },
      });
    }
  }, [auth0User, userError, logout, navigate]);

  const role = user?.role;
  const business = getUserBusiness(user);
  const isMultiLocationBusinessOwner = checkIfUserIsMultiLocationBusinessOwner(
    role,
    business,
  );
  const location = getUserLocation(user);
  const userLocationId = location?.id;
  const userAgentId = getUserLiveIQAgentId(user);

  const [cookies, baseSetCookie, baseRemoveCookie] = useCookies();

  // Have to make sure we're properly using the path variables
  const setCookie = (key, value, expiry) => {
    const options = {
      ...expiry,
      path: '/',
    };
    baseSetCookie(key, value, options);
  };

  const removeCookie = (cookieKey) => {
    baseRemoveCookie(cookieKey, { path: '/' });
  };

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const font = new FontFaceObserver('SeldonFont');

    font.load().then(() => {
      setFontLoaded(true);
    });
  }, []);

  // Mobile breakpoint logic
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMediumMobileScreen = screenWidthIsMediumMobileSizeOrSmaller(width);
  const isIpadScreen = screenWidthIsIpadSizeOrSmaller(width);

  const appMode =
    !role === superAdminRole
      ? liveAppModeKey
      : cookies[appModeCookieKey] || liveAppModeKey;
  const inDemoMode = appMode === demoAppModeKey;
  const inMonitoringMode =
    role === superAdminRole && locationIdSelected === monitoringAllActionKey;

  const drawerOpenFromLocalStorage = localStorage.getItem(drawerOpenKey);
  const drawerOpenDefault = !isMediumMobileScreen && false;
  const drawerExpandedFromLocalStorage =
    localStorage.getItem(drawerExpandedKey);
  const drawerExpandedDefault = true;

  const drawerOpenValue =
    drawerOpenFromLocalStorage != null
      ? drawerOpenFromLocalStorage === 'true'
        ? true
        : false
      : drawerOpenDefault;
  const drawerExpandedValue =
    drawerExpandedFromLocalStorage != null
      ? drawerExpandedFromLocalStorage === 'true'
        ? true
        : false
      : drawerExpandedDefault;

  const [drawerOpen, setDrawerOpen] = useState();
  const [drawerExpanded, setDrawerExpanded] = useState(drawerExpandedDefault);

  useEffect(() => {
    if (drawerOpenFromLocalStorage == null) {
      const drawerOpenDefault = !isMediumMobileScreen && false;
      localStorage.setItem(drawerOpenKey, drawerOpenDefault ? 'true' : 'false');
    }

    if (drawerExpandedFromLocalStorage == null) {
      localStorage.setItem(drawerExpandedKey, drawerExpandedDefault);
    }
  }, []);

  useEffect(() => {
    if (drawerOpenValue !== drawerOpen) {
      setDrawerOpen(drawerOpenValue);
    }
  }, [drawerOpenValue]);

  useEffect(() => {
    if (drawerExpandedValue !== drawerExpanded) {
      setDrawerExpanded(drawerExpandedValue);
    }
  }, [drawerExpandedValue]);

  useEffect(() => {
    if (isMediumMobileScreen) {
      if (drawerOpen) {
        localStorage.setItem(drawerOpenKey, 'false');
      }
    } else {
      if (!drawerOpen) {
        localStorage.setItem(drawerOpenKey, 'true');
      }
    }

    if (isIpadScreen) {
      if (drawerExpanded) {
        localStorage.setItem(drawerExpandedKey, 'false');
      }
    }
  }, [width]);

  useEffect(() => {
    if (user) {
      const locationId = getUserLocationId(user);

      if (isMultiLocationBusinessOwner && locationId && !locationIdSelected) {
        localStorage.setItem(locationIdSelectedKey, locationId);
        refetchUser();
      }
    }
  }, [user]);

  const connections = getUserConnections(user);
  const mindbodyStatus = connections[mindBodyKey];
  const zenotiStatus = connections[zenotiKey];
  const ghlStatus = connections[ghlKey];

  const mindbodyInitialized = mindbodyStatus === initializedStatusKey;
  const zenotiInitialized = zenotiStatus === initializedStatusKey;
  const ghlInitialized = ghlStatus === initializedStatusKey;

  const fullyConnected =
    mindbodyInitialized || zenotiInitialized || ghlInitialized;

  const baseUrl = getUrlWithoutParams(pathname, params);
  const isUserOnboardingPage = checkIfPathIsUserOnboarding(pathname);
  const isLocationOnboardingPage = checkIfPathIsLocationOnboarding(pathname);
  const isAgentSetupPage = checkIfPathIsAgentSetupPage(pathname, baseUrl);
  const isAppPage = checkIfPathIsAppPage(pathname);
  const isSuperAdmin = role === superAdminRole;

  if (user && !isWebsitePage) {
    const userHasOnboarded = user.hasOnboarded === true;
    const onboardingStatus = location.onboardingStatus?.status;
    const locationHasOnboarded = onboardingStatus === completeStatusKey;

    // If they've already onboarded and are accessing an onboarding screen, navigate them to the appropriate homescreen
    // Or if they've just logged in, navigate them to the appropriate homescreen based on their goals
    // Business page bug -- ignoring for that screen
    if (
      userHasOnboarded &&
      isUserOnboardingPage &&
      pathname !== Paths.businessOnboarding &&
      !isSuperAdmin
    ) {
      navigate(Paths.agentAppConnection.replace(':agentId', userAgentId));
    } else if (
      !userHasOnboarded &&
      !user?.enterprise?.business &&
      !isUserOnboardingPage
    ) {
      // If they've not already onboarded and are accessing a non-onboarding screen,
      // redirect them back to the appropriate step in the onboarding flow
      const onboardingPathToNavigateTo = user.firstName
        ? Paths.businessOnboarding
        : Paths.personalOnboarding;
      navigate(onboardingPathToNavigateTo);
    } else if (
      userLocationId &&
      !inMonitoringMode &&
      !locationHasOnboarded &&
      !isLocationOnboardingPage
    ) {
      const onboardingPathToNavigateTo =
        onboardingStatus === systemOnboardingStatus
          ? Paths.systemOnboarding
          : onboardingStatus === integrationOnboardingStatus
          ? Paths.integrationOnboarding
          : Paths.surveyOnboarding;
      navigate(onboardingPathToNavigateTo);
    } else if (
      isMultiLocationBusinessOwner &&
      !userLocationId &&
      userHasOnboarded &&
      !inMonitoringMode
    ) {
      // If they don't have a location ID selected, redirect them to the Head Office screen
      navigate(Paths.dashboard);
    } else if (isAppPage) {
      if (
        checkIfPathIsPrivilegedPage(pathname) &&
        !isSuperAdmin &&
        !role === consultantRole
      ) {
        navigate(Paths.homepage);
      }
    }
  }

  return (
    <BaseContext.Provider
      value={{
        inDemoMode,
        inMonitoringMode,
        user,
        refetchUser,
        userLoading,
        role,
        locationIdSelected,
        drawerOpen,
        drawerExpanded,
        isOnboardingPage: isUserOnboardingPage || isLocationOnboardingPage,
        isAgentSetupPage,
        fullyConnected,
        width,
        height,
        cookies,
        setCookie,
        removeCookie,
      }}
    >
      {userLoading || !fontLoaded ? (
        // While the user object is loading, show a loading indicator
        <LoadingIndicator fullScreen />
      ) : authGated && !user ? (
        // If this component is auth gated, redirect to the login page if they're unauthenticated, or display the component if they are
        <GetStarted />
      ) : (
        // If the user is logged in and onboarded, or auth is not required, just render the component
        <Component {...propsForComponent} />
      )}
    </BaseContext.Provider>
  );
};

export { AuthRouter, BaseContext };
