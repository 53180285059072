import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMyCampaigns } from '../../../../api/hooks/campaigns';
import { getTypesenseClient } from '../../../../api/typesense/typesense-client';
import OptionsFilterDropdown from '../../../../components/Analytics/OptionsFilterDropdown/OptionsFilterDropdown';
import { InfoIcon } from '../../../../components/Analytics/shared-analytics-styles';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Header from '../../../../components/NavBar/Header';
import ClientSearchBox from '../../../../components/Search/ClientSearchBox/ClientSearchBox';
import Snackbar from '../../../../components/Snackbar';
import Paths from '../../../../Paths';
import {
  ColumnCenteredDiv,
  ContentContainer,
  EndAlignedFlexDiv,
  LightDarkTextSpan,
  LightDarkTinyText,
  MediumNoDataIcon,
  PageContainer,
  PageTitleText,
  StartAlignedFlexDiv,
  StartAlignedMediumDarkTinyText,
  TinySecondaryButton,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  allKey,
  alreadyBookedCampaignMemberStatusKey,
  ascendingKey,
  campaignForPipelineCookieKey,
  clientContactType,
  contactedCampaignMemberStatusKey,
  contactNameForPipelineCookieKey,
  contactTypeForPipelineCookieKey,
  contactTypesArr,
  contactTypesForCampaigns,
  declinedCampaignMemberStatusKey,
  firstNameField,
  hotLeadCampaignMemberStatusKey,
  indirectlyBookedCampaignMemberStatusKey,
  intraStringSeparatorChar,
  leadContactType,
  optedOutCampaignMemberStatusKey,
  pauseSequencingCampaignMemberStatusKey,
  respondedCampaignMemberStatusKey,
  sequenceCompletedCampaignMemberStatusKey,
  stagingFilterConversionDelay,
  staleCampaignMemberStatusKey,
  successfullyBookedCampaignMemberStatusKey,
  typesenseChatsSchemaName,
  typesenseContactSchemaName,
  uncontactedCampaignMemberStatusKey,
} from '../../../../utils/constants';
import { getCookieExpiryObject } from '../../../../utils/date';
import {
  getSortParam,
  getTypesenseFilterString,
} from '../../../../utils/filters';
import { getFormattedFullNameFromUser } from '../../../../utils/name';
import { formatNumber } from '../../../../utils/numbers';
import { formatKeyToLabel } from '../../../../utils/string';
import {
  getUserLocationId,
  getUserTypesenseApiKey,
} from '../../../../utils/user';
import {
  PipelineClient,
  PipelineClientContainer,
  PipelineClientName,
  PipelineColumn,
  PipelineColumnHeader,
  PipelineColumnHeaderSubtext,
  PipelineColumnHeaderTitle,
  PipelineContainer,
  PipelineFiltersContainer,
  ResetFiltersIcon,
  SearchIconContainer,
} from './styled';

const getCampaignIdsString = (ids) => {
  return (ids || []).sort().join(',');
};

const sortParam = getSortParam(firstNameField, ascendingKey);

const pageSize = 200;

const statusLabelMap = {
  [uncontactedCampaignMemberStatusKey]: {
    label: 'Uncontacted',
    description:
      'Enrolled in the campaign, but have not yet been reached out to',
  },
  [contactedCampaignMemberStatusKey]: {
    label: 'Contacting',
    description:
      'Been contacted about the campaign, and are actively in discussion or being followed up with',
  },
  [respondedCampaignMemberStatusKey]: {
    label: 'Responded',
    description: 'Responded to the campaign outreach',
  },
  [successfullyBookedCampaignMemberStatusKey]: {
    label: 'Successfully Booked',
    description:
      'Received the campaign outreach, and successfully booked an appointment because of it',
  },
  [indirectlyBookedCampaignMemberStatusKey]: {
    label: 'Indirectly Booked',
    description:
      'Received the campaign outreach, and successfully booked an appointment, just not through your LiveIQ agent',
  },
  [hotLeadCampaignMemberStatusKey]: {
    label: 'Hot',
    description: `Haven't successfully booked an appointment, but show potential willingness to book`,
  },
  [alreadyBookedCampaignMemberStatusKey]: {
    label: 'Already Booked',
    description: 'Already booked outside of the campaign',
  },
  [staleCampaignMemberStatusKey]: {
    label: 'Follow-Ups Complete',
    description: `Have received all follow-ups for the campaign, but still haven't booked an appointment`,
  },
  [pauseSequencingCampaignMemberStatusKey]: {
    label: 'Follow-Ups Paused',
    description: 'Follow-ups paused',
  },
  [declinedCampaignMemberStatusKey]: {
    label: 'Declined',
    description: `Politely declined the campaign outreach, but haven't unsubscribed from marketing communications`,
  },
  [optedOutCampaignMemberStatusKey]: {
    label: 'Unsubscribed',
    description:
      'Declined the campaign outreach, and also unsubscribed from all marketing communications',
  },
};
const allStatuses = Object.keys(statusLabelMap);

const getContactSearchParameters = (
  locationId,
  campaignId,
  selectedContactType,
  searchFilter = '',
  pageNumber = 1,
) => {
  let filterString = getTypesenseFilterString([locationId], {});

  let searchParameters = {
    per_page: pageSize,
    page: pageNumber,
    sort_by: sortParam,
  };
  let countFilterString = `${filterString} && campaignIds:[${campaignId}] && campaigns:[${allStatuses
    .map((s) => `${campaignId}----${s}`)
    .join(', ')}]`;
  let countSearchParams = {
    per_page: 0,
    q: '',
    query_by: 'campaigns',
    facet_by: 'campaigns',
  };

  if (selectedContactType !== allKey) {
    filterString += ` && type:=[${selectedContactType}]`;
    countFilterString += ` && type:=[${selectedContactType}]`;
    searchParameters['filter_by'] = filterString;
  }

  if (searchFilter) {
    filterString += ` && campaignIds:=[${campaignId}]`;
    searchParameters = {
      ...searchParameters,
      q: searchFilter,
      query_by: 'firstName,lastName',
      filter_by: filterString,
    };
    countSearchParams['query_by'] += `,firstName,lastName`;
    countSearchParams['q'] = searchFilter;
  } else {
    searchParameters = {
      ...searchParameters,
      q: campaignId,
      query_by: 'campaigns',
    };
  }

  countSearchParams['filter_by'] = countFilterString;

  return { search: searchParameters, count: countSearchParams };
};

const expiry = getCookieExpiryObject();

const maxNameChars = 20;

const Pipeline = () => {
  const { user, drawerOpen, drawerExpanded, cookies, setCookie } =
    useContext(BaseContext);
  const locationId = getUserLocationId(user);

  const navigate = useNavigate();

  const {
    campaigns,
    loading: campaignsLoading,
    refetch: refetchCampaigns,
  } = useMyCampaigns({});

  useEffect(() => {
    refetchCampaigns();
  }, [refetchCampaigns]);

  const allCampaignIds = [];
  const campaignNameMap = {};
  const campaignOptions = (campaigns || []).map((c) => {
    const { id, name } = c;
    allCampaignIds.push(id);
    const formattedName =
      name.length > maxNameChars ? `${name.slice(0, maxNameChars)}...` : name;
    campaignNameMap[id] = formattedName;
    return { key: c.id, label: formattedName };
  });
  const campaignIdsString = getCampaignIdsString(allCampaignIds);

  const storedNameCookie = cookies[contactNameForPipelineCookieKey];
  const storedCampaignCookie = cookies[campaignForPipelineCookieKey];
  const storedContactTypeCookie = cookies[contactTypeForPipelineCookieKey];

  const [searchFilter, setSearchFilter] = useState(storedNameCookie);
  const [stagingSearchFilter, setStagingSearchFilter] =
    useState(storedNameCookie);
  const [selectedCampaignIdArr, setSelectedCampaignIdArr] = useState(
    storedCampaignCookie
      ? [storedCampaignCookie]
      : allCampaignIds?.length
      ? [allCampaignIds[0]]
      : [],
  );
  const [selectedContactTypes, setSelectedContactTypes] = useState(
    storedContactTypeCookie ? [storedContactTypeCookie] : contactTypesArr,
  );
  const [contacts, setContacts] = useState([]);
  const [statusCountMap, setStatusCountMap] = useState({});
  const [numResults, setNumResults] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [navigationLoading, setNavigationLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const selectedCampaignId = selectedCampaignIdArr?.[0];
  const selectedContactType = selectedContactTypes?.[0];

  const typesenseApiKey = getUserTypesenseApiKey(user);
  const typesenseClient = getTypesenseClient(typesenseApiKey);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingSearchFilter !== searchFilter) {
        setCookie(contactNameForPipelineCookieKey, stagingSearchFilter, expiry);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingSearchFilter]);

  useEffect(() => {
    setStagingSearchFilter(storedNameCookie);
    setSearchFilter(storedNameCookie);
  }, [storedNameCookie]);

  useEffect(() => {
    setSelectedCampaignIdArr(
      storedCampaignCookie
        ? [storedCampaignCookie]
        : allCampaignIds?.length
        ? [allCampaignIds[0]]
        : [],
    );
  }, [storedCampaignCookie, campaignIdsString]);

  useEffect(() => {
    setSelectedContactTypes(
      storedContactTypeCookie ? [storedContactTypeCookie] : contactTypesArr,
    );
  }, [storedContactTypeCookie]);

  useEffect(() => {
    async function fetchClients() {
      if (selectedCampaignId) {
        const { search, count } = getContactSearchParameters(
          locationId,
          selectedCampaignId,
          selectedContactType,
          searchFilter,
          1,
        );

        const allContacts = [];
        const countMap = {};

        let page = search['page'];
        let total;
        let totalNumPages;
        setDataLoading(true);

        while (totalNumPages == null || page <= totalNumPages) {
          search['page'] = page;
          const data = await typesenseClient
            .collections(typesenseContactSchemaName)
            .documents()
            .search(search);

          if (!totalNumPages) {
            total = data.found;
            totalNumPages = !total ? 0 : Math.ceil(total / pageSize);
          }

          const documents = data.hits.map((hit) => hit.document);
          documents.map((d) => {
            const statusForCampaign = (d?.campaigns || [])
              .find((c) => c.includes(selectedCampaignId))
              ?.split(intraStringSeparatorChar)?.[1];
            if (statusForCampaign) {
              const existingCount = countMap[statusForCampaign] || 0;
              countMap[statusForCampaign] = existingCount + 1;
            }
            allContacts.push(d);
          });
          page += 1;
        }
        setContacts(allContacts);
        setStatusCountMap(countMap);
        setNumResults(total);

        setDataLoading(false);
      }
    }

    fetchClients();
  }, [selectedCampaignId, selectedContactType, searchFilter]);

  const hasFilters =
    !campaignsLoading && !!selectedContactType && !!selectedCampaignId;

  const contactMap = {};
  allStatuses.map((s) => {
    contactMap[s] = [];
  });
  contacts.map((c) => {
    const campaignIds = c.campaigns;
    const relevantCampaignId = campaignIds.find((id) =>
      id.includes(selectedCampaignId),
    );
    if (relevantCampaignId) {
      const status = relevantCampaignId.split(intraStringSeparatorChar)[1];
      const contactsForStatus = contactMap[status] || [];
      contactMap[status] = [...contactsForStatus, c];
    }
  });
  const laneKeys = Object.keys(contactMap);

  const updateSearchFilter = (stagingSearchFilterValue) => {
    setStagingSearchFilter(stagingSearchFilterValue);
  };

  const onResetSearchFilter = () => {
    setStagingSearchFilter('');
  };

  const onChangeFilteredCampaigns = (id) => {
    setDataLoading(true);
    setCookie(campaignForPipelineCookieKey, id, expiry);
  };

  const onChangeContactType = (type) => {
    if (type !== selectedContactType) {
      setDataLoading(true);
      setCookie(contactTypeForPipelineCookieKey, type, expiry);
    }
  };

  const onResetFilters = () => {
    onChangeContactType(allKey);
    setStagingSearchFilter('');
  };

  const onNavigateToChat = async (contactId) => {
    setNavigationLoading(true);
    const searchParameters = {
      q: '*',
      query_by: 'userId',
      filter_by: `userId:${contactId}`,
      per_page: 1,
    };
    const data = await typesenseClient
      .collections(typesenseChatsSchemaName)
      .documents()
      .search(searchParameters);
    const chatId = data?.hits?.[0]?.document?.id;
    if (!chatId) {
      setSnackbarMessage(
        `Unable to navigate to chat, please search by name in the Chats tab`,
      );
      setNavigationLoading(false);
    } else {
      const chatPath = `${Paths.chats}?chat-id=${chatId}`;
      navigate(chatPath);
    }
  };

  const filtersApplied = searchFilter || selectedContactType !== allKey;

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          hideOverflow
        >
          <ColumnCenteredDiv>
            <PageTitleText>Pipeline</PageTitleText>
            {hasFilters && selectedCampaignId && (
              <PipelineFiltersContainer>
                <StartAlignedFlexDiv largerGap>
                  <ClientSearchBox
                    value={stagingSearchFilter}
                    onChange={(e) => updateSearchFilter(e.target.value)}
                    onReset={onResetSearchFilter}
                  />
                  <StartAlignedMediumDarkTinyText
                    smallLineHeight
                    centered
                  >
                    {dataLoading ? (
                      <>
                        <LoadingIndicator size={20} />
                        &nbsp;&nbsp;&nbsp;Campaign data loading...
                      </>
                    ) : (
                      <>
                        {formatNumber(numResults)}
                        {searchFilter && ` matching`}{' '}
                        {selectedContactType === clientContactType
                          ? 'client'
                          : selectedContactType === leadContactType
                          ? 'lead'
                          : 'contact'}
                        {numResults !== 1 ? 's' : ''}&nbsp;
                      </>
                    )}
                    <SearchIconContainer>
                      {filtersApplied && (
                        <Tooltip
                          title={
                            <TooltipTitleText>Reset filters</TooltipTitleText>
                          }
                        >
                          <ResetFiltersIcon
                            onClick={() => {
                              if (filtersApplied) {
                                onResetFilters();
                              }
                            }}
                          />
                        </Tooltip>
                      )}
                    </SearchIconContainer>
                  </StartAlignedMediumDarkTinyText>
                </StartAlignedFlexDiv>
                <EndAlignedFlexDiv gap={5}>
                  <OptionsFilterDropdown
                    filtered={selectedCampaignIdArr}
                    options={campaignOptions}
                    label={
                      selectedCampaignIdArr?.length
                        ? campaignNameMap[selectedCampaignIdArr[0]]
                        : 'Select Campaign'
                    }
                    onChange={onChangeFilteredCampaigns}
                    fixedWidth={220}
                  />
                  <OptionsFilterDropdown
                    filtered={[selectedContactType]}
                    options={contactTypesForCampaigns}
                    label={
                      selectedContactType === allKey
                        ? 'All Contacts'
                        : selectedContactType === clientContactType
                        ? 'Clients'
                        : selectedContactType === leadContactType
                        ? 'Leads'
                        : ''
                    }
                    onChange={onChangeContactType}
                    fixedWidth={120}
                  />
                </EndAlignedFlexDiv>
              </PipelineFiltersContainer>
            )}
            {!campaignsLoading && !navigationLoading ? (
              <PipelineContainer>
                {laneKeys.map((baseKey, idx) => {
                  const key =
                    baseKey === sequenceCompletedCampaignMemberStatusKey
                      ? staleCampaignMemberStatusKey
                      : baseKey;
                  const obj = statusLabelMap[key];
                  const label = obj?.label || '';

                  const start = idx === 0;
                  const end = idx === laneKeys.length - 1;

                  const laneContacts = contactMap[key];
                  const numContactsForLane = laneContacts?.length;

                  return (
                    <PipelineColumn
                      start={start}
                      end={end}
                    >
                      <PipelineColumnHeader
                        start={start}
                        end={end}
                      >
                        <PipelineColumnHeaderTitle status={key}>
                          {label}{' '}
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                {obj?.description || ''}
                              </TooltipTitleText>
                            }
                          >
                            <InfoIcon
                              primary
                              small
                              largeLeftMargin
                            />
                          </Tooltip>
                        </PipelineColumnHeaderTitle>
                        <PipelineColumnHeaderSubtext>
                          {dataLoading ? (
                            <>&nbsp;</>
                          ) : (
                            `(${formatNumber(statusCountMap[key]) || 0})`
                          )}
                        </PipelineColumnHeaderSubtext>
                      </PipelineColumnHeader>
                      <PipelineClientContainer end={end}>
                        {dataLoading ? (
                          <PipelineClient />
                        ) : numContactsForLane ? (
                          <>
                            {laneContacts.slice(0, 30).map((contact) => {
                              return (
                                <PipelineClient status={key}>
                                  <PipelineClientName>
                                    {getFormattedFullNameFromUser(contact)}
                                    {selectedContactType === allKey && (
                                      <LightDarkTextSpan>
                                        {' '}
                                        ({formatKeyToLabel(contact.type)})
                                      </LightDarkTextSpan>
                                    )}
                                  </PipelineClientName>
                                  <br></br>
                                  <TinySecondaryButton
                                    smallPadding
                                    onClick={() => onNavigateToChat(contact.id)}
                                  >
                                    View chat
                                  </TinySecondaryButton>
                                </PipelineClient>
                              );
                            })}
                            {numContactsForLane > 30 && (
                              <LightDarkTinyText italic>
                                Only displaying top 30 contacts per column,
                                search by name to see individual contacts
                              </LightDarkTinyText>
                            )}
                          </>
                        ) : (
                          <ColumnCenteredDiv topMargin={20}>
                            <MediumNoDataIcon />
                            <LightDarkTinyText>
                              No contacts with status
                            </LightDarkTinyText>
                          </ColumnCenteredDiv>
                        )}
                      </PipelineClientContainer>
                    </PipelineColumn>
                  );
                })}
              </PipelineContainer>
            ) : (
              <LoadingIndicator fullScreen />
            )}
          </ColumnCenteredDiv>
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Pipeline;
