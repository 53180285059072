import React, { useContext } from 'react';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import {
  LightDarkLargeTinyText,
  Table,
  TableBody,
  TableCell,
  TableCellHeader,
  TableHead,
  TableRow,
} from '../../../styles/shared-styled-components';
import { jeffsEmails } from '../../../utils/constants';
import { screenWidthIsSmallMobileSizeOrSmaller } from '../../../utils/view';
import {
  LargeTableHeaderText,
  TableHeaderText,
  TableRowText,
} from '../styles/style-CompetitorComparison';
import {
  FiveMinuteSetupContainer,
  FiveMinuteSetupHeaderText,
} from '../styles/style-FiveMinuteSetup';

const inDepthFeatures = [
  {
    description:
      'Creates, reschedules, and cancels appointments directly in your booking system',
    competitor: <>Often require a separate calendar&nbsp;&nbsp;❓</>,
  },
  {
    description:
      'Completely customizable instructions: service recommendation, booking policies, membership plans, add-ons, etc',
    competitor: <>Very limited customization offered&nbsp;&nbsp;❌</>,
  },
  {
    description:
      'Automates all communication channels: texts, calls, emails, social media',
    competitor: <>Usually just focused on texts&nbsp;&nbsp;❌</>,
  },
  {
    description:
      'Runs automated marketing campaigns based on custom filters, reactivating clients and converting leads',
    competitor: <>Typically only handle inbound requests&nbsp;&nbsp;❌</>,
  },
  {
    description: 'Allows for seamless human interception + escalation',
    competitor: <>Sometimes offered&nbsp;&nbsp;❓</>,
  },
  {
    description:
      'Follows up post-appointment to re-book, gather reviews, and upsell membership plans',
    competitor: <>Not offered&nbsp;&nbsp;❌</>,
  },
  {
    description:
      'Dynamically offers discounted sessions to fill up cancellations + empty slots at the start of the day',
    competitor: <>Not offered&nbsp;&nbsp;❌</>,
  },
  {
    description:
      'In-depth analytics centralizing multi-location data, showcasing revenue stats alongside appointment metrics',
    competitor: (
      <>
        Offer high-level appointment stats, without top-line revenue, separated
        by location&nbsp;&nbsp;❌
      </>
    ),
  },
  {
    description:
      'Custom-built for multi-location businesses, with corporate templates for one-click onboarding',
    competitor: <>Lacking tools for multi-location businesses&nbsp;&nbsp;❌</>,
  },
  {
    description: 'White-label & RFP for custom build-outs',
    competitor: <>Only out-of-the-box functionality offered&nbsp;&nbsp;❌</>,
  },
];

const features = [
  {
    description: 'Deep Integrations',
    subtext: 'Booking system, CRM, data warehouse',
    liveiq: 'Connected to all your key systems',
    competitor: <>Often require a separate calendar&nbsp;&nbsp;❓</>,
  },
  {
    description: 'Powerful Customizations',
    subtext:
      'Service recommendations, deposits, cancellations, interaction style',
    competitor: <>Very limited customization offered&nbsp;&nbsp;❌</>,
  },
  {
    description: 'Intelligent Upselling',
    subtext: 'Membership plans, add-ons, packages',
    competitor: <>Very limited customization offered&nbsp;&nbsp;❌</>,
  },
  {
    description: 'Omni-Channel',
    subtext: 'Phone calls, texts, email, social media',
    competitor: <>Usually just focused on texts&nbsp;&nbsp;❌</>,
  },
  {
    description: 'Flexible Campaigns',
    subtext:
      'Book new appointments, get reviews post-visit, dynamically fill cancellations',
    competitor: <>Typically only handle inbound requests&nbsp;&nbsp;❌</>,
  },
  {
    description: 'Seamless Human Interaction',
    subtext: 'Intelligent escalation, simple interception',
    competitor: <>Sometimes offered&nbsp;&nbsp;❓</>,
  },
  {
    description: 'Multi-Location Tools',
    subtext: 'Analytics, templates, white-labelling',
    competitor: <>Not offered&nbsp;&nbsp;❌</>,
  },
];

const reasons = [
  'Train & Instruct Your AI including Service Recommendations',
  'Forecast Appointment Time Length from Customer Input',
  'Upsell Add-Ons',
  'Convert Memberships',
  'Reactivate Clients + Convert Leads',
  'Filter and Target a Campaign Audience',
  'Auto Client Reactivation based on All Attributes',
  'Auto Lead Conversion across All Channels',
  'Auto Deposit & Cancellation Fee Workflow',
  'Solicit Reviews Post-Visit',
  'Auto Fill Open Time Slots w/ Promos',
  'Customizable Analytics & KPI Dashboard across All Data Attributes',
  'Franchise / Brand Templates & Tooling',
  'Whitelabel & RFP for Custom-Build',
  'Complete System and App Integrations',
];
const numReasons = reasons.length;
const batches = Math.ceil(numReasons / 3);
const indices = Array.from({ length: batches }, (_, i) => 0 + i);

const CompetitorComparison = ({ darkMode }) => {
  const { user, width, inDemoMode } = useContext(BaseContext);
  const isMobileScreen = screenWidthIsSmallMobileSizeOrSmaller(width);

  return (
    <div id='competitor'>
      <FiveMinuteSetupContainer dark={darkMode}>
        {inDemoMode && jeffsEmails.includes(user?.email) ? (
          <>
            <FiveMinuteSetupHeaderText dark={darkMode}>
              {numReasons} Reasons We're Best-in-Class
            </FiveMinuteSetupHeaderText>
            <Table
              topMargin={30}
              maxWidth={1400}
              width={'90%'}
            >
              <TableHead>
                <TableRow height={50}>
                  <TableCellHeader end></TableCellHeader>
                  <TableCellHeader end>
                    {' '}
                    <LargeTableHeaderText>Selling Points</LargeTableHeaderText>
                  </TableCellHeader>
                  <TableCellHeader end></TableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {indices.map((i) => (
                  <TableRow>
                    <TableCell>
                      <TableRowText>{reasons[i * 3]}</TableRowText>
                    </TableCell>
                    <TableCell>
                      <TableRowText>{reasons[i * 3 + 1]}</TableRowText>
                    </TableCell>
                    <TableCell end>
                      <TableRowText>{reasons[i * 3 + 2]}</TableRowText>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <>
            <FiveMinuteSetupHeaderText dark={darkMode}>
              Best-in-Industry AI
            </FiveMinuteSetupHeaderText>
            <Table
              topMargin={30}
              maxWidth={1400}
              width={'90%'}
            >
              <TableHead>
                <TableRow height={50}>
                  <TableCellHeader
                    width={isMobileScreen ? '50%' : '60%'}
                    end={isMobileScreen}
                  >
                    <TableHeaderText>Feature</TableHeaderText>
                  </TableCellHeader>
                  <TableCellHeader
                    width={isMobileScreen ? '18%' : '20%'}
                    end={isMobileScreen}
                  >
                    <TableHeaderText>LiveIQ</TableHeaderText>
                  </TableCellHeader>
                  <TableCellHeader
                    end
                    width={isMobileScreen ? '32%' : '20%'}
                  >
                    <TableHeaderText>Competitors</TableHeaderText>
                  </TableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {features.map((f) => (
                  <TableRow>
                    <TableCell>
                      <TableRowText>
                        {f.description}
                        <br></br>
                        <LightDarkLargeTinyText
                          smallLineHeight
                          topMargin={5}
                        >
                          {f.subtext}
                        </LightDarkLargeTinyText>
                      </TableRowText>
                    </TableCell>
                    <TableCell>
                      <TableRowText>✅</TableRowText>
                    </TableCell>
                    <TableCell end>
                      <TableRowText>❌</TableRowText>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </FiveMinuteSetupContainer>
    </div>
  );
};

export default CompetitorComparison;
