import { useMutation } from '@apollo/client';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { REMOVE_CALL_BOOKMARK } from '../../../api/mutations/call';
import {
  BaseTextSpan,
  BookmarkIcon,
  EmptyBookmarkIcon,
  EmptyGapFlexDiv,
  ExtraSmallPrimaryCopyIcon,
  HoveredTextSpan,
  LightDarkTextSpan,
  LightTinyText,
  MediumDarkLargeTinyText,
  PauseIcon,
  PlayIcon,
  PrimaryText,
  SingleMessageIcon,
  StartAlignedFlexDiv,
  Tooltip,
  TooltipTitleText,
  WhiteTinyText,
} from '../../../styles/shared-styled-components';
import {
  alreadyBookedActions,
  alreadyBookedMessageContent,
  appointmentBookedMessageContent,
  appointmentBookedStatus,
  appointmentCancelledMessageContent,
  appointmentDepositExpiredMessageContent,
  appointmentDepositExpiredStatus,
  appointmentDepositPaidMessageContent,
  appointmentDepositRequiredStatus,
  appointmentErrorMessageContent,
  appointmentErrorStatus,
  appointmentMonitoringMessageContent,
  appointmentMonitoringStatus,
  appointmentRequiresDepositMessageContent,
  appointmentRescheduledMessageContent,
  assistantKey,
  availsFetchedMessageContent,
  callEndedChatEvent,
  callEndedMessageContent,
  callbackRequestedChatEvent,
  callbackRequestedMessageContent,
  campaignDeclinedMessageContent,
  chatResumedMessageContent,
  disengagedChatStatusKey,
  endOfExchangeChatMessageContent,
  hotLeadMessageContent,
  humanAgentKey,
  interStringSeparatorChar,
  interceptedChatStatusKey,
  internalContactType,
  intraStringSeparatorChar,
  lastFollowUpMessageContent,
  newCallChatEvent,
  newCallMessageContent,
  sequenceTypeLabelMap,
  staleMessageContent,
  superAdminRole,
  supportAgentRole,
  systemKey,
  textSeparatorChar,
  unsupportedActions,
  userKey,
  userRoleLabelMap,
} from '../../../utils/constants';
import { getTimestamp } from '../../../utils/date';
import {
  getFormattedFullNameFromUser,
  getWordWithCapitalizedFirstLetter,
} from '../../../utils/name';
import { formatPhoneNumber } from '../../../utils/numbers';
import { copyTextToClipboard, formatKeyToLabel } from '../../../utils/string';
import { getUserLocation } from '../../../utils/user';
import { scrollElementIntoView } from '../../../utils/view';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import { DownArrow, UpArrow } from '../../Micro/ArrowIcon/styled';
import BookmarkCallModal from '../../Modals/BookmarkCallModal';
import MessageAttachments from '../MessageAttachments';
import {
  ExpandedStatusChangeDetailsContainer,
  ExpandedStatusChangeDetailsContentSection,
  ExpandedStatusChangeDetailsHeaderSection,
  ExpandedStatusChangeDetailsHeaderText,
  ExpandedStatusChangeDetailsSubtext,
  ExpandedStatusChangeDetailsSubtextSection,
  LeftBubble,
  LeftBubbleAuthorText,
  LeftBubbleContainer,
  LeftBubbleMetadataContainer,
  MessagesBox,
  ModifiedScrollWrapper,
  RightBubble,
  RightBubbleAuthorText,
  RightBubbleContainer,
  RightBubbleMetadataContainer,
  StatusChangeBannerContainer,
  StatusChangeDetailsContainer,
  StatusChangeLine,
  StatusChangeText,
  StatusChangeTextContainer,
  TranscriptMessageContainer,
} from './styled';

const scrubMessage = (
  inDemoMode,
  text,
  clientFirstName,
  locationName,
  address,
  phoneNumber,
) => {
  if (!inDemoMode) {
    return text;
  }

  if (!text) {
    return '';
  }

  return text
    .replaceAll(`${clientFirstName}`, clientFirstName?.[0])
    .replaceAll(address, '123 Main St')
    .replaceAll(formatPhoneNumber(phoneNumber, true), '123-456-7890')
    .replaceAll(formatPhoneNumber(phoneNumber, false), '123-456-7890');
};

const getAppointmentBookerName = (appointmentString, agentName, userMap) => {
  const split = appointmentString.split(interStringSeparatorChar);

  // Return user name if included in string
  if (split.length === 2) {
    const userId = split[1];
    return userMap[userId];
  }

  // Else return agent name
  return agentName;
};

const messagesContainerId = 'messages-container';

const checkIfSystemMessageType = (sender, message, type) => {
  return sender === systemKey && message?.includes(type);
};

const checkSystemMessageType = (sender, message) => {
  if (sender !== systemKey || !message) {
    return null;
  }

  if (message.includes(newCallMessageContent)) {
    return newCallChatEvent;
  }
  if (message.includes(callEndedMessageContent)) {
    return callEndedChatEvent;
  }
  if (message.includes(callbackRequestedMessageContent)) {
    return callbackRequestedChatEvent;
  }
  if (message.includes(disengagedChatStatusKey)) {
    return disengagedChatStatusKey;
  }
  if (message.includes(interceptedChatStatusKey)) {
    return interceptedChatStatusKey;
  }
  if (message.includes(availsFetchedMessageContent)) {
    return availsFetchedMessageContent;
  }
  if (message.includes(appointmentBookedMessageContent)) {
    return appointmentBookedMessageContent;
  }
  if (message.includes(appointmentErrorMessageContent)) {
    return appointmentErrorMessageContent;
  }
  if (message.includes(appointmentRequiresDepositMessageContent)) {
    return appointmentRequiresDepositMessageContent;
  }
  if (message.includes(appointmentDepositPaidMessageContent)) {
    return appointmentDepositPaidMessageContent;
  }
  if (message.includes(appointmentDepositExpiredMessageContent)) {
    return appointmentDepositExpiredMessageContent;
  }
  if (message.includes(appointmentRescheduledMessageContent)) {
    return appointmentRescheduledMessageContent;
  }
  if (message.includes(appointmentCancelledMessageContent)) {
    return appointmentCancelledMessageContent;
  }

  if (message.includes(appointmentMonitoringMessageContent)) {
    return appointmentMonitoringMessageContent;
  }
  if (message.includes(alreadyBookedMessageContent)) {
    return alreadyBookedMessageContent;
  }
  if (message.includes(campaignDeclinedMessageContent)) {
    return campaignDeclinedMessageContent;
  }
  if (message.includes(hotLeadMessageContent)) {
    return hotLeadMessageContent;
  }
  if (message.includes(chatResumedMessageContent)) {
    return chatResumedMessageContent;
  }
  if (message.includes(endOfExchangeChatMessageContent)) {
    return endOfExchangeChatMessageContent;
  }
  if (message.includes(lastFollowUpMessageContent)) {
    return lastFollowUpMessageContent;
  }
  if (message.includes(staleMessageContent)) {
    return staleMessageContent;
  }

  return null;
};

const getInternalMessageLabel = (metadata) => {
  const reason = metadata?.reason;
  const numbersContacted = metadata?.numbersContacted || [];
  const reasonLabel =
    reason === callbackRequestedChatEvent
      ? `Callback Requested ${textSeparatorChar} `
      : '';
  return `${reasonLabel} ${numbersContacted.length} number${
    numbersContacted.length === 1 ? '' : 's'
  } contacted`;
};

const getPredictionsLabel = (actions, sessionTypeLabels) => {
  const actionLabels = [];

  if (actions.length) {
    actions.map((a) => {
      const { base, reconciled } = a;
      const formattedBase = formatKeyToLabel(base);

      if (base === reconciled) {
        actionLabels.push(`${textSeparatorChar} ${formattedBase}`);
      } else {
        const formattedReconciled = formatKeyToLabel(reconciled);

        actionLabels.push(
          `${textSeparatorChar} Originally identified '${formattedBase}', reconciled to '${formattedReconciled}'`,
        );
      }
    });
  } else {
    actionLabels.push(`None identified`);
  }

  if (!sessionTypeLabels.length) {
    sessionTypeLabels.push(`None identified`);
  }

  return (
    <>
      Actions:
      <br />
      {actionLabels.map((l) => (
        <>
          {l}
          <br />
        </>
      ))}
      <br />
      Services:
      <br />
      {sessionTypeLabels.map((l) => (
        <>
          {l}
          <br />
        </>
      ))}
    </>
  );
};

const MessageRenderer = ({
  messages,
  callMap,
  subscribeToNewMessages,
  refetch,
  context,
  chatType,
  agentName,
  client,
  disengagementText,
  interceptionText,
  onClickAppointment,
  onClickDeposit,
  userMap,
  snackbarSetter,
}) => {
  const { inDemoMode, user, inMonitoringMode } = useContext(BaseContext);

  const audioRefs = useRef({});

  const [removeCallBookmarkMutation] = useMutation(REMOVE_CALL_BOOKMARK);

  const location = getUserLocation(user);
  const phoneNumber = location?.businessPhoneNumberForClient;
  const locationName = location?.name;
  const address = location?.address;
  const isSuperAdmin = user?.role === superAdminRole;

  const clientFirstName = client.firstName;
  const clientFirstInitial = clientFirstName?.[0];
  const clientName = getFormattedFullNameFromUser(
    client,
    inDemoMode,
    clientFirstInitial,
  );

  const [collapsedCallIds, setCollapsedCallIds] = useState([]);
  const [editingBookmarkCallId, setEditingBookmarkCallId] = useState();
  const [bookmarkCallModalOpen, setBookmarkCallModalOpen] = useState(false);
  const [playingCallId, setPlayingCallId] = useState(null);

  useEffect(() => {
    subscribeToNewMessages();
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    scrollElementIntoView(
      document,
      messagesContainerId,
      'end',
      'nearest',
      'instant',
    );
  };

  const handlePlay = async (callId) => {
    try {
      // Pause other audios before playing the new one
      Object.keys(audioRefs.current).forEach((id) => {
        if (id !== callId && audioRefs.current[id]) {
          audioRefs.current[id].pause();
        }
      });

      // Only play if it's not already the playing call
      if (playingCallId !== callId) {
        setPlayingCallId(callId);
        await audioRefs.current[callId]?.play();
      }
    } catch (error) {
      console.error('Audio playback failed:', error);
    }
  };

  const handlePause = (callId) => {
    if (playingCallId === callId) {
      audioRefs.current[callId]?.pause();
      setPlayingCallId(null);
    }
  };

  const onBookmark = async () => {
    await refetch();
    snackbarSetter(`Created successfully`);
  };

  const onRemoveBookmark = async (id) => {
    removeCallBookmarkMutation({
      variables: {
        id,
      },
      onCompleted: async () => {
        await refetch();
        snackbarSetter(`Bookmark removed`);
      },
    });
  };

  const sortedMessages = sortBy(messages, (m) => m.sentAt);

  let previousMessageWasSystem = false;

  return (
    <>
      <ModifiedScrollWrapper>
        <MessagesBox id={messagesContainerId}>
          {sortedMessages.map((m, idx) => {
            const message = m.content;
            const sender = m.sender;
            const formattedMessage = scrubMessage(
              inDemoMode,
              message,
              clientFirstName,
              locationName,
              address,
              phoneNumber,
            );
            const lines = formattedMessage
              ? formattedMessage.split('\n')
              : [''];

            const isAssistantMessage = sender === assistantKey;
            const isRightSideMessage =
              context === 'demo'
                ? sender === userKey
                : context === 'interception'
                ? sender === humanAgentKey || isAssistantMessage
                : null;
            const isBubbleMessage = sender !== systemKey;

            const authorText = isAssistantMessage
              ? agentName
              : sender === userKey
              ? clientName
              : sender === humanAgentKey
              ? inDemoMode || inMonitoringMode
                ? 'Human Agent'
                : m.senderUserId && userMap
                ? userMap[m.senderUserId]
                : userRoleLabelMap[supportAgentRole]
              : 'Agent';

            const timestamp = getTimestamp(m.sentAt, false, false);

            const subtext = `${authorText} ${textSeparatorChar} ${timestamp} ${
              m.delivered == null
                ? ''
                : m.delivered === false
                ? `(Failed)`
                : `(Delivered)`
            }`;

            const isSystemMessage = sender === systemKey;

            const systemMessageType = checkSystemMessageType(sender, message);
            const isNewCall = systemMessageType === newCallChatEvent;
            const isCallEnd = systemMessageType === callEndedChatEvent;
            const isAppointmentBooking = checkIfSystemMessageType(
              sender,
              message,
              appointmentBookedMessageContent,
            );
            const isAppointmentError = checkIfSystemMessageType(
              sender,
              message,
              appointmentErrorMessageContent,
            );
            const isAppointmentRequiresDeposit = checkIfSystemMessageType(
              sender,
              message,
              appointmentRequiresDepositMessageContent,
            );
            const isAppointmentReschedule = checkIfSystemMessageType(
              sender,
              message,
              appointmentRescheduledMessageContent,
            );
            const isAppointmentCancellation = checkIfSystemMessageType(
              sender,
              message,
              appointmentCancelledMessageContent,
            );
            const isAppointmentMonitoring = checkIfSystemMessageType(
              sender,
              message,
              appointmentMonitoringMessageContent,
            );
            const isCallbackRequested =
              systemMessageType === callbackRequestedChatEvent;
            const isEndOfExchange = checkIfSystemMessageType(
              sender,
              message,
              endOfExchangeChatMessageContent,
            );

            let endOfExchangeReason;
            if (isEndOfExchange) {
              const reasonKey = message.split(interStringSeparatorChar)?.[1];
              endOfExchangeReason = unsupportedActions.includes(reasonKey)
                ? `Unsupported Action - ${getWordWithCapitalizedFirstLetter(
                    reasonKey,
                  )}`
                : alreadyBookedActions.includes(reasonKey)
                ? `Already Booked`
                : '';
            }

            const isAppointmentEvent =
              isAppointmentBooking ||
              isAppointmentError ||
              isAppointmentMonitoring ||
              isAppointmentRequiresDeposit ||
              isAppointmentCancellation;

            if (isSystemMessage && !(isAppointmentEvent && idx === 0)) {
              previousMessageWasSystem =
                sortedMessages[idx - 1]?.sender === systemKey;
            }

            const isBottom = idx === sortedMessages?.length - 1;

            const attachments = m.attachments;

            const predictions = m.metadata?.predictions;
            const actions = predictions?.actions || [];
            const numActions = actions.length;
            const sessionTypeMap = {};
            const sessionTypeLabels = [];
            (predictions?.sessionTypes || []).map((s) => {
              let formattedSessionType;
              if (s?.reconciled) {
                formattedSessionType = s;
              } else {
                formattedSessionType = { base: s, reconciled: s };
              }

              const base = formattedSessionType.base;
              const reconciled = formattedSessionType.reconciled;

              const str =
                base === reconciled
                  ? reconciled
                  : `originally identified '${base}', reconciled to '${reconciled}'`;

              if (!sessionTypeMap[str]) {
                sessionTypeMap[str] = true;
                sessionTypeLabels.push(str);
              }
            });

            const numSessionTypes = sessionTypeLabels.length;

            const sequence = m.sequence;
            const campaignName = sequence?.name;
            const sequenceType = sequence?.type;
            const sequenceLabel =
              campaignName && sequenceType
                ? `'${campaignName}': ${sequenceTypeLabelMap[sequenceType]}`
                : '';

            const internalLabel =
              chatType === internalContactType
                ? getInternalMessageLabel(m.metadata)
                : '';
            const predictionsLabel =
              numActions || numSessionTypes
                ? getPredictionsLabel(actions, sessionTypeLabels)
                : null;
            const callId =
              isCallEnd || isNewCall
                ? message.split(intraStringSeparatorChar)[1]
                : null;
            const serviceFetched =
              systemMessageType === availsFetchedMessageContent
                ? message.split(intraStringSeparatorChar)[1]
                : null;
            const callExpanded = !!callId && !collapsedCallIds.includes(callId);
            const call = callId ? callMap[callId] : null;
            const callNumber = call?.fromNumber;
            const analysis = call?.metadata?.analysis;
            const sentiment = analysis?.user_sentiment;
            const summary = analysis?.call_summary;
            return (
              <>
                {isRightSideMessage ? (
                  <RightBubbleContainer>
                    {attachments?.length ? (
                      <MessageAttachments
                        attachments={attachments}
                        side='right'
                      />
                    ) : (
                      <></>
                    )}
                    <RightBubble>
                      {' '}
                      {lines.map((l) => (
                        <>
                          {l}
                          <br></br>
                        </>
                      ))}
                    </RightBubble>
                    {isAssistantMessage &&
                      (internalLabel ? (
                        <RightBubbleMetadataContainer>
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                {m?.metadata?.numbersContacted
                                  ?.map((n) => formatPhoneNumber(n.number))
                                  ?.join(', ')}
                              </TooltipTitleText>
                            }
                          >
                            <WhiteTinyText>{internalLabel}</WhiteTinyText>
                          </Tooltip>
                        </RightBubbleMetadataContainer>
                      ) : sequenceLabel ? (
                        <RightBubbleMetadataContainer>
                          <WhiteTinyText>{sequenceLabel}</WhiteTinyText>
                        </RightBubbleMetadataContainer>
                      ) : predictionsLabel ? (
                        <RightBubbleMetadataContainer>
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                {predictionsLabel}
                              </TooltipTitleText>
                            }
                            placement='left'
                          >
                            <WhiteTinyText>
                              Identified: {numActions} action
                              {numActions === 1 ? '' : 's'}, {numSessionTypes}{' '}
                              service
                              {numSessionTypes === 1 ? '' : 's'}
                            </WhiteTinyText>
                          </Tooltip>
                        </RightBubbleMetadataContainer>
                      ) : (
                        <></>
                      ))}
                    <RightBubbleAuthorText>{subtext}</RightBubbleAuthorText>
                  </RightBubbleContainer>
                ) : isBubbleMessage ? (
                  <LeftBubbleContainer>
                    {attachments?.length ? (
                      <MessageAttachments
                        attachments={attachments}
                        side='left'
                      />
                    ) : (
                      <></>
                    )}
                    <LeftBubble>
                      {lines.map((l) => (
                        <>
                          {l}
                          <br></br>
                        </>
                      ))}
                    </LeftBubble>
                    {isAssistantMessage && predictionsLabel && (
                      <LeftBubbleMetadataContainer>
                        <Tooltip
                          title={
                            <TooltipTitleText>
                              {predictionsLabel}
                            </TooltipTitleText>
                          }
                          placement='right'
                        >
                          <LightTinyText>
                            Identified: {numActions} action
                            {numActions === 1 ? '' : 's'}, {numSessionTypes}{' '}
                            service
                            {numSessionTypes === 1 ? '' : 's'}
                          </LightTinyText>
                        </Tooltip>
                      </LeftBubbleMetadataContainer>
                    )}
                    <LeftBubbleAuthorText>{subtext}</LeftBubbleAuthorText>
                  </LeftBubbleContainer>
                ) : isNewCall ? (
                  <>
                    <StatusChangeBannerContainer
                      isBottom={isBottom}
                      isNextToBanner={previousMessageWasSystem}
                    >
                      <StatusChangeLine status={newCallChatEvent}>
                        <StatusChangeTextContainer status={newCallChatEvent}>
                          <StatusChangeText>
                            <BaseTextSpan>
                              Inbound{' '}
                              {callNumber && callNumber !== 'web_call'
                                ? 'phone'
                                : 'web'}{' '}
                              call ({timestamp})
                            </BaseTextSpan>
                            <HoveredTextSpan>
                              Inbound{' '}
                              {callNumber && callNumber !== 'web_call'
                                ? 'phone'
                                : 'web'}{' '}
                              call ({timestamp})
                            </HoveredTextSpan>
                          </StatusChangeText>
                        </StatusChangeTextContainer>
                      </StatusChangeLine>
                    </StatusChangeBannerContainer>
                  </>
                ) : isCallEnd ? (
                  <>
                    <StatusChangeDetailsContainer
                      isNextToBanner={previousMessageWasSystem}
                      onClick={() => {
                        if (callExpanded) {
                          setCollapsedCallIds([...collapsedCallIds, callId]);
                        } else {
                          setCollapsedCallIds([
                            collapsedCallIds.filter((id) => id !== callId),
                          ]);
                        }
                      }}
                    >
                      <MediumDarkLargeTinyText>
                        Call details
                      </MediumDarkLargeTinyText>
                      <EmptyGapFlexDiv>
                        {callExpanded ? (
                          <UpArrow color='primary' />
                        ) : (
                          <DownArrow color='primary' />
                        )}
                      </EmptyGapFlexDiv>
                    </StatusChangeDetailsContainer>
                    {callExpanded && (
                      <>
                        <ExpandedStatusChangeDetailsContainer>
                          <ExpandedStatusChangeDetailsContentSection>
                            <ExpandedStatusChangeDetailsHeaderSection>
                              <ExpandedStatusChangeDetailsHeaderText>
                                Overview
                              </ExpandedStatusChangeDetailsHeaderText>
                              {call?.bookmark ? (
                                <Tooltip
                                  title={
                                    <TooltipTitleText>
                                      Remove event
                                    </TooltipTitleText>
                                  }
                                >
                                  <BookmarkIcon
                                    small
                                    onClick={() => {
                                      onRemoveBookmark(callId);
                                    }}
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={
                                    <TooltipTitleText>
                                      Mark event
                                    </TooltipTitleText>
                                  }
                                >
                                  <EmptyBookmarkIcon
                                    small
                                    onClick={() => {
                                      setEditingBookmarkCallId(callId);
                                      setBookmarkCallModalOpen(true);
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {isSuperAdmin && (
                                <Tooltip
                                  title={
                                    <TooltipTitleText>
                                      Copy ID '{callId.slice(0, 4)}'
                                    </TooltipTitleText>
                                  }
                                >
                                  <ExtraSmallPrimaryCopyIcon
                                    onClick={() => {
                                      copyTextToClipboard(callId);
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </ExpandedStatusChangeDetailsHeaderSection>
                            <ExpandedStatusChangeDetailsSubtextSection>
                              <ExpandedStatusChangeDetailsSubtext>
                                {callNumber !== 'web_call' && (
                                  <>
                                    <PrimaryText>Phone:</PrimaryText>{' '}
                                    {formatPhoneNumber(callNumber)}
                                    &nbsp;&nbsp;{textSeparatorChar}&nbsp;&nbsp;
                                  </>
                                )}
                                <PrimaryText>Direction:</PrimaryText>{' '}
                                {call?.direction === 'inbound'
                                  ? 'Inbound'
                                  : 'Outbound'}
                                &nbsp;&nbsp;
                                {textSeparatorChar}
                                &nbsp;&nbsp;
                                <PrimaryText>Sentiment:</PrimaryText>{' '}
                                {sentiment}
                                &nbsp;&nbsp;{textSeparatorChar}&nbsp;&nbsp;
                                <PrimaryText>Duration:</PrimaryText>{' '}
                                {call?.durationSeconds} s
                              </ExpandedStatusChangeDetailsSubtext>
                              <ExpandedStatusChangeDetailsSubtext>
                                <PrimaryText>Summary:</PrimaryText> {summary}
                              </ExpandedStatusChangeDetailsSubtext>
                            </ExpandedStatusChangeDetailsSubtextSection>
                          </ExpandedStatusChangeDetailsContentSection>
                          <ExpandedStatusChangeDetailsContentSection>
                            <ExpandedStatusChangeDetailsHeaderSection>
                              <ExpandedStatusChangeDetailsHeaderText>
                                Playback
                              </ExpandedStatusChangeDetailsHeaderText>
                              <SingleMessageIcon small />
                            </ExpandedStatusChangeDetailsHeaderSection>
                            <ExpandedStatusChangeDetailsSubtextSection>
                              <StartAlignedFlexDiv lowerGap>
                                <ExpandedStatusChangeDetailsSubtext>
                                  <PrimaryText>Listen </PrimaryText>
                                </ExpandedStatusChangeDetailsSubtext>
                                {playingCallId === callId ? (
                                  <PauseIcon
                                    onClick={() => handlePause(callId)}
                                  />
                                ) : (
                                  <PlayIcon
                                    onClick={() => handlePlay(callId)}
                                  />
                                )}
                              </StartAlignedFlexDiv>
                            </ExpandedStatusChangeDetailsSubtextSection>
                            <ExpandedStatusChangeDetailsSubtext>
                              <PrimaryText>Transcript:</PrimaryText> <br></br>
                              {call?.transcript?.messages?.map((m) => (
                                <TranscriptMessageContainer>
                                  {m.role === 'agent' ? 'Agent' : 'User'}:&nbsp;
                                  <LightDarkTextSpan>
                                    {m.content}
                                  </LightDarkTextSpan>
                                  <br></br>
                                </TranscriptMessageContainer>
                              ))}
                            </ExpandedStatusChangeDetailsSubtext>
                          </ExpandedStatusChangeDetailsContentSection>
                        </ExpandedStatusChangeDetailsContainer>
                      </>
                    )}
                    <audio
                      ref={(el) => (audioRefs.current[callId] = el)}
                      onPlay={() => handlePlay(callId)}
                      onPause={() => handlePause(callId)}
                      onEnded={() => setPlayingCallId(null)}
                    >
                      <source
                        src={call?.transcript?.recordingUrl}
                        type='audio/wav'
                      />
                      Your browser does not support the audio element.
                    </audio>
                    <StatusChangeBannerContainer isBottom={isBottom}>
                      <StatusChangeLine status={callEndedChatEvent}>
                        <StatusChangeTextContainer status={callEndedChatEvent}>
                          <StatusChangeText>
                            <BaseTextSpan>
                              Call ended ({timestamp})
                            </BaseTextSpan>
                            <HoveredTextSpan>
                              Call ended ({timestamp})
                            </HoveredTextSpan>
                          </StatusChangeText>
                        </StatusChangeTextContainer>
                      </StatusChangeLine>
                    </StatusChangeBannerContainer>
                  </>
                ) : isCallbackRequested ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={callbackRequestedChatEvent}>
                      <StatusChangeTextContainer
                        status={callbackRequestedChatEvent}
                      >
                        <StatusChangeText>
                          <BaseTextSpan>
                            Callback requested ({timestamp})
                          </BaseTextSpan>
                          <HoveredTextSpan>
                            Callback requested ({timestamp})
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === disengagedChatStatusKey ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={disengagedChatStatusKey}>
                      <StatusChangeTextContainer
                        status={disengagedChatStatusKey}
                      >
                        <StatusChangeText>
                          <BaseTextSpan>Disengaged</BaseTextSpan>
                          <HoveredTextSpan>
                            {disengagementText} ({timestamp})
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === interceptedChatStatusKey ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={interceptedChatStatusKey}>
                      <StatusChangeTextContainer
                        status={interceptedChatStatusKey}
                      >
                        <StatusChangeText>
                          <BaseTextSpan>Intercepted</BaseTextSpan>
                          <HoveredTextSpan>
                            {interceptionText} ({timestamp})
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === alreadyBookedActions ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={interceptedChatStatusKey}>
                      <StatusChangeTextContainer
                        status={interceptedChatStatusKey}
                      >
                        <StatusChangeText>
                          <BaseTextSpan>Already Booked</BaseTextSpan>
                          <HoveredTextSpan>Already Booked</HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === hotLeadMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={appointmentMonitoringStatus}>
                      <StatusChangeTextContainer
                        status={appointmentMonitoringStatus}
                      >
                        <StatusChangeText>
                          <BaseTextSpan>Hot Lead&nbsp;&nbsp;🔥</BaseTextSpan>
                          <HoveredTextSpan>
                            Hot Lead&nbsp;&nbsp;🔥
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === campaignDeclinedMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={disengagedChatStatusKey}>
                      <StatusChangeTextContainer
                        status={disengagedChatStatusKey}
                      >
                        <StatusChangeText>
                          <BaseTextSpan>Campaign Declined</BaseTextSpan>
                          <HoveredTextSpan>Campaign Declined</HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === chatResumedMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={chatResumedMessageContent}>
                      <StatusChangeTextContainer
                        status={chatResumedMessageContent}
                      >
                        <StatusChangeText>
                          <BaseTextSpan>Resumed</BaseTextSpan>
                          <HoveredTextSpan>
                            Chat resumed ({timestamp})
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === endOfExchangeChatMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={endOfExchangeChatMessageContent}>
                      <StatusChangeTextContainer
                        status={endOfExchangeChatMessageContent}
                      >
                        <StatusChangeText>
                          <BaseTextSpan>End of Exchange</BaseTextSpan>
                          <HoveredTextSpan>
                            End of current exchange
                            {endOfExchangeReason
                              ? `: ${endOfExchangeReason}`
                              : ''}{' '}
                            ({timestamp})
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === availsFetchedMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={availsFetchedMessageContent}>
                      <StatusChangeTextContainer
                        status={availsFetchedMessageContent}
                      >
                        <StatusChangeText>
                          <BaseTextSpan>
                            Availabilities Fetched for '{serviceFetched}'
                          </BaseTextSpan>
                          <HoveredTextSpan>
                            Availabilities Fetched for '{serviceFetched}'
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === appointmentBookedMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={appointmentBookedStatus}>
                      <StatusChangeTextContainer
                        status={appointmentBookedStatus}
                      >
                        <StatusChangeText
                          status={appointmentBookedStatus}
                          onClick={onClickAppointment}
                        >
                          <BaseTextSpan>Booked </BaseTextSpan>
                          <HoveredTextSpan>
                            Appointment Booked by{' '}
                            {inDemoMode
                              ? `${agentName}`
                              : getAppointmentBookerName(
                                  m.content,
                                  agentName,
                                  userMap,
                                )}
                          </HoveredTextSpan>
                        </StatusChangeText>
                        <WhiteTinyText>🎉</WhiteTinyText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === appointmentErrorMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={appointmentErrorStatus}>
                      <StatusChangeTextContainer
                        status={appointmentErrorStatus}
                      >
                        <StatusChangeText
                          status={appointmentErrorStatus}
                          onClick={onClickAppointment}
                        >
                          <BaseTextSpan>Booking Error</BaseTextSpan>
                          <HoveredTextSpan>
                            Error when booking appointment
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType ===
                  appointmentRequiresDepositMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={appointmentDepositRequiredStatus}>
                      <StatusChangeTextContainer
                        status={appointmentDepositRequiredStatus}
                      >
                        <StatusChangeText
                          status={appointmentDepositRequiredStatus}
                          onClick={onClickAppointment}
                        >
                          <BaseTextSpan>Deposit Pending</BaseTextSpan>
                          <HoveredTextSpan>
                            Appointment ready for deposit collection!
                          </HoveredTextSpan>
                        </StatusChangeText>
                        <WhiteTinyText>⏳</WhiteTinyText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType ===
                  appointmentDepositPaidMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={appointmentDepositRequiredStatus}>
                      <StatusChangeTextContainer
                        status={appointmentDepositRequiredStatus}
                      >
                        <StatusChangeText
                          status={appointmentDepositRequiredStatus}
                          onClick={() => onClickDeposit()}
                        >
                          <BaseTextSpan>
                            Deposit Collected&nbsp;&nbsp;🎉
                          </BaseTextSpan>
                          <HoveredTextSpan>
                            Appointment confirmed, deposit collected
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType ===
                  appointmentRescheduledMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={appointmentMonitoringStatus}>
                      <StatusChangeTextContainer
                        status={appointmentMonitoringStatus}
                      >
                        <StatusChangeText>
                          <BaseTextSpan>
                            Appointment rescheduled ({timestamp})
                          </BaseTextSpan>
                          <HoveredTextSpan>
                            Appointment rescheduled ({timestamp})
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType ===
                  appointmentDepositExpiredMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={disengagedChatStatusKey}>
                      <StatusChangeTextContainer
                        status={disengagedChatStatusKey}
                      >
                        <StatusChangeText
                          status={appointmentDepositExpiredStatus}
                          onClick={() => onClickDeposit()}
                        >
                          <BaseTextSpan>
                            Deposit Expired, Appointment Cancelled
                          </BaseTextSpan>
                          <HoveredTextSpan>
                            Deposit Expired, Appointment Cancelled
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType ===
                  appointmentMonitoringMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={appointmentMonitoringStatus}>
                      <StatusChangeTextContainer
                        status={appointmentMonitoringStatus}
                      >
                        <StatusChangeText status={appointmentMonitoringStatus}>
                          <BaseTextSpan>Monitoring </BaseTextSpan>
                          <HoveredTextSpan>
                            Monitoring potential booking
                          </HoveredTextSpan>
                        </StatusChangeText>
                        <WhiteTinyText>🔍</WhiteTinyText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === appointmentCancelledMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={disengagedChatStatusKey}>
                      <StatusChangeTextContainer
                        status={disengagedChatStatusKey}
                      >
                        <StatusChangeText>
                          <BaseTextSpan>
                            Appointment cancelled ({timestamp})
                          </BaseTextSpan>
                          <HoveredTextSpan>
                            Appointment cancelled ({timestamp})
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === lastFollowUpMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={lastFollowUpMessageContent}>
                      <StatusChangeTextContainer
                        status={lastFollowUpMessageContent}
                      >
                        <StatusChangeText status={lastFollowUpMessageContent}>
                          <BaseTextSpan>Follow-Ups Completed</BaseTextSpan>
                          <HoveredTextSpan>
                            All follow-ups completed for campaign
                          </HoveredTextSpan>
                        </StatusChangeText>
                        <WhiteTinyText>✔</WhiteTinyText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : systemMessageType === staleMessageContent ? (
                  <StatusChangeBannerContainer
                    isBottom={isBottom}
                    isNextToBanner={previousMessageWasSystem}
                  >
                    <StatusChangeLine status={staleMessageContent}>
                      <StatusChangeTextContainer status={staleMessageContent}>
                        <StatusChangeText status={staleMessageContent}>
                          <BaseTextSpan>Campaign Stale</BaseTextSpan>
                          <HoveredTextSpan>
                            No response for {'>=3'} days after all follow-ups
                            sent
                          </HoveredTextSpan>
                        </StatusChangeText>
                      </StatusChangeTextContainer>
                    </StatusChangeLine>
                  </StatusChangeBannerContainer>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </MessagesBox>
      </ModifiedScrollWrapper>
      <BookmarkCallModal
        isOpen={bookmarkCallModalOpen}
        onClose={() => {
          setEditingBookmarkCallId();
          setBookmarkCallModalOpen(false);
        }}
        callId={editingBookmarkCallId}
        onCreate={onBookmark}
      />
    </>
  );
};

export default MessageRenderer;
