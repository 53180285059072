import styled from 'styled-components';
import { FiveMinuteSetupSubText } from './style-FiveMinuteSetup';

const logos = {
  VIO: 'https://storage.googleapis.com/liveiq-assets/partner-logos/vio-logo.png',
  Omorfia:
    'https://storage.googleapis.com/liveiq-assets/partner-logos/omorfia-logo-tp.png',
  Boardroom:
    'https://storage.googleapis.com/liveiq-assets/partner-logos/boardroom.avif',
  Sisters:
    'https://storage.googleapis.com/liveiq-assets/partner-logos/sisters-logo.png',
  Jazz: 'https://storage.googleapis.com/liveiq-assets/partner-logos/jazz-logo.png',
  Glo: 'https://storage.googleapis.com/liveiq-assets/partner-logos/glotanning.png',
  Icebox:
    'https://storage.googleapis.com/liveiq-assets/partner-logos/icebox-logo.png',
  Skinlab:
    'https://storage.googleapis.com/liveiq-assets/partner-logos/skinlab.png',
  'Fox River':
    'https://storage.googleapis.com/liveiq-assets/partner-logos/foxriver.png',
  Liquivida:
    'https://storage.googleapis.com/liveiq-assets/partner-logos/liquivida.webp',
  Beautique:
    'https://storage.googleapis.com/liveiq-assets/partner-logos/beautique.png',
  'Ovum Health':
    'https://storage.googleapis.com/liveiq-assets/partner-logos/ovum.png',
  'HLC Wellness':
    'https://storage.googleapis.com/liveiq-assets/partner-logos/hlc.webp',
  Bellatox:
    'https://storage.googleapis.com/liveiq-assets/partner-logos/bellatox.webp',
};

// Tooltip styling
const Tooltip = styled.div`
  visibility: hidden;
  text-align: center;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the logo */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  overflow: visible;
  ::after {
    overflow: visible;
    content: '${(props) => props.name}';
    position: absolute;
    top: 150%; /* Arrow */
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-color: transparent;
    background: ${({ theme }) => theme.color.charcoal};
    color: ${({ theme }) => theme.color.lightGray};
    border-radius: 5px;
  }
`;

// Logo container with hover effects
const LogoContainer = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: none;

  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }

  &:hover img {
    transform: scale(1.03);
  }

  img {
    height: ${(props) => props.height || 40}px;
    width: auto;
    transition: transform 0.3s;
  }
`;

// Logo components
export const VioLogo = () => (
  <LogoContainer height={55}>
    <span>
      <img
        src={logos['VIO']}
        alt='VIO Logo'
      />

      <Tooltip name={'VIO'} />
    </span>
  </LogoContainer>
);

export const OmorfiaLogo = () => (
  <LogoContainer height={130}>
    <span>
      <img
        src={logos['Omorfia']}
        alt='Omorfia Logo'
      />
    </span>
  </LogoContainer>
);

export const SistersLogo = () => (
  <LogoContainer height={120}>
    <span>
      <img
        src={logos['Sisters']}
        alt='Sisters Logo'
      />
      <Tooltip name={'Sisters Beauty Lounge'} />
    </span>
  </LogoContainer>
);

export const JazzLogo = () => (
  <LogoContainer height={125}>
    <span>
      <img
        src={logos['Jazz']}
        alt='Jazz Logo'
      />
      <Tooltip name={'Jazz Lounge'} />
    </span>
  </LogoContainer>
);

export const BoardroomLogo = () => (
  <LogoContainer height={105}>
    <span>
      <img
        src={logos['Boardroom']}
        alt='Boardroom Logo'
      />

      <Tooltip name={'Boardroom Salons'} />
    </span>
  </LogoContainer>
);

export const IceboxLogo = () => (
  <LogoContainer height={32}>
    <span>
      <img
        src={logos['Icebox']}
        alt='Icebox Logo'
      />

      <Tooltip name={'Icebox Cryotherapy'} />
    </span>
  </LogoContainer>
);

export const GloTanningLogo = () => (
  <LogoContainer height={105}>
    <span>
      <img
        src={logos['Glo']}
        alt='Glo Tanning Logo'
      />

      <Tooltip name={'Glo Tanning'} />
    </span>
  </LogoContainer>
);

export const SkinlabLogo = () => (
  <LogoContainer>
    <span>
      <img
        src={logos['Skinlab']}
        alt='Skinlab Logo'
      />

      <Tooltip name={'Skinlab'} />
    </span>
  </LogoContainer>
);

export const FoxRiverLogo = () => (
  <LogoContainer height={45}>
    <img
      src={logos['Fox River']}
      alt='Fox River Logo'
    />
    <Tooltip name={'Fox River'} />
  </LogoContainer>
);

export const LiquividaLogo = () => (
  <LogoContainer height={63}>
    <img
      src={logos['Liquivida']}
      alt='Liquivida Logo'
    />
    <Tooltip name={'Liquivida'} />
  </LogoContainer>
);

export const BeautiqueLogo = () => (
  <LogoContainer height={30}>
    <img
      src={logos['Beautique']}
      alt='Beautique Logo'
    />
    <Tooltip name={'Beautique'} />
  </LogoContainer>
);

export const OvumHealthLogo = () => (
  <LogoContainer height={22}>
    <img
      src={logos['Ovum Health']}
      alt='Ovum Health Logo'
    />
    <Tooltip name={'Ovum'} />
  </LogoContainer>
);

export const HLCWellnessLogo = () => (
  <LogoContainer height={30}>
    <img
      src={logos['HLC Wellness']}
      alt='HLC Wellness Logo'
    />
    <Tooltip name={'HLC'} />
  </LogoContainer>
);

export const BellatoxLogo = () => (
  <LogoContainer>
    <img
      src={logos['Bellatox']}
      alt='Bellatox'
    />
    <Tooltip name={'Bellatox'} />
  </LogoContainer>
);

export const AvaTrustedText = styled(FiveMinuteSetupSubText)`
  font-size: 23px;
  text-align: center;
  letter-spacing: 1.5px;
`;
