import { useApolloClient, useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { CREATE_CAMPAIGN } from '../../../api/mutations/campaign';
import {
  DarkMLText,
  InputContainerForMediumGap,
  InputContainerForSmallGap,
  InputWidthDiv,
  MediumDarkEssText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SMCheckbox,
} from '../../../styles/shared-styled-components';
import { addMyCampaignInCache } from '../../../utils/cache';
import {
  agentProductMap,
  campaignTypes,
  dropdownInputType,
  serviceFollowUpCampaignTypeKey,
} from '../../../utils/constants';
import { safeParseInt } from '../../../utils/numbers';
import { getUserLiveIQAgents, getUserLocationId } from '../../../utils/user';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import DateFilter from '../../Filters/DateFilter';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';
import {
  DateRangeFieldContainer,
  DateRangeFieldTitleContainer,
} from './styled';

const CreateCampaignModal = ({ isOpen, onClose, onCreate }) => {
  const { cache } = useApolloClient();
  const { user } = useContext(BaseContext);
  const locationId = getUserLocationId(user);
  const agents = getUserLiveIQAgents(user);
  const [createCampaignMutation, { loading: createCampaignLoading }] =
    useMutation(CREATE_CAMPAIGN);

  const formattedAgents = [];
  agents.map((a) => {
    const product = agentProductMap[a.productKey];
    const formattedAgent = {
      key: a.id,
      label: `${a.name}, the ${product?.description}`,
    };
    formattedAgents.push(formattedAgent);
  });

  const [agentId, setAgentId] = useState(
    formattedAgents?.length === 1 ? formattedAgents[0].key : null,
  );
  const [name, setName] = useState();
  const [type, setType] = useState();
  const [isTargettingClients, setIsTargettingClients] = useState(false);
  const [isTargettingLeads, setIsTargettingLeads] = useState(false);
  const [messageNewLeads, setMessageNewLeads] = useState();
  const [lastContactDelayDays, setLastContactDelayDays] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [runsIndefinitely, setRunsIndefinitely] = useState(
    !startDate && !endDate,
  );

  const handleClose = () => {
    setAgentId();
    setName();
    setType();
    setMessageNewLeads();
    setLastContactDelayDays();
    setStartDate();
    setEndDate();
    onClose();
  };

  const handleCreate = () => {
    createCampaignMutation({
      variables: {
        locationId,
        name,
        type,
        startDate,
        endDate,
        targetClients: isTargettingClients,
        targetLeads: isTargettingLeads,
        agentId,
        messageNewLeads: messageNewLeads || false,
        lastContactDelayDays: safeParseInt(lastContactDelayDays) || 0,
      },
      onCompleted: async (data) => {
        const campaign = data.createCampaign;

        await addMyCampaignInCache(campaign, cache);

        onCreate(campaign);
        handleClose();
      },
    });
  };

  if (createCampaignLoading) {
    return <LoadingIndicator />;
  }

  const createEnabled =
    name &&
    agentId &&
    type &&
    (runsIndefinitely || (!runsIndefinitely && startDate && endDate)) &&
    (isTargettingLeads || isTargettingClients);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <ModalContainer>
        <DarkMLText>Create Campaign</DarkMLText>
        <ModalInputsContainer>
          <ModalInputsContainerScrollWrapper>
            <Input
              id='name'
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label='Campaign name'
              isRequired={false}
              useExtraSmallGap
            />
            <Input
              id='agent'
              value={agentId}
              onChange={(e) => {
                setAgentId(e.target.value);
              }}
              label='Agent'
              isRequired={false}
              type={dropdownInputType}
              options={formattedAgents}
              useSmallGap={true}
            />
            <Input
              id='type'
              value={type}
              onChange={(e) => {
                const newType = e.target.value;
                setType(newType);
                if (newType === serviceFollowUpCampaignTypeKey) {
                  setMessageNewLeads(false);
                  setIsTargettingClients(true);
                  setLastContactDelayDays();
                }
              }}
              label='Type'
              isRequired={false}
              type={dropdownInputType}
              options={campaignTypes}
              useSmallGap={true}
            />
            {type !== serviceFollowUpCampaignTypeKey && (
              <InputContainerForMediumGap>
                <InputWidthDiv>
                  <MediumDarkEssText>Target clients</MediumDarkEssText>
                  <SMCheckbox
                    checked={isTargettingClients}
                    onClick={() => {
                      const updatedIsTargettingClients = !isTargettingClients;
                      setIsTargettingClients(updatedIsTargettingClients);
                    }}
                  />
                </InputWidthDiv>
                <InputWidthDiv>
                  <MediumDarkEssText>Target leads</MediumDarkEssText>
                  <SMCheckbox
                    checked={isTargettingLeads}
                    onClick={() => {
                      const updatedIsTargettingLeads = !isTargettingLeads;
                      setIsTargettingLeads(updatedIsTargettingLeads);
                      setMessageNewLeads(updatedIsTargettingLeads);
                      setLastContactDelayDays(0);
                    }}
                  />
                </InputWidthDiv>
                {isTargettingLeads && (
                  <InputContainerForSmallGap>
                    <InputWidthDiv>
                      <MediumDarkEssText>
                        Will message new leads immediately
                      </MediumDarkEssText>
                    </InputWidthDiv>
                  </InputContainerForSmallGap>
                )}
              </InputContainerForMediumGap>
            )}
            {type !== serviceFollowUpCampaignTypeKey && (
              <InputContainerForMediumGap>
                <DateRangeFieldContainer>
                  <DateRangeFieldTitleContainer>
                    <MediumDarkEssText>Runs indefinitely</MediumDarkEssText>
                    <SMCheckbox
                      checked={runsIndefinitely}
                      onClick={() => {
                        const updatedRunsIndefinitely = !runsIndefinitely;
                        setRunsIndefinitely(updatedRunsIndefinitely);
                        if (updatedRunsIndefinitely === true) {
                          setStartDate();
                          setEndDate();
                        }
                      }}
                    />
                  </DateRangeFieldTitleContainer>
                  {!runsIndefinitely && (
                    <DateFilter
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                    />
                  )}
                </DateRangeFieldContainer>
              </InputContainerForMediumGap>
            )}
          </ModalInputsContainerScrollWrapper>
        </ModalInputsContainer>
        <ModalActions>
          <MediumPrimaryButton
            disabled={!createEnabled}
            onClick={handleCreate}
          >
            Create
          </MediumPrimaryButton>
          <MediumSecondaryButton onClick={handleClose}>
            Close
          </MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default CreateCampaignModal;
