import { QuestionMark } from '@mui/icons-material';
import styled from 'styled-components';
import { IncompleteModuleIcon } from '../../../../pages-app/Tabs/Training/Training/styled';
import { CompleteIcon } from '../../../../styles/shared-styled-components';

export const BookableDiagnosticIcon = styled(CompleteIcon)`
  position: static;
  height: 20px;
  width: 20px;
  top: default;
  right: default;
`;

export const UnbookableDiagnosticIcon = styled(IncompleteModuleIcon)`
  position: static;
  height: 18px;
  width: 18px;
  color: ${(props) => props.theme.color.lightError};
`;

export const UnclassifiedDiagnosticIcon = styled(QuestionMark)`
  position: static;
  height: 18px;
  width: 18px;
  top: default;
  right: default;
  color: ${(props) => props.theme.color.disabledText};
`;
