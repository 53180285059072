import styled, { css } from 'styled-components';
import {
  MinusIcon as BaseMinusIcon,
  PlusIcon as BasePlusIcon,
  CenterFlexStyle,
  EssGapStyle,
  EssIconSize,
} from '../../../styles/shared-styled-components';

export const Container = styled.div`
  ${CenterFlexStyle}
  ${EssGapStyle}
`;

const IconStyle = css`
  ${EssIconSize}
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
`;

export const ComponentContainer = styled.div`
  width: 13px;
  ${CenterFlexStyle}
`;

export const MinusIcon = styled(BaseMinusIcon)`
  ${IconStyle}
`;

export const PlusIcon = styled(BasePlusIcon)`
  ${IconStyle}
`;
