import React from 'react';
import {
  DarkMLText,
  EmptyGapColumnCenteredDiv,
  ErrorSmallText,
  MediumDarkSmallText,
  MediumGapColumnCenteredDiv,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SuccessSmallText,
} from '../../../styles/shared-styled-components';
import { completeStatusKey } from '../../../utils/constants';
import { getTimestamp } from '../../../utils/date';

const CheckoutSessionModal = ({ session, isOpen, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  console.log('session', session);

  return (
    session && (
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <ModalContainer>
          <DarkMLText>Checkout Status</DarkMLText>
          <ModalInputsContainer>
            <ModalInputsContainerScrollWrapper
              columnFlex
              largeGap
            >
              <MediumGapColumnCenteredDiv topMargin={10}>
                <EmptyGapColumnCenteredDiv>
                  <MediumDarkSmallText>
                    Plan: '{session.name}'<br></br>
                    {session.status === completeStatusKey ? (
                      <SuccessSmallText>
                        Paid
                        <br></br>({getTimestamp(session.updatedAt)})
                      </SuccessSmallText>
                    ) : (
                      <ErrorSmallText>
                        Unpaid
                        <br></br>
                        (last updated {getTimestamp(session.updatedAt)})
                      </ErrorSmallText>
                    )}
                  </MediumDarkSmallText>
                </EmptyGapColumnCenteredDiv>
              </MediumGapColumnCenteredDiv>
            </ModalInputsContainerScrollWrapper>
          </ModalInputsContainer>
          <ModalActions>
            <MediumSecondaryButton onClick={handleClose}>
              Close
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    )
  );
};

export default CheckoutSessionModal;
