import styled, { css } from 'styled-components';
import { BaseStatusChip } from '../../../../styles/shared-styled-components';
import {
  agentErrorInterceptionReasonKey,
  clarifyingCauseKey,
  errorCauseKey,
  unclassifiedKey,
  upsellingCauseKey,
  usingSeparatePlatformlInterceptionReasonKey,
} from '../../../../utils/constants';

export const CountContainer = styled.div`
  position: absolute;
  top: -30px;
  left: 3px;
`;

export const StatusChip = styled(BaseStatusChip)`
  ${(props) =>
    [unclassifiedKey, usingSeparatePlatformlInterceptionReasonKey].includes(
      props.status,
    )
      ? css`
          color: ${({ theme }) => theme.color.disabledText};
          background-color: ${({ theme }) => theme.color.disabled};
        `
      : [upsellingCauseKey].includes(props.status)
      ? css`
          color: white;
          background-color: ${({ theme }) => theme.color.brightGreen};
        `
      : [
          clarifyingCauseKey,
          errorCauseKey,
          agentErrorInterceptionReasonKey,
        ].includes(props.status)
      ? css`
          color: white;
          background-color: ${({ theme }) => theme.color.lightError};
        `
      : css`
          color: white;
          background-color: ${({ theme }) => theme.color.blue};
        `}
  min-width: ${(props) => props.fixedMinWidth && `${props.fixedMinWidth}px`};
  margin-left: 10px;
  margin-top: 3px;
`;
