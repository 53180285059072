import { useApolloClient } from '@apollo/client';
import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useHubSpotDataSyncStatus } from '../../../api/hooks/app-connections';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Header from '../../../components/NavBar/Header';
import {
  ContentContainer,
  DarkMediumText,
  PageContainer,
} from '../../../styles/shared-styled-components';
import { updateMyUserAppConnectionsInCache } from '../../../utils/cache';
import { hubSpotKey, inProgressStatusKey } from '../../../utils/constants';

const HubSpotSyncStatus = () => {
  const { cache } = useApolloClient();

  const { user, refetchUser, drawerOpen, drawerExpanded } =
    useContext(BaseContext);
  const [searchParams] = useSearchParams();
  const tokenId = searchParams?.get('tid');

  const onCompleted = async (data) => {
    if (data) {
      const status = data.getHubSpotDataSyncStatus;
      if (status === inProgressStatusKey) {
        refetchUser();
        await updateMyUserAppConnectionsInCache(
          hubSpotKey,
          true,
          user?.email,
          cache,
        );
      }
    }
  };

  const { data: syncStatus, loading: syncStatusLoading } =
    useHubSpotDataSyncStatus(tokenId, onCompleted);

  if (syncStatusLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    syncStatus && (
      <>
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
          >
            <DarkMediumText>
              {!user
                ? 'Must be logged in to verify HubSpot sync status'
                : syncStatus === inProgressStatusKey
                ? 'HubSpot connection successful, data syncing!'
                : `HubSpot Sync status: ${syncStatus}`}
            </DarkMediumText>
          </ContentContainer>
        </PageContainer>
      </>
    )
  );
};

export default HubSpotSyncStatus;
