import gql from 'graphql-tag';

export const GET_MY_MESSAGE_TEMPLATES = gql`
  query getMyMessageTemplates {
    getMyMessageTemplates {
      id
      versionId
      createdBy
      name
      contactType
      templateType
      createdAt
      updatedAt
    }
  }
`;

export const GET_MESSAGE_TEMPLATE_CONTENT = gql`
  query getMessageTemplateContent($id: String!) {
    getMessageTemplateContent(id: $id) {
      template {
        id
        name
      }
      content {
        id
        type
        text
        followUpText
        customStopMessage
        description
        autoGenerateText
        copyServiceName
        files
        filesDescription
        targetLink
        membershipId
      }
    }
  }
`;

export const GET_SCRIPT = gql`
  query getScript($id: String!) {
    getScript(id: $id) {
      id
      name
      goal
      status
      nodes {
        id
        title
        content
        type
        conditionJson
        configJson
        expectedResponseJson
        sessionTypeId
        bookingOffering
      }
      edges {
        id
        source
        target
      }
    }
  }
`;

export const GET_MY_FILES = gql`
  query getMyFiles {
    getMyFiles {
      id
      name
      url
      createdAt
      updatedAt
    }
  }
`;

export const GET_MY_FAQS = gql`
  query getMyFaqs {
    getMyFaqs {
      id
      question
      answer
      relevantCategoryId
      relevantSessionTypeIds
      createdAt
      updatedAt
    }
  }
`;
