import ConstructionIcon from '@mui/icons-material/Construction';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import Person2Icon from '@mui/icons-material/Person2';
import PolicyIcon from '@mui/icons-material/Policy';
import Menu from '@mui/material/Menu';
import styled, { css } from 'styled-components';
import {
  CenterFlexStyle,
  CreditCardIcon,
  MediumIconSize,
  SMIconSize,
  SMMIconSize,
  SecondaryButton,
  SecondarySecondaryButton,
  SettingsIcon,
  SmallGapStyle,
  StyledMenuItemIconStyle,
  SyncIcon,
} from '../../../styles/shared-styled-components';
import { AuthButtonStyle } from '../../Auth/LoginButton/styled';
import { DataMagicIcon } from '../../Charts/ChartHeader/styled';

const ProfileButtonWidthStyle = css`
  ${(props) =>
    !props.isMobileScreen
      ? css`
          padding: 20px;
          width: fit-content;
        `
      : css`
          min-width: 100px;
        `}
`;

export const DataIconsContainer = styled.div`
  ${CenterFlexStyle}
  ${SmallGapStyle}
`;

export const DataSyncIconContainer = styled.div`
  ${MediumIconSize}
  border-radius: 50%;
  ${CenterFlexStyle}
  cursor: pointer;
`;

export const DataSyncIcon = styled(SyncIcon)`
  color: ${({ theme }) => theme.color.lightPrimary};
  ${SMIconSize}
  cursor: pointer;
`;

export const GrowthTipsIconContainer = styled.div`
  ${MediumIconSize}
  border-radius: 50%;
  ${(props) =>
    props.present === 'true'
      ? css`
          color: ${({ theme }) => theme.color.mediumDarkText};
          background-color: ${({ theme }) => theme.color.gold};
        `
      : css`
          background-color: ${({ theme }) => theme.color.lightPrimary};
          color: white;
          &:hover {
            color: ${({ theme }) => theme.color.mediumDarkText};
            background-color: ${({ theme }) => theme.color.gold};
          }
        `}
  ${CenterFlexStyle}
  margin-right: -10px;
  cursor: pointer;
`;

export const GrowthTipsIcon = styled(DataMagicIcon)`
  color: unset;
  cursor: pointer;
`;

export const ProfileMenuButton = styled(SecondaryButton)`
  ${AuthButtonStyle}
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.typography.largeTinySize};
  ${MediumIconSize}
  ${ProfileButtonWidthStyle}
  padding: 5px;
  .MuiButton-startIcon {
    margin: 0px;
  }
`;

export const ProfileMenuButtonPlaceholder = styled(SecondarySecondaryButton)`
  ${AuthButtonStyle}
  color: ${({ theme }) => theme.color.white};
  ${MediumIconSize}
  ${ProfileButtonWidthStyle}
`;

export const ExpandIcon = styled(KeyboardArrowDownIcon)``;

export const ProfileMenu = styled(Menu)`
  color: ${({ theme }) => theme.color.white};
  min-width: 5vw;
  top: 10px;
  .MuiMenu-list {
    border: 1px solid ${({ theme }) => theme.color.lightGrey};
    border-radius: 4px;
  }
`;

export const ProfileIconInHeader = styled(Person2Icon)`
  color: ${({ theme }) => theme.color.white};
  margin-right: ${(props) => !props.isMobileScreen && '10px'};
`;

const DropdownIconStyle = css`
  ${StyledMenuItemIconStyle}
  color: ${({ theme }) => theme.color.primary};
`;

export const ProfileIconInDropdown = styled(Person2Icon)`
  ${DropdownIconStyle}
`;

export const ConnectionsIconInDropdown = styled(ElectricalServicesIcon)`
  ${DropdownIconStyle}
`;

export const BillingIconInDropdown = styled(CreditCardIcon)`
  ${DropdownIconStyle}
`;

export const TermsIconInDropdown = styled(PolicyIcon)`
  ${DropdownIconStyle}
`;

export const DemoIconInDropdown = styled(ConstructionIcon)`
  ${DropdownIconStyle}
`;

export const SettingsIconInDropdown = styled(SettingsIcon)`
  ${DropdownIconStyle}
  ${SMMIconSize}
`;

export const ProfileLogoutIcon = styled(LogoutIcon)`
  ${DropdownIconStyle}
`;
