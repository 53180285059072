import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useAllPaymentPlans } from '../../../api/hooks/payment';
import { UPDATE_LOCATION_PAYMENT_PLANS } from '../../../api/mutations/payment';
import {
  DarkMLText,
  EmptyGapColumnCenteredDiv,
  MediumDarkEssText,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  PrimaryText,
  SmallCheckbox,
} from '../../../styles/shared-styled-components';
import { currencyMap, paymentTypeMap } from '../../../utils/constants';
import { formatNumber } from '../../../utils/numbers';
import LoadingIndicator from '../../LoadingIndicator';

const AssignPaymentPlanModal = ({
  assignedPlans,
  isOpen,
  onClose,
  onAssign,
}) => {
  const { plans, loading } = useAllPaymentPlans({ skipCondition: !isOpen });

  const [updateLocationPaymentPlansMutation, { loading: updateLoading }] =
    useMutation(UPDATE_LOCATION_PAYMENT_PLANS);

  console.log('plans', plans);
  console.log('assignedPlans', assignedPlans);

  const assignedPlanMap = {};
  (assignedPlans || []).map((p) => {
    assignedPlanMap[p.productId] = true;
  });

  const [assignments, setAssignments] = useState(assignedPlanMap);

  useEffect(() => {
    const assignedPlanMap = {};
    (assignedPlans || []).map((p) => {
      assignedPlanMap[p.productId] = true;
    });
    setAssignments(assignedPlanMap);
  }, [assignedPlans]);

  const onSave = async () => {
    updateLocationPaymentPlansMutation({
      variables: {
        planIds: Object.keys(assignments),
      },
      onCompleted: async () => {
        handleClose();
        await onAssign();
      },
    });
  };

  const handleClose = () => {
    onClose();
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    plans?.length && (
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <ModalContainer>
          <DarkMLText>Assign Payment Plan</DarkMLText>
          <ModalInputsContainer>
            <ModalInputsContainerScrollWrapper
              columnFlex
              largeGap
            >
              <MediumGapColumnCenteredDiv topMargin={10}>
                {plans.map((p) => {
                  const id = p.id;
                  const currency = currencyMap[p.currency];
                  const assigned = !!assignments[id];
                  return (
                    <EmptyGapColumnCenteredDiv>
                      <MediumDarkEssText>
                        {p.name}
                        <br></br>
                        {currency.prefix}
                        {formatNumber(p.amount)} {currency.suffix} (
                        {paymentTypeMap[p.type]})<br></br>
                        <PrimaryText>
                          Assigned:{' '}
                          <SmallCheckbox
                            checked={assigned}
                            onClick={() => {
                              const updatedMap = { ...assignments };
                              if (assigned) {
                                delete updatedMap[id];
                              } else {
                                updatedMap[id] = true;
                              }
                              setAssignments(updatedMap);
                            }}
                          />
                        </PrimaryText>
                      </MediumDarkEssText>
                    </EmptyGapColumnCenteredDiv>
                  );
                })}
              </MediumGapColumnCenteredDiv>
            </ModalInputsContainerScrollWrapper>
          </ModalInputsContainer>
          <ModalActions>
            <MediumPrimaryButton onClick={() => onSave()}>
              Save assignments
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={handleClose}>
              Close
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    )
  );
};

export default AssignPaymentPlanModal;
