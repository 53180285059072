import { useApolloClient, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { DELETE_PROMO } from '../../../api/mutations/enterprise';
import {
  MLText,
  MediumDarkSMText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
} from '../../../styles/shared-styled-components';
import {
  deletePromoFromCampaignsInCache,
  deletePromoInCache,
} from '../../../utils/cache';
import LoadingIndicator from '../../LoadingIndicator';

const DeletePromoModal = ({ isOpen, onClose, promoToDelete, onDelete }) => {
  const { cache } = useApolloClient();

  const [deletePromoMutation, { loading: deletePromoLoading }] =
    useMutation(DELETE_PROMO);

  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    const promoId = promoToDelete.id;

    deletePromoMutation({
      variables: {
        id: promoId,
      },
      onCompleted: async (data) => {
        const success = data.deletePromo.success;

        if (success) {
          await deletePromoInCache(promoId, cache);
          await deletePromoFromCampaignsInCache(promoId, cache);
          onDelete();
        }
        setLoading(false);
      },
    });
  };

  if (deletePromoLoading || loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    promoToDelete && (
      <Modal
        open={isOpen}
        onClose={onClose}
      >
        <ModalContainer>
          <MLText>Delete Promo</MLText>
          <MediumDarkSMText>
            Are you sure you want to delete the promo for{' '}
            {promoToDelete.name ? `the ${promoToDelete.name}` : 'this'}'
            campaign?
          </MediumDarkSMText>
          <ModalActions>
            <MediumPrimaryButton onClick={handleDelete}>
              Delete
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={onClose}>
              Cancel
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    )
  );
};

export default DeletePromoModal;
