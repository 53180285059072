import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { STORE_TWILIO_ACCESS_TOKEN } from '../../../api/mutations/app-connections';
import {
  ColumnCenteredDiv,
  DarkMLText,
  Modal,
  ModalActions,
  ModalContainer,
  PrimaryButton,
} from '../../../styles/shared-styled-components';
import {
  booleanOptions,
  dropdownInputType,
  phoneInputType,
} from '../../../utils/constants';
import Input from '../../Form/Input/Input';

const TwilioModal = ({ isOpen, onClose, onSuccess, location }) => {
  const [storeTwilioAccessTokenMutation] = useMutation(
    STORE_TWILIO_ACCESS_TOKEN,
  );

  const usingInternal = location?.usingInternalMessagingService;
  const [liveIqManaged, setLiveIqManaged] = useState(
    usingInternal !== '' && usingInternal != null ? usingInternal : true,
  );
  const [number, setNumber] = useState(
    location?.messagingServicePhoneNumber || '',
  );
  const [accessToken, setAccessToken] = useState('');

  const onSave = () => {
    storeTwilioAccessTokenMutation({
      variables: {
        managedByLiveIq: liveIqManaged,
        number,
        accessToken,
      },
      onCompleted: async () => {
        await onSuccess();
        setAccessToken();
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer>
        <DarkMLText>Update Your Twilio Access Token</DarkMLText>
        <Input
          id='liveiq-managed'
          value={liveIqManaged}
          onChange={(e) => {
            setLiveIqManaged(e.target.value);
          }}
          label='Managed by LiveIQ'
          isRequired={false}
          removeGap={true}
          useSmallWidth={true}
          type={dropdownInputType}
          options={booleanOptions}
        />
        <Input
          id='number'
          value={number}
          onChange={(value) => {
            setNumber(value);
          }}
          label='Phone number'
          isRequired={false}
          removeGap={true}
          useSmallWidth={true}
          type={phoneInputType}
        />
        <ModalActions>
          <ColumnCenteredDiv>
            <PrimaryButton
              disabled={!number}
              onClick={onSave}
            >
              Save
            </PrimaryButton>
          </ColumnCenteredDiv>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default TwilioModal;
