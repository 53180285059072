import { useApolloClient, useMutation } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useMySessionTypes,
  useMyStaff,
  usePromoById,
} from '../../../../api/hooks/enterprise';
import { EDIT_PROMO } from '../../../../api/mutations/enterprise';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import Input from '../../../../components/Form/Input';
import SplitInput from '../../../../components/Form/SplitInput/SplitInput';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Header from '../../../../components/NavBar/Header';
import PopperMenuCalendar from '../../../../components/Schedule/Calendar/PopperMenuCalendar';
import MetaSetter from '../../../../components/Utils/MetaSetter';
import Paths from '../../../../Paths';
import {
  CalendarIcon,
  CenteredDivWithLargerGap,
  Checkbox,
  CheckboxPlaceholder,
  Chip,
  ChipCancelIcon,
  ContentContainer,
  Dropdown,
  ExtraSmallTextInput,
  Form,
  FormControlLabel,
  FormGroup,
  FullWidthStartAlignedDiv,
  LightDarkEssText,
  MediumDarkEssText,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  PageContainer,
  PageHeaderContainer,
  PageSubtitleText,
  PageTitleBottomBorderContainer,
  ScrollWrapper,
  SecondaryBackIcon,
  SMCheckbox,
  Stack,
  StartAlignedLightDarkLargeTinyText,
  StartAlignedMediumDarkSmallText,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import { updatePromoByIdInCache } from '../../../../utils/cache';
import {
  discountOptions,
  dropdownInputType,
  flatRateType,
  percentInputType,
  uncategorizedKey,
  unclassifiedKey,
} from '../../../../utils/constants';
import { removeItemObject } from '../../../../utils/data';
import { dateToTextFormat } from '../../../../utils/date';
import { getInitials } from '../../../../utils/name';
import { getUserLocationId } from '../../../../utils/user';
import {
  DateContainer,
  DatesContainer,
  DateTextContainer,
  SelectContainer,
  SelectTitleTextContainer,
} from './styled';

const allStaffKey = 'all_staff';
const allStaffLabel = 'All Staff';
const allServicesKey = 'all_services';
const allServicesLabel = 'All Services';

const currentDtString = new Date();

const EditPromo = ({}) => {
  const { cache } = useApolloClient();
  const navigate = useNavigate();

  const { user, drawerOpen, drawerExpanded, inDemoMode, cookies, setCookie } =
    useContext(BaseContext);
  const locationId = getUserLocationId(user);

  const contentContainerRef = useRef();

  const { promoId: promoIdToEdit } = useParams();

  const { sessionTypes: sessionTypeCatalog, loading: sessionTypesLoading } =
    useMySessionTypes({ onlyBookable: true });

  const { staff: staffData, loading: staffLoading } = useMyStaff({
    locationId,
  });
  const { promo: promoToEditData, loading: promoToEditLoading } = usePromoById({
    id: promoIdToEdit,
    editing: true,
  });

  const [editPromoMutation] = useMutation(EDIT_PROMO);

  const [promoToEditDescription, setPromoToEditDescription] = useState('');
  const [availableSessionTypes, setAvailableSessionTypes] = useState();
  const [promoToEditSessionTypes, setPromoToEditSessionTypes] = useState({});
  const [availableStaff, setAvailableStaff] = useState();
  const [promoToEditStaff, setPromoToEditStaff] = useState([]);
  const [promoToEditDiscountType, setPromoToEditDiscountType] =
    useState(percentInputType);
  const [promoToEditDiscountAmount, setPromoToEditDiscountAmount] = useState();
  const [promoToEditStartDate, setPromoToEditStartDate] = useState();
  const [
    promoToEditStartDateMenuAnchorEl,
    setPromoToEditStartDateMenuAnchorEl,
  ] = useState();
  const [promoToEditEndDate, setPromoToEditEndDate] = useState();
  const [promoToEditEndDateMenuAnchorEl, setPromoToEditEndDateMenuAnchorEl] =
    useState();
  const [searchFilter, setSearchFilter] = useState('');

  const promoRunsIndefinitely = !promoToEditStartDate && !promoToEditEndDate;

  const promoToEditStartDateMenuOpenBoolean = Boolean(
    promoToEditStartDateMenuAnchorEl,
  );
  const promoToEditEndDateMenuOpenBoolean = Boolean(
    promoToEditEndDateMenuAnchorEl,
  );

  useEffect(() => {
    if (sessionTypeCatalog) {
      const values = Object.values(sessionTypeCatalog?.categories);
      const uncategorizedMap = sessionTypeCatalog?.[uncategorizedKey];
      const allSessionTypes = [];
      allSessionTypes.push({
        categoryId: uncategorizedKey,
        categoryName: 'Uncategorized',
        services: [
          ...(uncategorizedMap?.dropIns || []),
          ...(uncategorizedMap?.consultations || []),
        ],
      });
      values.map((s) => {
        allSessionTypes.push({
          categoryId: s.id,
          categoryName: s.name,
          services: [...(s?.dropIns || []), ...(s?.consultations || [])],
        });
      });
      const packages = sessionTypeCatalog?.packages;
      allSessionTypes.push({
        categoryId: 'packages',
        categoryName: 'Packages',
        services: Object.values(packages),
      });
      setAvailableSessionTypes(allSessionTypes);
    }
  }, [sessionTypeCatalog]);

  useEffect(() => {
    if (promoToEditData) {
      const promoToEdit = promoToEditData.promo;
      setPromoToEditDescription(promoToEdit.description);
      setPromoToEditDiscountType(promoToEdit.discountType);
      setPromoToEditDiscountAmount(promoToEdit.discountAmount);
      setPromoToEditStartDate(promoToEdit.startDate);
      setPromoToEditEndDate(promoToEdit.endDate);

      setPromoToEditStaff(promoToEdit.staff);

      const promoToEditSessionTypeRes = promoToEdit.sessionTypes;
      setPromoToEditSessionTypes(promoToEditSessionTypeRes);

      const availableStaffData = promoToEditData.availableStaff || [];
      if (availableStaffData.length === 0) {
        const allStaffObject = {
          id: allStaffKey,
          name: allStaffKey,
          isAllKey: true,
        };
        setAvailableStaff([allStaffObject]);
      } else {
        setAvailableStaff(availableStaffData);
      }
    }
  }, [promoToEditData]);

  const onUpdatePromoSessionTypes = (
    checked,
    id,
    name,
    isCategory,
    isAllKey = false,
    categoryId = null,
  ) => {
    let newServices;

    if (checked) {
      const object = {
        id,
        categoryId,
        isCategory,
        isAllKey,
        name,
      };
      if (isAllKey) {
        newServices = { [id]: object };
      } else if (isCategory) {
        newServices = {
          ...promoToEditSessionTypes,
          [id]: object,
        };
        newServices = Object.fromEntries(
          Object.entries({ ...newServices }).filter(
            ([entryId, value]) =>
              value.categoryId !== id &&
              !(value.categoryId == null && value.isCategory == null),
          ),
        );
      } else {
        newServices = { ...promoToEditSessionTypes };
        newServices[id] = object;
      }
    } else {
      newServices = removeItemObject(promoToEditSessionTypes, id);
    }

    setPromoToEditSessionTypes(newServices);
  };

  const onUpdatePromoStaff = (checked, id, name, isAllKey = false) => {
    let newStaff;

    if (checked) {
      const object = {
        id,
        name,
        isAllKey,
      };
      if (isAllKey) {
        newStaff = { [id]: object };
      } else {
        newStaff = { ...promoToEditStaff };
        newStaff[id] = object;
      }
    } else {
      newStaff = removeItemObject(promoToEditStaff, id);
    }

    setPromoToEditStaff(newStaff);
  };

  const onSavePromo = () => {
    editPromoMutation({
      variables: {
        id: promoIdToEdit,
        description: promoToEditDescription,
        staff: promoToEditStaffArray,
        sessionTypes: promoToEditSessionTypesArray,
        discountType: promoToEditDiscountType,
        discountAmount: parseFloat(promoToEditDiscountAmount),
        startDate: promoToEditStartDate,
        endDate: promoToEditEndDate,
      },
      onCompleted: async (data) => {
        const updatedPromo = data.editPromo;

        if (updatedPromo) {
          await updatePromoByIdInCache(promoIdToEdit, updatedPromo, cache);
          onClickBack();
        }
      },
    });
  };

  const resetPromoToEditData = () => {
    setPromoToEditDescription();
    setPromoToEditSessionTypes([]);
    setPromoToEditStaff([]);
    setPromoToEditStartDate();
    setPromoToEditEndDate();
  };

  const onClickBack = () => {
    resetPromoToEditData();
    navigate(Paths.campaigns);
  };

  const promoToEditStaffArray = Object.values(promoToEditStaff) || [];
  const promoToEditSessionTypesArray =
    Object.values(promoToEditSessionTypes) || [];

  const allStaffSelected = allStaffKey in promoToEditStaff;
  const allServicesSelected = allServicesKey in promoToEditSessionTypes;

  const savePromoEnabled =
    promoToEditDiscountType &&
    promoToEditDiscountAmount != null &&
    promoToEditDescription &&
    promoToEditSessionTypesArray.length &&
    promoToEditStaffArray.length &&
    (promoRunsIndefinitely || (promoToEditStartDate && promoToEditEndDate));

  if (promoToEditLoading || staffLoading || sessionTypesLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <MetaSetter
        title={`Edit Promo`}
        description={`Edit LiveIQ Promo`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
        removeAllTopMargin
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          ref={contentContainerRef}
          fullWidth
          hideOverflow
          removeGap
          topPadding={0}
          center
        >
          <PageHeaderContainer
            ref={contentContainerRef}
            fixed
          >
            <CenteredDivWithLargerGap>
              <Tooltip
                title={<TooltipTitleText>Back to campaigns</TooltipTitleText>}
                placement='left'
              >
                <SecondaryBackIcon onClick={() => onClickBack()} />
              </Tooltip>
              <PageSubtitleText>Editing Campaign Promo</PageSubtitleText>
            </CenteredDivWithLargerGap>
            <PageTitleBottomBorderContainer />
          </PageHeaderContainer>
          <ScrollWrapper topPadding={80}>
            <MediumGapColumnCenteredDiv>
              <Input
                id='description'
                value={promoToEditDescription}
                onChange={(e) => {
                  setPromoToEditDescription(e.target.value);
                }}
                label='Description'
                useSmallGap={true}
                isRequired={false}
                multiline
                numRows={3}
              />
              <SelectContainer>
                <SelectTitleTextContainer>
                  <StartAlignedMediumDarkSmallText>
                    Select which services to apply the promo for
                  </StartAlignedMediumDarkSmallText>
                </SelectTitleTextContainer>
                <Input
                  id='services'
                  value={promoToEditSessionTypesArray}
                  removeGap={true}
                  CustomInputComponent={
                    <Form fitContentHeight>
                      <Dropdown
                        multiple
                        value={promoToEditSessionTypesArray}
                        renderValue={(selected) => (
                          <Stack>
                            {Object.values(selected)?.map(
                              (selectedServiceObject) => {
                                const { id, name, isCategory, isAllKey } =
                                  selectedServiceObject;

                                const categoryLabel = isCategory
                                  ? `All ${name} Services`
                                  : null;

                                const formattedLabel = isAllKey
                                  ? allServicesLabel
                                  : isCategory
                                  ? categoryLabel
                                  : name?.length > 26
                                  ? `${name.slice(0, 23)}...`
                                  : name;

                                const isHeader = isCategory || isAllKey;

                                return (
                                  <Chip
                                    key={id}
                                    secondary={isHeader}
                                    ternary={!isHeader}
                                    label={formattedLabel}
                                    onDelete={() => {
                                      const updatedPromoToEditSessionTypes =
                                        removeItemObject(
                                          promoToEditSessionTypes,
                                          id,
                                        );
                                      setPromoToEditSessionTypes(
                                        updatedPromoToEditSessionTypes,
                                      );
                                    }}
                                    deleteIcon={
                                      <ChipCancelIcon
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                  />
                                );
                              },
                            )}
                          </Stack>
                        )}
                        onBlur={() => {
                          setSearchFilter('');
                        }}
                      >
                        <FormGroup
                          key={'search'}
                          smallBottomMargin={true}
                          small
                        >
                          <FormControlLabel
                            isLabel
                            isTitle
                            control={
                              <ExtraSmallTextInput
                                bottomMargin={10}
                                leftMargin={13}
                                fixedWidth={350}
                                value={searchFilter}
                                onChange={(e) =>
                                  setSearchFilter(e.target.value)
                                }
                              />
                            }
                          />
                        </FormGroup>
                        {!searchFilter && (
                          <FormGroup
                            key={allServicesKey}
                            smallBottomMargin={true}
                          >
                            <FormControlLabel
                              isLabel
                              isTitle
                              control={
                                <Checkbox
                                  checked={allServicesSelected}
                                  onClick={(e) =>
                                    onUpdatePromoSessionTypes(
                                      e.target.checked,
                                      allServicesKey,
                                      allServicesLabel,
                                      false,
                                      true,
                                    )
                                  }
                                />
                              }
                              label={`${allServicesLabel} Eligible for Selected Staff`}
                            />
                          </FormGroup>
                        )}
                        {sortBy(availableSessionTypes, (s) =>
                          s.categoryName === unclassifiedKey
                            ? 'zz'
                            : s.categoryName === 'Packages'
                            ? 'zzz'
                            : s.categoryName,
                        )?.map((sessionTypeCategory) => {
                          const { categoryId, categoryName, services } =
                            sessionTypeCategory;
                          const isPackages = categoryName === 'Packages';
                          const categoryLabel = isPackages
                            ? 'Packages'
                            : `${categoryName || 'Unknown Category'} Services`;
                          const wholeCategorySelected =
                            categoryId in promoToEditSessionTypes;
                          const lowercaseCategoryName =
                            categoryName?.toLowerCase();

                          const filtered = sortBy(
                            services,
                            (s) => s.displayName,
                          ).filter(
                            (s) =>
                              !searchFilter ||
                              s.displayName
                                .toLowerCase()
                                .includes(searchFilter),
                          );
                          return (
                            filtered.length && (
                              <>
                                <FormGroup
                                  key={categoryId}
                                  smallBottomMargin={wholeCategorySelected}
                                  topMargin={10}
                                  bottomMargin={10}
                                >
                                  <FormControlLabel
                                    isLabel
                                    isTitle
                                    control={
                                      !searchFilter &&
                                      !isPackages &&
                                      lowercaseCategoryName !==
                                        uncategorizedKey ? (
                                        <Checkbox
                                          checked={wholeCategorySelected}
                                          onClick={(e) =>
                                            onUpdatePromoSessionTypes(
                                              e.target.checked,
                                              categoryId,
                                              categoryName,
                                              true,
                                            )
                                          }
                                        />
                                      ) : (
                                        <CheckboxPlaceholder />
                                      )
                                    }
                                    label={categoryLabel}
                                  />
                                </FormGroup>
                                {(searchFilter || !wholeCategorySelected) &&
                                  filtered.map((s, idx) => (
                                    <FormGroup
                                      key={s.id}
                                      largeBottomMargin={
                                        idx === services?.length - 1
                                      }
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              wholeCategorySelected ||
                                              s.id in promoToEditSessionTypes
                                            }
                                            onClick={(e) =>
                                              onUpdatePromoSessionTypes(
                                                e.target.checked,
                                                s.id,
                                                s.displayName,
                                                false,
                                                false,
                                                categoryId,
                                              )
                                            }
                                          />
                                        }
                                        label={s.displayName}
                                      />
                                    </FormGroup>
                                  ))}
                              </>
                            )
                          );
                        })}
                      </Dropdown>
                    </Form>
                  }
                />
              </SelectContainer>
              <SelectContainer>
                <SelectTitleTextContainer>
                  <StartAlignedMediumDarkSmallText>
                    Select which staff to apply the promo for
                  </StartAlignedMediumDarkSmallText>
                </SelectTitleTextContainer>
                <Input
                  id='staff'
                  value={promoToEditStaffArray}
                  removeGap={true}
                  CustomInputComponent={
                    <Form fitContentHeight>
                      <Dropdown
                        multiple
                        value={promoToEditStaffArray}
                        renderValue={(selected) => {
                          return (
                            <Stack>
                              {Object.values(selected)?.map(
                                (selectedStaffObject) => {
                                  const { id, name, isAllKey } =
                                    selectedStaffObject;
                                  const formattedName = isAllKey
                                    ? allStaffLabel
                                    : inDemoMode
                                    ? `${getInitials(
                                        name.split(' ')?.[0],
                                        name.split(' ')?.[1],
                                      )}`
                                    : name?.length > 20
                                    ? `${name.slice(0, 17)}...`
                                    : name;

                                  return (
                                    <Chip
                                      key={id}
                                      secondary={isAllKey}
                                      label={formattedName}
                                      onDelete={() => {
                                        const updatedStaff = removeItemObject(
                                          promoToEditStaff,
                                          id,
                                        );
                                        setPromoToEditStaff(updatedStaff);
                                      }}
                                      deleteIcon={
                                        <ChipCancelIcon
                                          onMouseDown={(event) =>
                                            event.stopPropagation()
                                          }
                                        />
                                      }
                                    />
                                  );
                                },
                              )}
                            </Stack>
                          );
                        }}
                      >
                        <FormGroup key={allStaffKey}>
                          <FormControlLabel
                            isLabel
                            isTitle
                            control={
                              <Checkbox
                                checked={allStaffSelected}
                                onClick={(e) =>
                                  onUpdatePromoStaff(
                                    e.target.checked,
                                    allStaffKey,
                                    allStaffLabel,
                                    true,
                                  )
                                }
                              />
                            }
                            label={'All Eligible Staff'}
                          />
                        </FormGroup>
                        {!allStaffSelected &&
                          sortBy(availableStaff, (s) => s.firstName)?.map(
                            (staffMember) => {
                              const { id, firstName, lastName } = staffMember;
                              const name = inDemoMode
                                ? getInitials(firstName, lastName)
                                : `${firstName} ${lastName}`;

                              return (
                                <>
                                  <FormGroup key={id}>
                                    <FormControlLabel
                                      isLabel
                                      control={
                                        <Checkbox
                                          checked={id in promoToEditStaff}
                                          onClick={(e) =>
                                            onUpdatePromoStaff(
                                              e.target.checked,
                                              id,
                                              name,
                                            )
                                          }
                                        />
                                      }
                                      label={name}
                                    />
                                  </FormGroup>
                                </>
                              );
                            },
                          )}
                      </Dropdown>
                    </Form>
                  }
                />
              </SelectContainer>
              <SelectContainer>
                <SelectTitleTextContainer>
                  <StartAlignedMediumDarkSmallText>
                    Apply discount
                  </StartAlignedMediumDarkSmallText>
                </SelectTitleTextContainer>
                <SplitInput
                  id={'discount'}
                  splitInputs={[
                    {
                      id: 'discount-type',
                      value: promoToEditDiscountType,
                      onChange: (e) =>
                        setPromoToEditDiscountType(e.target.value),
                      label: 'Type',
                      type: dropdownInputType,
                      options: discountOptions,
                    },
                    {
                      id: 'amount',
                      value: promoToEditDiscountAmount,
                      onChange: (e) =>
                        setPromoToEditDiscountAmount(e.target.value),
                      label:
                        promoToEditDiscountType !== flatRateType
                          ? 'Amount discounted'
                          : 'Flat rate price ($) ',
                      type: promoToEditDiscountType,
                    },
                  ]}
                  useExtraSmallGap={true}
                />
              </SelectContainer>
              <SelectContainer>
                <SelectTitleTextContainer start>
                  <StartAlignedMediumDarkSmallText>
                    Promo runs indefinitely
                  </StartAlignedMediumDarkSmallText>
                  <SMCheckbox
                    checked={promoRunsIndefinitely}
                    onClick={() => {
                      const updatedRunsIndefinitely = !promoRunsIndefinitely;
                      if (updatedRunsIndefinitely) {
                        setPromoToEditStartDate();
                        setPromoToEditEndDate();
                      } else {
                        setPromoToEditStartDate(currentDtString);
                        setPromoToEditEndDate(currentDtString);
                      }
                    }}
                  />
                </SelectTitleTextContainer>
                {!promoRunsIndefinitely ? (
                  <DatesContainer>
                    <DateContainer>
                      <DateTextContainer>
                        <MediumDarkEssText>Start Date</MediumDarkEssText>
                        <CalendarIcon
                          selected={promoToEditStartDateMenuOpenBoolean}
                          onClick={(e) =>
                            setPromoToEditStartDateMenuAnchorEl(e.currentTarget)
                          }
                        />
                        <PopperMenuCalendar
                          date={promoToEditStartDate}
                          open={promoToEditStartDateMenuOpenBoolean}
                          anchorElement={promoToEditStartDateMenuAnchorEl}
                          onClose={() => setPromoToEditStartDateMenuAnchorEl()}
                          onChange={(d) => {
                            setPromoToEditStartDate(d);
                            setPromoToEditStartDateMenuAnchorEl();
                          }}
                          placement='bottom-start'
                        />
                      </DateTextContainer>
                      {promoToEditStartDate ? (
                        <LightDarkEssText>
                          {dateToTextFormat(promoToEditStartDate)}
                        </LightDarkEssText>
                      ) : (
                        <LightDarkEssText>
                          <br></br>
                        </LightDarkEssText>
                      )}
                    </DateContainer>
                    <DateContainer end>
                      <DateTextContainer end>
                        <MediumDarkEssText>End Date</MediumDarkEssText>
                        <CalendarIcon
                          selected={promoToEditEndDateMenuOpenBoolean}
                          onClick={(e) => {
                            setPromoToEditEndDateMenuAnchorEl(e.currentTarget);
                          }}
                        />
                        <PopperMenuCalendar
                          date={promoToEditEndDate}
                          open={promoToEditEndDateMenuOpenBoolean}
                          anchorElement={promoToEditEndDateMenuAnchorEl}
                          onClose={() => setPromoToEditEndDateMenuAnchorEl()}
                          onChange={(d) => {
                            setPromoToEditEndDate(d);
                            setPromoToEditEndDateMenuAnchorEl();
                          }}
                          placement='bottom-start'
                        />
                      </DateTextContainer>
                      {promoToEditEndDate ? (
                        <LightDarkEssText>
                          {dateToTextFormat(promoToEditEndDate)}
                        </LightDarkEssText>
                      ) : (
                        <LightDarkEssText>
                          <br></br>
                        </LightDarkEssText>
                      )}
                    </DateContainer>
                  </DatesContainer>
                ) : (
                  <FullWidthStartAlignedDiv>
                    <StartAlignedLightDarkLargeTinyText>
                      When asked about promo duration your agent will provide a
                      vague response to elicit urgency, such as: "This promo
                      will expire in a month or two"
                    </StartAlignedLightDarkLargeTinyText>
                  </FullWidthStartAlignedDiv>
                )}
              </SelectContainer>
              <CenteredDivWithLargerGap topMargin={20}>
                <MediumPrimaryButton
                  disabled={!savePromoEnabled}
                  onClick={() => onSavePromo()}
                >
                  Save
                </MediumPrimaryButton>
                <MediumSecondaryButton onClick={() => onClickBack()}>
                  Cancel
                </MediumSecondaryButton>
              </CenteredDivWithLargerGap>
            </MediumGapColumnCenteredDiv>
          </ScrollWrapper>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default EditPromo;
