import React from 'react';
import {
  CampaignTypesContainer,
  CampaignTypesLeftContainer,
  CampaignTypesMedia,
  CampaignTypesMediaContainer,
  CampaignTypesWrapper,
  FillCancellationsIcon,
  MembershipsIcon,
  NewClientIcon,
  PhoneIcon,
  ReactivateIcon,
  ReviewsIcon,
} from '../styles/style-CampaignTypes';
import {
  TestimonialHeaderSubText,
  TestimonialHeaderText,
} from '../styles/style-Testimonials';

const campaignData = [
  {
    header: 'Turn missed calls into profit',
    subText:
      'Aesthetics has some of the highest missed call rates, our AI can make sure no client goes unanswered',
    icon: <PhoneIcon />,
  },
  {
    header: 'Convert leads into clients',
    subText:
      'Convert new leads from your CRM, with automatic follow-ups and sales tactics to get them booked',
    icon: <NewClientIcon />,
  },
  {
    header: 'Fill cancellations',
    subText: 'Automatically find and fill cancelled slots on your calendar',
    icon: <FillCancellationsIcon />,
  },
  {
    header: 'Re-activate existing clients',
    subText:
      'Target past clients with special promos to get them back in the door',
    icon: <ReactivateIcon />,
  },
  {
    header: 'Sell membership plans',
    subText: 'Target frequent clients to upgrade them to membership plans',
    icon: <MembershipsIcon />,
  },
  {
    header: 'Collect reviews & feedback',
    subText:
      'Improve your reviews, and collect post-appointment feedback, written straight into your EMR',
    icon: <ReviewsIcon />,
  },
];

const CampaignTypes = ({ darkMode }) => {
  return (
    <>
      <CampaignTypesWrapper>
        {campaignData.map((campaign, index) => (
          <CampaignTypesContainer
            dark={darkMode}
            key={index}
          >
            <CampaignTypesLeftContainer>
              <p>
                <TestimonialHeaderText>{campaign.header}</TestimonialHeaderText>
              </p>
              <TestimonialHeaderSubText>
                {campaign.subText}
              </TestimonialHeaderSubText>
            </CampaignTypesLeftContainer>
            <CampaignTypesMediaContainer>
              <CampaignTypesMedia noFixedHeight>
                {campaign.icon}
              </CampaignTypesMedia>
            </CampaignTypesMediaContainer>
          </CampaignTypesContainer>
        ))}
      </CampaignTypesWrapper>
    </>
  );
};

export default CampaignTypes;
