import { gql } from '@apollo/client';

export const GET_ALL_PAYMENT_PLANS = gql`
  query getAllPaymentPlans {
    getAllPaymentPlans
  }
`;

export const GET_LOCATION_PAYMENT_PLANS = gql`
  query getLocationPaymentPlans {
    getLocationPaymentPlans
  }
`;

export const GET_CHECKOUT_SESSION = gql`
  query getCheckoutSession($id: String!) {
    getCheckoutSession(id: $id)
  }
`;
