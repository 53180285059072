import { gql } from '@apollo/client';

export const UPDATE_LOCATION_PAYMENT_PLANS = gql`
  mutation updateLocationPaymentPlans($planIds: [String]!) {
    updateLocationPaymentPlans(planIds: $planIds) {
      success
    }
  }
`;

export const CREATE_CHECKOUT_SESSION = gql`
  mutation createCheckoutSession($productSourceId: String!) {
    createCheckoutSession(productSourceId: $productSourceId)
  }
`;
