import styled, { css } from 'styled-components';
import {
  AutoHorizontalMargins,
  BaseBorderRadiusStyle,
  CenterFlexStyle,
  ColumnCenteredDiv,
  ExtraSmallGapStyle,
  ExtraSmallText,
  MediumGapStyle,
  SMGapStyle,
  ScrollStyle,
  SmallGapStyle,
  StartAlignedMediumDarkExtraSmallText,
  UnderlineStyle,
} from '../../../styles/shared-styled-components';

export const FilterFieldsTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  width: calc(80% + 40px - 10px);
  ${AutoHorizontalMargins}
`;

export const SaveFilterIconContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 5px;
`;

export const FieldsContainer = styled(ColumnCenteredDiv)`
  border: 2px solid ${({ theme }) => theme.color.muiInput};
  ${BaseBorderRadiusStyle}
  width: 80%;
  ${AutoHorizontalMargins}
  height: ${(props) => (props.largeHeight ? `450px` : '340px')};
  justify-content: flex-start;
  padding: 20px;
  overflow-x: hidden;
  ${(props) =>
    props.disableScroll
      ? css`
          overflow: hidden;
        `
      : css`
          ${ScrollStyle}
        `}
`;

export const ScrollableFieldsContainer = styled.div`
  ${ScrollStyle}
`;

export const FieldContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.end
      ? css`
          align-items: flex-start;
          padding: 4px 2px;
        `
      : css`
          border-bottom: 1px solid ${({ theme }) => theme.color.muiInput};
          align-items: flex-end;
          padding: 10px 2px;
        `}
`;

export const BackToFieldsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  ${SmallGapStyle}
`;

export const FilterFieldsSection = styled(ColumnCenteredDiv)`
  ${MediumGapStyle}
  width: 100%;
`;

export const FilterFieldsContainer = styled(ColumnCenteredDiv)`
  ${SmallGapStyle}
  margin-top: 10px;
  width: 100%;
`;

export const FilterFieldContainer = styled(ColumnCenteredDiv)`
  width: ${(props) => (props.fullWidth ? '100%' : '80%')};
  ${SmallGapStyle};
`;

export const CheckboxFieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  ${SmallGapStyle}
  ${AutoHorizontalMargins}
`;

export const CenteredCheckboxFieldContainer = styled(CheckboxFieldContainer)`
  width: 35%;
`;

export const FilterOperatorsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${SMGapStyle}
  width: 100%;
`;

export const FilterOperatorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
`;

export const FilterOperatorText = styled(StartAlignedMediumDarkExtraSmallText)`
  font-size: 14px;
`;

export const SelectOptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  ${SmallGapStyle}
`;

export const ToggleAllOptionsText = styled(ExtraSmallText)`
  cursor: pointer;
  ${UnderlineStyle}
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
`;

export const ToggleAllOptionsTextSpan = styled.span`
  cursor: pointer;
  ${UnderlineStyle}
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  text-decoration-color: ${({ theme }) => theme.color.disabledText} !important;
`;

export const SelectOptionChip = styled.div`
  padding: 3px 8px;
  border-radius: 7px;
  cursor: pointer;
  ${(props) =>
    props.selected
      ? css`
          color: white;
          background-color: ${({ theme }) => theme.color.brightGreen};
        `
      : css`
          color: ${({ theme }) => theme.color.lightDarkText};
          background-color: ${({ theme }) => theme.color.muiInput};
        `}
`;

export const SelectOptionText = styled(ExtraSmallText)`
  color: unset;
`;

export const TemplatesContainer = styled(FieldsContainer)`
  height: 443px;
`;

export const TemplateContainer = styled(FieldContainer)``;

export const TemplateActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${ExtraSmallGapStyle}
  height: 100%;
`;

export const EditTemplateContainer = styled(FieldsContainer)`
  height: 378px;
  ${MediumGapStyle}
`;

export const EditTemplateTitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const BackToTemplatesContainer = styled.div`
  position: absolute;
  left: 0px;
`;

export const TemplateFieldsSection = styled(ColumnCenteredDiv)``;

export const TemplateFieldsContainer = styled.div`
  ${CenterFlexStyle}
  ${SMGapStyle}
`;
