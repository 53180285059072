import { sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { ServiceSubmodulesDrawer } from '../../../../pages-app/Tabs/Training/Training/styled';
import {
  AutoScrollWrapper,
  CenteredDiv,
  ClearInputIcon,
  ColumnCenteredDiv,
  FullWidthCenteredDiv,
  FullWidthColumnCenteredDiv,
  InputWidthDiv,
  LargeNoDataIcon,
  LargeTinyText,
  LightDarkExtraSmallText,
  LightDarkSMText,
  LightPrimaryEssText,
  MediumDarkExtraSmallText,
  MediumNoDataIcon,
  ModularWidthInput,
  SelectIcon,
  SmallCheckbox,
  StartAlignedFlexDiv,
  StartAlignedMediumDarkLargeTinyText,
} from '../../../../styles/shared-styled-components';
import {
  collapsedCategoryIdsCookieKey,
  discontinuedSessionTypeStatus,
  ghlAppKey,
  packagesLabel,
  selectedUpsellSessionTypeCookieKey,
  stagingFilterConversionDelay,
  unbookableSessionTypeStatus,
  uncategorizedKey,
  unclassifiedKey,
} from '../../../../utils/constants';
import { getCookieExpiryObject } from '../../../../utils/date';
import { parseCookieValueString } from '../../../../utils/string';
import { BaseContext } from '../../../Auth/AuthRouter/AuthRouter';
import { DownArrow, UpArrow } from '../../../Micro/ArrowIcon/styled';
import Snackbar from '../../../Snackbar';
import {
  EmptyDataContainer,
  OptionItemContainer,
  OptionsSearchBoxContainer,
  OptionSubitemText,
  OptionSubitemTextContainer,
  PromptContainer,
} from '../../shared-training-components';
import { AddOnsSection, CategoryNameContainer } from './styled';

const cookieExpiryObject = getCookieExpiryObject();

const Upsells = ({
  drawerOpen,
  drawerExpanded,
  sessionTypeCatalog,
  agentName,
  calendarPlatform,
  onUpdate,
}) => {
  const { cookies, setCookie } = useContext(BaseContext);

  const collapsedCategoryIdsCookieValue =
    cookies[collapsedCategoryIdsCookieKey] || [];
  const selectedSessionTypeCookieValue =
    cookies[selectedUpsellSessionTypeCookieKey];
  const selectedId = selectedSessionTypeCookieValue?.id;

  const [availableSessionTypes, setAvailableSessionTypes] = useState([]);
  const [sessionTypeEditing, setSessionTypeEditing] = useState();
  const [stagingSearchFilter, setStagingSearchFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [stagingAddOnSearchFilter, setStagingAddOnSearchFilter] = useState('');
  const [addOnSearchFilter, setAddOnSearchFilter] = useState('');
  const [collapsedCategoryIds, setCollapsedCategoryIds] = useState(
    parseCookieValueString(collapsedCategoryIdsCookieValue) || [],
  );
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const categoriesMap = sessionTypeCatalog?.categories || {};
  const allCategories = Object.values(categoriesMap);
  const uncategorizedMap = sessionTypeCatalog?.[uncategorizedKey];

  const allAddOnsMap = {};
  const addOnsMap = allCategories.reduce((acc, c) => {
    const addOnsForCategory = c?.addOns || [];

    // Add each consultation to the map using its ID as the key
    addOnsForCategory.forEach((a) => {
      const included =
        !addOnSearchFilter ||
        (a.displayName?.toLowerCase().includes(addOnSearchFilter) &&
          ![
            unbookableSessionTypeStatus,
            discontinuedSessionTypeStatus,
          ].includes(a.bookableStatus));
      if (included) {
        acc[a.id] = a;
      }
      allAddOnsMap[a.id] = a;
    });

    return acc;
  }, {});
  const uncategorizedAddOns = sessionTypeCatalog?.uncategorized?.addOns || [];
  uncategorizedAddOns.map((a) => {
    const included =
      !addOnSearchFilter ||
      (a.displayName?.toLowerCase().includes(addOnSearchFilter) &&
        ![unbookableSessionTypeStatus, discontinuedSessionTypeStatus].includes(
          a.bookableStatus,
        ));
    if (included) {
      addOnsMap[a.id] = a;
    }
    allAddOnsMap[a.id] = a;
  });

  const addOnsMapForService = sessionTypeEditing?.addOns || {};
  const availableAddOns = [];
  Object.keys(addOnsMapForService).map((id) => {
    const obj = allAddOnsMap[id];
    if (obj) {
      availableAddOns.push(obj);
    }
  });
  const currentAddOns = sessionTypeEditing?.upsellSessionTypeIds || [];
  const allAddOns = sortBy(availableAddOns, (a) => a.displayName);
  const filteredAddOns = sortBy(availableAddOns, (a) => a.displayName);

  useEffect(() => {
    if (sessionTypeCatalog) {
      let allSessionTypes = [];
      allCategories.map((c) => {
        const dropIns = c?.dropIns || [];
        const availableDropIns = dropIns.filter((d) => {
          if (d.id === selectedId) {
            setSessionTypeEditing(d);
          }
          return !!Object.keys(d?.addOns)?.length;
        });
        allSessionTypes.push({
          categoryId: c.id,
          categoryName: c.name,
          dropIns: availableDropIns,
        });
      });

      const uncatDropIns = uncategorizedMap?.dropIns || [];
      uncatDropIns.map((d) => {
        if (d.id === selectedId) {
          setSessionTypeEditing(d);
        }
      });
      allSessionTypes.push({
        categoryId: uncategorizedKey,
        categoryName: 'Uncategorized',
        dropIns: uncatDropIns,
      });
      setAvailableSessionTypes(allSessionTypes);
    }
  }, [sessionTypeCatalog, selectedId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingSearchFilter !== searchFilter) {
        setSearchFilter(stagingSearchFilter);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingSearchFilter]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingAddOnSearchFilter !== addOnSearchFilter) {
        setAddOnSearchFilter(stagingAddOnSearchFilter);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingAddOnSearchFilter]);

  useEffect(() => {
    if (collapsedCategoryIdsCookieValue != null) {
      setCollapsedCategoryIds(
        parseCookieValueString(collapsedCategoryIdsCookieValue) || [],
      );
    }
  }, [collapsedCategoryIdsCookieValue]);

  const onChangeCollapsedCategories = (id, shouldAdd) => {
    let updatedCollapsedCategories;
    if (shouldAdd) {
      updatedCollapsedCategories = [...collapsedCategoryIds, id];
    } else {
      updatedCollapsedCategories = collapsedCategoryIds.filter((i) => i !== id);
    }

    const updatedCollapsedCategoriesString = parseCookieValueString(
      updatedCollapsedCategories,
    );

    setCookie(
      collapsedCategoryIdsCookieKey,
      updatedCollapsedCategoriesString,
      cookieExpiryObject,
    );
  };

  const onUpdateAddOn = (id, shouldAdd) => {
    let updatedAddOns;
    if (shouldAdd) {
      updatedAddOns = [...currentAddOns, id];
    } else {
      updatedAddOns = currentAddOns.filter((i) => i !== id);
    }
    onUpdate({
      ...sessionTypeEditing,
      upsellSessionTypeIds: updatedAddOns,
    });
  };

  const lowercaseSearchFilter = searchFilter?.toLowerCase();
  const lowercaseAddOnSearchFilter = addOnSearchFilter?.toLowerCase();

  console.log('sessionTypeEditing', sessionTypeEditing);

  return (
    <>
      <ServiceSubmodulesDrawer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
        service={true}
      >
        <FullWidthCenteredDiv>
          <OptionsSearchBoxContainer fixedHeight={50}>
            <ModularWidthInput
              value={stagingSearchFilter}
              onChange={(e) => setStagingSearchFilter(e.target.value)}
              placeholder='Search services'
              InputProps={{
                endAdornment: (
                  <ClearInputIcon onClick={() => setStagingSearchFilter('')} />
                ),
              }}
              extraSmallHeight
              useFullWidth
            />
          </OptionsSearchBoxContainer>
        </FullWidthCenteredDiv>
        <AutoScrollWrapper
          disableMaxHeight
          bottomMargin={70}
        >
          {sortBy(availableSessionTypes, (s) =>
            s.categoryName === packagesLabel
              ? 'zzzz'
              : s.categoryName === unclassifiedKey || !s.categoryName
              ? 'zzz'
              : s.categoryName,
          )?.map((sessionTypeCategory) => {
            const { categoryId, categoryName, dropIns } = sessionTypeCategory;
            const categoryLabel = `${categoryName || 'Uncategorized'}`;

            const collapsed = collapsedCategoryIds.includes(categoryId);

            const filteredServices = dropIns.filter(
              (s) =>
                (!searchFilter ||
                  s.displayName
                    ?.toLowerCase()
                    .includes(lowercaseSearchFilter)) &&
                s?.bookableStatus !== unbookableSessionTypeStatus &&
                s?.bookableStatus !== discontinuedSessionTypeStatus,
            );
            const numServices = filteredServices?.length || 0;

            return numServices ? (
              <>
                <CategoryNameContainer>
                  <OptionItemContainer
                    top
                    bottomMargin={-10}
                  >
                    <StartAlignedFlexDiv>
                      <StartAlignedMediumDarkLargeTinyText
                        break
                        smallLineHeight
                      >
                        {categoryLabel} (
                        {`${numServices}${
                          searchFilter ? ` filtered services` : ``
                        }`}
                        )
                      </StartAlignedMediumDarkLargeTinyText>
                      <CenteredDiv>
                        {collapsed ? (
                          <DownArrow
                            color='darkGray'
                            pointer
                            onClick={() => {
                              onChangeCollapsedCategories(categoryId, false);
                            }}
                          />
                        ) : (
                          <UpArrow
                            color='darkGray'
                            pointer
                            onClick={() => {
                              onChangeCollapsedCategories(categoryId, true);
                            }}
                          />
                        )}
                      </CenteredDiv>
                    </StartAlignedFlexDiv>
                  </OptionItemContainer>
                </CategoryNameContainer>
                {!collapsed && (
                  <>
                    {sortBy(filteredServices, (s) => s.displayName).map(
                      (s, idx) => {
                        const { id, displayName, upsellSessionTypeIds } = s;
                        const selected = id === selectedId;
                        return (
                          <CategoryNameContainer
                            clickable
                            onClick={() =>
                              setCookie(
                                selectedUpsellSessionTypeCookieKey,
                                { id, name: displayName },
                                cookieExpiryObject,
                              )
                            }
                            selected={selected}
                          >
                            <OptionItemContainer
                              bottom={idx === numServices - 1}
                              widthPercentage={95}
                            >
                              <OptionSubitemTextContainer>
                                <OptionSubitemText
                                  start
                                  clickable
                                  selected={selected}
                                >
                                  {displayName}
                                  {upsellSessionTypeIds?.length
                                    ? ` (${upsellSessionTypeIds.length} add-on${
                                        upsellSessionTypeIds.length === 1
                                          ? ''
                                          : 's'
                                      })`
                                    : ''}
                                </OptionSubitemText>
                              </OptionSubitemTextContainer>
                            </OptionItemContainer>
                          </CategoryNameContainer>
                        );
                      },
                    )}
                  </>
                )}
              </>
            ) : (
              <></>
            );
          })}
        </AutoScrollWrapper>
      </ServiceSubmodulesDrawer>
      <AddOnsSection hideOverflow>
        <PromptContainer overflow>
          {sessionTypeEditing ? (
            <>
              {allAddOns.length ? (
                <>
                  <FullWidthColumnCenteredDiv>
                    <MediumDarkExtraSmallText>
                      Tell {agentName} what add-ons to upsell when booking a{' '}
                      {sessionTypeEditing?.displayName} session:
                    </MediumDarkExtraSmallText>
                    <LargeTinyText>
                      {!currentAddOns?.length
                        ? `None selected`
                        : `${currentAddOns
                            .map((id) => allAddOnsMap[id].displayName)
                            .join(', ')}`}
                    </LargeTinyText>
                    <OptionsSearchBoxContainer fixedHeight={50}>
                      <ModularWidthInput
                        value={stagingAddOnSearchFilter}
                        onChange={(e) =>
                          setStagingAddOnSearchFilter(e.target.value)
                        }
                        placeholder='Search add-ons'
                        InputProps={{
                          endAdornment: (
                            <ClearInputIcon
                              onClick={() => setStagingAddOnSearchFilter('')}
                            />
                          ),
                        }}
                        extraSmallHeight
                        noWidthPercentage
                        useSmallWidth
                      />
                    </OptionsSearchBoxContainer>
                  </FullWidthColumnCenteredDiv>
                  <AutoScrollWrapper
                    disableMaxHeight
                    bottomMargin={70}
                  >
                    {filteredAddOns?.length ? (
                      <>
                        {filteredAddOns.map((a) => {
                          const { id, displayName } = a;
                          const selected = currentAddOns?.includes(id);
                          return (
                            <InputWidthDiv small>
                              <CategoryNameContainer
                                clickable
                                onClick={() => {}}
                              >
                                <OptionItemContainer widthPercentage={95}>
                                  <OptionSubitemTextContainer>
                                    <OptionSubitemText
                                      start
                                      clickable
                                    >
                                      {displayName}
                                    </OptionSubitemText>
                                  </OptionSubitemTextContainer>
                                  <SmallCheckbox
                                    removePadding
                                    checked={selected}
                                    onClick={() => {
                                      onUpdateAddOn(id, !selected);
                                    }}
                                  />
                                </OptionItemContainer>
                              </CategoryNameContainer>
                            </InputWidthDiv>
                          );
                        })}
                      </>
                    ) : (
                      <ColumnCenteredDiv topMargin={50}>
                        <LargeNoDataIcon />
                        <LightPrimaryEssText>
                          No matching results
                        </LightPrimaryEssText>
                      </ColumnCenteredDiv>
                    )}
                  </AutoScrollWrapper>
                </>
              ) : (
                <FullWidthColumnCenteredDiv>
                  <MediumDarkExtraSmallText>
                    Tell {agentName} what add-ons to upsell when booking a{' '}
                    {sessionTypeEditing?.displayName} session:
                  </MediumDarkExtraSmallText>
                  <EmptyDataContainer removeGap>
                    <MediumNoDataIcon />
                    <LightDarkExtraSmallText>
                      No add-ons found,{' '}
                      {calendarPlatform === ghlAppKey
                        ? `create add-on services`
                        : `make add-ons eligible for this service`}{' '}
                      in order to assign upsells
                    </LightDarkExtraSmallText>
                  </EmptyDataContainer>
                </FullWidthColumnCenteredDiv>
              )}
            </>
          ) : (
            <EmptyDataContainer>
              <LightDarkSMText>
                Select a service to attach upsells for
              </LightDarkSMText>
              <SelectIcon />
            </EmptyDataContainer>
          )}
        </PromptContainer>
      </AddOnsSection>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        quick
      />
    </>
  );
};

export default Upsells;
