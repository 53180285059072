import React from 'react';
import {
  CenteredDiv,
  SmallCheckbox,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  dropdownInputType,
  googleKey,
  yelpTypeKey,
} from '../../../../utils/constants';
import { getWordWithCapitalizedFirstLetter } from '../../../../utils/name';
import { safeParseInt } from '../../../../utils/numbers';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  PromptContainer,
  PromptInput,
  PromptsSection,
  PromptTitleText,
  SpacedPromptCheckboxContainer,
} from '../../shared-training-components';

const FollowUps = ({ policy, onChange, links, loading }) => {
  const followUpPolicy = policy?.followUp || {};
  const defaultDays = followUpPolicy?.defaultDays || '';
  const rebook = followUpPolicy?.rebook || '';
  const solicitReviews = followUpPolicy?.solicitReviews || '';
  const membershipUpsell = followUpPolicy?.membershipUpsell || '';
  const defaultReviewPlatform = followUpPolicy?.defaultReviewPlatform || '';

  const cyclingThroughReviewPlatforms = !defaultReviewPlatform;
  const availableReviewPlatformsToAssign = [];
  Object.keys(links || {}).map((key) => {
    const link = links[key];
    if (!!link && (key === yelpTypeKey || key === googleKey)) {
      availableReviewPlatformsToAssign.push({
        key,
        label: getWordWithCapitalizedFirstLetter(key),
      });
    }
  });
  const defaultPlatformToSelect = availableReviewPlatformsToAssign?.[0]?.key;

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer>
        <PromptInput
          value={defaultDays}
          onChange={(e) => {
            onChange({
              ...followUpPolicy,
              defaultDays: safeParseInt(e.target.value),
            });
          }}
          label='Default follow-up days delay post-appointment'
        />
      </PromptContainer>
      <PromptContainer>
        <SpacedPromptCheckboxContainer>
          <PromptTitleText>Try to re-book next appointment</PromptTitleText>
          <SmallCheckbox
            checked={rebook}
            onClick={() => {
              onChange({
                ...followUpPolicy,
                rebook: !rebook,
              });
            }}
          />
        </SpacedPromptCheckboxContainer>
        <SpacedPromptCheckboxContainer>
          <PromptTitleText>Upsell membership in follow-up</PromptTitleText>
          <SmallCheckbox
            checked={membershipUpsell}
            onClick={() => {
              onChange({
                ...followUpPolicy,
                membershipUpsell: !membershipUpsell,
              });
            }}
          />
        </SpacedPromptCheckboxContainer>
        <SpacedPromptCheckboxContainer>
          <PromptTitleText>Solicit reviews</PromptTitleText>
          <SmallCheckbox
            checked={solicitReviews}
            onClick={() => {
              onChange({
                ...followUpPolicy,
                solicitReviews: !solicitReviews,
              });
            }}
          />
        </SpacedPromptCheckboxContainer>
      </PromptContainer>
      {solicitReviews && (
        <PromptContainer>
          <SpacedPromptCheckboxContainer bottomMargin={15}>
            <PromptTitleText>
              Dynamically cycle through review platforms
            </PromptTitleText>
            {defaultPlatformToSelect ? (
              <SmallCheckbox
                checked={cyclingThroughReviewPlatforms}
                onClick={() => {
                  onChange({
                    ...followUpPolicy,
                    defaultReviewPlatform: cyclingThroughReviewPlatforms
                      ? defaultPlatformToSelect
                      : null,
                  });
                }}
                disabled
              />
            ) : (
              <CenteredDiv>
                <Tooltip
                  title={
                    <TooltipTitleText>
                      Must populate review links before toggling this setting
                    </TooltipTitleText>
                  }
                >
                  <span>
                    <SmallCheckbox
                      checked={cyclingThroughReviewPlatforms}
                      onClick={() => {
                        onChange({
                          ...followUpPolicy,
                          defaultReviewPlatform: cyclingThroughReviewPlatforms
                            ? defaultPlatformToSelect
                            : null,
                        });
                      }}
                      disabled
                    />
                  </span>
                </Tooltip>
              </CenteredDiv>
            )}
          </SpacedPromptCheckboxContainer>
          {!cyclingThroughReviewPlatforms && (
            <PromptInput
              value={defaultReviewPlatform}
              onChange={(e) => {
                onChange({
                  ...followUpPolicy,
                  defaultReviewPlatform: e.target.value,
                });
              }}
              type={dropdownInputType}
              options={availableReviewPlatformsToAssign}
              label='Default review platform'
            />
          )}
        </PromptContainer>
      )}
    </PromptsSection>
  );
};

export default FollowUps;
