import MuiAddIcon from '@mui/icons-material/Add';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddCardIcon from '@mui/icons-material/AddCard';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import MuiEmailIcon from '@mui/icons-material/AlternateEmail';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import BackspaceIcon from '@mui/icons-material/Backspace';
import BiotechIcon from '@mui/icons-material/Biotech';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkEmptyIcon from '@mui/icons-material/BookmarkAddOutlined';
import MuiCancelIcon from '@mui/icons-material/Cancel';
import MuiChatIcon from '@mui/icons-material/Chat';
import MuiCheckIconWithoutBackground from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MuiChecklistIcon from '@mui/icons-material/Checklist';
import MuiCloseIcon from '@mui/icons-material/Close';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MuiCopyIcon from '@mui/icons-material/ContentCopy';
import MuiDashboardIcon from '@mui/icons-material/Dashboard';
import MuiDeleteIcon from '@mui/icons-material/Delete';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import MuiEditIcon from '@mui/icons-material/Edit';
import MuiCalendarIcon from '@mui/icons-material/EditCalendar';
import EventIcon from '@mui/icons-material/Event';
import {
  default as EventAvailableIcon,
  default as MuiBookableIcon,
} from '@mui/icons-material/EventAvailable';
import MuiUnbookableIcon from '@mui/icons-material/EventBusy';
import EventNoteIcon from '@mui/icons-material/EventNote';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HelpIcon from '@mui/icons-material/Help';
import HighQualityIcon from '@mui/icons-material/HighQuality';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import InfoIcon from '@mui/icons-material/Info';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import MuiLightbulbIcon from '@mui/icons-material/Lightbulb';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MuiLockIcon from '@mui/icons-material/Lock';
import MuiMenuIcon from '@mui/icons-material/Menu';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OutboxIcon from '@mui/icons-material/Outbox';
import MuiPauseIcon from '@mui/icons-material/Pause';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MuiPeopleIcon from '@mui/icons-material/People';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MuiPercentIcon from '@mui/icons-material/Percent';
import MuiPersonSearchIcon from '@mui/icons-material/PersonSearch';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MuiPlayIcon from '@mui/icons-material/PlayArrowRounded';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import MuiPolicyIcon from '@mui/icons-material/Policy';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import MuiRefreshIcon from '@mui/icons-material/Refresh';
import MuiMinusIcon from '@mui/icons-material/Remove';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import MuiSaveIcon from '@mui/icons-material/Save';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SchemaIcon from '@mui/icons-material/Schema';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import MuiSettingsIcon from '@mui/icons-material/Settings';
import SpeedIcon from '@mui/icons-material/Speed';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import MuiFilledStarIcon from '@mui/icons-material/Star';
import MuiEmptyStarIcon from '@mui/icons-material/StarBorder';
import StarCategoryIcon from '@mui/icons-material/StarPurple500';
import MuiStopIcon from '@mui/icons-material/StopSharp';
import StoreIcon from '@mui/icons-material/Store';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MuiSortIcon from '@mui/icons-material/SwapVert';
import MuiSyncIcon from '@mui/icons-material/Sync';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import MuiVerifiedIcon from '@mui/icons-material/Verified';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import MuiButton from '@mui/material/Button';
import MuiCheckbox from '@mui/material/Checkbox';
import MuiChip from '@mui/material/Chip';
import MuiContainer from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiFormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MuiMenuItem from '@mui/material/MenuItem';
import MobileStepper from '@mui/material/MobileStepper';
import MuiRadio from '@mui/material/Radio';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Snackbar from '@mui/material/Snackbar';
import MuiStack from '@mui/material/Stack';
import MuiTab from '@mui/material/Tab';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableContainer from '@mui/material/TableContainer';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import MuiTabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import MuiTooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { MuiTelInput } from 'mui-tel-input';
import { BiSolidBrain } from 'react-icons/bi';
import { CgArrowsExpandUpRight } from 'react-icons/cg';
import {
  FaBullhorn,
  FaExclamationCircle,
  FaRegCalendarCheck,
} from 'react-icons/fa';
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoCloudUpload, IoSyncCircle } from 'react-icons/io5';
import { LiaObjectUngroup } from 'react-icons/lia';
import {
  MdDoNotDisturbAlt,
  MdNewReleases,
  MdOutlineControlCamera,
  MdStackedLineChart,
} from 'react-icons/md';
import { RiMenuFoldFill, RiMenuUnfoldFill } from 'react-icons/ri';
import { TbCalendarSearch } from 'react-icons/tb';
import { TiFlowSwitch } from 'react-icons/ti';
import styled, { css } from 'styled-components';
import { DownArrow, UpArrow } from '../components/Micro/ArrowIcon/styled';
import {
  cancelledStatusKey,
  chatDemoContextKey,
  completeStatusKey,
  directlyBookableSessionTypeStatus,
  indirectlyBookableSessionTypeStatus,
  inProgressStatusKey,
  noShowStatusKey,
  readyToPublishStatusKey,
  scheduledStatusKey,
  sourcePackageBookableSessionTypeStatus,
  underReviewStatusKey,
} from '../utils/constants';
import spacing from './spacing';

export const BaseLogo = styled.img`
  height: 40px;
  width: auto;
`;

export const ColumnFlexStyle = css`
  display: flex;
  flex-direction: column;
`;

export const CenterFlexStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextStyle = css`
  font-family: ${({ theme }) => theme.typography.baseFont};
  color: ${(props) =>
    props.error ? props.theme.color.lightError : props.theme.color.primary};
  font-weight: 400;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  cursor: ${(props) => props.clickable && 'pointer'};
  line-height: ${(props) => props.smallLineHeight && '1.2'};
  margin-top: ${(props) => props.topMargin && `${props.topMargin}px`};
  ${(props) =>
    props.centered &&
    css`
      ${CenterFlexStyle}
    `}
  ${(props) =>
    props.verticallyCentered &&
    css`
      display: flex;
      align-items: center;
    `}
  ${(props) =>
    props.clickable &&
    css`
      color: ${props.selected && `${props.theme.color.primary} !important`};
      &:hover {
        color: ${props.theme.color.primary};
        cursor: pointer;
      }
    `}
    ${(props) =>
    props.addGap &&
    css`
      ${ExtraSmallGapStyle}
    `}
`;

export const UnderlineStyle = css`
  text-decoration: underline;
  text-underline-offset: ${(props) => (props.useLargerOffset ? '6px' : '4px')};
`;

export const LargeOffsetUnderlineStyle = css`
  ${UnderlineStyle}
  text-underline-offset: 6px;
`;

export const BaseTypography = styled(Typography)`
  text-transform: ${(props) => props.capitalize && 'capitalize'};
  font-style: ${(props) => props.italic && 'italic'};
  ${(props) =>
    props.centerElements &&
    css`
      display: inline-flex;
      align-items: center;
      gap: 5px;
    `}
  ${(props) =>
    props.break &&
    css`
      overflow-wrap: anywhere;
    `}
`;

export const BoldBlackText = styled.span`
  font-weight: bold;
  color: black;
`;

export const MediumDarkTextSpan = styled.span`
  color: ${({ theme }) => theme.color.mediumDarkText};
`;

export const ItalicTextSpan = styled.span`
  font-style: italic;
`;

export const GoldText = styled.span`
  color: ${({ theme }) => theme.color.gold};
`;

export const BoldGoldText = styled(BoldBlackText)`
  color: ${({ theme }) => theme.color.gold};
`;

export const DarkGoldText = styled.span`
  color: ${({ theme }) => theme.color.darkGold};
`;

export const PrimaryText = styled.span`
  color: ${({ theme }) => theme.color.primary};
`;

export const LightPrimaryText = styled.span`
  color: ${({ theme }) => theme.color.lightPrimary};
`;

const baseTextClassName = 'base-text';
const hoveredTextClassName = 'hovered';

export const BaseTextSpan = styled.span.attrs({
  className: baseTextClassName,
})``;

export const HoveredTextSpan = styled.span.attrs({
  className: hoveredTextClassName,
})``;

export const ToggleHoveredTextDisplayStyle = css`
  .${hoveredTextClassName} {
    display: none;
  }

  &:hover .${baseTextClassName} {
    display: none;
  }

  &:hover .${hoveredTextClassName} {
    display: inline;
  }
`;

export const BoldPrimaryText = styled(PrimaryText)`
  font-family: ${({ theme }) => theme.typography.logoFont};
`;

export const DisabledText = styled(BaseTypography)`
  color: ${({ theme }) => theme.color.disabledText};
`;

export const ExtraTinyText = styled(BaseTypography)`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.extraTinySize};
`;

export const LightExtraTinyText = styled(ExtraTinyText)`
  color: ${({ theme }) => theme.color.lightText};
`;

export const MediumDarkExtraTinyText = styled(ExtraTinyText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
`;

export const LightDarkExtraTinyText = styled(ExtraTinyText)`
  color: ${({ theme }) => theme.color.lightDarkText};
`;

export const StartAlignedLightDarkExtraTinyText = styled(
  LightDarkExtraTinyText,
)`
  text-align: start;
`;

export const LightPrimaryExtraTinyText = styled(ExtraTinyText)`
  color: ${({ theme }) => theme.color.lightPrimary};
`;

export const TinyText = styled(BaseTypography)`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.tinySize};
`;

export const MediumDarkTinyText = styled(TinyText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
`;

export const StartAlignedMediumDarkTinyText = styled(MediumDarkTinyText)`
  text-align: start;
`;

export const LightTinyText = styled(TinyText)`
  color: ${({ theme }) => theme.color.lightText};
  text-align: start;
`;

export const LightErrorTinyText = styled(TinyText)`
  color: ${({ theme }) => theme.color.lightError};
  text-align: start;
`;

export const SuperLightErrorTinyText = styled(TinyText)`
  color: ${({ theme }) => theme.color.superLightError};
  text-align: start;
`;

export const LightDarkTinyText = styled(TinyText)`
  color: ${({ theme }) => theme.color.lightDarkText};
  text-align: start;
`;

export const BrightGreenTinyText = styled(TinyText)`
  color: ${({ theme }) => theme.color.brightGreen};
  text-align: start;
`;

export const LightTinyLinkText = styled(LightTinyText)`
  ${UnderlineStyle}
`;

export const TinyLinkText = styled(TinyText)`
  cursor: pointer;
  color: ${({ theme }) => theme.color.primary};
  &:hover {
    ${UnderlineStyle}
  }
`;

export const WhiteTinyText = styled(TinyText)`
  color: white;
`;

export const SuccessTinyText = styled(TinyText)`
  color: ${({ theme }) => theme.color.brightGreen};
`;

export const StatusTextSpan = styled.span`
  color: ${(props) =>
    props.status === completeStatusKey
      ? props.theme.color.brightGreen
      : props.status === cancelledStatusKey || props.status === noShowStatusKey
      ? props.theme.color.error
      : props.status === scheduledStatusKey
      ? props.theme.color.primary
      : props.theme.color.mediumDarkText};
`;

export const CapitalizedTextSpan = styled.span`
  text-transform: capitalize;
`;

export const LargeTinyText = styled(BaseTypography)`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.largeTinySize};
`;

export const StartAlignedLargeTinyText = styled(LargeTinyText)`
  text-align: start;
`;

export const EndAlignedLargeTinyText = styled(StartAlignedLargeTinyText)`
  text-align: end;
`;

export const StartAlignedLargeTinyLinkText = styled(StartAlignedLargeTinyText)`
  ${UnderlineStyle}
  cursor: pointer;
`;

export const MediumDarkLargeTinyText = styled(LargeTinyText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
`;

export const MediumDarkLargeTinyLinkText = styled(MediumDarkLargeTinyText)`
  ${UnderlineStyle}
  cursor: pointer;
`;

export const DarkLargeTinyText = styled(LargeTinyText)`
  color: ${({ theme }) => theme.color.darkText};
`;

export const DisabledLargeTinyText = styled(LargeTinyText)`
  color: ${({ theme }) => theme.color.disabledText};
`;

export const ErrorLargeTinyText = styled(LargeTinyText)`
  color: ${({ theme }) => theme.color.lightError};
`;

export const StartAlignedMediumDarkLargeTinyText = styled(
  MediumDarkLargeTinyText,
)`
  text-align: start;
`;

export const StartAlignedSuccessLargeTinyText = styled(MediumDarkLargeTinyText)`
  text-align: start;
  color: ${({ theme }) => theme.color.brightGreen};
`;

export const StartAlignedErrorLargeTinyText = styled(MediumDarkLargeTinyText)`
  text-align: start;
  color: ${({ theme }) => theme.color.lightError};
`;

export const WhiteLargeTinyText = styled(LargeTinyText)`
  color: white;
`;

export const LightDarkLargeTinyText = styled(LargeTinyText)`
  color: ${({ theme }) => theme.color.lightDarkText};
`;

export const StartAlignedLightDarkLargeTinyText = styled(
  LightDarkLargeTinyText,
)`
  text-align: start;
`;

export const BrightGreenLargeTinyText = styled(LargeTinyText)`
  color: ${({ theme }) => theme.color.brightGreen};
`;

export const StartAlignedBrightGreenLargeTinyText = styled(
  BrightGreenLargeTinyText,
)`
  text-align: start;
`;

export const ExtraSmallText = styled(BaseTypography)`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.extraSmallSize};
`;

export const LightDarkExtraSmallText = styled(ExtraSmallText)`
  color: ${({ theme }) => theme.color.lightDarkText};
`;

export const LightExtraSmallText = styled(ExtraSmallText)`
  color: ${({ theme }) => theme.color.lightText};
`;

export const WhiteExtraSmallText = styled(ExtraSmallText)`
  color: white;
`;

export const ErrorExtraSmallText = styled(ExtraSmallText)`
  color: ${({ theme }) => theme.color.lightError};
`;

export const VeryLightExtraSmallText = styled(ExtraSmallText)`
  color: ${({ theme }) => theme.color.muiInput};
`;

export const StartAlignedExtraSmallText = styled(ExtraSmallText)`
  text-align: start;
`;

export const StartAlignedExtraSmallLightDarkText = styled(
  StartAlignedExtraSmallText,
)`
  color: ${({ theme }) => theme.color.lightDarkText};
`;

export const StartAlignedExtraSmallLinkText = styled(
  StartAlignedExtraSmallText,
)`
  ${UnderlineStyle}
  cursor: pointer;
`;

const StartAlignedLDEST = styled(LightDarkExtraSmallText)`
  text-align: start;
`;
export const StartAlignedLightDarkExtraSmallText = StartAlignedLDEST;

export const LightDarkExtraSmallLinkText = styled.a`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.smallSize};
  color: ${({ theme }) => theme.color.lightDarkText};
  ${UnderlineStyle}
  cursor: pointer;
`;

export const MediumDarkExtraSmallText = styled(ExtraSmallText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
`;

export const DarkExtraSmallText = styled(ExtraSmallText)`
  color: ${({ theme }) => theme.color.darkText};
`;

export const StartAlignedMediumDarkExtraSmallText = styled(
  MediumDarkExtraSmallText,
)`
  text-align: start;
`;

export const EssText = styled(BaseTypography)`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.essSize};
`;

export const StartAlignedEssText = styled(EssText)`
  text-align: start;
  margin-left: ${(props) => props.addLeftMargin && '12px'};
`;

export const StartAlignedSecondaryEssText = styled(EssText)`
  text-align: start;
  color: ${({ theme }) => theme.color.secondary};
`;

export const StartAlignedDarkEssText = styled(EssText)`
  text-align: start;
  color: ${({ theme }) => theme.color.darkText};
`;

export const MediumDarkEssText = styled(EssText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
`;

export const ClickableMediumDarkEssText = styled(MediumDarkEssText)`
  cursor: pointer;
`;

export const DarkEssText = styled(EssText)`
  color: ${({ theme }) => theme.color.darkText};
`;

export const LightESSText = styled(EssText)`
  color: ${({ theme }) => theme.color.lightText};
`;

export const LightDarkEssText = styled(EssText)`
  color: ${({ theme }) => theme.color.lightDarkText};
`;

export const SuperScriptText = styled.sup`
  color: ${({ theme }) => theme.color.darkGold} !important;
  margin-left: 10px;
  font-size: 10px;
`;

export const StartAlignedMediumDarkEssText = styled(MediumDarkEssText)`
  text-align: start;
`;

export const StartAlignedLightDarkEssText = styled(EssText)`
  text-align: start;
  color: ${({ theme }) => theme.color.lightDarkText};
`;

export const SecondaryEssText = styled(EssText)`
  color: ${({ theme }) => theme.color.secondary};
`;

export const LightPrimaryEssText = styled(EssText)`
  color: ${({ theme }) => theme.color.lightPrimary};
`;

export const WhiteEssText = styled(EssText)`
  color: white;
`;

export const SmallText = styled(BaseTypography)`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.smallSize};
`;

export const StartAlignedSmallLinkText = styled(SmallText)`
  text-align: start;
  cursor: pointer;
  ${UnderlineStyle}
`;

export const SecondarySmallText = styled(SmallText)`
  color: ${({ theme }) => theme.color.secondary};
`;

export const StartAlignedSmallText = styled(SmallText)`
  text-align: start;
`;

export const StartAlignedSecondarySmallText = styled(SecondarySmallText)`
  text-align: start;
`;

export const MediumDarkSmallText = styled(SmallText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
`;

export const SuccessSmallText = styled(SmallText)`
  color: ${({ theme }) => theme.color.brightGreen};
`;

export const ErrorSmallText = styled(SmallText)`
  color: ${({ theme }) => theme.color.lightError};
`;

export const StartAlignedMediumDarkSmallLinkText = styled(MediumDarkSmallText)`
  text-align: start;
  cursor: pointer;
  ${UnderlineStyle}
`;

export const DarkSmallText = styled(SmallText)`
  color: ${({ theme }) => theme.color.darkText};
`;

export const LightDarkSmallText = styled(SmallText)`
  color: ${({ theme }) => theme.color.lightDarkText};
`;

export const LightSmallText = styled(SmallText)`
  color: ${({ theme }) => theme.color.lightText};
`;

export const LightPrimarySmallText = styled(SmallText)`
  color: ${({ theme }) => theme.color.lightPrimary};
`;

export const StartAlignedLightDarkSmallText = styled(LightDarkSmallText)`
  text-align: start;
`;

export const StartAlignedMediumDarkSmallText = styled(MediumDarkSmallText)`
  text-align: start;
`;

export const StartAlignedDarkSmallText = styled(DarkSmallText)`
  text-align: start;
`;

export const SmallLinkText = styled.a`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.smallSize};
  ${UnderlineStyle}
  cursor: pointer;
`;

export const SMText = styled(BaseTypography)`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.smSize};
`;

export const DarkPrimarySMText = styled(SMText)`
  color: ${({ theme }) => theme.color.darkPrimary};
`;

export const SecondarySMText = styled(SMText)`
  color: ${({ theme }) => theme.color.secondary};
`;

export const MediumDarkSMText = styled(SMText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
  overflow-wrap: break-word;
`;

export const DarkSMText = styled(SMText)`
  color: ${({ theme }) => theme.color.darkText};
`;

export const WhiteSMText = styled(SMText)`
  color: white;
`;

export const LightDarkSMText = styled(SMText)`
  color: ${({ theme }) => theme.color.lightDarkText};
`;

export const LightSMText = styled(SMText)`
  color: ${({ theme }) => theme.color.lightText};
`;

export const StartAlignedDarkSMText = styled(DarkSMText)`
  text-align: start;
`;

export const StartAlignedLightDarkSMText = styled(StartAlignedDarkSMText)`
  color: ${({ theme }) => theme.color.lightDarkText};
`;

export const StartAlignedMediumDarkSMText = styled(MediumDarkSMText)`
  text-align: start;
`;

export const StartAlignedSMText = styled(SMText)`
  text-align: start;
`;

export const SMLinkText = styled.a`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.smSize};
  color: ${({ theme }) => theme.color.secondary};
  ${UnderlineStyle}
  cursor: pointer;
`;

export const MediumText = styled(BaseTypography)`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.mediumSize};
  line-height: 1.2;
`;

export const SecondaryMediumText = styled(MediumText)`
  color: ${({ theme }) => theme.color.secondary};
`;

export const MediumDarkMediumText = styled(MediumText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
`;

export const LightDarkMediumText = styled(MediumText)`
  color: ${({ theme }) => theme.color.lightDarkText};
`;

export const DarkMediumText = styled(MediumText)`
  color: ${({ theme }) => theme.color.darkText};
`;

export const WhiteMediumText = styled(MediumText)`
  color: white;
`;

export const StartAlignedMediumDarkMediumText = styled(MediumDarkMediumText)`
  text-align: start;
`;

export const StartAlignedDarkMediumText = styled(DarkMediumText)`
  text-align: start;
`;

export const StartAlignedSecondaryMediumText = styled(SecondaryMediumText)`
  text-align: start;
`;

export const StartAlignedLightDarkMediumText = styled(LightDarkMediumText)`
  text-align: start;
`;

export const MediumLinkedText = styled(MediumText)`
  color: ${({ theme }) => theme.color.secondary};
  ${UnderlineStyle}
  cursor: pointer;
`;

export const SuccessMediumText = styled(MediumText)`
  color: ${({ theme }) => theme.color.brightGreen};
`;

export const MMLText = styled(BaseTypography)`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.mmlSize};
  line-height: 1.2;
`;

export const DarkMMLText = styled(MMLText)`
  color: ${({ theme }) => theme.color.darkText};
`;

export const MLText = styled(BaseTypography)`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.mlSize};
  line-height: 1.2;
`;

export const MediumDarkMLText = styled(MLText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
`;

export const PropDrivenAlignedMediumDarkMLText = styled(MediumDarkMLText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
  text-align: ${(props) => (props.center ? 'center' : 'start')};
`;

export const DarkMLText = styled(MLText)`
  color: ${({ theme }) => theme.color.darkText};
`;

export const StartAlignedMLText = styled(MLText)`
  text-align: start;
`;

export const StartAlignedMediumDarkMLText = styled(MediumDarkMLText)`
  text-align: start;
`;

export const StartAlignedDarkMLText = styled(DarkMLText)`
  text-align: start;
`;

export const SecondaryMLText = styled(MLText)`
  color: ${({ theme }) => theme.color.secondary};
`;

export const WhiteMLText = styled(MLText)`
  color: white;
`;

export const SuccessMLText = styled(MLText)`
  color: ${({ theme }) => theme.color.brightGreen};
`;

export const PageTitleText = styled(DarkMLText)``;

export const PageSubtitleText = styled(DarkMMLText)``;

export const PageTitleBottomBorderContainer = styled.div`
  width: 100vw;
  border-bottom: 2px solid ${({ theme }) => theme.color.muiInputLightGray};
  margin-top: 5px;
`;

export const LightDarkTextSpan = styled.span`
  color: ${({ theme }) => theme.color.lightDarkText};
  line-height: ${(props) => props.smallLineHeight && '1.2'};
`;

export const LargeText = styled(BaseTypography)`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.largeSize};
  line-height: 1.2;
`;

export const SecondaryLargeText = styled(LargeText)`
  color: ${({ theme }) => theme.color.secondary};
`;

export const MediumDarkLargeText = styled(LargeText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
`;

export const StartAlignedMediumDarkLargeText = styled(MediumDarkLargeText)`
  text-align: start;
`;

export const DarkLargeText = styled(LargeText)`
  color: ${({ theme }) => theme.color.darkText};
`;

export const WhiteLargeText = styled(LargeText)`
  color: white;
`;

export const ExtraLargeText = styled(BaseTypography)`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.extraLargeSize};
  font-size: clamp(35px, 45px, 4vw);
  line-height: 1.2;
`;

export const DarkExtraLargeText = styled(ExtraLargeText)`
  color: ${({ theme }) => theme.color.darkText};
`;

export const MediumDarkExtraLargeText = styled(ExtraLargeText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
`;

export const StartAlignedDarkExtraLargeText = styled(ExtraLargeText)`
  text-align: start;
  color: ${({ theme }) => theme.color.darkText};
`;

export const MoneyExtraLargeText = styled(ExtraLargeText)`
  color: ${({ theme }) => theme.color.secondary};
`;

export const WhiteExtraLargeText = styled(ExtraLargeText)`
  color: white;
`;

export const SubHeadlineText = styled(MediumText)`
  color: ${({ theme }) => theme.color.gray};
`;

export const UnderlinedTextGradientStyle = css`
  position: relative;
  display: inline-block;
  background: linear-gradient(to left, #f69ec4, #f9dd94 100%);
  background-position: 0 100%;
  background-size: 100% 3px;
  background-repeat: repeat-x;
`;

export const UnderlinedTextGradient = styled.span`
  ${UnderlinedTextGradientStyle}
`;

export const UnderlinedTextStyle = css`
  text-decoration: underline;
  text-underline-offset: ${(props) => (props.useLargerOffset ? '5px' : '3px')};
  text-decoration-thickness: 2px;
  color: ${({ theme }) => theme.color.primaryHover};
  cursor: pointer;
`;

export const UnderlinedText = styled.span`
  ${UnderlinedTextStyle}
`;

export const BrightGreenText = styled.span`
  color: ${({ theme }) => theme.color.brightGreen};
  ${(props) =>
    props.useSmSize &&
    css`
      font-size: ${({ theme }) => theme.typography.smSize};
    `}
`;

export const OrangeText = styled.span`
  color: ${({ theme }) => theme.color.orange};
  ${(props) =>
    props.useSmSize &&
    css`
      font-size: ${({ theme }) => theme.typography.smSize};
    `}
`;

export const LightErrorText = styled.span`
  color: ${({ theme }) => theme.color.lightError};
`;

export const LightDarkLinkTextSpan = styled.span`
  color: ${({ theme }) => theme.color.lightDarkText};
  font-family: ${({ theme }) => theme.typography.baseFont};
  cursor: pointer;
  ${UnderlineStyle}
`;

export const SmallLightDarkTextSpan = styled.span`
  color: ${({ theme }) => theme.color.lightDarkText};
  font-size: ${({ theme }) => theme.typography.smallSize};
`;

export const CustomWidthDiv = styled.div`
  width: ${(props) => (props.width ? `${props.width}%` : '90%')};
`;

export const BaseWidth = css`
  // So that content aligns with header LHS and RHS
  width: calc(
    100% - ${({ theme }) => theme.spacing.pageHorizontalPadding * 2}px
  );
`;

export const BasePadding = css`
  padding: ${({ theme }) => theme.spacing.baseVerticalPadding}px 0px;
`;

export const BelowHeaderPadding = css`
  padding: ${({ theme }) => theme.spacing.belowHeaderVerticalPadding}px 0px;
`;

export const ContainerLevelHorizontalPadding = css`
  padding-left: ${({ theme }) => theme.spacing.pageHorizontalPadding}px;
  padding-right: ${({ theme }) => theme.spacing.pageHorizontalPadding}px;
`;

export const ContainerPadding = css`
  padding: ${({ theme }) => theme.spacing.baseContainerPadding}px;
`;

export const SmallContainerPadding = css`
  padding: ${({ theme }) => theme.spacing.smallContainerPadding}px;
`;

export const SMContainerPadding = css`
  padding: ${({ theme }) => theme.spacing.smContainerPadding}px;
`;

export const BaseBorderRadiusStyle = css`
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
`;

export const AutoHorizontalMargins = css`
  margin-left: auto;
  margin-right: auto;
`;

export const BaseWidthCentered = css`
  ${BaseWidth}
  ${AutoHorizontalMargins}
`;

// Makes sure page content will always start immediately below the header:
// header height + header top padding + header bottom padding
export const BelowHeaderTopMarginStyle = css`
  margin-top: ${(props) =>
    props.removeAllTopMargin
      ? `${props.theme.spacing.headerHeight - 10}px`
      : `${
          props.theme.spacing.headerHeight +
          props.theme.spacing.headerVerticalPadding * 2
        }px`};
`;

export const ExtraSmallGapStyle = css`
  gap: ${({ theme }) => theme.spacing.extraSmallGap}px;
`;

export const EssGapStyle = css`
  gap: ${({ theme }) => theme.spacing.essGap}px;
`;

export const SmallGapStyle = css`
  gap: ${({ theme }) => theme.spacing.smallGap}px;
`;

export const SMMGapStyle = css`
  gap: ${({ theme }) => theme.spacing.smmGap}px;
`;

export const SMGapStyle = css`
  gap: ${({ theme }) => theme.spacing.smGap}px;
`;

export const MediumLiteGapStyle = css`
  gap: ${({ theme }) => theme.spacing.mediumLiteGap}px;
`;

export const MediumGapStyle = css`
  gap: ${({ theme }) => theme.spacing.mediumGap}px;
`;

export const MLGapStyle = css`
  gap: ${({ theme }) => theme.spacing.mlGap}px;
`;

export const MMLGapStyle = css`
  gap: ${({ theme }) => theme.spacing.mmlGap}px;
`;

export const LargeGapStyle = css`
  gap: ${({ theme }) => theme.spacing.largeGap}px;
`;

export const ExtraLargeGapStyle = css`
  gap: ${({ theme }) => theme.spacing.extraLargeGap}px;
`;

export const GinormousGapStyle = css`
  gap: ${({ theme }) => theme.spacing.ginormousGap}px;
`;

export const DashboardIcon = styled(MuiDashboardIcon)``;

export const HQIconSize = 40;
export const HQIcon = styled(MuiDashboardIcon)`
  width: ${HQIconSize}px;
  height: ${HQIconSize}px;
  color: ${({ theme }) => theme.color.primary};
`;

export const NewLogo = styled.img.attrs({
  src: 'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/logos/liveiq-small-icon.png',
})`
  height: ${(props) => (props.large ? `55px` : `32px`)};
  margin-right: ${(props) => props.marginRight && `${props.marginRight}px`};
`;

export const SmallLogo = styled.img.attrs({
  src: '/small-logo.png',
})`
  height: ${(props) => (props.large ? `55px` : props.small ? '28px' : `35px`)};
  margin-right: ${(props) => props.marginRight && `${props.marginRight}px`};
  margin-left: ${(props) => props.marginLeft && `${props.marginLeft}px`};
  width: 55px;
  cursor: pointer;
`;

export const SmallLogoTransparent = styled(SmallLogo).attrs({
  src: 'https://storage.googleapis.com/liveiq-assets/small-logo.png',
})`
  width: unset;
`;

export const ExpandedDrawerWidthValue = 170;
// Should equal the collapsed width style value
export const CollapsedDrawerWidthValue = 77;

export const CollapsedDrawerWidthStyle = css`
  width: ${({ theme }) =>
    theme.spacing.pageHorizontalPadding + HQIconSize + theme.spacing.smGap}px;
`;

export const StandalonePageContainerStyle = css`
  margin-top: ${({ theme }) =>
    theme.spacing.headerHeight +
    theme.spacing.headerVerticalPadding * 2 +
    theme.spacing.belowHeaderVerticalPadding}px;
  max-width: 1600px;
  width: 90vw;
  padding: 0px 10px;
  ${AutoHorizontalMargins}
`;

export const ChatDrawerWidth = 300;
export const ModulesDrawerWidth = 185;
export const SubmodulesDrawerWidth = 200;
export const ServiceSubmodulesDrawerWidth = 270;
export const ClientProfileDrawerWidth = 240;
export const ClientProfileDrawerWidthWithOffset = ClientProfileDrawerWidth - 20;
export const NodeDrawerWidth = 400;
export const NodeDrawerWidthWithOffset = NodeDrawerWidth - 20;
const PageWidthOffset = 0;
const LeftMarginChatDrawerOffset = -10;

export const PageContainer = styled(MuiContainer).attrs({
  id: 'page-container',
})`
  ${BelowHeaderTopMarginStyle}
  padding: 0px;
  background-color: ${(props) =>
    props.backgroundcolor === 'primary'
      ? props.theme.color.primary
      : props.backgroundcolor === 'secondary'
      ? props.theme.color.secondary
      : props.backgroundcolor === 'white'
      ? 'white'
      : null};
  ${(props) =>
    props.hideOverflow &&
    css`
      overflow-y: hidden !important;
    `}

  --offset: ${(props) =>
    props.chatDrawerDisplayed && props.clientProfileDrawerDisplayed
      ? `${
          ChatDrawerWidth + PageWidthOffset + ClientProfileDrawerWidthWithOffset
        }px`
      : props.chatDrawerDisplayed
      ? `${ChatDrawerWidth + PageWidthOffset}px`
      : props.clientProfileDrawerDisplayed
      ? `${ClientProfileDrawerWidthWithOffset + PageWidthOffset}px`
      : `${PageWidthOffset}px`};

  margin-left: ${(props) =>
    props.drawerExpanded
      ? `${
          props.chatDrawerDisplayed
            ? ExpandedDrawerWidthValue +
              ChatDrawerWidth +
              LeftMarginChatDrawerOffset
            : props.moduleDrawerDisplayed
            ? ExpandedDrawerWidthValue
            : ExpandedDrawerWidthValue + LeftMarginChatDrawerOffset
        }px`
      : props.drawerOpen
      ? `${
          props.chatDrawerDisplayed
            ? CollapsedDrawerWidthValue +
              ChatDrawerWidth +
              LeftMarginChatDrawerOffset
            : props.moduleDrawerDisplayed
            ? CollapsedDrawerWidthValue
            : CollapsedDrawerWidthValue + LeftMarginChatDrawerOffset
        }px`
      : `${
          props.chatDrawerDisplayed
            ? ChatDrawerWidth + LeftMarginChatDrawerOffset
            : PageWidthOffset + LeftMarginChatDrawerOffset
        }px`};

  margin-right: ${(props) =>
    props.clientProfileDrawerDisplayed
      ? `${ClientProfileDrawerWidthWithOffset}px`
      : '0px'};

  &.MuiContainer-root {
    max-width: 100% !important;
  }

  position: ${(props) => props.fixed && 'fixed'};

  width: ${(props) =>
    props.drawerExpanded
      ? `calc(100vw - ${ExpandedDrawerWidthValue}px - var(--offset))`
      : props.drawerOpen
      ? `calc(100vw - ${CollapsedDrawerWidthValue}px - var(--offset))`
      : props.chatDrawerDisplayed
      ? `calc(100vw - var(--offset))`
      : null};
`;

export const ContentContainer = styled.div`
  ${ColumnFlexStyle}
  ${LargeGapStyle}  
  gap: ${(props) =>
    props.removeGap ? '0px' : `${props.theme.spacing.largeGap}px`};
  flex-grow: 1;
  ${(props) =>
    props.fullWidth
      ? css`
          max-width: 100%;
          gap: 0px;
        `
      : css`
          max-width: ${(props) =>
            !props.unbounded && 'min(calc(100% - 100px), 1450px)'};
          width: ${(props) => (props.chatDrawerDisplayed ? '100%' : '96%')};
        `}
  padding-top: ${(props) =>
    props.topPadding
      ? `${props.topPadding}px`
      : `${props.theme.spacing.belowHeaderVerticalPadding}px`};
  align-items: ${(props) => props.centerContent && 'center'};
  margin-bottom: ${(props) => props.bottomMargin && `${props.bottomMargin}px`};
  ${AutoHorizontalMargins}
  ${(props) =>
    props.hideOverflow
      ? css`
          overflow-y: hidden;
          padding-bottom: 0px;
        `
      : css`
          padding-bottom: 20px;
        `}
        ${(props) =>
    props.extraLargeBottomPadding
      ? css`
          padding-bottom: 260px;
        `
      : props.largeBottomPadding
      ? css`
          padding-bottom: 120px;
        `
      : ``}
    ${(props) =>
    props.center &&
    css`
      align-items: center;
    `}
`;

export const FixedContentContainer = styled(ContentContainer)`
  margin: unset;
  position: fixed;
`;

export const BaseButton = styled(MuiButton).attrs({
  disableRipple: true,
  variant: 'contained',
  disableElevation: true,
})`
  cursor: pointer;
  text-transform: none;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.typography.smSize};
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  min-width: ${(props) =>
    props.fixedMinWidth ? `${props.fixedMinWidth}px` : '250px'};
  ${AutoHorizontalMargins}
  padding: ${(props) => (props.useSmallPadding ? '3px 30px' : '3px 40px')};
  ${(props) =>
    props.removeMargins &&
    css`
      margin: unset;
    `}
  gap: ${(props) =>
    props.addGap &&
    css`
      display: flex;
      justify-content: space-around;
      ${EssGapStyle}
    `}
    ${(props) =>
    props.centerElements &&
    css`
      display: inline-flex;
      align-items: center;
      gap: 5px;
    `}
`;

export const PrimaryButton = styled(BaseButton)`
  font-family: ${({ theme }) => theme.typography.baseFont};
  background-color: ${({ theme }) => theme.color.primary};
  border-color: ${({ theme }) => theme.color.primary};
  color: ${(props) =>
    !props.disabled ? 'white' : props.theme.color.disabledText};
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'default')};
  &:hover {
    background-color: ${(props) =>
      !props.disabled
        ? props.theme.color.primaryHover
        : props.theme.color.disabled};
  }
  ${(props) =>
    props.removeMargins &&
    css`
      margin: unset !important;
    `}
`;

export const SecondaryButton = styled(PrimaryButton).attrs({
  variant: 'outlined',
})`
  color: ${({ theme }) => theme.color.primary};
  background-color: white;
  border-color: ${({ theme }) => theme.color.primary};
  margin-left: auto !important;
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.color.lightPrimary};
    border: 1px solid ${({ theme }) => theme.color.primaryHover};
  }
  ${(props) =>
    props.removeMargins &&
    css`
      margin: unset !important;
    `}
`;

export const ButtonTextContainer = styled.div`
  width: 100%;
  padding: 3px;
  border-radius: 10px;
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${(props) => props.theme.color.primary};
        color: white !important;
      }
    `}
`;

export const ButtonText = styled(ExtraSmallText)`
  ${(props) =>
    props.disabled
      ? css`
          color: ${(props) => props.theme.color.disabledText};
        `
      : css`
          &:hover {
            color: inherit;
          }
        `}
`;

export const ButtonInputLabel = styled(InputLabel)`
  font-family: ${({ theme }) => theme.typography.baseFont};
  font-size: 13px;
`;

export const SecondaryPrimaryButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.color.secondary};
  color: white;
  &:hover {
    background-color: ${(props) => props.theme.color.secondaryHover};
  }
`;

export const SuccessfulPrimaryButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.color.brightGreen};
  color: white;
  width: ${(props) => props.fixedMinWidth && `${props.fixedMinWidth}px`};
  ${ExtraSmallGapStyle}
  &:hover {
    background-color: ${(props) => props.theme.color.brightGreen};
  }
`;

export const SecondarySecondaryButton = styled(SecondaryButton)`
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.secondary};
  border-color: ${({ theme }) => theme.color.secondary};
  &:hover {
    background-color: ${({ theme }) => theme.color.secondary};
    color: white;
    border-color: ${({ theme }) => theme.color.secondary};
  }
`;

export const TextButton = styled(BaseButton).attrs({
  variant: 'text',
})`
  padding: 0px;
  font-family: ${({ theme }) => theme.typography.baseFont};
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.typography.smallSize};
  display: flex;
  ${CenterFlexStyle}
  &:hover {
    background-color: ${({ theme }) => theme.color.primary};
    color: white;
  }
`;

export const PrimaryTextButton = styled(TextButton)`
  color: ${({ theme }) => theme.color.primary};
  &:hover {
    background-color: ${({ theme }) => theme.color.primary};
  }
`;

const DarkBackgroundButtonStyle = css`
  background-color: transparent;
  color: white;
  &:hover {
    background-color: ${({ theme }) => theme.color.primary};
  }
`;

export const DarkBackgroundSecondaryButton = styled(SecondaryButton)`
  ${DarkBackgroundButtonStyle}
`;

const MediumButtonStyle = css`
  min-width: min(23vw, 200px);
  width: fit-content;
  font-size: 19px;
  padding: ${(props) => (props.largePadding ? '5px 30px' : '3px 20px')};
`;

export const MediumPrimaryButton = styled(PrimaryButton)`
  ${MediumButtonStyle}
`;

export const MediumSecondaryButton = styled(SecondaryButton)`
  ${MediumButtonStyle}
`;

export const MediumSuccessfulButton = styled(MediumPrimaryButton)`
  background-color: ${({ theme }) => theme.color.brightGreen};
  color: white;
  width: ${(props) => props.fixedMinWidth && `${props.fixedMinWidth}px`};
  ${ExtraSmallGapStyle}
  &:hover {
    background-color: ${(props) => props.theme.color.brightGreen};
  }
`;

const MSB = MediumSecondaryButton;

const FlexibleMediumButtonStyle = css`
  margin: 0px !important;
  min-width: ${(props) => props.fixedMinWidth && `${props.fixedMinWidth}px`};
`;

export const FlexibleMediumPrimaryButton = styled(MediumPrimaryButton)`
  ${FlexibleMediumButtonStyle}
`;
export const FlexibleMediumSecondaryButton = styled(MSB)`
  ${FlexibleMediumButtonStyle}
`;

export const MediumDarkBackgroundSecondaryButton = styled(MSB)`
  ${DarkBackgroundButtonStyle}
`;

export const MediumTextButton = styled(TextButton)`
  ${MediumButtonStyle}
  color: ${({ theme }) => theme.color.primary};
`;

const MELPB = styled(MediumPrimaryButton).attrs({
  target: '_blank',
  component: 'a',
})``;
export const MediumExternalLinkPrimaryButton = MELPB;

const SmallButtonStyle = css`
  min-width: 200px;
  margin-left: 0px !important;
  margin-right: 0px;
  font-size: 16px;
  padding: 3px 20px;
`;

export const SmallPrimaryButton = styled(PrimaryButton)`
  ${SmallButtonStyle}
`;

export const SmallSecondaryButton = styled(SecondaryButton)`
  ${SmallButtonStyle}
`;

export const SmallSuccessfulButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.color.brightGreen};
  color: white;
  width: ${(props) => props.fixedMinWidth && `${props.fixedMinWidth}px`};
  ${ExtraSmallGapStyle}
  &:hover {
    background-color: ${(props) => props.theme.color.brightGreen};
  }
  ${SmallButtonStyle}
`;

const ExtraSmallButtonStyle = css`
  min-width: 50px;
  padding: ${(props) =>
    props.largePadding
      ? '5px 30px'
      : props.mediumPadding
      ? '3px 25px'
      : props.smallPadding
      ? '3px 10px'
      : props.paddingUnset
      ? 'unset'
      : '5px 10px'};
  ${ExtraSmallGapStyle}
  position: relative;
  font-size: 15px;
`;

export const ExtraSmallPrimaryButton = styled(SmallPrimaryButton)`
  ${ExtraSmallButtonStyle}
  background-color: ${({ theme }) => theme.color.lightPrimary};
`;

export const CompleteExtraSmallPrimaryButton = styled(ExtraSmallPrimaryButton)`
  background-color: ${({ theme }) => theme.color.brightGreen};
  cursor: default;
  &:hover {
    background-color: ${({ theme }) => theme.color.brightGreen};
  }
`;

const TinyButtonStyle = css`
  height: 30px;
  width: fit-content;
  padding: ${(props) => (props.smallPadding ? `1px 10px` : `1px 30px`)};
  font-size: 14px;
`;

export const TinyPrimaryButton = styled(ExtraSmallPrimaryButton)`
  ${TinyButtonStyle}
`;

const MCIWB = MuiCheckIconWithoutBackground;
export const CompleteButtonCheckmarkIcon = styled(MCIWB)`
  ${(props) =>
    props.small &&
    css`
      ${SmallIconSize}
    `}
  color: ${(props) =>
    props.success
      ? props.theme.color.brightGreen
      : props.gold
      ? props.theme.color.gold
      : 'unset'};
`;

export const ExtraSmallSecondaryButton = styled(SmallSecondaryButton)`
  border-color: ${({ theme }) => theme.color.lightPrimary};
  ${ExtraSmallButtonStyle}
`;

const ESB = ExtraSmallSecondaryButton;

export const TinySecondaryButton = styled(ExtraSmallSecondaryButton)`
  ${TinyButtonStyle}
`;

export const ExtraSmallSecondarySecondaryButton = styled(ESB)`
  font-size: 14px;
  padding: 3px 15px;
  border-color: ${({ theme }) => theme.color.secondary};
  color: ${({ theme }) => theme.color.secondary};
  &:hover {
    background-color: ${({ theme }) => theme.color.lightSecondary};
    border-color: ${({ theme }) => theme.color.lightSecondary};
    color: white;
  }
`;

export const ExtraSmallCompleteButton = styled(ExtraSmallSecondaryButton)`
  border-color: ${({ theme }) => theme.color.brightGreen};
  color: ${({ theme }) => theme.color.brightGreen};
  font-size: 10px;
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.color.brightGreen};
    border-color: ${({ theme }) => theme.color.brightGreen};
  }
`;

export const TinyCompleteButton = styled(ExtraSmallCompleteButton)`
  ${TinyButtonStyle}
`;

export const ExtraSmallTextButton = styled(TextButton)`
  border-color: ${({ theme }) => theme.color.lightPrimary};
  font-size: ${({ theme }) => theme.typography.extraSmallSize};
  ${ExtraSmallButtonStyle}
`;

export const ExtraSmallDeleteButton = styled(SmallSecondaryButton)`
  border-color: ${({ theme }) => theme.color.lightError};
  color: ${({ theme }) => theme.color.lightError};
  ${ExtraSmallButtonStyle}
  cursor: ${(props) => (props.inactive ? 'default' : 'pointer')};
  &:hover {
    background-color: ${({ theme }) => theme.color.lightError};
    color: white;
    border-color: ${({ theme }) => theme.color.lightError};
  }
`;

export const TextLink = styled.a`
  color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.typography.baseFont};
  font-size: ${({ theme }) => theme.typography.smSize};
  ${UnderlineStyle}
  cursor: pointer;
`;

export const LinkTextSpan = styled.a`
  ${UnderlineStyle}
  cursor: pointer;
  color: inherit;
  margin-left: ${(props) => props.addLeftMargin && `5px`};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const PrimaryTextSpan = styled.span`
  color: ${({ theme }) => theme.color.primary};
`;

export const PrimaryLinkTextSpan = styled(LinkTextSpan)`
  color: ${({ theme }) => theme.color.primary};
`;

export const NoUnderlineLink = styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
`;

const AdaptableButtonStyle = css`
  height: max-content;
  min-width: 9.2em;
  padding: 3px;
  font-size: ${({ theme }) => theme.typography.extraSmallSize};
  ${(props) =>
    !props.removeMargins &&
    css`
      margin: auto;
    `};
`;

export const AdaptablePrimaryButton = styled(PrimaryButton)`
  ${AdaptableButtonStyle}
  background-color: ${(props) =>
    props.enabled ? props.theme.color.primary : props.theme.color.disabled};
  color: ${(props) =>
    props.enabled ? 'white' : props.theme.color.disabledText};
  cursor: ${(props) => (props.enabled ? 'pointer' : 'default')};
  &:hover {
    background-color: ${(props) =>
      props.enabled ? props.theme.color.darkHover : props.theme.color.disabled};
  }
`;

export const AdaptableSecondaryButton = styled(SecondaryButton)`
  ${AdaptableButtonStyle}
`;

export const AdaptableTextButton = styled(TextButton)`
  ${AdaptableButtonStyle}
`;

export const ExternalLinkPrimaryButton = styled(PrimaryButton).attrs({
  target: '_blank',
  component: 'a',
})``;

export const ExternalLinkSecondaryButton = styled(SecondaryButton).attrs({
  target: '_blank',
  component: 'a',
})``;

const LargeButtonStyle = css`
  width: 12em;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: ${(props) => (props.noMargin ? '0px' : 'auto')};
`;

export const LargePrimaryButton = styled(PrimaryButton)`
  ${LargeButtonStyle}
`;

export const LargeSecondaryButton = styled(SecondaryButton)`
  ${LargeButtonStyle}
`;

export const LargeSecondaryPrimaryButton = styled(SecondaryPrimaryButton)`
  ${LargeButtonStyle}
`;

export const LargeSecondarySecondaryButton = styled(SecondarySecondaryButton)`
  ${LargeButtonStyle}
`;

const LSB = LargeSecondaryButton;
const LELSB = styled(LSB).attrs({
  target: '_blank',
  component: 'a',
})``;
export const LargeExternalLinkSecondaryButton = LELSB;

const ExtraLargeButtonStyle = css`
  width: clamp(300px, 22em, min(600px, 96%));
  padding-left: 20px;
  padding-right: 20px;
  margin: 0px;
`;

export const ExtraLargePrimaryButton = styled(PrimaryButton)`
  ${ExtraLargeButtonStyle}
`;

export const ExtraLargeSecondaryButton = styled(SecondaryButton)`
  ${ExtraLargeButtonStyle}
`;

export const ExtraLargeTextButton = styled(TextButton)`
  ${ExtraLargeButtonStyle}
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.typography.smSize};
  &:hover {
    background-color: ${({ theme }) => theme.color.primary};
  }
`;

export const ExtraLargeSecondaryPrimaryButton = styled(SecondaryPrimaryButton)`
  ${ExtraLargeButtonStyle}
`;

const ELPB = ExtraLargePrimaryButton;
export const ExtraLargeExternalLinkPrimaryButton = styled(ELPB).attrs({
  target: '_blank',
  component: 'a',
})``;

const ELSB = ExtraLargeSecondaryButton;
const ELELSB = styled(ELSB).attrs({
  target: '_blank',
  component: 'a',
})``;
export const ExtraLargeExternalLinkSecondaryButton = ELELSB;

export const ColumnButtonContainer = styled.div`
  ${ColumnFlexStyle}
  ${SMGapStyle}
  align-items: center;
`;

export const RelativeDiv = styled.div`
  position: relative;
`;

export const IconContainerStyle = css`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;

const IconContainerMargin = 3;

export const IconContainerRight = styled.div`
  ${IconContainerStyle}
  margin-left: ${IconContainerMargin}px;
`;

export const IconContainerLeft = styled(IconContainerRight)`
  ${IconContainerStyle}
  margin-right: ${IconContainerMargin}px;
`;

export const LargeTinyIconSize = css`
  height: 12px;
  width: 12px;
`;

export const ExtraSmallIconSize = css`
  height: 14px;
  width: 14px;
`;

export const EssIconSize = css`
  height: 16px;
  width: 16px;
`;

export const SmallIconSize = css`
  height: 18px;
  width: 18px;
`;

export const SMMIconSize = css`
  height: 22px;
  width: 22px;
`;

export const SMMMIconSize = css`
  height: 24px;
  width: 24px;
`;

export const SMIconSize = css`
  height: 26px;
  width: 26px;
`;

export const MediumLiteIconSize = css`
  height: 28px;
  width: 28px;
`;

export const MediumIconSize = css`
  height: 37px;
  width: 37px;
`;

export const MMLIconSize = css`
  height: 42px;
  width: 42px;
`;

export const MLIconSize = css`
  height: 48px;
  width: 48px;
`;

export const LargeIconSize = css`
  height: 60px;
  width: 60px;
`;

export const ELLIconSize = css`
  height: 70px;
  width: 70px;
`;

export const ExtraLargeIconSize = css`
  height: 80px;
  width: 80px;
`;

export const RemoveIcon = styled(RemoveCircleIcon)`
  color: ${({ theme }) => theme.color.error};
  ${SMMIconSize}
  cursor: pointer;
`;

export const SecondaryRemoveIcon = styled(RemoveIcon)`
  color: ${({ theme }) => theme.color.lightError};
  ${SmallIconSize}
  &:hover {
    color: ${({ theme }) => theme.color.error};
  }
`;

export const DeleteIcon = styled(MuiDeleteIcon)`
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.superLightError : theme.color.lightError};
  ${SMMIconSize}
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const EditIcon = styled(MuiEditIcon)`
  color: ${({ theme }) => theme.color.lightPrimary};
  ${SMMIconSize}
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const MediumEditIcon = styled(EditIcon)`
  ${EssIconSize}
  color: ${(props) =>
    props.color === 'darkGray'
      ? props.theme.color.muiInputDarkGray
      : props.color === 'primary'
      ? props.theme.color.primary
      : props.color === 'lightPrimary'
      ? props.theme.color.lightPrimary
      : 'inherit'};
`;

export const SmallEditIconContainer = styled.div`
  ${CenterFlexStyle}
  border-radius: 50%;
  ${SmallIconSize}
  border: 1px solid ${({ theme }) => theme.color.lightPrimary};
  cursor: pointer;
  color: ${({ theme }) => theme.color.lightPrimary};
  background-color: white;
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.color.primary};
  }
`;

export const SmallEditIcon = styled(EditIcon)`
  ${ExtraSmallIconSize}
  color: inherit;
  &:hover {
    color: inherit;
  }
`;

export const PrimaryEditIcon = styled(EditIcon)`
  color: ${({ theme }) => theme.color.primary};
`;

export const SmallPrimaryEditIcon = styled(EditIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${SmallIconSize}
  cursor: pointer;
`;

export const TitleEditIconContainer = styled.div`
  border-radius: 50%;
  cursor: pointer;
  ${CenterFlexStyle}
  padding: 3px;
  ${SmallIconSize}
  background-color: ${({ theme }) => theme.color.lightPrimary};
  color: white;
  &:hover {
    background-color: ${({ theme }) => theme.color.primary};
  }
`;

export const TitleEditIcon = styled(MuiEditIcon)`
  color: unset;
  ${EssIconSize}
  cursor: pointer;
`;

export const ListEditIcon = styled(EditIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${SmallIconSize}
  cursor: pointer;
`;

export const LightPrimaryListEditIcon = styled(ListEditIcon)`
  color: ${({ theme }) => theme.color.lightPrimary};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const DeleteButtonContainer = styled.div`
  position: absolute;
  top: -5px;
  right: -6px;
  background-color: ${({ theme }) => theme.color.lightError};
  border-radius: 50%;
  height: 16px;
  width: 16px;
  ${CenterFlexStyle}
  color: white;
  &:hover {
    color: ${({ theme }) => theme.color.muiInput};
  }
`;

export const XIcon = styled(MuiCloseIcon)`
  color: ${({ theme }) => theme.color.error};
  ${SMMIconSize}
  cursor: pointer;
`;

export const ResetIcon = styled(XIcon)`
  color: unset;
  ${(props) =>
    props.small
      ? css`
          ${ExtraSmallIconSize}
        `
      : css`
          ${SmallIconSize}
        `}
  margin-left: ${(props) => props.leftMargin && `${props.leftMargin}px`};
`;

export const ClearInputIcon = styled(BackspaceIcon)`
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.color.muiInput};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.superLightError};
  }
`;

export const FilterIcon = styled(FilterAltIcon)`
  ${SmallIconSize}
`;

export const OutlinedFilterIcon = styled(FilterAltOutlinedIcon)`
  ${SmallIconSize}
`;

export const SortIcon = styled(MuiSortIcon)`
  ${SmallIconSize}
`;

export const LockIcon = styled(MuiLockIcon)`
  color: ${({ theme }) => theme.color.muiInputDarkGray};
  ${MediumIconSize}
  cursor: pointer;
`;

export const CheckIcon = styled(CheckBoxIcon)`
  color: ${({ theme }) => theme.color.brightGreen};
  ${MediumIconSize}
  cursor: pointer;
`;

export const ListCheckIcon = styled(CheckCircleIcon)`
  ${SmallIconSize}
  cursor: pointer;
  color: ${({ theme }) => theme.color.brightGreen};
`;

export const TrackProgressIcon = styled(StackedLineChartIcon)`
  ${SMMIconSize}
`;

export const SaveIcon = styled(MuiSaveIcon)`
  ${SMMIconSize}
  cursor: pointer;
`;

export const SmallSaveIconContainer = styled(SmallEditIconContainer)`
  color: ${({ theme }) => theme.color.brightGreen};
  border-color: ${({ theme }) => theme.color.brightGreen};
  padding: 1px;
  background-color: white;
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.color.brightGreen};
  }
`;

export const SmallSaveIcon = styled(CompleteButtonCheckmarkIcon)`
  ${EssIconSize}
  color: inherit;
  &:hover {
    color: inherit;
  }
`;

export const StartIcon = styled(MuiPlayIcon)``;

export const StopIcon = styled(MuiStopIcon)``;

export const BookmarkIcon = styled(BookmarkAddIcon)`
  cursor: pointer;
  ${(props) =>
    props.small
      ? css`
          height: 20px;
          width: 20px;
        `
      : css`
          ${SMMMIconSize}
        `}
  ${(props) =>
    props.disabled
      ? css`
          color: ${({ theme }) => theme.color.muiInput};
        `
      : css`
          color: ${({ theme }) => theme.color.primary};
          cursor: pointer;
        `}
`;

export const EmptyBookmarkIcon = styled(BookmarkEmptyIcon)`
  cursor: pointer;
  ${(props) =>
    props.small
      ? css`
          height: 20px;
          width: 20px;
        `
      : css`
          ${SMMMIconSize}
        `}
  ${(props) =>
    props.disabled
      ? css`
          color: ${({ theme }) => theme.color.muiInput};
        `
      : css`
          color: ${({ theme }) => theme.color.primary};
          cursor: pointer;
        `}
`;

export const VerifiedIcon = styled(MuiVerifiedIcon)`
  color: ${({ theme }) => theme.color.primaryHover};
  ${SMIconSize}
  cursor: pointer;
`;

export const UncheckedIcon = styled(CheckBoxOutlineBlankIcon)`
  color: ${({ theme }) => theme.color.brightGreen};
  ${MediumIconSize}
  cursor: pointer;
`;

export const CheckIconWithoutBackground = styled(MuiCheckIconWithoutBackground)`
  ${MediumIconSize}
`;

export const InProgressIcon = styled(HourglassTopIcon)`
  ${MediumIconSize}
  color: ${({ theme }) => theme.color.primary};
`;

export const ToDoIcon = styled(PriorityHighIcon)``;

export const CheckedRadioButton = styled(RadioButtonCheckedIcon)`
  ${ExtraSmallIconSize}
  cursor: pointer;
  color: ${({ theme }) => theme.color.primary};
  margin-top: 1px;
`;

export const UncheckedRadioButton = styled(RadioButtonUncheckedIcon)`
  ${ExtraSmallIconSize}
  cursor: pointer;
  margin-top: 1px;
`;

const MenuItemRadioIconStyle = css``;

export const MenuItemCheckedRadioButton = styled(CheckedRadioButton)`
  ${MenuItemRadioIconStyle}
`;

export const MenuItemUncheckedRadioButton = styled(UncheckedRadioButton)`
  ${MenuItemRadioIconStyle}
`;

export const MoreMenuIcon = styled(MoreHorizIcon)`
  color: ${({ theme }) => theme.color.lightPrimary};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const BrainIcon = styled(BiSolidBrain)``;

export const PolicyIcon = styled(MuiPolicyIcon)``;

export const ChatIcon = styled(MuiChatIcon)``;

export const NoDataIcon = styled(SearchOffIcon)`
  ${ExtraLargeIconSize}
  color: ${({ theme }) => theme.color.lightPrimary};
`;

export const LargeNoDataIcon = styled(NoDataIcon)`
  ${LargeIconSize}
`;

export const MediumNoDataIcon = styled(NoDataIcon)`
  ${MediumIconSize}
`;

export const ReleaseIcon = styled(OutboxIcon)`
  cursor: pointer;
`;

export const ClinicIcon = styled(StoreIcon)`
  ${MediumIconSize}
  color: ${({ theme }) => theme.color.offwhite};
`;

export const IntegrationsIcon = styled(CloudUploadIcon)``;

export const AddLocationIconContainer = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  height: 80px;
  width: 80px;
  border-radius: 50%;
  ${CenterFlexStyle}
`;

export const AddLocationIcon = styled(AddBusinessIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${MLIconSize}
`;

export const PeopleIcon = styled(PeopleAltIcon)`
  color: ${({ theme }) => theme.color.secondary};
`;

export const CancelIcon = styled(MuiCancelIcon)`
  color: white !important;
  ${SMIconSize}
  cursor: pointer;
`;

export const RedCancelIcon = styled(CancelIcon)`
  ${(props) =>
    props.extraSmall
      ? css`
          ${ExtraSmallIconSize}
        `
      : props.smm
      ? css`
          ${SMMIconSize}
        `
      : css`
          ${SmallIconSize}
        `}
  color: ${({ theme }) => theme.color.superLightError} !important;
  &:hover {
    color: ${({ theme }) => theme.color.lightError} !important;
  }
`;

export const NotApplicableIcon = styled(MdDoNotDisturbAlt)``;

export const GroupIcon = styled(LiaObjectUngroup)`
  color: ${({ theme }) => theme.color.primary};
  ${SmallIconSize}
  cursor: pointer;
`;

export const ChipCancelIcon = styled(CancelIcon)`
  ${EssIconSize}
`;

export const AddIcon = styled(MuiAddIcon)`
  ${SMIconSize}
  color: ${(props) =>
    props.lightPrimary
      ? props.theme.color.lightPrimary
      : props.green
      ? props.theme.color.lightGreen
      : 'inherit'};
  cursor: ${(props) => props.pointer && 'pointer'};
  margin-left: ${(props) => props.leftMargin && `${props.leftMargin}px`};
`;

export const OpenLinkIcon = styled(OpenInNewIcon)`
  ${SMIconSize}
`;

export const HealthCheckIcon = styled(SpeedIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${LargeIconSize}
`;

export const BenchmarksIcon = styled(QueryStatsIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${ELLIconSize}
`;

export const KeyIcon = styled(VpnKeyIcon)`
  ${LargeIconSize}
  color: ${({ theme }) => theme.color.primary};
`;

export const InfoTextIcon = styled(HelpIcon)`
  ${SMMIconSize}
  color: ${({ theme }) => theme.color.lightSecondaryAccent};
  cursor: pointer;
  vertical-align: middle;
`;

export const CapitalIInfoTextIcon = styled(InfoIcon)`
  ${(props) =>
    props.small
      ? css`
          ${SmallIconSize}
        `
      : css`
          ${SMMIconSize}
        `}
  color: ${({ theme }) => theme.color.lightPrimary};
  cursor: pointer;
  vertical-align: -2px;
`;

export const ChecklistIcon = styled(MuiChecklistIcon)`
  ${ExtraLargeIconSize}
  color: ${({ theme }) => theme.color.secondary};
`;

export const CopyIcon = styled(MuiCopyIcon)`
  cursor: pointer;
  margin-left: ${(props) => props.leftMargin && `${props.leftMargin}px`};
`;

export const PrimaryCopyIcon = styled(CopyIcon)`
  color: ${({ theme }) => theme.color.primary};
`;

export const ModelTrainingIconContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const ModelTrainingIcon = styled(IoCloudUpload)`
  color: ${({ theme }) => theme.color.primary};
  ${SMMMIconSize}
  margin-top: 7px;
`;

export const ModelTrainedIcon = styled(CloudDoneIcon)`
  color: ${({ theme }) => theme.color.brightGreen};
  ${SMMMIconSize}
  margin-top: 7px;
`;

export const ChangesUnsavedIcon = styled(MdNewReleases)`
  color: ${({ theme }) => theme.color.lightOrange};
  position: absolute;
  right: -4px;
  top: 4px;
  height: 14px;
  width: 14px;
`;

export const ErrorSavingIcon = styled(FaExclamationCircle)`
  color: ${({ theme }) => theme.color.lightError};
  position: absolute;
  right: -3px;
  top: 5px;
  height: 12px;
  width: 12px;
`;

export const UninitializedIcon = styled(ErrorSavingIcon)`
  color: ${({ theme }) => theme.color.muiInput};
`;

export const SyncInProgressIcon = styled(IoSyncCircle)`
  color: ${({ theme }) => theme.color.secondary};
  position: absolute;
  right: -5px;
  top: 4px;
  height: 15px;
  width: 15px;
`;

export const CompleteIcon = styled(IoIosCheckmarkCircle)`
  color: ${({ theme }) => theme.color.brightGreen};
  position: absolute;
  right: -5px;
  top: 4px;
  height: 15px;
  width: 15px;
`;

export const SmallCopyIcon = styled(CopyIcon)`
  ${SmallIconSize}
`;

export const SmallPrimaryCopyIcon = styled(SmallCopyIcon)`
  color: ${({ theme }) => theme.color.primary};
`;

export const ExtraSmallPrimaryCopyIcon = styled(SmallCopyIcon)`
  ${EssIconSize}
  color: ${({ theme }) => theme.color.primary};
`;

export const ExtraSmallLightPrimaryCopyIcon = styled(ExtraSmallPrimaryCopyIcon)`
  color: ${({ theme }) => theme.color.lightPrimary};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const CopyIdIcon = styled(CopyIcon)`
  ${ExtraSmallIconSize}
  color: ${({ theme }) => theme.color.muiInputDarkGray};
  margin-left: ${(props) =>
    props.addLeftMargin ? '2px' : props.addLargeLeftMargin ? '10px' : '-7px'};
  margin-top: ${(props) => props.addTopMargin && '2px'};
  &:hover {
    color: ${({ theme }) => theme.color.darkGray};
  }
`;

export const EssCopyIcon = styled(CopyIcon)`
  ${EssIconSize}
`;

export const ShareLinkIcon = styled(InsertLinkIcon)`
  ${SMIconSize}
  margin-top: -1px;
  transform: rotate(90deg);
  margin-right: -2px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.lightPrimary};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const ClientIconContainer = styled.div`
  ${CenterFlexStyle}
  border: 1px solid ${({ theme }) => theme.color.muiInput};
  border-radius: 50%;
  background-color: white;
  color: ${({ theme }) => theme.color.lightPrimary};
  cursor: pointer;
  padding: 3px;
  &:hover {
    background-color: ${({ theme }) => theme.color.lightPrimary};
    color: white;
  }
`;

export const ClientIcon = styled(SensorOccupiedIcon)``;

export const ClientIconButton = styled(ClientIcon)`
  background-color: white;
  color: ${({ theme }) => theme.color.lightPrimary};
  ${SMMIconSize}
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
  ${(props) =>
    props.selected &&
    css`
      color: ${({ theme }) => theme.color.primary};
    `}
`;

export const FixedWidthCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) =>
    props.fullWidth ? 'unset' : props.fixedWidth || '170px'};
`;

export const Checkbox = styled(MuiCheckbox)`
  padding: ${(props) => (props.removePadding ? '0px' : '0px 10px')};
  .MuiSvgIcon-root {
    ${SMIconSize}
  }
`;

export const CheckboxPlaceholder = styled.div`
  padding: ${(props) => (props.removePadding ? '0px' : '0px 10px')};
  ${SMIconSize}
`;

export const SmallCheckbox = styled(Checkbox)`
  .MuiSvgIcon-root {
    ${SmallIconSize}
  }
  ${(props) =>
    props.darkGray
      ? css`
          color: ${({ theme }) => theme.color.muiInputDarkGray} !important;
          &:hover {
            color: ${({ theme }) => theme.color.muiInputDarkGray} !important;
          }
        `
      : css`
          color: ${({ theme }) => theme.color.lightPrimary} !important;
          &:hover {
            color: ${({ theme }) => theme.color.primary} !important;
          }
        `}
  padding: ${(props) => props.removePadding && '0px'};
`;

export const SMCheckbox = styled(SmallCheckbox)`
  .MuiSvgIcon-root {
    height: 20px;
    width: 20px;
  }
  margin-right: ${(props) =>
    props.largeNegativeRightMargin ? '-12px' : '-6px'};
  margin-top: ${(props) => props.topMargin != null && `${props.topMargin}px`};
`;

export const ExtraSmallCheckbox = styled(SmallCheckbox)`
  .MuiSvgIcon-root {
    ${ExtraSmallIconSize}
  }
  margin-right: ${(props) =>
    props.largeNegativeRightMargin ? '-12px' : '-6px'};
  margin-top: ${(props) => props.topMargin != null && `${props.topMargin}px`};
`;

export const RadioButton = styled(MuiRadio)`
  .MuiSvgIcon-root {
    ${(props) =>
      props.small
        ? css`
            ${ExtraSmallIconSize}
          `
        : `
    ${SmallIconSize}
    `}
  }
  color: ${({ theme }) => theme.color.lightPrimary} !important;
  &:hover {
    color: ${({ theme }) => theme.color.primary} !important;
  }
`;

export const EmptySMIconDiv = styled.div`
  ${SMIconSize}
`;

export const AdornmentContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const AdornmentIconStyle = css`
  color: ${({ theme }) => theme.color.primary};
  ${(props) =>
    props.small
      ? css`
          ${SMMIconSize}
        `
      : css`
          ${SMIconSize}
        `}
`;

export const DollarIcon = styled(AttachMoneyIcon)`
  ${AdornmentIconStyle}
`;

export const OutlinedDollarIcon = styled(MonetizationOnIcon)``;

export const PercentIcon = styled(MuiPercentIcon)`
  ${AdornmentIconStyle}
`;

export const ExclamationIcon = styled(PriorityHighIcon)`
  ${MLIconSize}
  color: ${({ theme }) => theme.color.primary};
`;

export const ErrorTextExclamationIcon = styled(ExclamationIcon)`
  ${SmallIconSize}
  color: ${({ theme }) => theme.color.lightError};
`;

export const HamburgerMenu = styled(MuiMenuIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${MediumIconSize}
  cursor: pointer;
`;

const MenuActionStyle = css`
  height: 23px;
  width: 23px;
  margin-right: 3px;
  color: ${({ theme }) => theme.color.muiInputDarkGray};
  cursor: pointer;
`;

export const ExpandMenuIcon = styled(RiMenuUnfoldFill)`
  ${MenuActionStyle}
`;

export const CollapseMenuIcon = styled(RiMenuFoldFill)`
  ${MenuActionStyle}
`;

export const CatalogIcon = styled(FolderSpecialIcon)``;

export const OverviewIcon = styled(MdOutlineControlCamera)``;

export const MoneyTransferIcon = styled(FaMoneyBillTransfer)``;

export const MarketingIcon = styled(FaBullhorn)``;

export const AppointmentWithNoteIcon = styled(EventNoteIcon)`
  ${SMMIconSize}
  color: ${({ theme }) => theme.color.brightGreen};
  opacity: 0.8;
`;

export const FlexDiv = styled.div`
  display: flex;
  ${EssGapStyle}
  margin-top: ${(props) => props.topMargin && `${props.topMargin}px`};
  margin-bottom: ${(props) => props.bottomMargin && `${props.bottomMargin}px`};
`;

export const SMGapFlexDiv = styled(FlexDiv)`
  ${SMGapStyle}
`;

export const MLGapFlexDiv = styled(FlexDiv)`
  ${MLGapStyle}
`;

export const LargeGapFlexDiv = styled(FlexDiv)`
  ${LargeGapStyle}
`;

export const ExtraSmallGapFlexDiv = styled(FlexDiv)`
  ${ExtraSmallGapStyle}
`;

export const EmptyGapFlexDiv = styled(FlexDiv)`
  gap: 0px;
`;

export const StartAlignedFlexDiv = styled(FlexDiv)`
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  ${(props) =>
    props.addGap
      ? css`
          ${SmallGapStyle}
        `
      : props.largerGap
      ? css`
          ${SMGapStyle}
        `
      : props.lowerGap
      ? `
        gap: 4px;
      `
      : props.removeGap
      ? `
        gap: 0px;
      `
      : 'inherit'}
`;

export const ColumnDiv = styled.div`
  ${ColumnFlexStyle}
`;

export const ColumnDivWithGap = styled(ColumnDiv)`
  ${ExtraSmallGapStyle}
`;

export const ColumnDivWithSmallGap = styled(ColumnDiv)`
  ${SmallGapStyle}
  width: 100%;
`;

export const CenteredDiv = styled.div`
  ${CenterFlexStyle}
  position: ${(props) => props.relative && 'relative'};
  margin-top: ${(props) => props.topMargin && `${props.topMargin}px`};
  margin-bottom: ${(props) => props.bottomMargin && `${props.bottomMargin}px`};
`;

export const FullWidthCenteredDiv = styled(CenteredDiv)`
  width: 100%;
`;

export const FullWidthStartAlignedDiv = styled(FullWidthCenteredDiv)`
  justify-content: flex-start;
`;

export const FullWidthEndAlignedDiv = styled(FullWidthCenteredDiv)`
  justify-content: flex-end;
`;

export const SpacedDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.width ? props.width : '100%')};
`;

export const DynamicPercentageWidthDiv = styled.div`
  width: ${(props) => `${props.width}%`};
`;

export const FixedWidthDiv = styled.div`
  width: ${(props) => `${props.width}px`};
  margin: ${(props) => props.margin && `${props.margin}`};
`;

export const FixedWidthCenteredDiv = styled(FixedWidthDiv)`
  ${CenterFlexStyle}
`;

export const FullWidthCenteredDivWithGap = styled(FullWidthCenteredDiv)`
  ${SmallGapStyle}
  position: ${(props) => props.relativePosition && 'relative'};
`;

export const FullHeightCenteredDiv = styled(CenteredDiv)`
  height: 100%;
`;

export const FullSizeCenteredDiv = styled(FullWidthCenteredDiv)`
  height: 100%;
`;

export const FullSizeColumnCenteredDiv = styled(FullSizeCenteredDiv)`
  flex-direction: column;
`;

const FSCCD = FullSizeColumnCenteredDiv;
export const FullSizeColumnCenteredDivWithGap = styled(FSCCD)`
  ${SMGapStyle}
  margin-top: ${(props) => props.topMargin && `${props.topMargin}px`};
`;

export const CenteredDivWithGap = styled(CenteredDiv)`
  ${SmallGapStyle}
  margin-top: ${(props) => props.topMargin && `${props.topMargin}px`};
  margin-bottom: ${(props) => props.bottomMargin && `${props.bottomMargin}px`};
`;

export const CenteredDivWithExtraSmallGap = styled(CenteredDiv)`
  ${ExtraSmallGapStyle}
`;

export const CenteredDivWithLargerGap = styled(CenteredDivWithGap)`
  ${MediumGapStyle}
`;

export const ColumnCenteredDiv = styled(CenteredDiv)`
  flex-direction: column;
  ${SmallGapStyle}
  margin-top: ${(props) => props.topMargin && `${props.topMargin}px`};
  margin-bottom: ${(props) => props.bottomMargin && `${props.bottomMargin}px`};
  margin-right: ${(props) => props.rightMargin && `${props.rightMargin}px`};
  margin-left: ${(props) => props.leftMargin && `${props.leftMargin}px`};
`;

export const PageHeaderContainer = styled(ColumnCenteredDiv)`
  overflow-y: hidden;
  padding-bottom: 0px;
  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
      background-color: white;
      z-index: 10;
      padding-top: ${(props) =>
        props.theme.spacing.belowHeaderVerticalPadding}px;
    `}
`;

export const ColumnStartAlignedDiv = styled(CenteredDiv)`
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${(props) => props.topMargin && `${props.topMargin}px`};
  padding: 0px 10px;
`;

export const FullWidthColumnCenteredDiv = styled(ColumnCenteredDiv)`
  width: 100%;
`;

const MGFWCCD = styled(FullWidthColumnCenteredDiv)`
  ${MediumGapStyle}
`;

export const MediumGapFullWidthColumnCenteredDiv = MGFWCCD;

export const FullWidthStartAlignedColumnDiv = styled(ColumnCenteredDiv)`
  width: 100%;
  align-items: flex-start;
`;

export const TinyGapColumnCenteredDiv = styled(ColumnCenteredDiv)`
  ${ExtraSmallGapStyle}
`;

export const ExtraTinyGapColumnCenteredDiv = styled(ColumnCenteredDiv)`
  gap: 2px;
`;

export const SMGapColumnCenteredDiv = styled(ColumnCenteredDiv)`
  ${SMGapStyle}
`;

export const MediumGapColumnCenteredDiv = styled(ColumnCenteredDiv)`
  ${MediumGapStyle}
`;

export const MLGapColumnCenteredDiv = styled(ColumnCenteredDiv)`
  ${MLGapStyle}
`;

export const LargeGapColumnCenteredDiv = styled(ColumnCenteredDiv)`
  ${LargeGapStyle}
`;

export const EmptyGapColumnCenteredDiv = styled(ColumnCenteredDiv)`
  gap: 0px;
`;

const EMGCCD = EmptyGapColumnCenteredDiv;
export const FullSizeEmptyGapColumnCenteredDiv = styled(EMGCCD)`
  width: 100%;
  height: 100%;
`;

export const EndAlignedFlexDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: ${(props) => (props.gap ? `${props.gap}px` : 'unset')};
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${props.theme.color.muiInput};
      border-radius: 5px;
      padding: 2px 10px;
      width: fit-content;
      cursor: pointer;
      margin-left: 3px;
    `}
`;

export const TextInputWidthStyle = css`
  width: clamp(330px, 45vw, 700px);
`;

export const EndAlignedColumnDiv = styled(EmptyGapColumnCenteredDiv)`
  align-items: flex-end;
`;

export const SpaceBetweenFlexDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const DividerStyle = css`
  border-width: 2px;
  border-top: 0px;
  border-left: 0px;
  border-color: ${({ theme }) => theme.color.muiInput};
`;

export const HorizontalSectionMenuDivider = styled.hr`
  ${DividerStyle}
  margin: 0px;
  border-width: 1px;
`;

export const VerticalSectionMenuDivider = styled.hr`
  border-width: 2px;
  border-top: 0px;
  border-left: 0px;
  border-color: ${({ theme }) => theme.color.lightPrimary};
  margin: 0px 5px;
  width: 100%;
  ${AutoHorizontalMargins}
`;

export const ClickableDiv = styled.div`
  cursor: pointer;
`;

export const LargeInputHeight = 68;

const InputStyleHeight = css`
  ${(props) =>
    props.fixedHeight
      ? css`
          height: ${props.fixedHeight}px;
          padding-top: unset;
        `
      : props.noFixedHeight
      ? css`
          height: unset;
        `
      : !props.fitContentHeight
      ? css`
          height: 62px;
        `
      : css`
          min-height: ${LargeInputHeight}px;
          height: fit-content;
        `};
`;

export const InputButton = styled(ExtraSmallSecondaryButton)`
  ${InputStyleHeight}
`;

export const InputStyle = css`
  .MuiOutlinedInput-input {
    font-family: ${({ theme }) => theme.typography.baseFont};
    font-size: ${(props) =>
      props.useExtraSmallText
        ? '15px'
        : props.useSmallText
        ? '17px'
        : props.useMediumText
        ? props.theme.typography.essSize
        : props.theme.typography.essSize};
    // If the dropdown needs to fit content, we assume it might expand --> add more padding
    padding: ${(props) =>
      `${props.fitContentHeight} ? '20px' : '10px' ${props.theme.spacing.inputVerticalPadding}px`};
    // So that the text doesn't ram up against the adornment
    padding-left: ${(props) =>
      props.expanded ? '5px' : props.smallLeftPadding ? '10px' : '20px'};
    ${(props) =>
      props.multiline &&
      css`
        margin-top: ${!!props.label && props.rows < 3
          ? `15px`
          : !!props.label
          ? `-10px`
          : props.rows === 2
          ? '10px'
          : props.rows === 3
          ? '-2px'
          : props.rows === 4
          ? `-10px`
          : props.rows === 5
          ? `-20px`
          : props.rows === 6
          ? `-30px`
          : `-40px`};
      `}
  }
  .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.spacing.inputBorderRadius}px;
    font-size: ${(props) =>
      props.useExtraSmallText
        ? props.theme.typography.essSize
        : props.theme.typography.smSize};
    ${InputStyleHeight}
    padding-right: 9px;
    ${(props) =>
      props.multiline &&
      css`
        padding-top: ${props.rows < 3 ? '5px' : '25px'};
      `}
  }
  .MuiInputLabel-root {
    font-family: ${({ theme }) => theme.typography.baseFont};
    font-size: ${(props) =>
      props.useExtraSmallText
        ? props.theme.typography.largeTinySize
        : props.theme.typography.extraSmallSize};
    color: ${(props) =>
      props.containsError
        ? props.theme.color.error
        : props.theme.color.muiInputDarkGray};
    // This has to be large so that the input label text is centered
    height: 100%;
    display: flex;
    ${(props) =>
      props.multiline
        ? css`
            align-items: flex-start;
          `
        : css`
            // Have to increase margin if helper text present
            margin-top: -${(props) => props.theme.spacing.inputVerticalPadding}px;
            align-items: center;
          `}
  }
  .MuiFormHelperText-root {
    font-family: ${({ theme }) => theme.typography.baseFont};
    font-size: ${({ theme }) => theme.typography.tinySize};
    color: ${({ theme }) => theme.color.error};
    position: absolute;
    bottom: -25px;
  }
  // Changing the floating label text when shrunk
  .MuiInputLabel-shrink {
    font-size: ${(props) =>
      props.useExtraSmallText
        ? props.theme.typography.essSize
        : props.theme.typography.inputLabelSMSize} !important;
    // So that there's more space on the right
    margin-left: 0px;
    padding-bottom: 2px;
    // This has to be large enough to keep the label text centered, but small enough to not block the tap target for the actual text below it
    ${(props) =>
      props.multiline
        ? css`
            height: 30px;
          `
        : css`
            height: 70px;
          `}
  }
  // Changing the floating label text when focused
  .MuiInputLabel-root.Mui-focused {
    padding-right: 8px;
  }

  // To make sure fields dont expand to take up a disproportionate amount of space on the page
  flex-grow: 0;
`;

export const SmallerTextInputWidthStyle = css`
  width: clamp(250px, 45vw, 530px);
`;

export const ExtraSmallTextInputWidthStyle = css`
  width: ${(props) =>
    props.fixedWidth ? `${props.fixedWidth}px` : 'clamp(200px, 40vw, 300px)'};
  margin-left: ${(props) => props.leftMargin && `${props.leftMargin}px`};
  margin-right: ${(props) => props.rightMargin && `${props.rightMargin}px`};
`;

const ExtraSmallTextInputHeightStyle = css`
  .MuiOutlinedInput-root {
    height: ${(props) =>
      props.fixedHeight ? `${props.fixedHeight}px` : '40px'} !important;
    margin-top: ${(props) => props.topMargin && `${props.topMargin}px`};
    margin-bottom: ${(props) =>
      props.bottomMargin && `${props.bottomMargin}px`};
  }
`;

export const TextInput = styled(TextField).attrs({
  inputProps: {
    textAlign: 'center',
  },
})`
  ${InputStyle}
  ${(props) =>
    !props.removeAutoMargins &&
    css`
      ${AutoHorizontalMargins}
    `};
  height: unset;
  ${(props) =>
    props.useFullWidth
      ? css`
          width: 100%;
          margin: 0px;
        `
      : props.useExtraSmallWidth
      ? css`
          ${ExtraSmallTextInputWidthStyle}
        `
      : props.useSmallWidth
      ? css`
          ${SmallerTextInputWidthStyle}
        `
      : css`
          ${TextInputWidthStyle}
        `};
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.containsError
        ? props.theme.color.error
        : props.theme.color.muiInput};
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.containsError
        ? props.theme.color.error
        : props.theme.color.muiInput};
  }
`;

export const ExtraSmallTextInput = styled(TextInput).attrs({
  useSmallText: true,
})`
  ${ExtraSmallTextInputHeightStyle}
  ${ExtraSmallTextInputWidthStyle}
  margin: ${(props) => !props.leftMargin && !props.rightMargin && `0px`};
`;

export const TinyTextInput = styled(ExtraSmallTextInput)`
  .MuiOutlinedInput-root {
    height: 30px;
  }
`;

export const ModularWidthInput = styled(TextInput)`
  width: ${(props) =>
    props.noWidthPercentage
      ? null
      : props.widthPercentage
      ? `${props.widthPercentage}%`
      : '100%'};
  ${(props) =>
    props.extraSmallHeight &&
    css`
      ${ExtraSmallTextInputHeightStyle}
    `}
  .MuiOutlinedInput-input {
    font-size: 15px !important;
  }
  margin: unset;
`;

export const PhoneInput = styled(MuiTelInput).attrs({
  // onlyCountries: ['US', 'CA'],
  defaultCountry: 'US',
  forceCallingCode: true,
})`
  ${InputStyle}
  ${AutoHorizontalMargins}
  ${(props) =>
    !props.useSmallWidth
      ? css`
          ${TextInputWidthStyle}
        `
      : css`
          ${SmallerTextInputWidthStyle}
        `};
`;

export const TitleTextInputStyle = css`
  ${InputStyle}
  .MuiOutlinedInput-input {
    font-size: ${({ theme }) => theme.typography.mlSize};
  }
  .MuiOutlinedInput-root {
    font-size: ${({ theme }) => theme.typography.mlSize};
  }
  .MuiInputLabel-root {
    font-size: ${({ theme }) => theme.typography.mlSize};
  }
`;

export const TitleTextInput = styled(TextInput)`
  ${TitleTextInputStyle}
`;

// No exact numbers on these margins -- just making it look nice around the inputs
export const InputContainerForMediumGap = styled(EmptyGapColumnCenteredDiv)`
  margin-top: ${(props) =>
    props.topMargin
      ? props.topMargin
      : props.removeMargin
      ? '0px'
      : `${parseInt(props.theme.spacing.mediumGap) - 5}`}px;
  margin-bottom: ${(props) => (props.removeMargin ? '0px' : '8px')};
  gap: ${(props) => props.gap && `${props.gap}px`};
`;

export const InputContainerForSmallGap = styled(EmptyGapColumnCenteredDiv)`
  margin-top: ${({ theme }) => theme.spacing.smallGap}px;
`;

export const StartAlignedInputTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${TextInputWidthStyle}
  ${AutoHorizontalMargins}
  ${ExtraSmallGapStyle}
  margin-top: 10px;
`;

export const InputWidthDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  ${(props) =>
    props.small
      ? css`
          ${SmallerTextInputWidthStyle}
        `
      : css`
          ${TextInputWidthStyle}
        `}
`;

export const InputLabelToggleContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
`;

export const FormHeaderText = styled(StartAlignedMediumDarkSmallText)`
  ${TextInputWidthStyle}
  line-height: 1.23;
`;

export const Form = styled(FormControl)`
  ${InputStyle}
  // If no width provided, assume the base text input width.
  // Else, assume its split --> take up available width 
  ${(props) =>
    props.useFullWidth
      ? css`
          width: 100%;
        `
      : props.useSmallWidth
      ? css`
          ${SmallerTextInputWidthStyle}
        `
      : props.useExtraSmallWidth
      ? css`
          ${ExtraSmallTextInputWidthStyle}
        `
      : props.fixedWidth
      ? css`
          width: ${props.fixedWidth}px;
        `
      : !props.noFixedWidth
      ? css`
          ${TextInputWidthStyle}
        `
      : css`
          flex: 1;
        `}
        ${(props) =>
    props.autoHorizontalMargins &&
    css`
      ${AutoHorizontalMargins}
    `}
  .MuiInputLabel-root {
    font-size: ${({ theme }) => theme.typography.extraSmallSize};
  }
  .MuiInputLabel-shrink {
    font-size: ${({ theme }) => theme.typography.inputLabelSMSize} !important;
    color: ${({ theme }) => theme.color.muiInputDarkGray};
    height: 24px;
    margin-top: 0px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.containsError
        ? props.theme.color.error
        : props.theme.color.lightGray};
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.containsError
        ? props.theme.color.error
        : props.theme.color.muiInput};
  }
`;

export const DropdownLabel = styled(InputLabel)`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.typography.smallSize};
  ${TextStyle}
`;

export const Dropdown = styled(Select)`
  .MuiSelect-select {
    font-family: ${({ theme }) => theme.typography.baseFont};
    font-size: ${(props) =>
      props.useSmallText ? '17px' : props.theme.typography.titleFontSize};
  }
  .MuiOutlinedInput-notchedOutline {
    // Making sure the label text isnt cut off
    font-size: ${(props) => props.theme.typography.titleFontSize};
    border-color: ${(props) =>
      props.containsError
        ? props.theme.color.error
        : props.theme.color.muiInput};
  }
  cursor: pointer;
`;

export const FormGroup = styled(MuiFormGroup)`
  padding: 0px 10px;
  ${(props) =>
    !props.small &&
    css`
      ${TitleTextInputStyle}
    `}
  .MuiTypography-root {
    font-size: ${({ theme }) => theme.typography.smSize};
  }
  width: 100%;
  margin-bottom: ${(props) =>
    props.largeBottomMargin ? '15px' : props.smallBottomMargin ? '5px' : '0px'};
  margin-top: ${(props) => props.topMargin && `${props.topMargin}px`};
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  .MuiTypography-root {
    font-family: ${({ theme }) => theme.typography.baseFont};
    font-size: ${(props) =>
      props.isLabel
        ? props.theme.typography.smallSize
        : props.theme.typography.extraSmallSize};
    color: ${(props) =>
      props.isTitle
        ? props.theme.color.primary
        : props.theme.color.mediumDarkText};
  }
  .MuiCheckbox-root.Mui-checked {
    color: ${({ theme }) => theme.color.primary};
  }
  .MuiTypography-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  margin-left: 1px;
  ${SmallGapStyle}
  margin-right: 0px;
  width: ${(props) => props.useFullWidth && '100%'};
`;

export const MenuList = styled.div`
  max-width: 80vw;
`;

const MenuItemStyle = css`
  padding: 6px 16px;
`;

export const MenuItem = styled(MuiMenuItem).attrs({
  disableRipple: true,
})`
  font-family: ${({ theme }) => theme.typography.baseFont};
  font-size: ${({ theme }) => theme.typography.extraSmallSize};
  color: black;
  justify-content: space-between;
  ${MenuItemStyle}
  max-width: 80vw;
  ${SMGapStyle}
  word-wrap: break-word;
  ${(props) =>
    props.bottomItem &&
    css`
      border-top: 1px dotted ${({ theme }) => theme.color.muiInput};
      margin-top: 10px;
      padding-top: 10px;
    `};
  margin-bottom: ${(props) => props.addBottomMargin && '20px'};
  ${(props) =>
    props.disableHover &&
    css`
      &:hover {
        background-color: unset;
        cursor: default;
      }
    `}
`;

export const MenuItemWithSubLabel = styled(MenuItem)`
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
`;

export const FilterUpArrow = styled(UpArrow)`
  margin-right: 5px;
  margin-top: 2px;
`;

export const FilterDownArrow = styled(DownArrow)`
  margin-right: 5px;
  margin-top: 2px;
`;

export const MenuUpArrow = styled(UpArrow)`
  ${EssIconSize}
`;

export const MenuDownArrow = styled(DownArrow)`
  ${EssIconSize}
`;

export const FilterOptionText = styled(LightExtraTinyText)`
  color: ${(props) => props.selected && props.theme.color.primary};
`;

export const SelectMenuItemTitleContainer = styled(MenuItem)`
  ${ColumnFlexStyle}
  align-items: flex-start;
  ${ExtraSmallGapStyle}
  pointer-events: none;
  margin-top: 20px;
  height: 40px;
`;

export const SelectMenuItem = styled(MenuItem)`
  height: 30px;
`;

export const MenuItemText = styled(EssText)`
  font-size: 20px;
  color: ${({ theme }) => theme.color.primary};
`;

export const MediumMenuItemText = styled(MenuItemText)`
  font-size: ${({ theme }) => theme.typography.essSize};
`;

export const SMMenuItemText = styled(MenuItemText)`
  font-size: ${({ theme }) => theme.typography.largeTinySize};
`;

export const MediumDarkSMMenuItemText = styled(SMMenuItemText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
`;

export const SelectMenuItemText = styled(MenuItemText)`
  text-align: start;
  width: 100%;
  font-size: ${({ theme }) => theme.typography.essSize};
`;

export const SmallMenuItemTitleContainer = styled(SelectMenuItemTitleContainer)`
  margin-top: ${(props) => (props.addTopMargin ? '20px' : '0px')};
`;

export const SmallMenuItemTitleText = styled(SelectMenuItemText).attrs({
  clickable: false,
})`
  font-size: 16px;
  cursor: default;
  color: ${(props) =>
    props.dark ? props.theme.color.mediumDarkText : props.theme.color.primary};
  &:hover {
    background-color: unset;
  }
`;

export const SmallMenuItemText = styled(SelectMenuItemText)`
  font-size: 16px;
  color: ${(props) =>
    props.primary
      ? props.theme.color.primary
      : props.theme.color.mediumDarkText};
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  align-items: center;
  gap: 3px;
  ${(props) =>
    props.selected &&
    css`
      color: ${props.theme.color.primary};
      ${UnderlineStyle}
    `}
`;

export const SmallMenuItemOptionText = styled(SmallMenuItemText)`
  font-size: 15px;
  margin-left: 5px;
  ${(props) =>
    props.selected &&
    css`
      ${UnderlineStyle}
    `}
`;

export const ExtraSmallMenuItemText = styled(SmallMenuItemText)`
  font-size: 14px;
`;

export const MenuItemCheckmark = styled(CheckIconWithoutBackground)`
  color: ${({ theme }) => theme.color.brightGreen};
  height: 20px;
  width: 20px;
  margin-left: 10px;
`;

export const SmallErrorMenuItemText = styled(SmallMenuItemText)`
  color: ${({ theme }) => theme.color.error};
`;

export const MenuItemSubText = styled(ExtraSmallText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
  ${CenterFlexStyle}
  ${ExtraSmallGapStyle}
`;

export const StyledMenuItemIconStyle = css`
  color: ${({ theme }) => theme.color.primary};
  margin-right: 40px;
`;

export const StyledNavBarMenuItem = css`
  ${StyledMenuItemIconStyle}
  width: 40px;
  height: 40px;
`;

export const Stack = styled(MuiStack)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${EssGapStyle}
  justify-content: flex-start;
`;

export const Chip = styled(MuiChip)`
  width: fit-content;
  color: white;
  background-color: ${(props) =>
    props.primary
      ? props.theme.color.lightPrimary
      : props.secondary
      ? props.theme.color.lightGreen
      : props.ternary
      ? props.theme.color.turquoise
      : props.theme.color.darkBlue};
  font-family: ${({ theme }) => theme.typography.baseFont};
  font-size: ${({ theme }) => theme.typography.tinySize};
  padding: 2px 5px;
`;

export const BaseStatusChip = styled.div`
  padding: 5px 22px;
  border-radius: 15px;
  ${CenterFlexStyle}
  font-size: 15px;
  font-family: ${({ theme }) => theme.typography.baseFont};
`;

export const ChipColorBasedOnTaskStatusStyle = css`
  color: white;
  ${(props) =>
    props.status === completeStatusKey
      ? css`
          // Incomplete
          background-color: ${({ theme }) => theme.color.red};
        `
      : props.status === readyToPublishStatusKey
      ? css`
          // Ready to list
          background-color: ${({ theme }) => theme.color.lightPrimary};
        `
      : props.status === underReviewStatusKey
      ? css`
          // Listing under review
          background-color: ${({ theme }) => theme.color.underReview};
        `
      : css`
          // Incomplete
          background-color: ${({ theme }) => theme.color.primary};
        `}
`;

export const ScrollStyle = css`
  overflow-y: scroll;

  /* WebKit-based browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.lightPrimary};
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    height: 159px;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.color.lightPrimary} transparent;

  /* Firefox thumb */
  * {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.color.lightPrimary} transparent;
  }

  /* To style the scrollbar thumb in Firefox */
  *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.lightPrimary};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const AutoScrollWrapper = styled.div`
  overflow: auto;
  max-height: ${(props) =>
    !props.disableMaxHeight
      ? props.offset
        ? `calc(90% - ${props.offset}px)`
        : props.fixedMaxHeight
        ? `${props.fixedMaxHeight}px`
        : `90%`
      : 'unset'};
  min-height: ${(props) => props.minHeight && `${props.minHeight}px`};
  margin-top: ${(props) => props.addTopMargin && '10px'};
  padding-bottom: ${(props) =>
    props.bottomMargin ? `${props.bottomMargin}px` : `20px`};
  padding-top: ${(props) => props.topPadding && `${props.topPadding}px`};
  overflow-x: hidden;
  padding-right: ${(props) => props.addRightPadding && '3px'};
  margin-right: -2px;
  position: relative;
`;

export const Modal = styled(Dialog)`
  border-right: 10px black;
  overflow: auto;
  .MuiPaper-root {
    max-width: ${(props) =>
      props.maxWidth ? `${props.maxWidth}px` : '1200px'} !important;
    min-width: min(92%, 750px);
  }
  .MuiDialog-paper {
    max-height: calc(100% - 50px);
    overflow-x: ${(props) => props.hideHorizontalScroll && 'hidden'};
  }
  z-index: ${({ theme }) => theme.spacing.headerZIndex + 1};
`;

export const ModalContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: space-between;
  height: 100%;
  overflow-x: hidden;
  overflow-y: ${(props) => props.hideVerticalScroll && 'hidden'};
  ${SMContainerPadding}
  ${(props) =>
    props.useExtraSmallGap
      ? css`
          gap: 1px;
        `
      : props.useSmallGap
      ? css`
          ${SmallGapStyle}
        `
      : props.useMediumGap
      ? css`
          ${MediumGapStyle}
        `
      : css`
          ${MLGapStyle}
        `};
`;

export const ModalInputsContainer = styled.div`
  ${CenterFlexStyle}
  flex-direction: column;
  margin-top: ${(props) =>
    props.addTopMargin ? '20px' : props.removeTopMargin ? '-15px' : ''};
`;

export const ModalInputsContainerWithGap = styled(ModalInputsContainer)`
  ${ExtraSmallGapStyle}
`;

const MGCCD = MediumGapColumnCenteredDiv;
export const ModalInputsContainerWithSpacing = styled(MGCCD)`
  margin-top: 20px;
`;

export const ModalContent = styled(DialogContent)`
  ${ColumnFlexStyle}
  ${MediumGapStyle}
  justify-content: center;
  align-items: center;
  ${BaseWidthCentered}
`;

export const ModalTitleText = styled(DarkLargeText)``;

export const ModalTitleTextContainer = styled(CenteredDiv)`
  ${MediumGapStyle}
`;

export const ModalParagraphText = styled(MediumDarkMediumText)`
  max-width: 750px;
  ${AutoHorizontalMargins}
  line-height: 1.35;
`;

export const ModalActions = styled(DialogActions)`
  ${ColumnFlexStyle}
  justify-content: center;
  margin-top: ${(props) => props.addTopMargin && '20px'};
  ${(props) =>
    props.useExtraSmallGap
      ? css`
          gap: 1px;
        `
      : props.useMediumGap
      ? css`
          ${MediumGapStyle}
        `
      : css`
          ${SmallGapStyle}
        `};
  ${AutoHorizontalMargins}
  overflow: hidden;
  width: 100%;
`;

export const HorizontalModalActions = styled(ModalActions)`
  flex-direction: row;
  justify-content: center;
`;

const MinModalButtonWidth = 300;

export const ModalPrimaryButton = styled(PrimaryButton)`
  min-width: ${MinModalButtonWidth}px;
`;

export const ModalSecondaryButton = styled(SecondaryButton)`
  min-width: ${MinModalButtonWidth}px;
`;

export const ModalActionsOutsideContainer = styled(ModalActions)`
  margin-bottom: 20px;
  ${AutoHorizontalMargins}
`;

export const ThinScrollBarStyle = css`
  ${ScrollStyle}
  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.lightPrimary};
  }
`;

export const ThickScrollBarStyle = css`
  ${ThinScrollBarStyle}
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 1px;
  }
`;

export const ScrollWrapper = styled.div`
  ${(props) =>
    props.thinScroll
      ? css`
          ${ThinScrollBarStyle}
        `
      : css`
          ${ScrollStyle}
        `}
  max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}px` : '90%')};
  margin-top: ${(props) => props.addTopMargin && '10px'};
  padding-bottom: ${(props) => !props.removeBottomPadding && '20px'};
  padding-top: ${(props) => props.topPadding && `${props.topPadding}px`};
  overflow-x: hidden;
  padding-right: ${(props) => props.addRightPadding && '3px'};
  width: ${(props) =>
    props.fullWidth ? '100%' : props.width ? `${props.width}` : null};
  ${(props) =>
    !!(props.width || props.centered) &&
    css`
      ${AutoHorizontalMargins}
    `}
  ${(props) =>
    props.columnFlex &&
    css`
      ${ColumnFlexStyle}
      ${SMGapStyle}
    `}
    ${(props) =>
    props.largeGap &&
    css`
      ${LargeGapStyle}
    `}
`;

const MICSW = styled(ScrollWrapper).attrs({
  fullWidth: true,
  maxHeight: 550,
})``;

export const ModalInputsContainerScrollWrapper = MICSW;

export const LoadingMoreContainer = styled.div`
  // Width with a bit less to show the border
  width: calc(100% - 10px);
  ${AutoHorizontalMargins}
  ${CenterFlexStyle}
  padding: 22px 3px;
  background-color: ${({ theme }) => theme.color.muiInputLightGray};
  border-right: 1px solid ${({ theme }) => theme.color.muiInput};
`;

export const FetchMoreContainer = styled(LoadingMoreContainer)`
  background-color: transparent;
  justify-content: flex-start;
  padding-left: 10px;
`;

export const FetchMoreText = styled(ExtraSmallText)`
  ${UnderlineStyle}
  cursor: pointer;
`;

export const BackIcon = styled(KeyboardArrowLeft)`
  ${MediumIconSize}
`;

export const SecondaryBackIcon = styled(BackIcon)`
  ${SMMIconSize}
  color: ${({ theme }) => theme.color.lightPrimary};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const NextIcon = styled(KeyboardArrowRight)`
  ${MediumIconSize}
`;

export const ContinueIcon = styled(ArrowRightIcon)`
  cursor: pointer;
`;

export const SendIcon = styled(ScheduleSendIcon)`
  cursor: pointer;
`;

export const ExpandArrowIcon = styled(KeyboardDoubleArrowRightIcon)``;

export const CollapseArrowIcon = styled(KeyboardDoubleArrowLeftIcon)``;

const ArrowIconStyle = css`
  ${SMIconSize}
  cursor: pointer;
  color: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.color.primary};
  }
`;

export const StyledNextIcon = styled(NextIcon)`
  ${ArrowIconStyle}
`;
export const BackButtonContainer = styled.div`
  position: absolute;
  left: 10px;
`;

export const StyledBackIcon = styled(BackIcon)`
  ${ArrowIconStyle}
`;

export const LargeBackIcon = styled(BackIcon)`
  ${ArrowIconStyle}
  ${MediumIconSize}
`;

const NavigationContainerHeight = 75;

const TotalNavigationContainerHeight =
  NavigationContainerHeight + spacing.smallContainerPadding * 2;

export const MaxInputContainerWidth = 1000;

export const InputsContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: center;
  overflow-x: hidden;
  height: ${(props) =>
    props.remainingHeight - TotalNavigationContainerHeight}px;
  ${ScrollStyle}
  max-width: ${MaxInputContainerWidth}px;
  min-width: 80%;
  padding-bottom: 20px;
  ${(props) =>
    props.smallGap
      ? css`
          margin: auto;
          ${MediumGapStyle}
        `
      : !props.removeMargin
      ? css`
          margin: auto;
          ${ExtraLargeGapStyle}
        `
      : css`
          width: 100%;
          ${MediumGapStyle}
        `}
`;

export const ContainerWithInputWidth = styled.div`
  ${(props) =>
    props.useFullWidth
      ? css`
          width: 100%;
          margin: 0px;
        `
      : !props.useSmallWidth
      ? css`
          ${TextInputWidthStyle}
        `
      : css`
          ${SmallerTextInputWidthStyle}
        `};
  ${AutoHorizontalMargins}
  ${(props) =>
    props.verticalMargin &&
    css`
      margin-top: ${props.verticalMargin}px;
      margin-bottom: ${props.verticalMargin}px;
    `}
`;

export const InputContainer = styled.div`
  ${ColumnFlexStyle}
  position: relative;
  align-items: center;
  // Enough room to separate the scrollbar, but not too much to skew the content one way on small screen sizes
  margin: ${(props) => !props.useFullWidth && '0px 10px'};
  ${(props) =>
    props.removeGap
      ? css`
          gap: 0px !important;
        `
      : props.useExtraSmallGap
      ? css`
          ${SMMGapStyle}
        `
      : !props.useSmallGap
      ? css`
          ${MMLGapStyle}
        `
      : css`
          ${MediumGapStyle}
        `}
`;

export const UneditableInputContainer = styled.div`
  height: 87px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const InputTitleContainer = styled.div`
  ${TextInputWidthStyle}
  margin-top: ${(props) => props.addTopMargin && '25px'};
`;

export const InputTitleText = styled(SMText)`
  width: ${(props) => (props.useFullWidthLabel ? '100%' : '95%')};
  text-align: ${(props) => (props.centered ? 'center' : 'start')};
  line-height: 1.175;
  color: ${({ theme }) => theme.color.mediumText};
`;

export const InputSubtitleTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 10px;
`;

export const InputSubtitleText = styled(LightDarkSmallText)`
  margin-top: 5px;
  text-align: start;
  line-height: 1.27;
`;

const MaxNavigationSectionWidth =
  spacing.pageHorizontalPadding * 2 + MaxInputContainerWidth;

export const NavigationSection = styled.div`
  width: 100vw;
  position: fixed;
  height: ${NavigationContainerHeight}px;
  bottom: 0px;
  right: ${(props) => (props.expanded ? '-60px' : '0px')};
  z-index: 100;
  background-color: white;
  border-top: 2px solid ${({ theme }) => theme.color.muiInput};
`;

export const NavigationButtonContainer = styled.div`
  display: flex;
  max-width: ${MaxNavigationSectionWidth}px;
  ${AutoHorizontalMargins}
  justify-content: space-between;
  height: 100%;
`;

const NBC = NavigationButtonContainer;
export const RefinedNavigationButtonContainer = styled(NBC)`
  padding: 0px 20px;
  align-items: center;
`;

export const SplitInputContainer = styled.div`
  display: flex;
  ${SMGapStyle}
  ${TextInputWidthStyle}
  justify-content: center;
  align-items: center;
`;

export const BottomBorder = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.color.secondary};
  ${BaseWidth}
  ${AutoHorizontalMargins}
`;

export const ProfileContainer = styled.div`
  ${ColumnFlexStyle}
  border: 1px solid ${({ theme }) => theme.color.secondary};
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  ${BaseWidth}
  ${AutoHorizontalMargins}
  padding: 10px 0px;
  ${MLGapStyle}
`;

export const ProfileHeaderContainer = styled.div`
  ${CenterFlexStyle}
  ${(props) =>
    props.shouldWrap
      ? css`
          ${ColumnFlexStyle}
          padding: 10px 20px;
          ${MediumGapStyle}
        `
      : css`
          ${CenterFlexStyle}
          padding: 10px 20px;
          ${MLGapStyle}
        `}
`;

export const ProfileAvatarContainer = styled.div`
  ${CenterFlexStyle}
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.lightHover};
  border: 3px solid ${({ theme }) => theme.color.primary};
  padding: 2px;
`;

const MediumAvatarSize = css`
  height: clamp(120px, 150px, 20vw);
  width: clamp(120px, 150px, 20vw);
`;

export const Avatar = styled.img`
  ${MediumAvatarSize}
  border-radius: 50%;
  object-fit: cover;
`;

export const ProfileNameContainer = styled.div`
  ${ColumnFlexStyle}
  ${SmallGapStyle}
  width: 100%;
  ${(props) =>
    props.shouldWrap
      ? css`
          justify-content: center;
          align-items: center;
        `
      : css`
          justify-content: flex-start;
          align-items: flex-start;
        `}
`;

export const ProfileContentContainer = styled.div`
  padding: 10px 20px;
  ${ColumnFlexStyle}
  ${ExtraLargeGapStyle}
`;

export const StyledSnackbar = styled(Snackbar).attrs({
  autoHideDuration: 4500,
})`
  color: white;
  width: clamp(280px, 'fit-content', 500px);
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  border: 1px solid ${({ theme }) => theme.color.muiInputLightGray};
  z-index: 100000;
  height: 50px;
  margin-left: ${(props) =>
    props.drawerExpanded ? '170px' : props.drawerOpen ? '60px' : '10px'};
  .MuiPaper-root {
    ${CenterFlexStyle}
  }
`;

export const QuickSnackbar = styled(StyledSnackbar).attrs({
  autoHideDuration: 1500,
})``;

export const StyledSuccessAlert = styled(Alert).attrs({
  severity: 'success',
})`
  width: 100%;
  font-weight: bolder;
  color: white;
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.largeTinySize};
  color: white;
  background-color: ${({ theme }) => theme.color.brightGreen};
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 1, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  .MuiAlert-icon {
    color: white;
    margin-right: 10px;
  }
  .MuiAlert-action {
    margin-left: 35px;
    margin-top: -2px;
  }
  height: 85%;
`;

export const Tabs = styled(MuiTabs)`
  display: flex;
  color: ${({ theme }) => theme.color.muiInput};
  .Mui-selected {
    color: ${({ theme }) => theme.color.primary};
  }
  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.color.primary};
  }
  margin-bottom: ${(props) => (props.addBottomMargin ? '20px' : '-12px')};
  min-height: 35px;
  height: 35px;
`;

export const Tab = styled(MuiTab)`
  ${TextStyle}
  text-transform: none;
  font-size: ${({ theme }) => theme.typography.tinySize};
  text-underline-offset: 6px;
  padding: 0px 30px;
  width: clamp(182px, 240px);
  color: ${({ theme }) => theme.color.muiInputDarkGray};
  min-height: 30px;
  height: 30px;
`;

export const SecondaryTabs = styled(Tabs)`
  display: flex;
  margin-top: -10px;
  margin-bottom: 10px;
  .Mui-selected {
    color: ${({ theme }) => theme.color.darkBlue};
  }
  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.color.darkBlue};
  }
`;

export const SecondaryTab = styled(Tab)`
  font-size: 15px;
  color: ${({ theme }) => theme.color.darkText};
`;

export const LightTabs = styled(Tabs)`
  color: ${({ theme }) => theme.color.primary};
  .Mui-selected {
    color: white;
  }
  .MuiTabs-indicator {
    background-color: white;
  }
`;

export const Tooltip = styled(MuiTooltip).attrs({
  arrow: true,
})`
  z-index: 1000;
`;

export const TooltipTitleText = styled(BaseTypography)`
  ${TextStyle}
  font-size: 15px;
  color: white;
  line-height: 1.2 !important;
`;

export const TooltipSubtitleText = styled(TooltipTitleText)`
  font-size: 13px;
  line-height: 1.5 !important;
`;

export const FilterPage = styled(ColumnCenteredDiv)`
  padding-top: 20px;
  width: 280px;
`;

export const FilterPageTitleText = styled(EssText)`
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.color.secondary};
`;

export const MoreFiltersCarousel = styled(MobileStepper)`
  margin-top: 10px;
  width: ${(props) => (props.useFullWidth ? '100%' : '90%')};
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.addTopMargin &&
    css`
      margin-top: 30px;
    `}
  ${(props) =>
    !props.removeGap
      ? css`
          ${SMGapStyle}
        `
      : css`
          padding: 0px !important;
        `}    
  .MuiMobileStepper-dotActive {
    background-color: ${({ theme }) => theme.color.secondary};
  }
`;

export const CarouselNavigationButton = styled(TextButton)`
  padding: 0px;
  min-width: 90px;
  font-size: 16px;
`;

export const CarouselBackIcon = styled(BackIcon)`
  ${SMIconSize}
`;

export const CarouselNextIcon = styled(NextIcon)`
  ${SMIconSize}
`;

export const RangeSlider = styled(Slider)`
  width: 70%;
  color: ${({ theme }) => theme.color.lightPrimary};
  .MuiSlider-markLabel {
    font-family: ${({ theme }) => theme.typography.baseFont};
    color: ${({ theme }) => theme.color.lightDarkText};
  }
  .MuiSlider-valueLabel {
    font-family: ${({ theme }) => theme.typography.baseFont};
  }
  .MuiSlider-mark {
    height: 0px;
  }
`;

const TopCardCircularFrameBorderWidth = 5;

export const TopCardCircularFrame = styled.div`
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.lightSecondaryAccent};
  ${AutoHorizontalMargins}
  ${CenterFlexStyle}
  position: absolute;
  left: calc(50% - ${(props) => props.size / 2}px);
  top: -${(props) => `${props.size / 2 + TopCardCircularFrameBorderWidth}px`};
  border: ${TopCardCircularFrameBorderWidth}px solid
    ${({ theme }) => theme.color.muiInput};
`;

export const FileInput = styled.input.attrs({
  type: 'file',
  accept:
    'application/pdf, .csv, application/vnd.ms-excel, .doc, .docx, image/png, image/gif, image/jpeg, .svg',
})`
  display: none;
`;

export const MindBodyLogo = styled.img.attrs({
  src: 'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/logos/mindbody-online-logo.png',
})`
  width: min(80px, 10vh);
  height: min(80px, 10vh);
  margin-bottom: 2px;
`;

export const ZenotiLogo = styled.img.attrs({
  src: 'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/logos/zenoti-logo.svg',
})`
  width: 200px;
  height: min(80px, 10vh);
  margin-bottom: 2px;
`;

export const BoulevardLogo = styled.img.attrs({
  src: 'https://storage.googleapis.com/liveiq-assets/partner-logos/boulevard-logo1.png',
})`
  width: 250px;
  height: min(80px, 10vh);
  margin-bottom: 2px;
`;

export const NextechLogo = styled.img.attrs({
  src: 'https://storage.googleapis.com/liveiq-assets/partner-logos/nextech-logo.png',
})`
  width: 250px;
  height: min(80px, 10vh);
  margin-bottom: 2px;
`;

export const HubspotLogo = styled.img.attrs({
  src: 'https://storage.googleapis.com/liveiq-assets/partner-logos/hubspot-logo.svg',
})`
  width: 250px;
  height: min(80px, 10vh);
  margin-bottom: 2px;
`;

export const SnowflakeLogo = styled.img.attrs({
  src: 'https://storage.googleapis.com/liveiq-assets/partner-logos/snowflake-logo.png',
})`
  width: 250px;
  height: min(80px, 10vh);
  margin-bottom: 2px;
`;

export const BaseDrawerContainer = styled.div`
  position: fixed;
  top: ${({ theme }) =>
    theme.spacing.headerHeight + theme.spacing.headerVerticalPadding * 2 + 2}px;
  ${(props) =>
    props.expanded
      ? css`
          width: ${ExpandedDrawerWidthValue}px;
        `
      : css`
          ${CollapsedDrawerWidthStyle}
        `}
  height: calc(
    100dvh -
      ${({ theme }) =>
    theme.spacing.headerHeight + theme.spacing.headerVerticalPadding * 2 - 20}px
  );
  padding-top: 5px;
  z-index: 100;
  ${ColumnFlexStyle}
  background-color: white;
  ${(props) =>
    !props.removeScroll &&
    css`
      ${ScrollStyle}
    `}
`;

export const BoxShadowStyle = css`
  box-shadow: ${(props) =>
    props.disableBoxShadow
      ? `unset`
      : props.lightBaseBoxShadow
      ? `rgba(0, 0, 0, 0.2) 0px 1px 2px, rgba(0, 0, 0, 0.2) 0px -1px 2px`
      : `rgba(0, 0, 0, 0.35) 0px 5px 10px, rgba(0, 0, 0, 0.35) 0px -1px 4px`};

  &:hover {
    box-shadow: ${(props) =>
      props.addHoverShadow &&
      `${props.theme.color.lightPrimaryRgba} 0px 3px 6px, ${props.theme.color.primaryHoverRgba} 0px -1px 3px`};
  }
`;

export const CardStyle = css`
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  padding: ${({ theme }) => `${theme.spacing.smallContainerPadding}px`};
  border: 1.5px solid
    ${(props) =>
      props.selected
        ? props.theme.color.lightPrimary
        : props.theme.color.muiInput};
  ${BoxShadowStyle}
`;

export const PanelStyle = css`
  border: 2px dotted ${({ theme }) => theme.color.muiInputLightGray};
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  cursor: pointer;
  ${(props) =>
    !props.unclickable &&
    css`
      &:hover {
        transform: scale(1.02);
        background-color: ${({ theme }) => theme.color.offwhite};
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);
      }
    `}
`;

export const BaseBoxHeightStyle = css`
  height: 470px;
`;

export const BaseBox = styled.div`
  border-radius: ${({ theme }) => theme.spacing.mlBorderRadius}px;
  border: 2px solid ${({ theme }) => theme.color.muiInput};
  ${AutoHorizontalMargins}
  width: 900px;
  ${ColumnFlexStyle}
  ${BaseBoxHeightStyle}
  margin-top: 20px;
  position: relative;
  height: 555px;
`;

export const BoxWithPadding = styled(BaseBox)`
  padding: 20px;
`;

export const BoxWithSpacing = styled(BoxWithPadding)`
  ${MediumGapStyle}
  height: ${(props) => props.height && `${props.height}px`};
  width: ${(props) => props.width && `${props.width}`};
`;

export const ScrollableBoxContentContainer = styled(ColumnCenteredDiv)`
  ${MLGapStyle}
  ${ScrollStyle}
  max-height: 80%;
  padding: 40px 0px;
`;

export const HorizontallyScrollableDiv = styled.div`
  overflow-x: auto;
  white-space: nowrap;
`;

export const BoxTitleContainer = styled.div`
  margin: 10px 0px;
  position: relative;
  width: 90%;
  ${AutoHorizontalMargins}
  ${CenterFlexStyle}
  ${(props) =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `}
`;

export const BoxContentContainer = styled(EmptyGapColumnCenteredDiv)`
  width: 90%;
  overflow-x: hidden;
  ${AutoHorizontalMargins}
  padding-top: 10px;
  padding-bottom: 3px;
  gap: 8px;
`;

export const BoxListItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 1px;
  padding-bottom: 6px;
  ${(props) =>
    props.largePadding &&
    css`
      padding: 10px 0px;
    `}
  ${(props) =>
    props.isBottom
      ? css`
          padding-top: 0px;
        `
      : css`
          border-bottom: 1px solid ${({ theme }) => theme.color.muiInput};
        `};
  width: calc(100% - 20px);
  height: 100%;
`;

export const BoxLoadingIndicatorContainer = styled(ColumnCenteredDiv)`
  ${BaseBoxHeightStyle}
`;

export const LargeChatBoxWidth = 1000;
export const ChatHeaderContainerHeight = 30;

export const ChatBoxBackgroundStyle = css`
  background: ${(props) =>
    props.remove
      ? 'white'
      : props.base
      ? `linear-gradient(180deg, ${props.theme.color.superDuperLightSecondary}, ${props.theme.color.superDuperLightPrimary})`
      : `linear-gradient(180deg, #e0eff5, #d8cfe8) border-box`};
  opacity: ${(props) => props.card && '0.8'};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const ChatBox = styled(BaseBox)`
  justify-content: space-between;
  padding-top: 0px;
  height: ${(props) => props.remainingHeight - 190}px;
  justify-content: space-between;
  padding-top: 0px;
  position: relative;
  ${(props) =>
    props.demo &&
    css`
      width: 100%;
      max-width: 100%;
    `}
  ${ChatBoxBackgroundStyle}
`;

export const ScrollbarCoverDiv = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  height: 8px;
  width: 8px;
  background-color: white;
`;

export const ChatActionsContainer = styled(CenteredDivWithGap)`
  height: 100%;
  padding: 20px 20px;
  border-radius: 0px 0px ${({ theme }) => theme.spacing.mlBorderRadius}px
    ${({ theme }) => theme.spacing.mlBorderRadius}px;
  background-color: white;
  ${MediumGapStyle}
  ${(props) =>
    props.isDemo &&
    css`
      height: 70px;
      border-top: 1px solid ${({ theme }) => theme.color.muiInput};
    `}
`;

export const ExpandIcon = styled(OpenInFullIcon)`
  ${SmallIconSize}
  color: ${({ theme }) => theme.color.defaultGray};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.muiInputDarkGray};
  }
`;

export const CollapseIcon = styled(CloseFullscreenIcon)`
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.color.muiInput};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.muiInputDarkGray};
  }
`;

export const BeginChatButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  ${SMGapStyle}
`;

export const InputPhoneNumberContainer = styled(ColumnCenteredDiv)`
  padding: 20px;
`;

export const PrimaryChatActionButton = styled(ExtraSmallPrimaryButton).attrs({
  mediumPadding: true,
})`
  height: ${(props) => (props.useLargeHeight ? '62px' : '50px')};
  min-width: 150px;
`;

export const ChatActionButton = styled(ExtraSmallSecondaryButton).attrs({
  mediumPadding: true,
})`
  height: ${(props) => (props.useLargeHeight ? '62px' : '50px')};
  min-width: 150px;
`;

export const PlaceholderChatActionButton = styled(ChatActionButton)`
  background-color: transparent;
  color: transparent;
`;

export const BaseCardWidthStyle = css`
  width: calc(
    100% - ${({ theme }) => `${theme.spacing.smallContainerPadding * 2}px`}
  );
`;

export const BaseCard = styled.div`
  ${CardStyle}
  ${BaseCardWidthStyle}
  ${ColumnFlexStyle}
  ${SMGapStyle}
  max-width: ${(props) => props.maxWidth && `${props.maxWidth}`};
`;

export const WholeSectionCard = styled(BaseCard)`
  width: 90%;
  ${AutoHorizontalMargins}
  height: calc(70dvh - 50px);
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const CardActionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${SmallGapStyle}
  margin-top: 10px;
`;

export const BackToPreviousScreenIcon = styled(BackIcon)``;

export const BackToPreviousScreenText = styled(ExtraSmallText)`
  color: unset;
`;

export const AboveFooterMarginStyle = css`
  margin-bottom: ${({ theme }) => theme.spacing.aboveFooterMargin}px;
`;

export const TableContainer = styled(MuiTableContainer).attrs({})`
  font-family: ${({ theme }) => theme.typography.baseFont};
  overflow: unset;
  align-self: center;
  padding-bottom: 2px;
  overflow-x: initial;
  position: relative;
`;

export const EmptyTableHeight = 200;

export const Table = styled(MuiTable)`
  height: ${(props) => props.isEmpty && `${EmptyTableHeight}px`};
  margin-top: ${(props) => props.topMargin && `${props.topMargin}px`};
  max-width: ${(props) => props.maxWidth && `${props.maxWidth}px`};
  width: ${(props) => props.width && `${props.width}`};
`;

export const TableHead = styled(MuiTableHead)``;

export const TableRow = styled(MuiTableRow)`
  ${(props) =>
    props.end &&
    css`
      border-bottom-width: 0px;
    `}
  height: ${(props) => (props.height ? `${props.height}` : 83)}px;
  width: ${(props) => props.width && `${props.width}px`};
  ${(props) =>
    props.disabled &&
    css`
      border-left: 2px solid ${({ theme }) => theme.color.mediumLightError};
    `}
`;

export const TableCell = styled(MuiTableCell)`
  font-size: 12px;
  font-family: ${({ theme }) => theme.typography.baseFont};
  color: ${({ theme }) => theme.color.lightDarkText};
  max-width: 80px;
  text-align: center;
  word-wrap: break-word;
  position: relative;
  line-height: 1.2;
  padding: ${(props) => (!props.removePadding ? `12px 5px` : '0px')};
  ${(props) =>
    props.end
      ? css``
      : css`
          border-right: 1px dotted ${({ theme }) => theme.color.muiInput};
        `}
  ${(props) =>
    props.isLastRow &&
    css`
      border-bottom-width: 0px;
    `}
    ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
      max-width: ${props.width}px;
    `}
`;

export const TableCellStack = styled.div`
  border: 1px solid ${({ theme }) => theme.color.muiInput};
  border-left: ${(props) => !props.showText && `0px`};
  ${(props) =>
    props.color === 'green'
      ? css`
          background-color: ${props.theme.color.brightGreen};
          color: ${(props) =>
            props.showText ? 'white' : props.theme.color.brightGreen};
        `
      : props.color === 'blue'
      ? css`
          background-color: ${props.theme.color.turquoise};
          color: ${(props) =>
            props.showText ? 'white' : props.theme.color.turquoise};
        `
      : props.color === 'orange'
      ? css`
          background-color: ${props.theme.color.lightOrange};
          color: ${(props) =>
            props.showText ? 'white' : props.theme.color.lightOrange};
        `
      : props.color === 'red'
      ? css`
          background-color: ${props.theme.color.lightError};
          color: ${(props) =>
            props.showText ? 'white' : props.theme.color.lightError};
        `
      : css``}
  ${(props) =>
    props.startOffset && props.endOffset
      ? css`
          width: ${(props) =>
            `calc(100% - ${props.startOffset + props.endOffset}%)`};
          margin-left: ${(props) => `${props.startOffset}%`};
          margin-right: ${(props) => `${props.endOffset}%`};
        `
      : props.startOffset
      ? css`
          width: ${(props) => `calc(100% - ${props.startOffset}%)`};
          margin-left: ${(props) => `${props.startOffset}%`};
        `
      : props.endOffset
      ? css`
          width: ${(props) => `calc(100% - ${props.endOffset}%)`};
          margin-right: ${(props) => `${props.endOffset}%`};
        `
      : css`
          width: 100%;
        `}
  flex: 1;
  height: 30px;
  cursor: pointer;
`;

export const TableCellHeader = styled(TableCell).attrs({
  align: 'center',
})`
  font-family: ${({ theme }) => theme.typography.baseFont};
  color: ${({ theme }) => theme.color.primary};
  font-size: ${(props) =>
    props.context === chatDemoContextKey ? '13px' : '15px'};
  ${(props) =>
    props.end
      ? css``
      : css`
          border-right: 1px solid ${({ theme }) => theme.color.muiInput};
        `}
  border-bottom: 1px solid ${({ theme }) => theme.color.lightPrimary};
  padding: 15px 2px;
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
      max-width: ${props.width}px;
    `}
`;

export const TableBody = styled(MuiTableBody)``;

const TablePaginationButtonStyle = css`
  cursor: ${(props) => !props.disabled && 'pointer'};
  height: 20px;
  color: ${(props) =>
    props.disabled ? props.theme.color.disabled : props.theme.color.primary};
  width: 20px;
  &:hover {
    background-color: ${(props) =>
      !props.disabled && props.theme.color.primary};
    color: ${(props) => !props.disabled && 'white'};
  }
  border-radius: 4px;
`;

export const NextPageButton = styled(NextIcon)`
  ${TablePaginationButtonStyle}
`;

export const PreviousPageButton = styled(BackIcon)`
  ${TablePaginationButtonStyle}
`;

export const CalendarIcon = styled(MuiCalendarIcon)`
  color: ${(props) =>
    props.selected
      ? props.theme.color.primary
      : props.theme.color.lightPrimary};
  margin-top: -3px;
  cursor: pointer;
  ${SMMMIconSize}
  &:hover {
    color: ${(props) => !props.disabled && props.theme.color.primary};
  }
`;

export const ViewCalendarIcon = styled(EventIcon)`
  color: ${({ theme }) => theme.color.primary};
`;

export const AppointmentBookedIcon = styled(MuiBookableIcon)`
  color: ${(props) =>
    props.selected
      ? props.theme.color.secondary
      : props.theme.color.brightGreen};
  ${(props) =>
    props.small
      ? css`
          ${SMMMIconSize}
        `
      : css`
          ${SMMIconSize}
        `}
  cursor: pointer;
  position: relative;
  margin-top: 4px;
`;

export const AppointmentBookedChip = styled.div`
  position: absolute;
  top: -1.5px;
  right: -4.5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.brightGreen};
  border: 1px solid white;
  ${CenterFlexStyle}
  padding: 1px;
`;

export const AppointmentBookingMonitoringIcon = styled(TbCalendarSearch)`
  ${(props) =>
    props.small
      ? css`
          ${SMMMIconSize}
        `
      : css`
          ${SMMIconSize}
        `}
  color: ${(props) =>
    props.selected ? props.theme.color.orange : props.theme.color.lightOrange};
  cursor: pointer;
  margin-top: ${(props) => `${props.topMargin}px`};
`;

export const DisengagedIcon = styled(DoDisturbOnOutlinedIcon)`
  color: ${(props) =>
    props.selected
      ? props.theme.color.lightError
      : props.theme.color.mediumLightError};
  ${SMMIconSize}
`;

export const BookableIcon = styled(MuiBookableIcon)`
  ${SMIconSize}
  color: ${({ theme }) => theme.color.lightPrimary};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const UnbookableIcon = styled(MuiUnbookableIcon)`
  ${SMIconSize}
  color: ${({ theme }) => theme.color.muiInput};
  &:hover {
    color: ${({ theme }) => theme.color.muiInputDarkGray};
  }
`;

export const SmallAddIcon = styled(AddIcon)`
  ${SMMIconSize}
`;

export const ExtraSmallAddIcon = styled(AddIcon)`
  ${EssIconSize}
`;

export const ExtraSmallPrimaryAddIcon = styled(ExtraSmallAddIcon)`
  margin-top: ${(props) => props.topMargin && `${props.topMargin}px`};
  cursor: pointer;
  color: ${({ theme }) => theme.color.lightPrimary};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const SmallFilterIcon = styled(FilterAltIcon)`
  ${SMMIconSize}
`;

export const SmallFilterButtonHangingChip = styled.div`
  position: absolute;
  top: -5px;
  right: -11px;
  background-color: ${({ theme }) => theme.color.brightGreen};
  height: 12px;
  width: 12px;
  border-radius: 50%;
  ${CenterFlexStyle}
  z-index: 1000;
`;

export const BaseChipText = styled(TinyText)`
  font-size: 8px;
  color: white;
`;

export const SmallFilterButtonHangingChipText = styled(BaseChipText)`
  font-size: 9px;
  margin-bottom: 1px;
  margin-left: 1px;
`;

export const SmallRemoveIcon = styled(RemoveIcon)`
  ${SMMIconSize}
  color: unset;
  cursor: ${(props) => (props.inactive ? 'default' : 'pointer')};
`;

export const StandaloneRemoveIcon = styled(SmallRemoveIcon)`
  border-color: ${({ theme }) => theme.color.lightError};
  color: ${(props) =>
    props.disabled ? props.theme.color.muiInput : props.theme.color.lightError};
`;

export const ListRemoveIcon = styled(StandaloneRemoveIcon)`
  ${SmallIconSize}
  cursor: pointer;
`;

export const StandaloneAddIcon = styled(AddIcon)`
  ${SMMIconSize}
  color: unset;
  color: ${(props) =>
    props.disabled
      ? props.theme.color.muiInput
      : props.theme.color.brightGreen};
  cursor: pointer;
`;

export const CategorizeIcon = styled(PlaylistAddIcon)`
  ${SMIconSize}
  color: unset;
  color: ${(props) =>
    props.disabled
      ? props.theme.color.muiInput
      : props.theme.color.lightPrimary};
  cursor: pointer;
  &:hover {
    color: ${(props) => !props.disabled && props.theme.color.primary};
  }
`;

export const SmallChatIcon = styled(ChatIcon)`
  ${SMMIconSize}
  color: unset;
`;

export const SmallPersonSearchIcon = styled(MuiPersonSearchIcon)`
  ${SMMIconSize}
  color: unset;
`;

export const CenteredSpan = styled.span`
  ${CenterFlexStyle}
`;

export const AgentIcon = styled(SupportAgentIcon)`
  color: ${(props) =>
    props.selected
      ? props.theme.color.primary
      : props.theme.color.lightPrimary};
  ${SMMMIconSize}
`;

export const AgentIconButtonContainer = styled.div`
  border-radius: 50%;
  height: 27px;
  width: 27px;
  margin-top: 0px;
  ${CenterFlexStyle}
  background-color: white;
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
`;

export const AgentIconInButton = styled(AgentIcon)`
  ${SMMIconSize}
  color: unset;
`;

export const HeadOfficeIcon = styled(HighQualityIcon)``;

export const AnalyticsIcon = styled(LeaderboardIcon)``;

export const WorkflowIcon = styled(TiFlowSwitch)``;

export const CardPageSection = styled.div`
  ${ColumnFlexStyle}
  width: 100%;
  ${SMGapStyle}
  ${(props) =>
    props.fixedHeight &&
    css`
      height: ${(props) => `${props.fixedHeight}px`};
      overflow-y: hidden;
    `}
  ${(props) =>
    props.fullWidth
      ? css`
          width: 100vw;
        `
      : css`
          ${AutoHorizontalMargins}
          max-width: ${(props) => !props.maxWidthUnset && '1050px'};
        `}
        position: ${(props) => props.fixed && 'fixed'};
`;

export const CardPageSectionActionsContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.spaced ? 'space-between' : 'flex-start'};
  align-items: center;
  margin: 0px !important;
  width: 100%;
  ${SmallGapStyle}
`;

const LGCCD = LargeGapColumnCenteredDiv;
export const AbsoluteLoadingIndicatorContainer = styled(LGCCD)`
  margin-top: 100px;
`;

export const FilledStarIcon = styled(MuiFilledStarIcon)`
  ${SMMIconSize}
  color: ${(props) =>
    props.disabled
      ? props.theme.color.muiInput
      : props.theme.color.lightPrimary};
  cursor: pointer;
  &:hover {
    color: ${(props) => !props.disabled && props.theme.color.primary};
  }
`;

export const EmptyStarIcon = styled(MuiEmptyStarIcon)`
  ${SMMIconSize}
  color: ${({ theme }) => theme.color.muiInput};
  cursor: pointer;
`;

export const OnIcon = styled(ToggleOnIcon)``;

export const OffIcon = styled(ToggleOffIcon)``;

export const StarSettingIcon = styled(StarCategoryIcon)`
  ${SMIconSize}
  color: ${(props) =>
    props.disabled
      ? props.theme.color.muiInput
      : props.theme.color.lightPrimary};
  cursor: pointer;
`;

export const UnitsIcon = styled(WidgetsIcon)`
  ${SMMIconSize}
  color: ${(props) =>
    props.disabled
      ? props.theme.color.muiInput
      : props.theme.color.lightPrimary};
  cursor: pointer;
`;

export const CompatibilityIcon = styled(NetworkCheckIcon)`
  ${SMMIconSize}
  color: ${(props) =>
    props.disabled
      ? props.theme.color.muiInput
      : props.theme.color.lightPrimary};
  cursor: pointer;
`;

export const NumberOfPeopleIcon = styled(MuiPeopleIcon)`
  ${SMMIconSize}
  color: ${(props) =>
    props.disabled
      ? props.theme.color.muiInput
      : props.theme.color.lightPrimary};
  cursor: pointer;
`;

export const SmartListIcon = styled(AutoModeIcon)`
  ${LargeIconSize}
  color: ${({ theme }) => theme.color.primary};
`;

export const SettingsIcon = styled(MuiSettingsIcon)`
  margin-left: 2px;
  color: ${({ theme }) => theme.color.lightPrimary};
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-bottom: 1px;
`;

export const EndActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1px;
`;

export const SingleMessageIcon = styled(ChatIcon)`
  ${SmallIconSize}
  color: ${(props) =>
    props.inherit ? 'inherit' : props.theme.color.lightPrimary};
`;

export const ScriptIcon = styled(SchemaIcon)`
  ${SmallIconSize}
  color: ${({ theme }) => theme.color.lightPrimary};
`;

export const TestIcon = styled(BiotechIcon)``;

export const SelectIcon = styled(AdsClickIcon)`
  ${MediumIconSize}
  color: ${({ theme }) => theme.color.muiInput};
`;

export const PlayIcon = styled(PlayCircleIcon)`
  ${EssIconSize}
  color: ${({ theme }) => theme.color.lightPrimary};
  cursor: pointer;
`;

export const OutlinePlayIcon = styled(PlayArrowIcon)`
  ${EssIconSize}
  color: ${({ theme }) => theme.color.lightPrimary};
  cursor: pointer;
`;

export const PauseIcon = styled(MuiPauseIcon)`
  ${EssIconSize}
  color: ${({ theme }) => theme.color.lightPrimary};
  cursor: pointer;
`;

// Bookable status icons
const BookableStatusIconStyle = css`
  ${SmallIconSize}
  cursor: pointer;
  ${(props) =>
    props.disabled
      ? css`
          color: ${props.theme.color.disabledText};
          &:hover {
            color: ${props.theme.color.disabledText};
          }
        `
      : props.primary
      ? css`
          color: ${props.theme.color.lightPrimary};
          &:hover {
            color: ${props.theme.color.primary};
          }
        `
      : css`
          color: inherit;
          &:hover {
            color: inherit;
          }
        `}
`;

export const DirectlyBookableIcon = styled(EventAvailableIcon)`
  ${BookableStatusIconStyle}
`;

export const PackageIcon = styled(GroupWorkIcon)`
  ${BookableStatusIconStyle}
`;

export const IndirectlyBookableIcon = styled(LocalPhoneIcon)`
  ${BookableStatusIconStyle}
  ${EssIconSize}
`;

export const RequiresConsultationIcon = styled(PendingActionsIcon)`
  ${BookableStatusIconStyle}
`;

export const UnbookableServiceIcon = styled(UnbookableIcon)`
  ${BookableStatusIconStyle}
`;

export const ExpandViewIcon = styled(CgArrowsExpandUpRight)`
  ${BookableStatusIconStyle}
`;

export const AgentAvatarContainer = styled.div`
  border-radius: 50%;
  height: 26px;
  width: 26px;
  border: 1px solid
    ${(props) =>
      props.analyst ? props.theme.color.lightBlue : props.theme.color.muiInput};
  ${CenterFlexStyle}
  overflow: hidden;
  cursor: pointer;
`;

const AgentAvatarStyle = css`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const ReceptionistAvatar = styled.img.attrs({
  src: 'https://storage.googleapis.com/liveiq-assets/avatars/receptionist-cropped.png',
})`
  ${AgentAvatarStyle}
`;

export const MarketerAvatar = styled.img.attrs({
  src: 'https://storage.googleapis.com/liveiq-assets/avatars/marketer-cropped.png',
})`
  ${AgentAvatarStyle}
`;

export const AnalystAvatar = styled.img.attrs({
  src: 'https://storage.googleapis.com/liveiq-assets/avatars/analyst-cropped.png',
})`
  ${AgentAvatarStyle}
`;

export const BookableStatusTextStyle = css`
  color: ${(props) =>
    props.status === directlyBookableSessionTypeStatus
      ? props.theme.color.lightGreen
      : props.status === indirectlyBookableSessionTypeStatus ||
        props.status === sourcePackageBookableSessionTypeStatus
      ? props.theme.color.lightDarkText
      : props.theme.color.lightError};
`;

export const BottomMessageContainer = styled.div`
  position: absolute;
  ${CenterFlexStyle}
  bottom: 30px;
  left: 30px;
  padding: 10px;
  color: ${({ theme }) => theme.color.lightDarkText};
  ${ExtraSmallGapStyle}
  background-color: white;
  border: 1px solid
    ${(props) =>
      props.saved
        ? props.theme.color.lightGreen
        : props.theme.color.lightOrange};
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
`;

export const CompletionCircle = styled(CircularProgress).attrs({
  variant: 'determinate',
  thickness: 22,
})`
  width: 20px !important;
  height: 20px !important;
  color: ${({ theme }) => theme.color.lightGreen};
  border-radius: 50%;
`;

export const CompletionCheckmark = styled(CompleteButtonCheckmarkIcon)`
  color: ${({ theme }) => theme.color.brightGreen};
  width: 20px !important;
  height: 20px !important;
`;

export const AgentColorStyle = css`
  color: ${(props) =>
    props.receptionist
      ? props.theme.color.offwhite
      : props.marketer
      ? props.theme.color.offwhite
      : props.theme.color.offwhite};
`;

export const PhoneIcon = styled(PhoneInTalkIcon)`
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.color.secondaryHover};
`;

export const ClientSearchPhoneIcon = styled(PhoneIcon)`
  ${EssIconSize}
  color: ${(props) =>
    props.unknown
      ? props.theme.color.superLightError
      : props.theme.color.lightPrimary};
  cursor: pointer;
`;

export const ClientSearchEmailIcon = styled(MuiEmailIcon)`
  ${EssIconSize}
  color: ${(props) =>
    props.unknown
      ? props.theme.color.superLightError
      : props.theme.color.lightPrimary};
  cursor: pointer;
`;

export const RocketIcon = styled(RocketLaunchIcon)``;

export const BookedIcon = styled(FaRegCalendarCheck)`
  color: ${(props) =>
    props.secondary
      ? props.theme.color.secondaryHover
      : props.theme.color.primary};
`;

export const ChartIcon = styled(MdStackedLineChart)`
  ${AgentColorStyle}
  color: ${({ theme }) => theme.color.lightOrange};
`;

export const LightbulbIcon = styled(MuiLightbulbIcon)`
  ${AgentColorStyle}
  ${SmallIconSize}
  color: ${({ theme }) => theme.color.lightOrange};
`;

export const SyncIcon = styled(MuiSyncIcon)`
  cursor: pointer;
  color: ${(props) =>
    props.disabled
      ? props.theme.color.disabled
      : props.theme.color.lightPrimary};
  ${(props) =>
    props.small &&
    css`
      height: 20px;
      width: 20px;
    `}
  &:hover {
    color: ${(props) =>
      props.disabled ? props.theme.color.disabled : props.theme.color.primary};
  }
`;

export const OverhangIconContainer = styled.div`
  display: flex;
  position: absolute;
  right: -7px;
  top: -8px;
  border-radius: 50%;
  border: 1px solid
    ${(props) =>
      props.status === inProgressStatusKey
        ? props.theme.color.turquoise
        : props.status === completeStatusKey
        ? props.theme.color.brightGreen
        : props.theme.color.muiInput};
  background-color: white;
`;

export const OverhangInProgressIcon = styled(InProgressIcon)`
  ${ExtraSmallIconSize}
  color: ${({ theme }) => theme.color.turquoise};
`;

export const OverhangCheckmarkIcon = styled(CompletionCheckmark)`
  height: 14px !important;
  width: 14px !important;
`;

export const RefreshIcon = styled(MuiRefreshIcon)`
  cursor: pointer;
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.color.lightPrimary};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const MinusIcon = styled(MuiMinusIcon)``;

export const PlusIcon = styled(MuiAddIcon)``;

export const CreditCardIcon = styled(AddCardIcon)``;

export const OverhangText = styled(LightDarkExtraTinyText)`
  font-size: 11px;
`;
