import { useApolloClient, useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import {
  useAllBusinesses,
  useAllConglomerates,
  useAllCorporations,
} from '../../../api/hooks/enterprise';
import { CREATE_ENTERPRISE } from '../../../api/mutations/enterprise';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import FormPage from '../../../components/Form/FormPage';
import Input from '../../../components/Form/Input';
import { InputToggleContainer } from '../../../components/Modals/EditServiceModal/styled';
import LocationSearchBox from '../../../components/Search/LocationSearchBox/LocationSearchBox';
import {
  ColumnCenteredDiv,
  EmptyGapColumnCenteredDiv,
  InputContainer,
  InputContainerForMediumGap,
  InputTitleContainer,
  MediumDarkEssText,
  SMCheckbox,
  StartAlignedMediumDarkEssText,
  StartAlignedMediumDarkLargeTinyText,
  StartAlignedSmallText,
  TextInputWidthStyle,
} from '../../../styles/shared-styled-components';
import {
  businessTypes,
  dropdownInputType,
  franchiseBusinessType,
  integerInputType,
  multiLocationBusinessType,
  singleLocationBusinessType,
} from '../../../utils/constants';
import { valueIsEmpty } from '../../../utils/data';
import {
  checkIfIsValidUrl,
  getIdOfFirstEmptyField,
} from '../../../utils/input';
import { removeAllLocationSpecificCookies } from '../../../utils/routing';
import { stripAllSpacesFromString } from '../../../utils/string';
import { locationIdSelectedKey } from '../../../utils/user';
import { scrollElementIntoView } from '../../../utils/view';

const websiteId = 'website';
const websiteErrorText = 'Please enter a valid URL';

const CreateBusiness = () => {
  const apolloClient = useApolloClient();
  const { user, userLoading, removeCookie } = useContext(BaseContext);
  const navigate = useNavigate();

  const [createEnterpriseMutation, { loading: createEnterpriseLoading }] =
    useMutation(CREATE_ENTERPRISE);

  const [type, setType] = useState('');
  const [isConglomerateAssociate, setIsConglomerateAssociate] = useState(false);
  const [conglomerateId, setConglomerateId] = useState(null);
  const [conglomerateName, setConglomerateName] = useState('');
  const [corporationId, setCorporationId] = useState(null);
  const [corporationName, setCorporationName] = useState('');
  const [corporationNumLocations, setCorporationNumLocations] = useState('');
  const [businessId, setBusinessId] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [nickname, setNickname] = useState('');
  const [numLocations, setNumLocations] = useState('');
  const [locationName, setLocationName] = useState('');
  const [website, setWebsite] = useState('');
  const [websiteErrorMessage, setWebsiteErrorMessage] = useState();
  const [stagingAddress, setStagingAddress] = useState('');
  const [address, setAddress] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [timezone, setTimezone] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [submittedDataWithErrors, setSubmittedDataWithErrors] = useState(false);
  const [redirectLoading, setRedirectLoading] = useState(false);

  const { conglomerates, loading: conglomeratesLoading } = useAllConglomerates(
    {},
  );
  const conglomerateOptions = conglomerates?.map((c) => {
    return { key: c.id, label: c.name };
  });
  const { corporations, loading: corporationsLoading } = useAllCorporations({});
  const corporationOptions = [];
  corporations?.map((c) => {
    if (!conglomerateId || c.conglomerateId === conglomerateId) {
      return { key: c.id, label: c.name };
    }
  });
  const { businesses, loading: businessesLoading } = useAllBusinesses({});
  const businessOptions = [];
  businesses?.map((b) => {
    if (!corporationId || b.corporationId === corporationId) {
      businessOptions.push({ key: b.id, label: b.name });
    }
  });

  const createEnterprise = () => {
    const x = {
      type,
      conglomerateId,
      conglomerateName,
      corporationId,
      corporationName,
      numLocations: parseInt(numLocations),
      businessId,
      businessName,
      locationName,
      nickname,
      website,
      address,
      lat: lat?.toString(),
      lng: lng?.toString(),
      timezone,
      postalCode,
    };

    createEnterpriseMutation({
      variables: {
        data: {
          type,
          conglomerateId,
          conglomerateName,
          corporationId,
          corporationName,
          numLocations: parseInt(numLocations),
          businessId,
          businessName,
          locationName,
          nickname,
          website,
          address,
          lat: lat?.toString(),
          lng: lng?.toString(),
          timezone,
          postalCode,
        },
      },
      onCompleted: async (data) => {
        setRedirectLoading(true);
        const result = data.createEnterprise;
        const createdEnterprise = result.enterprise;
        const createdLocation = createdEnterprise?.location?.id;

        if (createdLocation) {
          const createdLocationId = createdLocation.id;
          const createdAgentId = createdEnterprise?.location?.agents?.[0]?.id;

          removeAllLocationSpecificCookies(removeCookie);
          localStorage.setItem(locationIdSelectedKey, createdLocationId);
          await apolloClient.clearStore();

          const pathToNavigateTo = createdAgentId
            ? Paths.agentAppConnection.replace(':agentId', createdAgentId)
            : Paths.callback;
          navigate(pathToNavigateTo);
        }
      },
    });
  };

  const onClickContinue = () => {
    const formattedWebsite = stripAllSpacesFromString(website);
    const isValidUrl = checkIfIsValidUrl(formattedWebsite);

    if (!continueEnabled) {
      const idWithError = getIdOfFirstEmptyField(inputs);
      scrollElementIntoView(document, idWithError);
      setSubmittedDataWithErrors(true);

      if (website && !isValidUrl) {
        setWebsiteErrorMessage(websiteErrorText);
      }

      return;
    }

    if (!isValidUrl) {
      scrollElementIntoView(document, websiteId);
      setWebsiteErrorMessage(websiteErrorText);

      return;
    }

    createEnterprise();
  };

  const typeInputs = [
    <InputContainerForMediumGap removeMargin>
      <InputTitleContainer addTopMargin>
        <StartAlignedSmallText>
          What type of business does this location belong to?
        </StartAlignedSmallText>
      </InputTitleContainer>
      <ColumnCenteredDiv>
        <Input
          id='type'
          value={type}
          onChange={(e) => {
            const previousType = type;
            const newType = e.target.value;

            setType(newType);

            if (newType === singleLocationBusinessType) {
              setNumLocations(1);
            } else if (previousType === singleLocationBusinessType) {
              setNumLocations();
            }
          }}
          type={dropdownInputType}
          options={businessTypes}
          isRequired={false}
          label={'Type'}
          containsError={valueIsEmpty(type) && submittedDataWithErrors}
          useExtraSmallGap
        />
      </ColumnCenteredDiv>
    </InputContainerForMediumGap>,
  ];
  const inputs = [
    ...typeInputs,
    <>
      {type !== singleLocationBusinessType ? (
        <>
          <InputContainerForMediumGap removeMargin>
            <InputTitleContainer>
              <StartAlignedSmallText>Business info</StartAlignedSmallText>
            </InputTitleContainer>
            <ColumnCenteredDiv>
              <InputToggleContainer>
                <MediumDarkEssText>Create new business</MediumDarkEssText>
                <SMCheckbox
                  checked={corporationId == null}
                  onClick={() => {
                    setCorporationId(corporationId == null ? '' : null);
                  }}
                />
              </InputToggleContainer>
              {corporationId != null && (
                <Input
                  id='corporation'
                  value={corporationId}
                  onChange={(e) => {
                    setCorporationId(e.target.value);
                  }}
                  type={dropdownInputType}
                  options={corporationOptions}
                  isRequired={false}
                  label={'Business to attach to'}
                  removeGap
                />
              )}
            </ColumnCenteredDiv>
            {corporationId == null ? (
              <ColumnCenteredDiv topMargin={6}>
                <InputToggleContainer>
                  <StartAlignedMediumDarkEssText smallLineHeight>
                    This is a subsidiary of a larger corporation
                  </StartAlignedMediumDarkEssText>
                  <SMCheckbox
                    checked={isConglomerateAssociate}
                    onClick={() => {
                      setIsConglomerateAssociate(!isConglomerateAssociate);
                    }}
                  />
                </InputToggleContainer>
                {isConglomerateAssociate ? (
                  <ColumnCenteredDiv
                    topMargin={10}
                    bottomMargin={10}
                  >
                    <InputToggleContainer>
                      <StartAlignedMediumDarkEssText smallLineHeight>
                        Create new corporation
                      </StartAlignedMediumDarkEssText>
                      <SMCheckbox
                        checked={conglomerateId == null}
                        onClick={() => {
                          setConglomerateId(conglomerateId == null ? '' : null);
                        }}
                      />
                    </InputToggleContainer>
                    {conglomerateId == null ? (
                      <Input
                        id='conglomerate-name'
                        value={conglomerateName}
                        onChange={(e) => {
                          setConglomerateName(e.target.value);
                        }}
                        label='Corporation name'
                        containsError={
                          valueIsEmpty(conglomerateName) &&
                          submittedDataWithErrors
                        }
                        removeGap
                      />
                    ) : (
                      <Input
                        id='conglomerate'
                        value={conglomerateId}
                        onChange={(e) => {
                          setConglomerateId(e.target.value);
                        }}
                        type={dropdownInputType}
                        options={conglomerateOptions}
                        isRequired={false}
                        label={'Corporation to attach business to'}
                        removeGap
                      />
                    )}
                  </ColumnCenteredDiv>
                ) : (
                  <></>
                )}
              </ColumnCenteredDiv>
            ) : (
              <></>
            )}
          </InputContainerForMediumGap>
          {corporationId == null && (
            <>
              <Input
                id='business-name'
                value={corporationName}
                onChange={(e) => {
                  setCorporationName(e.target.value);
                }}
                label='Business name'
                containsError={
                  valueIsEmpty(corporationName) && submittedDataWithErrors
                }
                removeGap
              />
              <Input
                id='num-locations'
                value={numLocations}
                onChange={(e) => {
                  setNumLocations(e.target.value);
                }}
                label='Number of total locations in business'
                renderCondition={
                  type === franchiseBusinessType ||
                  type === multiLocationBusinessType
                }
                type={integerInputType}
                containsError={
                  valueIsEmpty(numLocations) && submittedDataWithErrors
                }
                removeGap
              />
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>,
    <>
      {type !== singleLocationBusinessType ? (
        <>
          <InputContainerForMediumGap
            removeMargin
            topMargin={20}
          >
            <InputTitleContainer>
              <StartAlignedSmallText smallLineHeight>
                Portfolio Info
              </StartAlignedSmallText>
              <StartAlignedMediumDarkLargeTinyText smallLineHeight>
                A portfolio is a set of locations within the business, such as a
                batch of locations owned by a particular franchisee
              </StartAlignedMediumDarkLargeTinyText>
            </InputTitleContainer>
            <ColumnCenteredDiv topMargin={15}>
              <InputToggleContainer>
                <MediumDarkEssText>
                  Create new portfolio within the business
                </MediumDarkEssText>
                <SMCheckbox
                  checked={businessId == null}
                  onClick={() => {
                    setBusinessId(businessId == null ? '' : null);
                  }}
                />
              </InputToggleContainer>
              {businessId != null ? (
                <Input
                  id='business'
                  value={businessId}
                  onChange={(e) => {
                    setBusinessId(e.target.value);
                  }}
                  type={dropdownInputType}
                  options={businessOptions}
                  isRequired={false}
                  label={'Portfolio to attach to'}
                  removeGap
                />
              ) : (
                <Input
                  id='name'
                  value={businessName}
                  onChange={(e) => {
                    setBusinessName(e.target.value);
                  }}
                  label='Portfolio name (leave blank if corporate-owned)'
                  containsError={
                    valueIsEmpty(businessName) && submittedDataWithErrors
                  }
                  removeGap
                />
              )}
            </ColumnCenteredDiv>
          </InputContainerForMediumGap>
        </>
      ) : (
        <></>
      )}
    </>,
    <>
      {type === singleLocationBusinessType && (
        <>
          <Input
            id='location-name'
            value={locationName}
            onChange={(e) => {
              setLocationName(e.target.value);
            }}
            label='Name'
            containsError={
              valueIsEmpty(locationName) && submittedDataWithErrors
            }
            removeGap
          />
          <Input
            id='nickname'
            value={nickname}
            onChange={(e) => {
              setNickname(e.target.value);
            }}
            label='Name abbreviation'
            containsError={valueIsEmpty(nickname) && submittedDataWithErrors}
            removeGap
          />
          <Input
            id='num-locations'
            value={numLocations}
            onChange={(e) => {
              setNumLocations(e.target.value);
            }}
            label='Number of locations'
            renderCondition={
              type === franchiseBusinessType ||
              type === multiLocationBusinessType
            }
            type={integerInputType}
            containsError={
              valueIsEmpty(numLocations) && submittedDataWithErrors
            }
            removeGap
          />
        </>
      )}
    </>,
    <EmptyGapColumnCenteredDiv>
      <InputTitleContainer>
        <StartAlignedSmallText>Location info</StartAlignedSmallText>
      </InputTitleContainer>
      <Input
        id='location-name'
        value={locationName}
        onChange={(e) => {
          setLocationName(e.target.value);
        }}
        label='Location name'
        renderCondition={type !== singleLocationBusinessType}
        containsError={valueIsEmpty(locationName) && submittedDataWithErrors}
        useExtraSmallGap
      />
    </EmptyGapColumnCenteredDiv>,
    <InputContainer useExtraSmallGap={true}>
      <LocationSearchBox
        customLabel={
          type === singleLocationBusinessType ? 'Address' : 'Location address'
        }
        value={stagingAddress}
        onChange={(newStagingAddress) => {
          setStagingAddress(newStagingAddress);

          if (!newStagingAddress) {
            setAddress('');
            setLat('');
            setLng('');
            setTimezone('');
            setPostalCode('');
          }
        }}
        onEnter={(newAddress, newLat, newLng, newTimezone, newPostalCode) => {
          setStagingAddress(newAddress);
          setAddress(newAddress);
          setLat(newLat);
          setLng(newLng);
          setTimezone(newTimezone);
          setPostalCode(newPostalCode);
        }}
        customPopperMenuStyle={TextInputWidthStyle}
      />
    </InputContainer>,
    <Input
      id={websiteId}
      value={website}
      onChange={(e) => {
        setWebsite(e.target.value);
        if (websiteErrorMessage) {
          setWebsiteErrorMessage();
        }
      }}
      label={
        type === singleLocationBusinessType ? 'Website' : 'Location website'
      }
      containsError={valueIsEmpty(website) && submittedDataWithErrors}
      removeGap
      errorMessage={websiteErrorMessage}
    />,
  ];

  const continueEnabled =
    !valueIsEmpty(locationName) &&
    (corporationId == null || !!corporationId) &&
    !valueIsEmpty(type) &&
    (type === singleLocationBusinessType ||
      (type !== singleLocationBusinessType &&
        !valueIsEmpty(numLocations) &&
        !valueIsEmpty(locationName))) &&
    !valueIsEmpty(website) &&
    !valueIsEmpty(address) &&
    !valueIsEmpty(timezone);

  const loading = userLoading || createEnterpriseLoading || redirectLoading;

  return (
    user && (
      <FormPage
        title='New Location'
        isLoading={loading}
        inputs={type ? inputs : typeInputs}
        continueEnabled={continueEnabled}
        onContinue={onClickContinue}
        backEnabled={false}
        continueButtonText={'Create'}
      />
    )
  );
};

export default CreateBusiness;
