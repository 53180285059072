import React, { useEffect, useState } from 'react';
import {
  MediumPrimaryButton,
  MediumSecondaryButton,
  MLText,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainerScrollWrapper,
} from '../../../styles/shared-styled-components';
import {
  dropdownInputType,
  eitherKey,
  flexibleBooleanOptions,
} from '../../../utils/constants';
import { PromptInput } from '../../Training/shared-training-components';

const FilterAppointmentsModal = ({ isOpen, onClose, filter, onSave }) => {
  const [stagingFilter, setStagingFilter] = useState(filter);

  useEffect(() => {
    if (!stagingFilter && filter) {
      setStagingFilter(filter);
    }
  }, [filter]);

  const bookedBy =
    stagingFilter?.bookedBy != null ? stagingFilter.bookedBy : eitherKey;

  const onChange = (key, value) => {
    setStagingFilter({
      ...stagingFilter,
      [key]: value,
    });
  };

  const onClickSave = () => {
    onSave(stagingFilter);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer useSmallGap={true}>
        <MLText>Filter Appointments</MLText>
        <ModalInputsContainerScrollWrapper
          addTopMargin
          topPadding={20}
          columnFlex
          largeGap
        >
          <PromptInput
            label='Booked by AI agent'
            value={bookedBy}
            type={dropdownInputType}
            onChange={(e) => {
              onChange('bookedBy', e.target.value);
            }}
            options={flexibleBooleanOptions}
          />
        </ModalInputsContainerScrollWrapper>
        <ModalActions addTopMargin={true}>
          <MediumPrimaryButton
            onClick={() => onClickSave()}
            disabled={false}
          >
            Apply filter
          </MediumPrimaryButton>
          <MediumSecondaryButton
            disabled={bookedBy === eitherKey}
            onClick={() => {
              if (bookedBy !== eitherKey) {
                onSave({ bookedBy: eitherKey });
              }
            }}
          >
            Reset
          </MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default FilterAppointmentsModal;
