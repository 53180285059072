import { AgCharts } from 'ag-charts-react';
import styled from 'styled-components';
import { CenterFlexStyle } from '../../styles/shared-styled-components';

export const mediumDarkText = '#645a56';
export const primary = '#7161a0';
export const teal = '#83b0b6';
export const royalBlue = '#6c5efb';
export const lavender = '#a482c2';
export const skyBlue = '#6fb6d6';
export const mauve = '#b984a0';
export const amethyst = '#7e6ca8';
export const lightBlue = '#83d1e2';
const violet = '#8b77a7';
export const steelBlue = '#5e83b0';
export const purple1 = '#7161a0';
export const purple2 = '#6c5efb';
export const purple3 = '#a482c2';
const secondary = '#b984a0';
const dodgerBlue = '#3498db';
export const alizarinCrimson = '#e74c3c';
export const emerald = '#2ecc71';
export const orange = '#f39c12';
export const turquoise = '#1abc9c';
export const carrot = '#e67e22';
export const wetAsphalt = '#34495e';
export const pumpkin = '#d35400';
export const nephritis = '#27ae60';

export const colorScheme = [
  skyBlue,
  lightBlue,
  dodgerBlue,
  purple2,
  teal,
  primary,
  lavender,
  nephritis,
  turquoise,
  steelBlue,
  emerald,
  mauve,
];

export const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 15px;
  padding: 0px 5px;
`;

export const StyledChart = styled(AgCharts)``;

export const ChartTitleContainer = styled.div`
  position: absolute;
  top: -25px;
  left: ${(props) => (props.left ? `calc(50% - 35px)` : '50%')};
  transform: translate(-50%, 0%);
`;

export const TooltipWrapper = styled.div`
  padding: 15px 30px;
  border-radius: 10px;
  background-color: white;
  ${CenterFlexStyle}
`;

export const TooltipText = styled.div`
  font-size: 14.5px;
  font-family: 'SeldonFont';
`;

export const baseOptionsWithoutLegend = {
  chart: {
    borderRadius: 10,
    height: '100%',
  },
  axes: [
    {
      type: 'category',
      position: 'bottom',
      label: {
        fontSize: 12,
        fontFamily: 'SeldonFont',
        // rotation: 0, // Keep the labels horizontal
        formatter: (params) => {
          // Truncate to the first 5 characters
          const value = params.value;
          return value.length > 7 ? `${value.slice(0, 7)}...` : value;
        },
      },
      gridLine: {
        enabled: false,
      },
    },
    {
      type: 'number',
      position: 'left',
      label: {
        fontSize: 12,
        fontFamily: 'SeldonFont',
      },
      gridLine: {
        enabled: false,
      },
    },
  ],
  tooltip: {
    position: {
      type: 'pointer',
      xOffset: 70,
      yOffset: 140,
    },
  },
};
export const baseOptions = {
  ...baseOptionsWithoutLegend,
  legend: {
    position: 'right',
    item: {
      label: {
        fontSize: 14,
        fontFamily: 'SeldonFont',
      },
      marker: {
        size: 10,
        shape: 'circle',
      },
    },
  },
};

export const basePalette = {
  fills: ['#9c90bd', 'rgb(37, 179, 77)', '#d8504d'],
  strokes: ['gray'],
};
