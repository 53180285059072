import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useCheckoutSession,
  useLocationPaymentPlans,
} from '../../../api/hooks/payment';
import { CREATE_CHECKOUT_SESSION } from '../../../api/mutations/payment';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import AssignPaymentPlanModal from '../../../components/Modals/AssignPaymentPlanModal';
import CheckoutSessionModal from '../../../components/Modals/CheckoutSessionModal/CheckoutSessionModal';
import Header from '../../../components/NavBar/Header';
import Snackbar from '../../../components/Snackbar';
import MetaSetter from '../../../components/Utils/MetaSetter';
import Paths from '../../../Paths';
import {
  BaseCard,
  CardActionsContainer,
  CardHeaderContainer,
  CenteredDiv,
  ColumnCenteredDiv,
  ColumnDivWithGap,
  CompleteButtonCheckmarkIcon,
  ContentContainer,
  DarkEssText,
  EmptyGapColumnCenteredDiv,
  ErrorTextExclamationIcon,
  ExtraSmallSecondaryButton,
  LargeNoDataIcon,
  MediumDarkSmallText,
  PageContainer,
  PageTitleText,
  SmallAddIcon,
  StartAlignedErrorLargeTinyText,
  StartAlignedFlexDiv,
  StartAlignedMediumDarkLargeTinyText,
  StartAlignedSuccessLargeTinyText,
  TinyPrimaryButton,
} from '../../../styles/shared-styled-components';
import {
  completeStatusKey,
  currencyMap,
  oneTimeFeeKey,
  paymentTypeMap,
  superAdminRole,
  textSeparatorChar,
} from '../../../utils/constants';
import { getTimestamp } from '../../../utils/date';
import { formatNumber } from '../../../utils/numbers';

const UnpaidText = (
  <StartAlignedErrorLargeTinyText verticallyCentered>
    Unpaid <ErrorTextExclamationIcon />
  </StartAlignedErrorLargeTinyText>
);

const Billing = () => {
  const navigate = useNavigate();
  const { user, drawerOpen, drawerExpanded } = useContext(BaseContext);
  const isSuperAdmin = user?.role === superAdminRole;

  const [searchParams] = useSearchParams();
  const checkoutSessionId = searchParams?.get('session');

  const { plans, loading: plansLoading, refetch } = useLocationPaymentPlans({});
  const hasPlans = !!plans?.length;

  const [createCheckoutSessionMutation] = useMutation(CREATE_CHECKOUT_SESSION);

  const { data: session, loading: sessionLoading } = useCheckoutSession({
    id: checkoutSessionId,
  });

  const [assignPlanModalOpen, setAssignPlanModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();

  let sessionRes;
  if (checkoutSessionId && session) {
    const paymentProductId = session?.paymentProductId;
    const matchingPlan = plans.find((p) => p.productId === paymentProductId);
    if (matchingPlan) {
      sessionRes = {
        name: matchingPlan.name,
        status: matchingPlan.status,
        updatedAt: matchingPlan.updatedAt,
      };
    }
  }

  const onBeginCheckout = async (productSourceId) => {
    setLoading(true);
    createCheckoutSessionMutation({
      variables: {
        productSourceId,
      },
      onCompleted: async (data) => {
        const res = data.createCheckoutSession;
        const url = res?.url;
        if (url) {
          window.location.href = url;
        } else {
          setLoading(false);
          setSnackbarMessage(
            `No payment plan found, please contact your LiveIQ rep`,
          );
        }
      },
    });
  };

  const onAssignPaymentPlan = async () => {
    setSnackbarMessage(`Plan assignments saved`);
    await refetch();
  };

  if (plansLoading || loading || sessionLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <MetaSetter
        title={`Billing`}
        description={`Billing`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <PageTitleText>Billing</PageTitleText>
          <ColumnCenteredDiv>
            {hasPlans ? (
              <>
                {isSuperAdmin && (
                  <StartAlignedFlexDiv>
                    <ExtraSmallSecondaryButton
                      onClick={() => setAssignPlanModalOpen(true)}
                    >
                      <SmallAddIcon /> Modify assigned payment plans
                    </ExtraSmallSecondaryButton>
                  </StartAlignedFlexDiv>
                )}
                {plans.map((p) => {
                  const currency = currencyMap[p.currency];
                  const paid = p.status === completeStatusKey;
                  const timestamp = p.lastChargedAt
                    ? getTimestamp(p.lastChargedAt, false, false, true)
                    : '';
                  return (
                    <BaseCard>
                      <ColumnDivWithGap>
                        <CardHeaderContainer>
                          <DarkEssText>
                            {p.name} {textSeparatorChar} {currency.prefix}
                            {formatNumber(p.amount)} {currency.suffix}
                          </DarkEssText>
                        </CardHeaderContainer>
                        <StartAlignedMediumDarkLargeTinyText>
                          {paymentTypeMap[p.type]}
                        </StartAlignedMediumDarkLargeTinyText>
                        {paid ? (
                          p.type === oneTimeFeeKey ? (
                            <StartAlignedSuccessLargeTinyText
                              verticallyCentered
                              addGap
                            >
                              Paid ({timestamp}){' '}
                              <CompleteButtonCheckmarkIcon small />
                            </StartAlignedSuccessLargeTinyText>
                          ) : (
                            <StartAlignedSuccessLargeTinyText
                              verticallyCentered
                              addGap
                            >
                              {timestamp
                                ? `Last charged ${timestamp}{' '}`
                                : 'Paid, last charged date unknown'}
                              <CompleteButtonCheckmarkIcon small />
                            </StartAlignedSuccessLargeTinyText>
                          )
                        ) : (
                          UnpaidText
                        )}
                      </ColumnDivWithGap>
                      {!paid && (
                        <CardActionsContainer>
                          <TinyPrimaryButton
                            largePadding
                            onClick={() => onBeginCheckout(p.sourceId)}
                          >
                            Proceed to checkout
                          </TinyPrimaryButton>
                        </CardActionsContainer>
                      )}
                    </BaseCard>
                  );
                })}
              </>
            ) : (
              <>
                <EmptyGapColumnCenteredDiv>
                  <LargeNoDataIcon />
                  <MediumDarkSmallText>
                    {isSuperAdmin ? (
                      <>No payment plans assigned to location</>
                    ) : (
                      <>
                        No payment plans assigned to your location, contact
                        LiveIQ support to set this up
                      </>
                    )}
                  </MediumDarkSmallText>
                </EmptyGapColumnCenteredDiv>
                <CenteredDiv>
                  <ExtraSmallSecondaryButton
                    onClick={() => setAssignPlanModalOpen(true)}
                  >
                    <SmallAddIcon /> Modify assigned payment plans
                  </ExtraSmallSecondaryButton>
                </CenteredDiv>
              </>
            )}
          </ColumnCenteredDiv>
        </ContentContainer>
      </PageContainer>
      <CheckoutSessionModal
        session={sessionRes}
        isOpen={!!sessionRes}
        onClose={() => {
          navigate(Paths.billing);
        }}
      />
      <AssignPaymentPlanModal
        isOpen={assignPlanModalOpen}
        onClose={() => setAssignPlanModalOpen(false)}
        assignedPlans={plans}
        onAssign={onAssignPaymentPlan}
      />
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Billing;
