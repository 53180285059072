import { useMutation } from '@apollo/client';
import { default as React, useState } from 'react';
import { BULK_EDIT_SERVICE_PROPERTIES } from '../../../api/mutations/training';
import {
  DarkEssText,
  Dropdown,
  DropdownLabel,
  Form,
  MLText,
  MediumPrimaryButton,
  MenuItem,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
} from '../../../styles/shared-styled-components';
import {
  contactTypesForServices,
  createdSessionTypeTypesLabelMap,
  dollarInputType,
  dropdownInputType,
  integerInputType,
  priceSettingsLabelMap,
  requiresConsultationBookableSessionTypeStatus,
  serviceCategoryOptions,
  sessionTypeBookableStatusLabelMap,
  sessionTypeUnitsLabelMap,
} from '../../../utils/constants';
import { safeParseInt } from '../../../utils/numbers';
import Input from '../../Form/Input';
import { ExpandedBookableStatusContainer } from '../EditServiceModal/styled';

const EditServicesInBulkModal = ({
  editingServices,
  isOpen,
  onClose,
  onSave,
  calendars,
}) => {
  const [bulkEditServicePropertiesMutation, { loading }] = useMutation(
    BULK_EDIT_SERVICE_PROPERTIES,
  );

  const ids = Object.keys(editingServices);

  const [calendar, setCalendar] = useState('');
  const [corpusCategory, setCorpusCategory] = useState('');
  const [clientType, setClientType] = useState('');
  const [price, setPrice] = useState('');
  const [priceSetting, setPriceSetting] = useState('');
  const [units, setUnits] = useState('');
  const [durationMinutes, setDurationMinutes] = useState('');
  const [displayDurationMinutes, setDisplayDurationMinutes] = useState('');
  const [bookingStatus, setBookingStatus] = useState('');
  const [type, setType] = useState('');
  const [numberOfPeopleToBook, setNumberOfPeopleToBook] = useState('');
  const [followUpDays, setFollowUpDays] = useState(null);
  const [clientDepositAmount, setClientDepositAmount] = useState(null);
  const [leadDepositAmount, setLeadDepositAmount] = useState(null);

  const handleClose = () => {
    setCalendar('');
    setCorpusCategory('');
    setClientType('');
    setPrice('');
    setPriceSetting('');
    setUnits('');
    setDurationMinutes('');
    setDisplayDurationMinutes('');
    setBookingStatus('');
    setType('');
    setNumberOfPeopleToBook('');
    setFollowUpDays('');
    setClientDepositAmount('');
    setLeadDepositAmount('');
    onClose();
  };

  const onClickSave = () => {
    bulkEditServicePropertiesMutation({
      variables: {
        properties: {
          category: corpusCategory,
          clientType,
          price: safeParseInt(price),
          priceSetting,
          units,
          durationMinutes: parseInt(durationMinutes),
          displayDurationMinutes: parseInt(displayDurationMinutes),
          type,
          bookableStatus: bookingStatus,
          numberOfPeopleToBook: safeParseInt(numberOfPeopleToBook),
          followUpDays: safeParseInt(followUpDays),
          clientDepositAmount,
          leadDepositAmount,
          locationCalendarId: calendar,
        },
        ids,
      },
      onCompleted: async (data) => {
        const success = data.bulkEditServiceProperties?.success;
        handleClose();
        await onSave(success);
      },
    });
  };

  const saveEnabled = true;
  const numEditing = ids?.length;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <ModalContainer useSmallGap={true}>
        <MLText>Bulk Editing Services ({numEditing})</MLText>
        <DarkEssText>
          Note: Any properties you leave blank will not change,
          <br></br>
          meaning the existing values for the services you're editing will stay
          the same
        </DarkEssText>
        <ModalInputsContainer>
          <ModalInputsContainerScrollWrapper>
            <Input
              id={'corpuscategory'}
              value={corpusCategory}
              label={'AI Model Corpus Category'}
              onChange={(e) => setCorpusCategory(e.target.value)}
              options={serviceCategoryOptions}
              type={dropdownInputType}
            />
            <Input
              value={clientType}
              label='Client type'
              onChange={(e) => {
                setClientType(e.target.value);
              }}
              useSmallText
              type={dropdownInputType}
              useSmallGap
              options={contactTypesForServices}
            />
            <Input
              id={'price'}
              value={price}
              label={'Base price'}
              onChange={(e) => setPrice(e.target.value)}
              type={dollarInputType}
            />
            <Input
              id={'price-setting'}
              value={priceSetting}
              label={'Price type'}
              onChange={(e) => setPriceSetting(e.target.value)}
              type={dropdownInputType}
              options={priceSettingsLabelMap}
              useSmallGap
            />
            <Input
              id={'units'}
              value={units}
              label={'Pricing units'}
              onChange={(e) => setUnits(e.target.value)}
              type={dropdownInputType}
              options={sessionTypeUnitsLabelMap}
            />
            <Input
              id={'display-duration'}
              value={displayDurationMinutes}
              label={'Minutes to schedule for the client'}
              onChange={(e) => setDisplayDurationMinutes(e.target.value)}
              type={integerInputType}
            />
            <Input
              id={'duration'}
              value={durationMinutes}
              label={'Minutes to schedule for the staff (includes prep time)'}
              onChange={(e) => setDurationMinutes(e.target.value)}
              type={integerInputType}
            />
            <Input
              id={'numpeople'}
              value={numberOfPeopleToBook}
              label={'Number of people per session'}
              onChange={(e) => setNumberOfPeopleToBook(e.target.value)}
              type={integerInputType}
            />
            <Input
              id={'type'}
              value={type}
              label={'Service type'}
              onChange={(e) => setType(e.target.value)}
              options={createdSessionTypeTypesLabelMap}
              type={dropdownInputType}
            />
            {calendars?.length ? (
              <Input
                id={'calendar'}
                value={calendar}
                label={'Calendar'}
                onChange={(e) => setCalendar(e.target.value)}
                options={calendars}
                type={dropdownInputType}
              />
            ) : (
              <></>
            )}
            <ExpandedBookableStatusContainer>
              <Form autoHorizontalMargins>
                <DropdownLabel>Booking status</DropdownLabel>
                <Dropdown
                  value={bookingStatus}
                  label={'Booking status'}
                  options={sessionTypeBookableStatusLabelMap.filter(
                    (o) =>
                      o.key !== requiresConsultationBookableSessionTypeStatus,
                  )}
                  onChange={(e) => {
                    const updatedStatus = e.target.value;
                    setBookingStatus(updatedStatus);
                  }}
                >
                  {sessionTypeBookableStatusLabelMap.map(
                    (bookableStatusObj) => {
                      const { key, label } = bookableStatusObj;
                      return (
                        <MenuItem
                          value={key}
                          key={key}
                        >
                          {label}
                        </MenuItem>
                      );
                    },
                  )}
                </Dropdown>
              </Form>
            </ExpandedBookableStatusContainer>
            <Input
              id={'followupdays'}
              value={followUpDays}
              label={'Follow-up days'}
              onChange={(e) => setFollowUpDays(e.target.value)}
              type={integerInputType}
            />
            <Input
              id={'client-deposit'}
              value={clientDepositAmount}
              label={'Client deposit amount'}
              onChange={(e) => setClientDepositAmount(e.target.value)}
              type={integerInputType}
              useSmallGap
            />
            <Input
              id={'lead-deposit'}
              value={leadDepositAmount}
              label={'Lead deposit amount'}
              onChange={(e) => setLeadDepositAmount(e.target.value)}
              type={integerInputType}
              useSmallGap
            />
          </ModalInputsContainerScrollWrapper>
        </ModalInputsContainer>
        <ModalActions addTopMargin={true}>
          <MediumPrimaryButton
            onClick={onClickSave}
            disabled={!saveEnabled}
          >
            Save
          </MediumPrimaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default EditServicesInBulkModal;
