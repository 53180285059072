import gql from 'graphql-tag';

export const INITIALIZE_APP = gql`
  mutation initializeApp($appName: AppName!, $asEmr: Boolean!) {
    initializeApp(appName: $appName, asEmr: $asEmr) {
      success
    }
  }
`;

export const REMOVE_APP_CONNECTION = gql`
  mutation removeAppConnection($appName: AppName) {
    removeAppConnection(appName: $appName)
  }
`;

export const STORE_ZENOTI_API_KEY = gql`
  mutation storeZenotiApiKey($apiKey: String!, $centerName: String) {
    storeZenotiApiKey(apiKey: $apiKey, centerName: $centerName) {
      success
    }
  }
`;

export const STORE_TWILIO_ACCESS_TOKEN = gql`
  mutation storeTwilioAccessToken(
    $managedByLiveIq: Boolean!
    $number: String!
    $accessToken: String
  ) {
    storeTwilioAccessToken(
      managedByLiveIq: $managedByLiveIq
      number: $number
      accessToken: $accessToken
    ) {
      success
    }
  }
`;
