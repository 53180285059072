import React, { useContext, useState } from 'react';
import {
  DarkMLText,
  InputContainerForSmallGap,
  LightDarkEssText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SmallCheckbox,
  StartAlignedInputTextContainer,
} from '../../../styles/shared-styled-components';
import { checkIfUserIsSuperAdmin } from '../../../utils/user';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import Input from '../../Form/Input';

const CreateFAQModal = ({ isOpen, onClose, category, onCreate }) => {
  const { user } = useContext(BaseContext);

  const isSuperAdmin = checkIfUserIsSuperAdmin(user);

  const [title, setTitle] = useState();
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const [universal, setUniversal] = useState(false);

  const handleClose = () => {
    setTitle();
    setQuestion();
    setAnswer();
    onClose();
  };

  const handleCreate = () => {
    onCreate(question, answer, universal);
    handleClose();
  };

  const categoryName = category?.name;

  const createEnabled = !!question && !!answer;

  return (
    categoryName && (
      <>
        <Modal
          open={isOpen}
          onClose={handleClose}
        >
          <ModalContainer>
            <DarkMLText>Create {categoryName} FAQ</DarkMLText>
            <ModalInputsContainer>
              <ModalInputsContainerScrollWrapper>
                <Input
                  id='question'
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                  label={'Question'}
                  isRequired={false}
                  multiline
                  numRows={3}
                  useExtraSmallGap
                />
                <Input
                  id='answer'
                  value={answer}
                  onChange={(e) => {
                    setAnswer(e.target.value);
                  }}
                  label={'Answer'}
                  isRequired={false}
                  multiline
                  numRows={6}
                  useSmallGap
                />
                {isSuperAdmin && (
                  <InputContainerForSmallGap>
                    <StartAlignedInputTextContainer>
                      <LightDarkEssText>Create universally</LightDarkEssText>
                      <SmallCheckbox
                        checked={universal}
                        onChange={() => {
                          setUniversal(!universal);
                        }}
                      />
                    </StartAlignedInputTextContainer>
                  </InputContainerForSmallGap>
                )}
              </ModalInputsContainerScrollWrapper>
            </ModalInputsContainer>
            <ModalActions>
              <MediumPrimaryButton
                disabled={!createEnabled}
                onClick={handleCreate}
              >
                Create
              </MediumPrimaryButton>
              <MediumSecondaryButton onClick={handleClose}>
                Close
              </MediumSecondaryButton>
            </ModalActions>
          </ModalContainer>
        </Modal>
      </>
    )
  );
};

export default CreateFAQModal;
