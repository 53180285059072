import { MessageOutlined, PieChartTwoTone } from '@mui/icons-material';
import React from 'react';
import { TbCloudDataConnection } from 'react-icons/tb';
import {
  FiveMinuteSetupContainer,
  FiveMinuteSetupHeaderText,
  FiveMinuteSetupMediaContainer,
  FiveMinuteSetupMediaHeaderText,
  FiveMinuteSetupMediaIcon,
  FiveMinuteSetupMediaItem,
  FiveMinuteSetupMediaSubText,
  FiveMinuteSetupSubText,
} from '../styles/style-FiveMinuteSetup';
import Integrations from './Integrations';

const stepsData = [
  {
    icon: <TbCloudDataConnection />,
    header: 'Connect to your EMR / CRM',
    subText:
      '1-click connection to your EMR & CRM, as easy as "Sign-in with Google"',
  },
  {
    icon: <MessageOutlined />,
    header: 'Train the AI agents on your business',
    subText:
      'Instruct the agents on all your booking flows, so they can understand your business as well as you do',
  },
  {
    icon: <PieChartTwoTone />,
    header: 'Launch your Campaigns',
    subText:
      'Customize which contacts to target, and watch the bookings flow in',
  },
];

const FiveMinuteSetup = ({ darkMode }) => {
  return (
    <div id='five-minute-setup'>
      <FiveMinuteSetupContainer dark={darkMode}>
        <FiveMinuteSetupHeaderText dark={darkMode}>
          5-Minute Setup
        </FiveMinuteSetupHeaderText>
        <FiveMinuteSetupSubText dark={darkMode}>
          Onboard your new superstar employee in 3 easy steps
        </FiveMinuteSetupSubText>
        <FiveMinuteSetupMediaContainer>
          {stepsData.map((step, index) => (
            <FiveMinuteSetupMediaItem key={index}>
              <FiveMinuteSetupMediaIcon>{step.icon}</FiveMinuteSetupMediaIcon>
              <FiveMinuteSetupMediaHeaderText dark={darkMode}>
                {step.header}
              </FiveMinuteSetupMediaHeaderText>
              <FiveMinuteSetupMediaSubText dark={darkMode}>
                {step.subText}
              </FiveMinuteSetupMediaSubText>
            </FiveMinuteSetupMediaItem>
          ))}
        </FiveMinuteSetupMediaContainer>
        <Integrations />
      </FiveMinuteSetupContainer>
    </div>
  );
};

export default FiveMinuteSetup;
