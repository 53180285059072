import React from 'react';
import {
  CenteredDiv,
  EmptyGapColumnCenteredDiv,
  MediumDarkExtraSmallText,
  SmallCheckbox,
} from '../../../../styles/shared-styled-components';
import {
  agnosticKey,
  depositAmountOptions,
  dollarInputType,
  dropdownInputType,
  flatRateType,
  holdKey,
  percentInputType,
  timeslotIntervalOptions,
} from '../../../../utils/constants';
import { safeParseInt } from '../../../../utils/numbers';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  PromptCheckboxContainer,
  PromptCheckboxContentContainer,
  PromptContainer,
  PromptInput,
  PromptsSection,
  PromptSubtitleText,
  PromptTitleText,
  SpacedPromptCheckboxContainer,
} from '../../shared-training-components';

const Booking = ({ policy, onChange, loading }) => {
  const depositPolicy = policy?.deposit || {};
  const bookingPolicy = policy?.bookings || {};
  const bookAppointments =
    bookingPolicy?.bookAppointments != null
      ? bookingPolicy.bookAppointments
      : true;
  const sendReminders =
    bookingPolicy?.sendReminders != null ? bookingPolicy.sendReminders : false;

  const requiredForLeads = depositPolicy?.requiredForLeads || '';
  const requiredForClients = depositPolicy?.requiredForClients || '';
  const requiredForMembers = depositPolicy?.requiredForMembers || '';
  const cancellationEnforced = depositPolicy?.cancellationEnforced || false;
  const type = depositPolicy?.type || '';
  const amount = depositPolicy?.amount || '';
  const formUrl = depositPolicy?.formUrl || '';
  const bufferHoursRequired = depositPolicy?.bufferHoursRequired || '';
  const timeslotIntervalPreference =
    bookingPolicy?.timeslotIntervalPreference || agnosticKey;

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  const depositApplied = !(
    !requiredForLeads &&
    !requiredForClients &&
    !requiredForMembers
  );

  return (
    <PromptsSection>
      <EmptyGapColumnCenteredDiv>
        <CenteredDiv>
          <PromptTitleText>Book appointments</PromptTitleText>
          <SmallCheckbox
            checked={bookAppointments}
            onChange={(e) => {
              onChange('bookings', {
                ...bookingPolicy,
                bookAppointments: e.target.checked,
              });
            }}
          />
        </CenteredDiv>
        <CenteredDiv>
          <PromptTitleText>Send transactional reminder texts</PromptTitleText>
          <SmallCheckbox
            checked={sendReminders}
            onChange={(e) => {
              onChange('bookings', {
                ...bookingPolicy,
                sendReminders: e.target.checked,
              });
            }}
          />
        </CenteredDiv>
      </EmptyGapColumnCenteredDiv>
      {bookAppointments && (
        <>
          <PromptContainer>
            <PromptTitleText>
              Select who your deposit policy should apply to
            </PromptTitleText>
            <PromptCheckboxContainer>
              <PromptCheckboxContentContainer>
                <PromptSubtitleText>Applies to leads</PromptSubtitleText>
                <SmallCheckbox
                  checked={requiredForLeads}
                  onClick={(e) => {
                    onChange('deposit', {
                      ...depositPolicy,
                      requiredForLeads: e.target.checked,
                    });
                  }}
                />
              </PromptCheckboxContentContainer>
              <PromptCheckboxContentContainer>
                <PromptSubtitleText>Applies to clients</PromptSubtitleText>
                <SmallCheckbox
                  checked={requiredForClients}
                  onChange={(e) => {
                    onChange('deposit', {
                      ...depositPolicy,
                      requiredForClients: e.target.checked,
                    });
                  }}
                />
              </PromptCheckboxContentContainer>
              <PromptCheckboxContentContainer>
                <PromptSubtitleText>Applies to members</PromptSubtitleText>
                <SmallCheckbox
                  checked={requiredForMembers}
                  onChange={(e) => {
                    onChange('deposit', {
                      ...depositPolicy,
                      requiredForMembers: e.target.checked,
                    });
                  }}
                />
              </PromptCheckboxContentContainer>
            </PromptCheckboxContainer>
          </PromptContainer>
          <PromptContainer>
            <PromptTitleText>Deposit type</PromptTitleText>
            {!depositApplied ? (
              <MediumDarkExtraSmallText>None</MediumDarkExtraSmallText>
            ) : (
              <>
                <PromptInput
                  value={type}
                  type={dropdownInputType}
                  options={depositAmountOptions}
                  onChange={(e) => {
                    const updatedType = e.target.value;
                    onChange('deposit', {
                      ...depositPolicy,
                      type: updatedType,
                      amount: updatedType === holdKey ? 0 : amount || null,
                    });
                  }}
                />
                <SpacedPromptCheckboxContainer
                  start
                  marginTop={8}
                >
                  <PromptSubtitleText>
                    Cancel appointment if deposit expired
                  </PromptSubtitleText>
                  <SmallCheckbox
                    checked={cancellationEnforced}
                    onChange={(e) => {
                      onChange('deposit', {
                        ...depositPolicy,
                        cancellationEnforced: e.target.checked,
                      });
                    }}
                  />
                </SpacedPromptCheckboxContainer>
              </>
            )}
          </PromptContainer>
          {depositApplied && (
            <>
              {(type === flatRateType || type === percentInputType) && (
                <PromptContainer>
                  <PromptTitleText>Amount required</PromptTitleText>
                  <PromptInput
                    value={amount}
                    type={dollarInputType}
                    onChange={(e) => {
                      onChange('deposit', {
                        ...depositPolicy,
                        amount: safeParseInt(e.target.value),
                      });
                    }}
                  />
                </PromptContainer>
              )}
              <PromptContainer>
                <PromptTitleText>Form URL</PromptTitleText>
                <PromptInput
                  value={formUrl}
                  onChange={(e) => {
                    onChange('deposit', {
                      ...depositPolicy,
                      formUrl: e.target.value,
                    });
                  }}
                />
              </PromptContainer>
            </>
          )}
          <PromptContainer>
            <PromptTitleText>Notice hours required for booking</PromptTitleText>
            <PromptInput
              value={bufferHoursRequired}
              onChange={(e) => {
                onChange('deposit', {
                  ...depositPolicy,
                  bufferHoursRequired: safeParseInt(e.target.value),
                });
              }}
            />
          </PromptContainer>
          <PromptContainer>
            <PromptTitleText>Timeslot interval preference</PromptTitleText>
            <PromptInput
              value={timeslotIntervalPreference}
              onChange={(e) => {
                onChange('bookings', {
                  ...bookingPolicy,
                  timeslotIntervalPreference: e.target.value,
                });
              }}
              type={dropdownInputType}
              options={timeslotIntervalOptions}
            />
          </PromptContainer>
        </>
      )}
    </PromptsSection>
  );
};

export default Booking;
